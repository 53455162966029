import Button from 'component/Buttons/Button'
import InputText from 'component/Form/Inputs/InputText'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import i18next from 'i18next'
import React, { FC, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { importPrice } from 'store/Onboarding'
import { useTypedSelector } from 'store/store'
import { getError, getSymbolFromCurrency, validationPatterns } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

const ImportMenu: FC = (props: any) => {
  const history = useHistory()
  const dispatch  = useDispatch()
  const { t } = useTranslation(['onboarding'])

  const backUrl = RouteUrls.ImportMenu
  const importMenuUrl = useSelector(selectors.onboarding.importUrl)

  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 4, 'import'))

  const { handleSubmit, register, errors, clearErrors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    i18next.on('languageChanged', () => {
      clearErrors()
    })
  }, [clearErrors])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  const onChangeInputText = useCallback((id) => {
    clearErrors()
  }, [clearErrors])
  
  const onSubmit = useCallback((data) => {
    dispatch(actions.onboarding.triggerSetImportMenuUrl({ url: data.importMenuUrl }))
    history.push(RouteUrls.Register)
  }, [dispatch, history])


  return (
    <LayoutSideBar pageName="import-menu-url">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__content--form-onboarding">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <span className="label label--purple">{importPrice} {getSymbolFromCurrency('JPA')}</span>
              <h1 className="ft-900 mt-2 mb-2">{t('onboarding:import:importUrl:title')}</h1>
              <p>{t('onboarding:import:importUrl:desc')}</p>
              <p className="mt-1 mb-4">※ {t('onboarding:import:importUrl:mention')}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputText 
                maxLength={80} 
                placeholder={t('onboarding:import:importUrl:inputUrl')}
                defaultValue={importMenuUrl} 
                handleChange={onChangeInputText} 
                className="input--material mb-1" 
                label={t('onboarding:import:importUrl:inputUrl')} 
                name="importMenuUrl" 
                inputRef={register({
                  pattern: {
                    value: validationPatterns.url,
                    message: getError('url')
                  }
                })} 
                error={errors.importMenuUrl} />
              <div className="ft-center">
                <Button className="mt-3" isPrimary={true} type="submit" text={t('onboarding:import:next')} /> 
              </div>
            </form>
          </div>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default ImportMenu
