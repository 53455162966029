import BannerError from 'component/Banners/BannerError'
import Button from 'component/Buttons/Button'
import InputCheckbox from 'component/Form/Inputs/InputCheckbox'
import InputSelect from 'component/Form/Inputs/InputSelect'
import InputText from 'component/Form/Inputs/InputText'
import TopBar from 'component/Headers/TopBar'
import BottomBar from 'component/Footers/BottomBar';
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
// import ModalLegals from 'component/Modals/ModalLegals'
import i18next from 'i18next'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { CREATION_CHOICE } from 'store/Onboarding'
import { useTypedSelector } from 'store/store'
import { getError, listPrefectures, validationPatterns } from 'utils/helpers'
// import { ModalContext } from 'utils/hooks/ModalBehavior'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { RouteUrls } from 'utils/routers/constants'

const Register: FC = (props: any) => {
  const history = useHistory()
  const dispatch  = useDispatch()
  const { t } = useTranslation(['login', 'onboarding','account'])

  const { showStickyBanner } = useContext(StickyBannerContext)
  // const { showModal } = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState(false)
  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 5))

  const creationOption = useSelector(selectors.onboarding.creationOption)
  // const importMenu = useSelector(selectors.onboarding.importMenu)
  // const backUrl = useMemo(() => {
  //   if (creationOption === CREATION_CHOICE.scratch) return RouteUrls.CreationOption
  //   if (importMenu === IMPORT_MENU_CHOICE.files) return RouteUrls.ImportMenuFiles
  //   if (importMenu === IMPORT_MENU_CHOICE.photos) return RouteUrls.ImportMenuPhotos
  //   return RouteUrls.ImportMenuUrl
  // }, [creationOption, importMenu])
    const backUrl = RouteUrls.BusinessType;

  const business = useSelector(selectors.onboarding.business)
  const user = useSelector(selectors.onboarding.user)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prefectures = useMemo(()=>listPrefectures(i18next.language), [i18next.language])
  const prefecturesOptions = useMemo(() => prefectures.map((p) => ({value: p.value, label: p.i18n})), [prefectures])

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const defaultPrefectureOption = useMemo(() => prefecturesOptions.find((p) => p.value === formMethods.getValues('business.prefecture')), [prefecturesOptions, formMethods])

  const errors = useMemo(() => formMethods.errors, [formMethods])
  const register = useMemo(() => formMethods.register, [formMethods])
  const setError = useMemo(() => formMethods.setError, [formMethods])
  const clearErrors = useMemo(() => formMethods.clearErrors, [formMethods])
  const getValues = useMemo(() => formMethods.getValues, [formMethods])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      clearErrors()
    })
  }, [clearErrors])

  const hasDifferentPassword = useCallback((data) => {
    if (data.user.password.trim().length && data.user.password !== data.confirmPassword) {
      setError('confirmPassword', {message: getError('inputConfirmNewPassword')});
      return true;
    }
    return false;
  }, [setError])

  const onBlurConfirmPassword = useCallback(() => {
    const data = getValues();
    hasDifferentPassword(data);
  }, [getValues, hasDifferentPassword])

  const onSubmit = useCallback((data) => {
    if (hasDifferentPassword(data)) {
      return;
    }

    const user = {
      firstname: data.user.firstname,
      lastname: data.user.lastname,
      email: data.user.email,
      agreeTos: data.user.agreeTos,
      optinEmail: data.user.optinEmail,
      password: data.user.password,
    }

    const businessData = {
      name: data.business.name,
      address: data.business.address,
      businessType: business.type,
      city: data.business.city,
      prefecture: data.business.prefecture,
      zipcode: data.business.zipcode,
      telephone: data.business.telephone,
      referrer: data.business.referrer,
      // ...(data.business.affiliateNumber.trim().length ? {affiliateNumber: data.business.affiliateNumber} : {})
    }

    dispatch(actions.onboarding.triggerSetBusinessInfo({...data.business}))
    dispatch(actions.onboarding.triggerSetUserInfo({firstname: user.firstname, lastname: user.lastname, email: user.email, agreeTos: user.agreeTos, optinEmail: user.optinEmail}))
    dispatch(actions.onboarding.register({ user, business:businessData}))

    setIsLoading(true)
  }, [dispatch, business, hasDifferentPassword])

  const onChangeInput = useCallback(() => {
    clearErrors()
  }, [clearErrors])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  // const onClickTerms = useCallback(() => {
  //   showModal(ModalLegals, {type: 'tscs'})
  // }, [showModal])
  //
  // const onClickPrivacy = useCallback(() => {
  //   showModal(ModalLegals, {type: 'privacyPolicy'})
  // }, [showModal])

  const onRegisterSuccess = useCallback(() => {
    setIsLoading(false);
    history.push(RouteUrls.EmailVerification)
  }, [history])

  const onRegisterError = useCallback((e) => {
    showStickyBanner(BannerError, { type: e.payload.message })
    setIsLoading(false)
  }, [showStickyBanner])

  const onStripeCheckoutError = useCallback(() => {
    history.push(`${RouteUrls.EmailVerification}?payment=failed`)
  }, [history])

  useSagaTakeEvery(actions.onboarding.registerSuccess, onRegisterSuccess)
  useSagaTakeEvery(actions.onboarding.registerError, onRegisterError)
  useSagaTakeEvery(actions.onboarding.goToImportStripeCheckoutError, onStripeCheckoutError)

  const renderCheckBoxLabel = useMemo(() => (
    <Trans i18nKey="onboarding:register:inputTos" values={{ terms: t('onboarding:register:termsAndConditions'), privacyPolicy: t('onboarding:register:privacyPolicy') }}>
      content
      <a href={`${process.env.REACT_APP_DIGITO_WEBSITE}/${i18next.language !== 'ja' ? `${i18next.language}/` : ''}terms-of-use`} className="c-primary" target="_blank" rel="noreferrer">terms</a>
      <a href={`${process.env.REACT_APP_DIGITO_WEBSITE}/${i18next.language !== 'ja' ? `${i18next.language}/` : ''}privacy-policy`} className="c-primary" target="_blank" rel="noreferrer">privacyPolicy</a>
    </Trans>
  ), [t])

  return (
    <LayoutSideBar pageName="register">
      <TopBar backLink={backUrl} isLoading={isLoading} />
      <LayoutContent className="page__content--form-onboarding page__padding-on-wrapper">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <h1 className="ft-900 mt-1 mb-2 f-inline">{t('onboarding:register:title')}</h1>
              <p className="h4 mb-3 c-gray-700">{t('onboarding:register:description')}</p>
            </div>
            <FormProvider  {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <h2 className="ft-900 mt-1 mb-2 f-inline">{ t('onboarding:register.businessInfoTitle') }</h2>
                <InputText
                  maxLength={80}
                  defaultValue={business.name}
                  className="input--material mb-1 ft-left"
                  label={t('onboarding:register:inputBusinessName')}
                  placeholder={t('onboarding:register:inputBusinessName')}
                  name="business.name"
                  handleChange={onChangeInput}
                  error={errors?.business?.name}
                  inputRef={(
                    register({
                      required: { value: true, message: getError('name') },
                      pattern: { value: validationPatterns.notEmptyString, message: getError('name') }
                    }))}
                  />
                <InputText
                  defaultValue={business.address}
                  className="input--material mb-1 ft-left"
                  label={t('onboarding:register:inputBusinessAddress')}
                  placeholder={t('onboarding:register:inputBusinessAddress')}
                  name="business.address"
                  handleChange={onChangeInput}
                  error={errors?.business?.address}
                  inputRef={(
                    register({
                      required: { value: true, message: getError('address') },
                      pattern: { value: validationPatterns.notEmptyString, message: getError('address') }
                    }))}
                  />
                <div className="mosaic mosaic--2 mosaic--gap-8">
                  <InputText
                    maxLength={10}
                    defaultValue={business.zipcode}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputBusinessZipcode')}
                    placeholder={t('onboarding:register:inputBusinessZipcode')}
                    name="business.zipcode"
                    handleChange={onChangeInput}
                    error={errors?.business?.zipcode}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('inputZipCode') },
                        pattern: { value: validationPatterns.zipCode, message: getError('inputZipCode') }
                      }))}
                    />
                  <InputText
                    maxLength={255}
                    defaultValue={business.city}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputBusinessCity')}
                    placeholder={t('onboarding:register:inputBusinessCity')}
                    name="business.city"
                    handleChange={onChangeInput}
                    error={errors?.business?.city}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('city') },
                        pattern: { value: validationPatterns.notEmptyString, message: getError('city') }
                      }))}
                    />
                  <InputSelect
                    registerInForm={true}
                    globalLabel={t('onboarding:register:inputBusinessPrefecture')}
                    name="business.prefecture"
                    defaultOption={defaultPrefectureOption}
                    placeholder={t('account:business:inputPrefecture')}
                    options={prefecturesOptions}
                  />
                  <InputText
                    maxLength={20}
                    defaultValue={business.telephone}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputBusinessTelephone')}
                    placeholder={t('onboarding:register:inputBusinessTelephone')}
                    name="business.telephone"
                    handleChange={onChangeInput}
                    error={errors?.business?.telephone}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('telephone') },
                        pattern: { value: validationPatterns.telephone, message: getError('telephone') }
                      }))}
                    />
                </div>
                <h2 className="ft-900 mt-3 mb-2 f-inline">{ t('onboarding:register.userInfoTitle') }</h2>
                <div className="mosaic mosaic--2 mosaic--gap-8">
                  <InputText
                    minLength={1}
                    maxLength={80}
                    defaultValue={user.firstname}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputUserFirstname')}
                    placeholder={t('onboarding:register:inputUserFirstname')}
                    name="user.firstname"
                    handleChange={onChangeInput}
                    error={errors?.user?.firstname}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('name') },
                        pattern: { value: validationPatterns.notEmptyString, message: getError('name') }
                      }))}
                    />
                  <InputText
                    minLength={1}
                    maxLength={80}
                    defaultValue={user.lastname}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputUserLastname')}
                    placeholder={t('onboarding:register:inputUserLastname')}
                    name="user.lastname"
                    handleChange={onChangeInput}
                    error={errors?.user?.lastname}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('name') },
                        pattern: { value: validationPatterns.notEmptyString, message: getError('name') }
                      }))}
                    />
                  <InputText
                  maxLength={255}
                  defaultValue={user.email}
                  className="input--material ft-left mosaic-span-2"
                  label={t('onboarding:register:inputUserEmail')}
                  placeholder={t('onboarding:register:inputUserEmail')}
                  name="user.email"
                  type="email"
                  handleChange={onChangeInput}
                  error={errors?.user?.email}
                  inputRef={(
                    register({
                      required: { value: true, message: getError('email') },
                      pattern: { value: validationPatterns.email, message: getError('email') }
                    }))}
                    />
                  <InputText
                    type="password"
                    autocomplete="off"
                    minLength={8}
                    maxLength={80}
                    className="input--material mb-1 ft-left"
                    label={t('onboarding:register:inputUserPassword')}
                    placeholder={t('onboarding:register:inputUserPassword')}
                    name="user.password"
                    handleChange={onChangeInput}
                    error={errors?.user?.password}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('required') },
                        pattern: { value: validationPatterns.notEmptyString, message: getError('required') }
                      }))}
                    />
                  <InputText
                    type="password"
                    autocomplete="off"
                    minLength={8}
                    maxLength={80}
                    className="input--material mb-1 ft-left"
                    label={t('onboarding:register:inputUserConfirmPassword')}
                    placeholder={t('onboarding:register:inputUserConfirmPassword')}
                    name="confirmPassword"
                    handleChange={onChangeInput}
                    error={errors?.confirmPassword}
                    onBlur={onBlurConfirmPassword}
                    inputRef={(
                      register({
                        required: { value: true, message: getError('required') },
                        pattern: { value: validationPatterns.notEmptyString, message: getError('required') }
                      }))}
                    />
                  <InputText
                    maxLength={255}
                    className="input--material mb-1 mosaic-span-2"
                    label={t('onboarding:register.inputBusinessReferrer')}
                    defaultValue={business.referrer}
                    placeholder={t('onboarding:register.inputBusinessReferrer')}
                    name="business.referrer"
                    handleChange={onChangeInput}
                    error={errors?.business?.referrer}
                    inputRef={register()}
                    />
                  </div>
                  {/* <InputText
                    maxLength={50}
                    defaultValue={business.affiliateNumber}
                    className="input--material ft-left"
                    label={t('onboarding:register:inputBusinessAffiliateNumber')}
                    placeholder={t('onboarding:register:inputBusinessAffiliateNumber')}
                    name="business.affiliateNumber"
                    handleChange={onChangeInput}
                    error={errors?.business?.affiliateNumber}
                    inputRef={register()}
                    /> */}
                  <InputCheckbox
                    error={errors?.user?.agreeTos}
                    label={renderCheckBoxLabel}
                    className="ft-left mt-2 checkbox--inline"
                    name="user.agreeTos"
                    id="user.agreeTos"
                    inputRef={register({required: getError('agreeTos')})}
                    onChange={onChangeInput}
                    />
                  {/* <InputCheckbox
                    defaultValue={user.optinEmail}
                    label={t('onboarding:register:emailOptin')}
                    className="ft-left mt-2"
                    name="user.optinEmail"
                    inputRef={register()}
                    id="user.optinEmail" /> */}
                  <Button isLoading={isLoading} isDisabled={isLoading} className="mt-3" isPrimary={true} type="submit" text={t('onboarding:register:register', {context: creationOption === CREATION_CHOICE.scratch ? 'free' : 'premium'})} />
                </form>
              </FormProvider>
          </div>
        </div>
        <BottomBar />
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default Register
