import React, { FC, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { useTranslation } from 'react-i18next'
import Button from 'component/Buttons/Button'
import InputSelect from 'component/Form/Inputs/InputSelect'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { useTypedSelector } from 'store/store'
import ModalProductEdition from '../ModalProductEdition'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'

interface ModalMenuProductEditionProps {
  onRequestClose: (noHistory?: boolean) => void,
  menuId?: number,
  selectedProductId?: number,
  categoryName: string,
  categoryId: number,
  parentCategoryId?: number,
  categoryLinkedBusinessId?: number | null,
}

const ModalMenuProductEdition: FC<ModalMenuProductEditionProps> = ({onRequestClose, menuId, selectedProductId, categoryId, categoryName, parentCategoryId, categoryLinkedBusinessId }) => {
  const { t } = useTranslation(['menuDetails']);
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)
  const { showStickyBanner } = useContext(StickyBannerContext)

  const selectedProductIds = useSelector(selectors.menuDetails.selectedProductsIds)

  const business = useSelector(selectors.business.business);
  const [linkedBusinessId, setLinkedBusinessId] = useState(business.id);

  const products = useTypedSelector(state => selectors.products.unselectedProducts(state, selectedProductIds, linkedBusinessId !== business.id ? linkedBusinessId : undefined))

  const isMyBusinessSelected = useMemo(() => linkedBusinessId === business.id, [linkedBusinessId, business]);

  const productOptions = useMemo(() => products.map((product) => ({label: product.i18n?.name, value: product.id.toString()})), [products])

  const [productId, setProductId] = useState(selectedProductId ? selectedProductId : -1)
  const defaultProductOption = useMemo(() => productOptions.find(product => selectedProductId && product.value === selectedProductId.toString()), [selectedProductId, productOptions])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<{message: string} | null>(null)

  const getLogo = useCallback((biz) => (biz && biz.businessConfig && biz.businessConfig.logoUrl) ? <img className="img-circle img-circle--20 mr-2" src={`${process.env.REACT_APP_PICTURE_URL}/${biz.businessConfig.logoUrl.replace('original', '75')}`} alt="" /> : undefined, []);

  const linkedBusinessesOptions = useMemo(() => business.linkedBusinesses.map((linkedBiz, id) => ({
    label: linkedBiz.name,
    value: linkedBiz.id.toString(),
    icon: getLogo(linkedBiz),
  })), [business, getLogo]);

  const myBusinessOption = useMemo(() => ({
    label: t('menus:categoryEditionModal.myBusinessLabel'),
    value: business.id.toString(),
  }), [business, t]);

  const businessesOptions = useMemo(() => [
    myBusinessOption,
    {
      label: t('menus:categoryEditionModal.linkedBusinesses'),
      value: '',
      options: linkedBusinessesOptions,
    }
  ], [myBusinessOption, linkedBusinessesOptions, t]);

  const defaultBusinessOption = useMemo(() => (categoryLinkedBusinessId && linkedBusinessesOptions.find(lnkBiz => lnkBiz.value === categoryLinkedBusinessId.toString())) || myBusinessOption, [linkedBusinessesOptions, categoryLinkedBusinessId, myBusinessOption]);

  const onChangeLinkedBusiness = useCallback((label, value) => {
    const newBusinessId = Number(value);
    setLinkedBusinessId(newBusinessId);

    if (business.id !== newBusinessId) {
      dispatch(actions.products.fetchLinkedProducts({business_id: newBusinessId }));
    }
  }, [dispatch, setLinkedBusinessId, business]);

  useEffect(() => {
    if (categoryLinkedBusinessId) {
      onChangeLinkedBusiness('', categoryLinkedBusinessId);
    }
  }, [categoryLinkedBusinessId, onChangeLinkedBusiness])

  const onSaveProduct = useCallback(() => {
    if (productId < 0 || !menuId) {
      setError({message: t('menuDetails:menuProductEditionModal:error:product')})
      return;
    }
    setIsLoading(true)
    dispatch(actions.menuDetails.triggerAddProduct({id: menuId, categoryId: categoryId, productId, parentCategoryId }))
  }, [dispatch, categoryId, productId, menuId, parentCategoryId, t])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading}  onClick={onRequestClose} text={t('menuDetails:menuProductEditionModal:cancel')} />
      <Button isDisabled={isLoading} isLoading={isLoading} onClick={onSaveProduct} type="submit" text={t('menuDetails:menuProductEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, onSaveProduct, isLoading])

  const onChangeSelect = useCallback((label, value) => {
    setError(null)
    setProductId(parseInt(value))
  }, [])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdtion = useCallback(() => {
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  const onClickNewProduct = useCallback(() => {
    const currentModalParams = {
      selectedProductId: productId,
      categoryName,
      categoryId,
      menuId,
      parentCategoryId
    }

    showModal(ModalProductEdition, { menuId, categoryId, parentCategoryId }, true, currentModalParams)
  }, [showModal, menuId, categoryName, categoryId, productId, parentCategoryId])

  useSagaTakeEvery(actions.menuDetails.addProductSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.menuDetails.addProductError, onErrorEdtion)

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:menuProductEditionModal:title', {categoryName})} onRequestClose={onRequestClose}
      className="modal--menu-product-edition"
      footerContent={footerContent}
      >
      <div className="f f-ai-center">
        <div className="f-1">
          {!!business.linkedBusinesses.length && <InputSelect
            defaultOption={defaultBusinessOption}
            className="mb-2"
            placeholder={business.name}
            options={businessesOptions}
            onChange={onChangeLinkedBusiness}
            isDisabled={!!categoryLinkedBusinessId}
          />}
          <InputSelect
            defaultOption={defaultProductOption}
            isSearchable={true} placeholder={t('menuDetails:menuProductEditionModal.inputProduct.placeholder')}
            searchPlaceholder={t('menuDetails:menuProductEditionModal.inputProduct.searchPlaceholder')}
            options={productOptions}
            onChange={onChangeSelect}
            error={error}
          />
        </div>
        <div className="ft-upper ft-700 c-gray-600 ml-2 mr-2">{t('menuDetails:menuProductEditionModal:or')}</div>
        <Button
          onClick={onClickNewProduct}
          text={t('menuDetails:menuProductEditionModal:new')}
          iconLeft="icon-plus"
          isDisabled={!isMyBusinessSelected}
        />
      </div>
    </LayoutModal>
  )
}
export default ModalMenuProductEdition
