import React, { FC, useCallback, useEffect, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import InputText from 'component/Form/Inputs/InputText'
import ModalForgotPassword from 'component/Modals/ModalPassword/ModalForgotPassword'
import Button from 'component/Buttons/Button'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { getError, validationPatterns } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'
import i18next from 'i18next'

interface ModalLoginProps {
  hideClose?: boolean,
  overlayClassname?: string
  onRequestClose: () => void
}

interface Inputs {
  email: string
  password: string
}

const ModalLogin: FC<ModalLoginProps> = (props) => {

  const [globalError, setGlobalError] = useState('')

  const isConnected = useSelector(selectors.user.connected)
  const query = new URLSearchParams(useLocation().search)
  const originParam = query.get('origin')
  const isFromLandingPage = originParam === 'landing';

  const isLoginPage = matchPath(useLocation().pathname, {
    path: RouteUrls.Login,
    exact: true,
    strict: false
  })

  const isResetPasswordPage = matchPath(useLocation().pathname, {
    path: RouteUrls.ResetPassword,
    exact: true,
    strict: false
  })


  const history = useHistory()
  const dispatch = useDispatch()

  const { handleSubmit, register, errors } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const { onRequestClose } = props

  const { t } = useTranslation(['login', 'common', 'onboarding'])

  const onChangeFormValue = useCallback((name, value) => {
    setGlobalError('')
  }, [])

  const onSubmit = (login: Inputs) => dispatch(actions.user.login(login))

  const onClickRegister = useCallback(() => {
    onRequestClose()
    history.push(RouteUrls.Home)
  }, [history, onRequestClose])

  const onSuccessLogin = useCallback((action) => {
    onRequestClose()
    history.push("/dashboard")
  }, [history, onRequestClose])

  const onErrorLogin = useCallback((action) => {
    setGlobalError(action.payload.message)
  }, [])

  useSagaTakeEvery(actions.user.loginSuccess, onSuccessLogin)
  useSagaTakeEvery(actions.user.loginError, onErrorLogin)

  const requiredEmailTranslation = getError('required')
  const requiredPasswordTranslation = getError('required')

  const handleOnClose = useCallback(() => {
    if (isFromLandingPage) {
      window.location.href = `${process.env.REACT_APP_DIGITO_WEBSITE!}/${i18next.language !== 'ja' ? `${i18next.language}/` : ''}`
    } else if (isLoginPage || isResetPasswordPage) {
      return;
    }
    onRequestClose()
  }, [onRequestClose, isFromLandingPage, isLoginPage, isResetPasswordPage])

  const handleOnBack = useCallback(()=>{
    if (isLoginPage) {
      window.location.href = `${process.env.REACT_APP_DIGITO_WEBSITE}/${i18next.language !== 'ja' ? `${i18next.language}/` : ''}`
      onRequestClose()
    }
  }, [isLoginPage, onRequestClose])

  useEffect(() => {
    if (isConnected) {
      onRequestClose()
    }
  }, [isConnected, onRequestClose])

  return (
    <LayoutModal onRequestClose={handleOnClose} showBack={isLoginPage ? true : false} onRequestBack={handleOnBack} backText={t('onboarding:businessName.back')} hideClose={props.hideClose} className="modal--login modal--sm" overlayClassname={props.overlayClassname}>
      <img src="static/images/login.svg" alt="Man behind his computer" />
      <h1 className="mb-4 ft-center">{t('login:loginModal.title')}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {globalError && (
          <div className="c-red ft-tertiary mb-2 ft-700 ft-center">
            {getError(globalError)}
          </div>
        )}
        <div className="no-mobile">
          <InputText
            name="email"
            className="mb-1"
            placeholder={t('login:loginModal:inputEmail')}
            autocomplete="email"
            type="email"
            label={t('login:loginModal:inputEmail')}
            inputRef={register({
              required: requiredEmailTranslation,
              pattern: {
                value: validationPatterns.email,
                message: getError('email')
              }
            })}
            required={false}
            error={errors.email}
            handleChange={onChangeFormValue}
          />
          <InputText
            name="password"
            className="mb-1"
            placeholder={t('login:loginModal:inputPassword')}
            type="password"
            autocomplete="current-password"
            label={t('login:loginModal:inputPassword')}
            inputRef={register({
              required: requiredPasswordTranslation
            })}
            required={false}
            error={errors.password}
            handleChange={onChangeFormValue}
          />
          <ModalConsumer>
            {({ showModal }) => (
              <div
                className="f f-jc-right mb-5 ft-secondary ft-500 c-primary cursor-p"
              >
                <span onClick={() => showModal(ModalForgotPassword, {
                  hideClose: props.hideClose,
                  overlayClassname: props.overlayClassname
                })}>{t('login:loginModal:forgotPassword')}</span>
              </div>
            )}
          </ModalConsumer>
        </div>
        <div>
          <Button className="f f-jc-center mb-2 no-mobile" text={t('login:loginModal.button')} isFull={true} isPrimary={true} type="submit" />
          <p className="ft-center mb-2 is-mobile">{ t('login:loginModal.sorryNoMobile') }</p>
          <div className="ft-center ft-secondary ft-500 c-gray-600">
            <span>{t('login:loginModal:noAccount')}</span>
            <button onClick={onClickRegister} type="button" className="ft-underline c-gray-600">{t('login:loginModal:registerHere')}</button>
          </div>
          <div className="ft-center is-mobile">
            <h2 className="ft-900 mt-4 mb-2 f-inline">{ t('onboarding:emailVerification.dontmiss') }</h2>
            <div className="f f-center" data-theme="dark">
              <a href="https://www.instagram.com/digitomenu/" target="_blank" rel="noreferrer" title="Instagram" className="menu__icon"><span className="icon icon-insta-fill icon-24 c-gray-200" /></a>
              <a href="https://www.facebook.com/digitomenu" target="_top" rel="noreferrer" title="Facebook" className="menu__icon"><span className="icon icon-facebook icon-24 c-gray-200" /></a>
            </div>
          </div>
        </div>
      </form>
    </LayoutModal>
  )
}

export default ModalLogin
