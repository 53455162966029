import React, { FC, useCallback } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import i18next from 'i18next';
import { useHistory, useLocation } from 'react-router';
import { ROUTE_PATHS } from 'utils/routers/constants';

interface ModalLegalsProps {
  onRequestClose: () => void,
  type: string
}

const ModalLegals: FC<ModalLegalsProps> = ({onRequestClose, type}) => {
  const ns = i18next.getResourceBundle(i18next.language, 'legals');
  const content = i18next.exists(type, {ns: 'legals'}) ? ns[type] : {};
  const location = useLocation()
  const history = useHistory()
  
  const parseTranslationKeys = useCallback((transObject, key): any => (
    <div key={key}>
      { Object.keys(transObject).map((key: string, index: number) => {
        if (key.includes('desc')) {
          return <p key={key} dangerouslySetInnerHTML={{__html: transObject[key]}} />
        } else if (key.includes('h1.title')) {
          return <h1 key={key}>{transObject[key]}</h1>
        } else if (key.includes('h2.title')) {
          return <h2 key={key}>{transObject[key]}</h2>
        } else if (key.includes('block')) {
          return parseTranslationKeys(transObject[key], key)
        } else if (key.includes('sub-subcondition')) {
          return <p className="sub-subcondition" key={key} dangerouslySetInnerHTML={{__html: transObject[key]}} />
        } else if (key.includes('subcondition')) {
          return <p className="subcondition" key={key} dangerouslySetInnerHTML={{__html: transObject[key]}} />
        } else if (key.includes("condition")) {
          return <p className="condition" key={key} dangerouslySetInnerHTML={{__html: transObject[key]}} />
        }
        return <></>
      }) }
    </div>
  ), [])

  const handleRequestClose = () => {
    onRequestClose()
    if (location.pathname === ROUTE_PATHS.privacyPolicy || location.pathname === ROUTE_PATHS.terms) {
      history.push(ROUTE_PATHS.home)
    }
  }

  return (
    <LayoutModal 
      onRequestClose={handleRequestClose} 
      className="modal--legals modal--lg"
      >
      <div className="mt-4">
        {parseTranslationKeys(content, 'tscs')}
      </div>
    </LayoutModal>
  )
}

export default ModalLegals
