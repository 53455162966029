import React, { FC, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { CategoryType } from 'store/Categories'
import { actions, selectors } from 'store'
import TextArea from 'component/Form/Inputs/TextArea'
import Button from 'component/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux';
import InputImageFile from 'component/Form/Inputs/InputImageFile'
import InputSelect from 'component/Form/Inputs/InputSelect';
import { useTypedSelector } from 'store/store'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import ModalPremium from '../ModalPremium'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'
import { getError } from 'utils/helpers'

interface ModalCategoryEditionProps {
  onRequestClose: (noHistory?: boolean) => void,
  category?: CategoryType,
  menuId?: number,
}

interface Inputs {
  name: string,
  description: string
}

const ModalCategoryEdition: FC<ModalCategoryEditionProps> = ({onRequestClose, category, menuId }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation(['menus', 'common'])
  const { showModal } = useContext(ModalContext)
  const { showStickyBanner } = useContext(StickyBannerContext)

  const business = useSelector(selectors.business.business);
  const isEditMode = useMemo(() => category && category.id ? true : false,[category])
  const photoUrl = useTypedSelector(state => selectors.categories.photoUrlForCategory(state, category))
  const photoUrlId = useTypedSelector(state => selectors.categories.photoUrlIdForCategory(state, category))
  const formId = useMemo(() => 'modal-category-edition-form', [])
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>()
  const [image, setImage] = useState<{url: string, name: string}>({ url: photoUrl || '', name: '' })

  const [linkedBusinessId, setLinkedBusinessId] = useState(isEditMode && category && category.linkedBusinessId ? category.linkedBusinessId : business.id);
  const [linkedBusiness, setLinkedBusiness] = useState(business.linkedBusinesses.find(biz => biz.id === linkedBusinessId) || business);

  const MAXLEN_NAME = (!!linkedBusinessId ? 60 : 40) // If linked business, increase limit
    + (business.id === 73 ? 2 : 0)
    + (business.id === 103 ? 3 : 0)
    + (business.id === 92 ? 6 : 0)
    + (business.id === 97 || business.id === 108 ? 9 : 0)
    + (business.id === 120 ? 45 : 0)
    ; // Temp hack to not affect certain existing businesses, extended limit 42~85
  const MAXLEN_DESC = 180
    + (business.id === 102 ? 33 : 0)
    + (business.id === 105 ? 36 : 0)
    + (business.id === 114 ? 60 : 0)
    + (business.id === 101 ? 81 : 0)
    + (business.id === 92 ? 83 : 0)
    + (business.id === 115 ? 98 : 0)
    + (business.id === 37 ? 105 : 0)
    + (business.id === 108 ? 130 : 0)
    + (business.id === 120 ? 1126 : 0)
    ; // Temp hack to not affect certain existing businesses, extended limit 213~1306

  const { register, errors, handleSubmit, getValues, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const getLogo = useCallback((biz) => (biz && biz.businessConfig && biz.businessConfig.logoUrl) ? <img className="img-circle img-circle--20 mr-2" src={`${process.env.REACT_APP_PICTURE_URL}/${biz.businessConfig.logoUrl.replace('original', '75')}`} alt="" /> : undefined, []);

  const linkedBusinessesOptions = useMemo(() => business.linkedBusinesses.map((linkedBiz, id) => ({
    label: linkedBiz.name,
    value: linkedBiz.id.toString(),
    icon: getLogo(linkedBiz),
  })), [business, getLogo]);

  const myBusinessOption = useMemo(() => ({
    label: t('menus:categoryEditionModal.myBusinessLabel'),
    value: business.id.toString(),
  }), [business, t]);

  const defaultBusinessOption = useMemo(() => (isEditMode && linkedBusinessesOptions.find(lnkBiz => lnkBiz.value === linkedBusinessId.toString())) || myBusinessOption, [isEditMode, linkedBusinessesOptions, linkedBusinessId, myBusinessOption]);

  const businessesOptions = useMemo(() => [
    myBusinessOption,
    {
      label: t('menus:categoryEditionModal.linkedBusinesses'),
      value: '',
      options: linkedBusinessesOptions,
    }
  ], [myBusinessOption, linkedBusinessesOptions, t]);

  const isMyBusinessSelected = useMemo(() => linkedBusinessId === business.id, [linkedBusinessId, business]);

  const onChangeLinkedBusiness = useCallback((label, value) => {
    const newLinkedBusinessId = Number(value);
    setLinkedBusinessId(newLinkedBusinessId);
    const newLinkedBusiness = business.linkedBusinesses.find(biz => biz.id === newLinkedBusinessId) || business;
    setLinkedBusiness(newLinkedBusiness);
  }, [setLinkedBusinessId, setLinkedBusiness, business]);

  useEffect(() => {
    const displayPhotoUrl = isMyBusinessSelected ? photoUrl : (linkedBusiness.businessConfig.coverUrl ? `${process.env.REACT_APP_PICTURE_URL}/${linkedBusiness.businessConfig.coverUrl}` : null);
    setImage({url: displayPhotoUrl || '', name: ''});
  }, [linkedBusinessId, isMyBusinessSelected, photoUrl, linkedBusiness]);

  const onSubmit = useCallback((form) => {
    const newCategory = {...form}
    newCategory.photoUrl = image.url.length > 0 ? (isMyBusinessSelected ? photoUrlId : linkedBusiness.businessConfig.coverUrl) : '';
    const imageFile = file ? { imageFile: file } : {}

    newCategory.linkedBusinessId = !isMyBusinessSelected ? linkedBusinessId : null;
    newCategory.linkedBusiness = !isMyBusinessSelected ? linkedBusiness : null;

    const menu = menuId !== undefined ? { menuId } : {}

    setIsLoading(true)
    if (isEditMode && category && category.id) {
      dispatch(actions.categories.updateCategory({ id: category.id, category: newCategory, ...imageFile , ...menu }))
    } else {
      dispatch(actions.categories.createCategory({ category: newCategory, ...imageFile, ...menu }))
    }
  }, [category, dispatch, isEditMode, menuId, file, photoUrlId, image, isMyBusinessSelected, linkedBusiness, linkedBusinessId])

  const onSuccessEdition = useCallback((action) => {
    if (menuId !== undefined) {
      dispatch(actions.menuDetails.removeUntoggledCategory({ id: action.payload.id }))
    }
    setIsLoading(false)
    onRequestClose(true)
  }, [onRequestClose, dispatch, menuId])

  const onErrorEdtion = useCallback(() => {
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menus:categoryEditionModal:cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} type="submit" form={formId} text={t(isEditMode ? 'menus:categoryEditionModal:saveEdit' : 'menus:categoryEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading, isEditMode])

  const handleOnChangeImage = useCallback((name, file, image) => {
    setFile(file)
    setImage(image)
  }, [])

  const onChangeInput = useCallback(() => {
    clearErrors()
  }, [clearErrors])

  const onClickPremium = useCallback(() => { // No longer required
    const formValues = getValues();
    let newCategory = category ? { ...category } : { photoUrl: '', i18n: {name: '', description: ''}}

    newCategory.i18n = {
      name: formValues.name ? formValues.name : newCategory.i18n?.name,
      description: formValues.description ? formValues.description : newCategory.i18n?.description
    }

    showModal(ModalPremium, {}, true, { category: newCategory, ...{menuId} })
  }, [showModal, getValues, category, menuId])

  useSagaTakeEvery(actions.categories.createCategorySuccess, onSuccessEdition)
  useSagaTakeEvery(actions.categories.updateCategorySuccess, onSuccessEdition)

  useSagaTakeEvery(actions.categories.createCategoryError, onErrorEdtion)
  useSagaTakeEvery(actions.categories.updateCategoryError, onErrorEdtion)

  return (
    <LayoutModal
      isLoading={isLoading}
      title={isEditMode ? t('menus:categoryEditionModal:title:edit') : t('menus:categoryEditionModal:title:add')} onRequestClose={onRequestClose}
      className="modal--category-edition"
      footerContent={footerContent}
      >
      {!isEditMode && !!business.linkedBusinesses.length && <p>{ t('menus:categoryEditionModal.linkedDesc') }</p>}
      {!!business.linkedBusinesses.length && <InputSelect
        defaultOption={defaultBusinessOption}
        className="mb-1"
        placeholder={business.name}
        options={businessesOptions}
        onChange={onChangeLinkedBusiness}
        isDisabled={isEditMode}
      />}

      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <InputImageFile
          title={t('menus:categoryEditionModal:inputFile:empty')}
          ctaText={t('menus:categoryEditionModal:inputFile:cta')}
          isPremiumOnly={false}
          onClickPremium={onClickPremium}
          defaultImage={image}
          displayImage={!isMyBusinessSelected ? image : undefined}
          disabled={!isMyBusinessSelected}
          handleOnChange={handleOnChangeImage}
          cardClassName="mb-1"
          className="mb-1"
          description={`${t('menus:categoryEditionModal.inputFile.conditions', {size: '600x200'})}`}
          validate={{
            type: "jpg|jpeg|png",
            maxBytes: 1024 * 1024 * 10 // 10mo
          }} />
        <InputText
          maxLength={MAXLEN_NAME}
          defaultValue={category?.i18n.name}
          className="input--material mb-1"
          placeholder={t('menus:categoryEditionModal.inputName')}
          label={t(`menus:categoryEditionModal.${isMyBusinessSelected ? 'inputName' : 'inputLinkedName'}`)}
          name="name"
          inputRef={register({
            required: {
              value: true,
              message: getError('name')
            }
          })}
          error={errors.name}
          handleChange={onChangeInput} />
        {isMyBusinessSelected && <TextArea
          maxLength={MAXLEN_DESC}
          defaultValue={category?.i18n.description}
          onChange={onChangeInput}
          label={t('menus:categoryEditionModal:textAreaDesc')}
          name="description"
          textAreaRef={register()} />}
      </form>
    </LayoutModal>
  )
}
export default ModalCategoryEdition
