import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface CardFileProps {
  className?: string,
  image: { url: any, name: string },
  onClickDeleteImage: () => void,
  htmlFor?: string,
  disabled?: boolean,
}

const CardFile: FC<CardFileProps> = ({className, image, onClickDeleteImage, htmlFor, disabled}) => {
  const { t } = useTranslation(['common'])
  return (
    <div className={`card-file ${className ? className : ''}`}>
      <div className="card-file__content">
        <div className="card-file__left">
          <img src={image?.url} alt="" />
        </div>
      </div>
      {!disabled && <div className="card-file__bottom">
        <button className="ft-700 c-gray-600" onClick={onClickDeleteImage}>{t('common:upload.delete')}</button>
        <label className="ft-700 c-primary ml-2" htmlFor={htmlFor ? htmlFor : 'input-file'}>{t('common:upload.edit')}</label>
      </div>}
    </div>
  )
}

export default CardFile
