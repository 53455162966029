import { call, all, put, SagaReturnType, takeEvery, take, race } from 'redux-saga/effects';
import { actions } from 'store';
import * as services from './services';

export function* fetchContent(action: ReturnType<typeof actions.translations.fetchContent>) {
  const { languageId } = action.payload

  try {
    yield put(actions.translations.setIsLoadingDatasList({isLoading: true}))
    yield put(actions.translations.setLang({lang: languageId}))
    const [
      categoriesRequest,
      subcategoriesRequest,
      productsRequest ]: SagaReturnType<typeof services.getTranslations>[] = yield all([
      call(services.getTranslations, 'categories', languageId),
      call(services.getTranslations, 'subcategories', languageId),
      call(services.getTranslations, 'products', languageId),
    ])
    yield put(actions.translations.setCategories({categories: categoriesRequest.translations}))
    yield put(actions.translations.setSubCategories({subCategories: subcategoriesRequest.translations}))
    yield put(actions.translations.setProducts({products: productsRequest.translations}))
    yield put(actions.translations.setIsLoadingDatasList({isLoading: false}))
  } catch (e) {
    yield put(actions.translations.setIsLoadingDatasList({isLoading: false}))
  }
}

export function* updateCategories(action: ReturnType<typeof actions.translations.updateCategories>) {
  const { languageId, categories } = action.payload
  try {
    yield call(services.putTranslations, 'categories', languageId, categories)
    yield put(actions.translations.fetchContent({languageId}))
    yield take(actions.translations.setCategories)
    yield put(actions.translations.updateCategoriesSuccess())
  } catch(e: any) {
    yield put(actions.translations.updateCategoriesError({error: e}))
  }
}

export function* updateProducts(action: ReturnType<typeof actions.translations.updateProducts>) {
  const { languageId, products } = action.payload
  try {
    yield call(services.putTranslations, 'products', languageId, products)
    yield put(actions.translations.fetchContent({languageId}))
    yield take(actions.translations.setProducts)
    yield put(actions.translations.updateProductsSuccess())
  } catch(e: any) {
    yield put(actions.translations.updateProductsError({error: e}))
  }
}

export function* resetTranslations(action: ReturnType<typeof actions.translations.resetTranslations>) {
  const { languageId, type } = action.payload
  try {
    yield call(services.postResetTranslations, type, languageId)
    yield put(actions.translations.fetchContent({languageId}))
    yield race([
      take(actions.translations.setCategories),
      take(actions.translations.setSubCategories),
      take(actions.translations.setProducts),
    ])
    yield put(actions.translations.resetTranslationsSuccess())
  } catch(e: any) {
    yield put(actions.translations.resetTranslationsError({error: e}))
  }
}

export function* root() {
  yield all([
    takeEvery(actions.translations.fetchContent, fetchContent),
    takeEvery(actions.translations.updateCategories, updateCategories),
    takeEvery(actions.translations.updateProducts, updateProducts),
    takeEvery(actions.translations.resetTranslations, resetTranslations)
  ])
}
