import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next/';
import ModalProductEdition from 'component/Modals/ModalProductEdition'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import CardEmptyContent from 'component/Cards/CardEmptyContent';
import ModalCategoryEdition from 'component/Modals/ModalCategoryEdition';
import ModalSubcategoryEdition from 'component/Modals/ModalSubcategoryEdition';
import Loader from 'component/Loader';
import FormListTranslation from 'component/Form/FormListTranslation';

interface TabTranslationsProps {
  activeTab: string,
  language: string
}

const TabTranslations: FC<TabTranslationsProps> = ({activeTab, language}) => {
  const { t } = useTranslation(['menus'])
  const isLoadingData = useSelector(selectors.translations.isLoadingDatasList)
  const categories = useSelector(selectors.translations.categoriesTranslations)
  const products = useSelector(selectors.translations.productsTranslations)
  const subcategories = useSelector(selectors.translations.subcategoriesTranslations)
  const { showModal } = useContext(ModalContext)
 
  const showModalProductEdition = useCallback(() => {
    showModal(ModalProductEdition)
  }, [showModal])

  const showModalCategoryEdition = useCallback(() => {
    showModal(ModalCategoryEdition)
  }, [showModal])

  const showModalSubcategoryEdition = useCallback(() => {
    showModal(ModalSubcategoryEdition)
  }, [showModal])

  const renderTabs = useCallback(() => {
    switch(activeTab) {
      case 'subcategories':
        return subcategories.length ? 
          <FormListTranslation type="subcategories" items={subcategories} language={language} />
          : <CardEmptyContent className="bg-white" title={t('menus:categories:empty:title')} desc={t('menus:categories:empty:desc')} ctaText={t('menus:categories:empty:cta')} onClickCTA={showModalSubcategoryEdition} />
      case 'products':
        return products.length ? 
          <FormListTranslation type="products" items={products} language={language} />
          : <CardEmptyContent className="bg-white" title={t('menus:products:empty:title')} desc={t('menus:products:empty:desc')} ctaText={t('menus:products:empty:cta')} onClickCTA={showModalProductEdition} />
      default:
        return categories.length ? 
          <FormListTranslation type="categories" items={categories} language={language} />
          : <CardEmptyContent className="bg-white" title={t('menus:categories:empty:title')} desc={t('menus:categories:empty:desc')} ctaText={t('menus:categories:empty:cta')} onClickCTA={showModalCategoryEdition} />
    }
  }, [activeTab, categories, products, subcategories, language, showModalCategoryEdition, showModalProductEdition, showModalSubcategoryEdition, t])

  return (
    <section className="tab">
      <div className="tab__body">
        {isLoadingData ?  <Loader /> : renderTabs()}
      </div>
    </section>
  )
}

export default TabTranslations