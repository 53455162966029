import {
  actions as appActions,
  reducer as appReducer,
  selectors as appSelectors
} from './App';


import {
  actions as userActions,
  reducer as userReducer,
  selectors as userSelectors
} from './User';

import {
  actions as businessActions,
  reducer as businessReducer,
  selectors as businessSelectors
} from './Business';

import {
  actions as menusActions,
  reducer as menusReducer,
  selectors as menusSelectors
} from './Menus';

import {
  actions as menuDetailsActions,
  reducer as menuDetailsReducer,
  selectors as menuDetailSelectors
} from './MenuDetails';

import {
  actions as categoriesActions,
  reducer as categoriesReducer,
  selectors as categoriesSelectors
} from './Categories';

import {
  actions as productsActions,
  reducer as productsReducer,
  selectors as productsSelectors
} from './Products';

import {
  actions as languagesActions,
  reducer as languagesReducer,
  selectors as languagesSelectors
} from './Languages';

import {
  actions as translationsActions,
  reducer as translationsReducer,
  selectors as translationsSelectors
} from './Translations';

import {
  actions as onboardingActions,
  reducer as onboardingReducer,
  selectors as onboardingSelectors
} from './Onboarding';

import {
  actions as adminActions,
  reducer as adminReducer,
  selectors as adminSelectors
} from './Admin'

export const actions = { 
  user: userActions, 
  business: businessActions, 
  app: appActions, 
  menus: menusActions,
  menuDetails: menuDetailsActions,
  categories: categoriesActions,
  products: productsActions,
  languages: languagesActions,
  translations: translationsActions,
  onboarding: onboardingActions,
  admin: adminActions
};

export const reducers = { 
  user: userReducer, 
  business: businessReducer, 
  app: appReducer, 
  menus: menusReducer,
  menuDetails: menuDetailsReducer,
  categories: categoriesReducer,
  products: productsReducer,
  languages: languagesReducer,
  translations: translationsReducer,
  onboarding: onboardingReducer,
  admin: adminReducer
};

export const selectors = { 
  user: userSelectors, 
  business: businessSelectors, 
  app: appSelectors, 
  menus: menusSelectors,
  menuDetails: menuDetailSelectors,
  categories: categoriesSelectors,
  products: productsSelectors,
  languages: languagesSelectors,
  translations: translationsSelectors,
  onboarding: onboardingSelectors,
  admin: adminSelectors
};