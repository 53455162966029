import { call, takeLatest, all, SagaReturnType, put, takeEvery, take, race, select } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { ProductsType } from 'store/Products';
import { CategoryType, SubcategoryType } from '.';
import * as services from './services';

const createTempCategory = (params: {
  id: number,
  photoUrl?: string,
  name: string,
  description?: string,
  subCategories?: [],
  parentCategoryId?: number,
  linkedBusinessId?: number | null,
  linkedBusiness?: any,
  products?: ProductsType[]
}
): CategoryType => {
  const subcategory = createTempSubcategory(params)
  delete subcategory.parentCategoryId
  return {
    ...subcategory,
    subCategories: params.subCategories || [],
    linkedBusinessId: params.linkedBusinessId || null,
    linkedBusiness: params.linkedBusiness || null,
  }
}

const createTempSubcategory = (params: {
  id: number,
  photoUrl?: string,
  name: string,
  description?: string,
  parentCategoryId?: number,
  products?: ProductsType[]
}
): SubcategoryType => ({
  id: params.id,
  photoUrl: params.photoUrl || '',
  parentCategoryId: params.parentCategoryId,
  products: params.products || [],
  i18n: {
    name: params.name,
    description: params.description || '',
  },
})



export function* fetchCategories(action: ReturnType<typeof actions.categories.fetchCategories>) {
  try {
    yield put(actions.categories.setIsLoadingDatasList({isLoading: true}))
    const request: SagaReturnType<typeof services.getCategories> = yield call(services.getCategories)

    yield put(actions.categories.setIsLoadingDatasList({isLoading: false}))
    yield put(actions.categories.setCategories({categories: request.categories}))
  } catch(e) {
    yield put(actions.categories.setIsLoadingDatasList({isLoading: false}))
  }
}

export function* fetchCategory(action: ReturnType<typeof actions.categories.fetchCategory>) {
  try {
    yield call(services.getCategory, action.payload.id)
  } catch(e) {
  }
}

export function* createCategory(action: ReturnType<typeof actions.categories.createCategory>) {
  const { category, menuId, imageFile, withFetch = true } = action.payload
  try {

    const request: SagaReturnType<typeof services.postCategory>= yield call(services.postCategory, category)
    const hasParentCategory = category.parentCategoryId !== undefined && category.parentCategoryId >= 0

    const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
    const tempCategory = createTempCategory({
      id: request.id,
      name: category.name,
      description: category.description,
      linkedBusinessId: category.linkedBusinessId,
      linkedBusiness: category.linkedBusiness,
    });
    const tempSubcategory = createTempSubcategory({id: request.id, name: category.name, description: category.description, parentCategoryId: category.parentCategoryId })

    const needsCreateImageFile = imageFile !== undefined
    const needsAddToMenu = menuId !== undefined

    if (needsCreateImageFile) {
      yield put(actions.categories.createCategoryFile({ id: request.id, file: imageFile! }))
      const [error, _success]: any[] = yield race([
        take(actions.categories.createCategoryFileError),
        take(actions.categories.createCategoryFileSuccess)
      ])
      if (error) yield put(actions.categories.createCategoryError({ error }))
      if (_success) tempCategory.photoUrl = _success.payload.key
    }

    if (needsAddToMenu) {
      if (isDraftMode) {
        yield put(hasParentCategory ? actions.categories.addSubcategory({category: tempSubcategory}) : actions.categories.addCategory({category: tempCategory }))
      }
      yield put(actions.menuDetails.triggerAddCategory({ id: menuId!, categoryId: request.id, parentCategoryId: category.parentCategoryId }))
      const [error, _success]: any[] = yield race([
        take(actions.menuDetails.addCategoryError),
        take(actions.menuDetails.addCategorySuccess)
      ])
      if (error) yield put(actions.categories.createCategoryError({ error }))
    } else {
      if (withFetch) {
        yield put(actions.categories.fetchCategories())
      }
    }
    yield put(actions.categories.createCategorySuccess({id: request.id}))
  } catch(e: any) {
    yield put(actions.categories.createCategoryError({error: e}))
  }
}

export function* createCategoryFile(action: ReturnType<typeof actions.categories.createCategoryFile>) {
  const { id, file } = action.payload
  try {
    const CSRFToken: string = yield select(selectors.user.CSRFToken)
    const request: SagaReturnType<typeof services.postFile>= yield call(services.postFile, CSRFToken, id, file)
    yield put(actions.categories.createCategoryFileSuccess({key: request.key}))
  } catch(e: any) {
    yield put(actions.categories.createCategoryFileError({error: e}))
  }
}

export function* updateCategory(action: ReturnType<typeof actions.categories.updateCategory>) {
  const { id, category, imageFile, menuId } = action.payload
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const needsAddToMenu = menuId !== undefined

  const tempCategory = createTempCategory({
    id: id,
    name: category.name,
    description: category.description,
    linkedBusinessId: category.linkedBusinessId,
    linkedBusiness: category.linkedBusiness,
  });
  const tempSubcategory = createTempSubcategory({id: id, name: category.name, description: category.description, parentCategoryId: category.parentCategoryId })

  try {
    const needsCreateImageFile = imageFile !== undefined

    yield call(services.patchCategory, category, id)

    if (needsCreateImageFile) {
      yield put(actions.categories.createCategoryFile({ id: id, file: imageFile! }))
      const [error, _success]: any[] = yield race([
        take(actions.categories.createCategoryFileError),
        take(actions.categories.createCategoryFileSuccess)
      ])
      if (error) yield put(actions.categories.updateCategoryError({ error }))
      if (_success) tempCategory.photoUrl = _success.payload.key
    } else {
      if (category.photoUrl) tempCategory.photoUrl = category.photoUrl;
    }

    yield put(actions.categories.updateCategorySuccess({ id }))

    if (!needsAddToMenu && !isDraftMode) {
      yield put(actions.categories.fetchCategories())
    } else {
      if (category.parentCategoryId !== undefined && category.parentCategoryId >= 0) {
        yield put(actions.menuDetails.updateSubcategory({ parentCategoryId: category.parentCategoryId, category: tempSubcategory }))
      } else {
        yield put(actions.menuDetails.updateCategory({id: id, category: tempCategory }))
      }
    }
  } catch(e: any) {
    yield put(actions.categories.updateCategoryError({error: e}))
  }
}

export function* removeCategory(action: ReturnType<typeof actions.categories.removeCategory>) {
  try {
    yield call(services.deleteCategory, action.payload.id)
    yield put(actions.categories.removeCategorySuccess())
    yield put(actions.categories.fetchCategories())
  } catch(e: any) {
    yield put(actions.categories.removeCategoryError(e))
  }
}

export function* root() {
  yield all([
    takeEvery(actions.categories.fetchCategories, fetchCategories),
    takeLatest(actions.categories.fetchCategory, fetchCategory),
    takeLatest(actions.categories.createCategory, createCategory),
    takeLatest(actions.categories.updateCategory, updateCategory),
    takeLatest(actions.categories.removeCategory, removeCategory),
    takeLatest(actions.categories.createCategoryFile, createCategoryFile)
  ])
}
