import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'api/helpers/customFetch';
import { BUSINESS_TYPE } from 'store/Business';
import { RootState } from '../store';

export const importPrice = "2,000";

export enum CREATION_CHOICE {
  scratch = "scratch",
  import = "import",
}

export enum IMPORT_MENU_CHOICE {
  photos = "photos",
  files = "files",
  url = "url"
}

export interface UploadFile {
  id: string,
  name: string,
  size: number,
  type: string,
  lastModified: number,
  url: string,
}

export interface OnboardingBusinessType  {
  name: string,
  type: BUSINESS_TYPE,
  address: string,
  affiliateNumber: string,
  city: string,
  prefecture: string,
  zipcode: string,
  telephone: string,
  referrer?: string,
}

export interface OnboardingUserType  {
  email: string,
  firstname: string,
  lastname: string,
  agreeTos: boolean,
  optinEmail: boolean
}

export interface OnboardingUserRegisterType extends OnboardingUserType {
  password: string
}

export interface OnboardingBusinessRegisterType {
  name: string,
  businessType: BUSINESS_TYPE,
  address: string,
  affiliateNumber?: string,
  city: string,
  prefecture: string,
  zipcode: string,
  telephone: string,
  referrer?: string,
}


export interface OnboardingState {
  isRegistered: boolean,
  verificationEmail: string,
  importId: number,
  data: {
    creationOption: CREATION_CHOICE,
    importMenu: IMPORT_MENU_CHOICE,
    business: OnboardingBusinessType
    user: OnboardingUserType,
    categories: {
      name: string,
      description: string
    }[],
    products: {
      name: string,
      description: string,
      price: number
    }[],
    import : {
      url: string,
      files: UploadFile[]
    },
  }
}

const initialState : OnboardingState = {
  isRegistered: false,
  verificationEmail: '',
  importId: -1,
  data: {
    creationOption: CREATION_CHOICE.scratch,
    importMenu: IMPORT_MENU_CHOICE.photos,
    business: {
      name: '',
      type: BUSINESS_TYPE.RESTAURANT,
      address: '',
      affiliateNumber: '',
      city: '',
      prefecture: '',
      zipcode   : '',
      telephone : '',
      referrer : '',
    },
    user: {
      email: '',
      firstname: '',
      lastname: '',
      agreeTos: false,
      optinEmail: false
    },
    categories: [
      { name: '', description: ''}
    ],
    products: [
      { name: '', description: '', price: -1 }
    ],
    import : {
      url: '',
      files: []
    },
  },
}

export const slice = createSlice({
  name: 'Onboarding',
  initialState,
  reducers: {
    setOnboarding: (state: OnboardingState, action: PayloadAction<{ onboarding: OnboardingState }>) => {
      const { onboarding } = action.payload
      const { data: { categories, products, import: importContent, creationOption }  } =  onboarding

      state.isRegistered = false
      state.data.creationOption = creationOption && creationOption.length ? creationOption : CREATION_CHOICE.scratch
      state.data.importMenu = action.payload.onboarding.data.importMenu

      state.data.business = {
        ...initialState.data.business,
        ...action.payload.onboarding.data.business,
      }
      state.data.user = {
        ...initialState.data.user,
        ...action.payload.onboarding.data.user,
      }
      state.data.categories = categories.length && creationOption === CREATION_CHOICE.scratch ? categories : initialState.data.categories
      state.data.products = products.length && creationOption === CREATION_CHOICE.scratch ? products : initialState.data.products

      state.data.import.url = importContent && creationOption === CREATION_CHOICE.import ? importContent.url : initialState.data.import.url

    },
    setVerificationEmail: (state: OnboardingState, action: PayloadAction<{ email: string }>) => {
      state.verificationEmail = action.payload.email
      state.isRegistered = true
    },
    setImportId: (state: OnboardingState, action: PayloadAction<{ importId: number }>) => {
      state.importId = action.payload.importId
    },
    triggerSetBusinessName: (state: OnboardingState, action: PayloadAction<{ name: string }>) => undefined,
    setBusinessName: (state: OnboardingState, action: PayloadAction<{ name: string }>) => {
      state.data.business.name = action.payload.name
    },
    triggerSetReferrer: (state: OnboardingState, action: PayloadAction<{ ref: string }>) => undefined,
    setReferrer: (state: OnboardingState, action: PayloadAction<{ ref: string }>) => {
      state.data.business.referrer = action.payload.ref;
    },
    triggerSetBusinessType: (state: OnboardingState, action: PayloadAction<{ type: BUSINESS_TYPE }>) => undefined,
    setBusinessType: (state: OnboardingState, action: PayloadAction<{ type: BUSINESS_TYPE }>) => {
      state.data.business.type = action.payload.type
    },
    triggerSetBusinessInfo: (state: OnboardingState, action: PayloadAction<{ name: string, address: string, affiliateNumber: string, city: string, prefecture: string, zipcode: string, telephone: string, referrer: string }>)  => undefined,
    setBusinessInfo: (state: OnboardingState, action: PayloadAction<{ name: string, address: string, affiliateNumber: string, city: string, prefecture: string, zipcode: string, telephone: string, referrer: string }>) => {
      state.data.business.name = action.payload.name
      state.data.business.address = action.payload.address
      state.data.business.affiliateNumber = action.payload.affiliateNumber
      state.data.business.city = action.payload.city
      state.data.business.prefecture = action.payload.prefecture
      state.data.business.zipcode = action.payload.zipcode
      state.data.business.telephone = action.payload.telephone
      state.data.business.referrer = action.payload.referrer
    },
    triggerSetUserInfo: (state: OnboardingState, action: PayloadAction<{ email: string, firstname: string, lastname: string, agreeTos: boolean, optinEmail: boolean }>)  => undefined,
    setUserInfo: (state: OnboardingState, action: PayloadAction<{ email: string, firstname: string, lastname: string, agreeTos: boolean, optinEmail: boolean }>) => {
      const {firstname, lastname, email, agreeTos, optinEmail } = action.payload
      state.data.user.firstname = firstname
      state.data.user.lastname = lastname
      state.data.user.email = email
      state.data.user.agreeTos = agreeTos
      state.data.user.optinEmail = optinEmail
      state.verificationEmail = email
    },
    triggerSetCreationOption: (state: OnboardingState, action: PayloadAction<{ creationOption: CREATION_CHOICE }>) => undefined,
    setCreationOption: (state: OnboardingState, action: PayloadAction<{ creationOption: CREATION_CHOICE }>) => {
      const { creationOption } = action.payload
      state.data.creationOption = creationOption

      if ( creationOption === CREATION_CHOICE.scratch) {
        state.data.import.url = ''
        state.data.import.files = []
      } else {
        state.data.categories = []
        state.data.products = []
      }
    },
    triggerSetImportMenu: (state: OnboardingState,action: PayloadAction<{ importMenu: IMPORT_MENU_CHOICE }>) => undefined,
    setImportMenu: (state: OnboardingState, action: PayloadAction<{ importMenu: IMPORT_MENU_CHOICE }>) => {
      const { importMenu } = action.payload
      state.data.importMenu = importMenu

      if (importMenu !== IMPORT_MENU_CHOICE.url) {
        state.data.import.url = ''
      }
      if (importMenu !== IMPORT_MENU_CHOICE.files) {
        state.data.import.files = []
      }
    },
    triggerSetImportMenuUrl: (state: OnboardingState,action: PayloadAction<{ url: string }>) => undefined,
    setImportMenuUrl: (state: OnboardingState, action: PayloadAction<{ url: string }>) => {
      state.data.import.url = action.payload.url
    },
    triggerSetCategories: (state: OnboardingState, action: PayloadAction<{ categories: {name: string, description: string}[] }>) => undefined,
    setCategories: (state: OnboardingState, action: PayloadAction<{ categories: {name: string, description: string}[] }>) => {
      state.data.categories = action.payload.categories
    },
    triggerSetProducts: (state: OnboardingState,  action: PayloadAction<{ products: {name: string, description: string, price: number}[] }>) => undefined,
    setProducts: (state: OnboardingState, action: PayloadAction<{ products: {name: string, description: string, price: number}[] }>) => {
      state.data.products = action.payload.products
    },
    setFiles: (state: OnboardingState, action: PayloadAction<{ files: UploadFile[] }>) => {
      state.data.import.files = action.payload.files
    },
    register: (state: OnboardingState, action: PayloadAction<{ user: OnboardingUserRegisterType, business: OnboardingBusinessRegisterType }>) => {
      state.verificationEmail = action.payload.user.email
    },
    registerSuccess: (state: OnboardingState) => {
      state.isRegistered = true
    },
    registerError: (state: OnboardingState, action: PayloadAction<{ error: string | ErrorType }>) => {
      state.isRegistered = false
    },
    resendVerifyEmail: (state: OnboardingState, action: PayloadAction<{ email: string }>) => undefined,
    resendVerifyEmailSuccess: (state: OnboardingState) => undefined,
    resendVerifyEmailError: (state: OnboardingState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    goToImportStripeCheckout: (state: OnboardingState, action: PayloadAction<{ importId: number }>) => undefined,
    goToImportStripeCheckoutError: () => undefined,
  }
})

// Selectors
const getRoot = (state: RootState) => state.onboarding;
const business = (state: RootState) => getRoot(state).data.business;
const user = (state: RootState) => getRoot(state).data.user;
const email = (state: RootState) => getRoot(state).data.user.email;
const verificationEmail = (state: RootState) => getRoot(state).verificationEmail;
const importId = (state: RootState) => getRoot(state).importId;
const businessName = (state: RootState) => getRoot(state).data.business.name;
const businessType = (state: RootState) => getRoot(state).data.business.type;
const categories = (state: RootState) => getRoot(state).data.categories;
const products = (state: RootState) => getRoot(state).data.products;
const creationOption = (state: RootState) => getRoot(state).data.creationOption;
const importMenu = (state: RootState) => getRoot(state).data.importMenu;
const importUrl = (state: RootState) => getRoot(state).data.import.url;
const importFiles = (state: RootState) => getRoot(state).data.import.files;
const isRegistered = (state: RootState) => getRoot(state).isRegistered;

const checkHasBusinessName = (state: RootState) => {
  const dataBusinessName = businessName(state)
  return dataBusinessName && dataBusinessName.trim().length > 0
}

const checkHasBusinessType = (state: RootState) => {
  const dataBusinessType = businessType(state)
  return dataBusinessType && dataBusinessType.trim().length && Object.values(BUSINESS_TYPE).indexOf(dataBusinessType) >= 0
}

const checkCreationOptionForScratch = (state: RootState) => {
  const dataCreationOption = creationOption(state)
  return dataCreationOption && dataCreationOption.trim().length && dataCreationOption === CREATION_CHOICE.scratch
}

const checkCreationOptionForImport = (state: RootState) => {
  const dataCreationOption = creationOption(state)
  return dataCreationOption && dataCreationOption.trim().length && dataCreationOption === CREATION_CHOICE.import
}

// const checkHasCategory = (state: RootState) => {
//   const dataCategories = categories(state)
//   return dataCategories && dataCategories.length && dataCategories[0].name.trim().length > 0
// }

// const checkHasProduct = (state: RootState) => {
//   const dataProducts = products(state)
//   return dataProducts && dataProducts.length && dataProducts[0].name.trim().length > 0 && dataProducts[0].description.trim().length > 0
// }

const checkIsReadyForRegister = (state: RootState) => {
  if (checkCreationOptionForScratch(state)) {
    return true
  } else if (checkCreationOptionForImport(state)) {
    if (importMenu(state) === IMPORT_MENU_CHOICE.url) {
      return importUrl(state).trim().length > 0
    } else if (importMenu(state) === IMPORT_MENU_CHOICE.files || importMenu(state) === IMPORT_MENU_CHOICE.photos) {
      return importFiles(state).length > 0
    }
  }
}

const isReadyForSubstep = (state: RootState, substep: string) => {
  switch (substep) {
    case 'category':
      return checkCreationOptionForScratch(state);
    case 'product':
      return checkCreationOptionForScratch(state);
    case 'import':
      return checkCreationOptionForImport(state);
    default:
      return false
  }
}

const isReadyForStep = (state: RootState, step: number, substep?: string) => {
  const isReadyForStep2 = checkHasBusinessName(state);
  const isReadyForStep3 = isReadyForStep2 && checkHasBusinessType(state);
  const isReadyForStep4 = substep && substep.length ? isReadyForStep3 && isReadyForSubstep(state, substep) : isReadyForStep3;
  const isReadyForStep5 = isReadyForStep4 && checkIsReadyForRegister(state);
  const isReadyForStep6 = isRegistered(state) && verificationEmail(state).length;

  switch (step) {
    case 2:
      return isReadyForStep2;
    case 3:
      return isReadyForStep3;
    case 4:
      return isReadyForStep4;
    case 5:
      return isReadyForStep5;
    case 6:
      return isReadyForStep6;
    default:
      return false
  }
};

export const selectors = {
  onboarding: getRoot,
  business,
  user,
  importId,
  importMenu,
  importUrl,
  importFiles,
  businessName,
  businessType,
  isReadyForStep,
  creationOption,
  categories,
  products,
  email,
  verificationEmail
}

// reducer / actions
export const { reducer, actions } = slice
