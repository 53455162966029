import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'store'
import { ProductsType } from 'store/Products'
import { useTypedSelector } from 'store/store'
import { getNumberFormated, getSymbolFromCurrency } from 'utils/helpers'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import ModalProductEdition from 'component/Modals/ModalProductEdition'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import DropdownActions from 'component/Buttons/DropdownActions'

interface CardProductProps {
  product: ProductsType,
  showDisused?: boolean,
}

const CardProduct: FC<CardProductProps> = ({ product, showDisused }) => {
  const { t } = useTranslation(['menus'])
  const dispatch = useDispatch()
  const photoUrl = useTypedSelector(state => selectors.products.photoUrl(state, product.id))

  const onDuplicateProduct = useCallback(() => {
    dispatch(actions.products.createProduct({ product: {
      name: `${product.i18n?.name} (${t('menus:copy')})`,
      description: product.i18n?.description,
      price: parseInt(product.price),
      photoUrl: product.photoUrl ? product.photoUrl : '',
      spiciness: product.spiciness ? product.spiciness : 0
    } }))
  }, [dispatch, product, t])

  const deleteProduct = useCallback(() => {
    dispatch(actions.products.removeProduct({ id: product.id }))
  }, [product, dispatch])

  const modalDeleteConfirmationProps = useMemo(() => ({
    title: t('menus:productDeleteConfirmationModal:title', {
      productName: product.i18n?.name
    }),
    desc: t('menus:productDeleteConfirmationModal:desc'),
    cancelCTAText: t('menus:productDeleteConfirmationModal:cancelCTA'),
    confirmCTAText: t('menus:productDeleteConfirmationModal:confirmCTA'),
    onConfirm: deleteProduct,
  }), [deleteProduct, t, product])

  return (
    <div className="card-product">
      <div className="card-product__body">
        <div className="row">
          <div className="f f-ai-center f-jc-space-b">
            <div className="f f-ai-center col col-md-6">
              {photoUrl ? <img className="card-product__thumbnail mr-3" src={photoUrl} alt="" /> : <div className="card-product__thumbnail--empty mr-3" />}
              <div className="card-product__name">
                <h3 className="h5"><span className="ft-600">{product.i18n?.name}</span></h3>
                <p className="mb-0 pre-wrap">{product.i18n?.description}</p>
              </div>
            </div>
            <div className="col col-md-5 card-product__price">
              <h3 className="h4 ft-600">{getNumberFormated(product.price)} {getSymbolFromCurrency(product.currency)}</h3>
            </div>
            <div className="col col-md-3">
              {(showDisused && product.isDisused) && <span className={`label label--light label--grey`}>{t('menus:product.disused')}</span>}
            </div>
            <DropdownActions iconClassName="icon-more icon-20 c-gray-800" className="mr-2">
              <li>
                <ModalConsumer>
                  {({ showModal }) => (<button className="ft-600" onClick={() => showModal(ModalProductEdition, { product })}><span className="icon icon-edit icon-16 mr-2 c-cream-500"></span>{t('menus:product:edit')}</button>)}
                </ModalConsumer>
              </li>
              <li style={{display: 'none'}}>
                <ModalConsumer>
                  {({ showModal }) => (<button className="ft-600" onClick={onDuplicateProduct}>{t('menus:product:duplicate')}</button>)}
                </ModalConsumer>
              </li>
              <li>
                <ModalConsumer>
                  {({ showModal }) => (<button className="c-red ft-600" onClick={() => showModal(ModalDeleteConfirmation, { ...modalDeleteConfirmationProps })}><span className="icon icon-trash icon-16 mr-2"></span>{t('menus:product:delete')}</button>)}
                </ModalConsumer>
              </li>
            </DropdownActions>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardProduct
