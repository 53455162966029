import { customFetch } from 'api/helpers/customFetch';
import  { API_CHECKOUT_SUBSCRIPTION_URL, API_UPLOAD_URL, API_BILLING_URL, API_CONTACT } from 'api/helpers/routes'
import { InputsContactForm } from 'component/Modals/ModalContact';
import { actions } from 'store';
import store from 'store/store';

export const uploadPicture = async(CSRFToken: string, file: File): Promise<{key: string}> => new Promise ((resolve, reject) => {
  const formData = new FormData()
  formData.append('file', file)

  const request = new XMLHttpRequest()
  request.open('POST', API_UPLOAD_URL)
  request.withCredentials = true
  request.setRequestHeader('X-CSRF-Token', CSRFToken)

  request.upload.addEventListener('progress', (e) => {
    const progress = (e.loaded / e.total) * 100;
    store.dispatch(actions.app.setUploadProgress({progress}))
  })
  
  request.addEventListener('load', () => {
    const response = JSON.parse(request.response)
    if (request.status === 200) resolve(response)
    if (request.status === 400) reject(response)
  })

  request.send(formData)
})

export const redirecToStripeCheckout = async(sessionId: string) => {
  const publishedKey = process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : ''
  const stripe: stripe.Stripe = Stripe(publishedKey)
  return stripe
    .redirectToCheckout({
      sessionId: sessionId
    })
    .catch((e: any) => {
      return e
    });
}

export const redirectToStripeBilling = async(url: string) => {
  window.location.replace(url)
}

export const subscribeFromStripe = async(period: 'month' | 'year'): Promise<{sessionId: string}> => {
  const request = await customFetch.post(API_CHECKOUT_SUBSCRIPTION_URL, {period}) // Create checkout session
  const response = await request.json()
  return { sessionId: response.id }
}

export const manageAccountFromStripe = async(): Promise<{url: string}> => {
  const request = await customFetch.post(API_BILLING_URL, {})
  const response = await request.json() 
  return { url: response.url }
}

export const handleContactForm = async(inputs: InputsContactForm): Promise<Response> => {
  const request = await customFetch.post(API_CONTACT, inputs)
  return request
}
