import React, { FC } from 'react'
import CardMenuCategory from 'component/Cards/CardMenuCategory'

import { Droppable } from "react-beautiful-dnd"
import { SubcategoryType } from 'store/Categories'
import { DraggableTypes } from 'component/MenuOverview'

interface ListMenuSubcategoryProps {
  menuId: number,
  subcategories: SubcategoryType[],
  parentCategoryId: number,
  linkedBusinessId?: number | null,
}

const ListMenuSubcategory: FC<ListMenuSubcategoryProps> = ({menuId, subcategories, parentCategoryId, linkedBusinessId}) => {
  return (
    <Droppable droppableId={`droppableSubcategoryZone-c${parentCategoryId}`} type={`${DraggableTypes.Subcategory}-${parentCategoryId}`}>
      {(provided, snapshot) => (
        <ul className="card-menu-category__subcategories" ref={provided.innerRef} {...provided.droppableProps}>
          { subcategories.map((subcategory, key) => <CardMenuCategory key={`sc-${subcategory.id}`} category={subcategory} order={key} menuId={menuId} parentCategoryId={parentCategoryId} parentCategoryLinkedBusinessId={linkedBusinessId} />) }
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  )
}

export default ListMenuSubcategory
