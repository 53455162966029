import React, { FC } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'

interface ModalRequestSentProps {
  hideClose?: boolean,
  onRequestClose: () => void
}

const ModalRequestSent: FC<ModalRequestSentProps> = (props) => {

  const { onRequestClose } = props

  const { t } = useTranslation(['login', 'common'])

  return (
    <LayoutModal onRequestClose={onRequestClose} hideClose={props.hideClose} className="modal--login modal--sm">
      <img src="static/images/contact-success.svg" alt="Man behind his computer" />
      <h1 className="mb-2 ft-center">{t('common:contactModal:successTitle')}</h1>
      <p className="ft-primary mb-4 ft-center">{t('common:contactModal:successDesc')}</p>
      <Button className="btn--full" text={t('common:contactModal:successCta')} isPrimary={true} onClick={() => onRequestClose()}  />
    </LayoutModal>
  )
}

export default ModalRequestSent
