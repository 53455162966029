import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import Switch from 'component/Form/Inputs/Switch';
import { useForm } from 'react-hook-form';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';

interface Inputs {

}

interface ModalPaymentMethodsProps {
  onRequestClose: () => void,
}

const ModalPaymentMethods: FC<ModalPaymentMethodsProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails']);
  const formId = useMemo(() => 'modal-business-paymentmethods-form', []);

  const { showStickyBanner } = useContext(StickyBannerContext);

  const business = useSelector(selectors.business.business);
  const bizCfg = business.businessConfig;

  const [canPayCash, setCanPayCash] = useState<boolean>(bizCfg?.canPayCash ? true : false);
  const [canPayCreditcard, setCanPayCreditcard] = useState<boolean>(bizCfg?.canPayCreditcard ? true : false);
  const [canPayQrcode, setCanPayQrcode] = useState<boolean>(bizCfg?.canPayQrcode ? true : false);
  const [canPayEmoney, setCanPayEmoney] = useState<boolean>(bizCfg?.canPayEmoney ? true : false);
  const [canPayTransportcard, setCanPayTransportcard] = useState<boolean>(bizCfg?.canPayTransportcard ? true : false);
  const [canPayPointcard, setCanPayPointcard] = useState<boolean>(bizCfg?.canPayPointcard ? true : false);

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form) => {
    setIsLoading(true);
    dispatch(actions.business.updatePaymentMethods({
      id: business.id,
      config: {
        canPayCash: canPayCash,
        canPayCreditcard: canPayCreditcard,
        canPayQrcode: canPayQrcode,
        canPayEmoney: canPayEmoney,
        canPayTransportcard: canPayTransportcard,
        canPayPointcard: canPayPointcard,
      }
    }));
  }, [dispatch, business.id, canPayCash, canPayCreditcard, canPayQrcode, canPayEmoney, canPayTransportcard, canPayPointcard]);

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false);
    onRequestClose();
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    showStickyBanner(BannerError);
    setIsLoading(false);
  }, [showStickyBanner])

  const onSwitchChange = useCallback((name, value) => {
    switch(name) {
      case 'canPayCash':
        setCanPayCash(value ? true : false);
        break;
      case 'canPayCreditcard':
        setCanPayCreditcard(value ? true : false);
        break;
      case 'canPayQrcode':
        setCanPayQrcode(value ? true : false);
        break;
      case 'canPayEmoney':
        setCanPayEmoney(value ? true : false);
        break;
      case 'canPayTransportcard':
        setCanPayTransportcard(value ? true : false);
        break;
      case 'canPayPointcard':
        setCanPayPointcard(value ? true : false);
        break;
      default:
        break;
    }
  }, [setCanPayCash, setCanPayCreditcard, setCanPayQrcode, setCanPayEmoney, setCanPayTransportcard, setCanPayPointcard])

  useSagaTakeEvery(actions.business.updatePaymentMethodsSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updatePaymentMethodsError, onErrorEdition)

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={onSubmit} text={t('menuDetails:ctas.save')} type="submit" form={formId} isPrimary={true} />
    </>
  ), [t, onRequestClose, onSubmit, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:paymentMethodsModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <div className="mb-1">
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputCash')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayCash ? 'available' : 'unavailable'}`)}
                name="canPayCash"
                value={canPayCash}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputCreditcard')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayCreditcard ? 'available' : 'unavailable'}`)}
                name="canPayCreditcard"
                value={canPayCreditcard}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputQrcode')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayQrcode ? 'available' : 'unavailable'}`)}
                name="canPayQrcode"
                value={canPayQrcode}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputEmoney')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayEmoney ? 'available' : 'unavailable'}`)}
                name="canPayEmoney"
                value={canPayEmoney}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputTransportcard')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayTransportcard ? 'available' : 'unavailable'}`)}
                name="canPayTransportcard"
                value={canPayTransportcard}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:paymentMethodsModal.inputPointcard')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:paymentMethodsModal.${canPayPointcard ? 'available' : 'unavailable'}`)}
                name="canPayPointcard"
                value={canPayPointcard}
                handleChange={onSwitchChange}
              />
            </span>
          </div>
        </form>
    </LayoutModal>
  )
}

export default ModalPaymentMethods
