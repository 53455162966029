import { customFetch } from 'api/helpers/customFetch'
import { API_CRSF_TOKEN_URL, API_LOGIN_URL, API_ME_URL, API_LOGOUT_URL, API_VERIFY_URL, API_PASSWORD_URL} from 'api/helpers/routes'
import { UserBusiness } from '.'

export const getCSRFToken = async (): Promise<{crumb: string}> => {
  const request = await fetch(API_CRSF_TOKEN_URL, {
    method: 'GET',
    credentials: 'include',
  })
  const token = await request.json()
  return token
}

export const getUserInfos = async(): Promise<{ firstname: string, lastname: string, email: string, business: UserBusiness, isAdmin: boolean, isFullAdmin: boolean }> => {
  const request = await customFetch.get(API_ME_URL)
  const user = await request.json()
  return user
}

export const handleLogin = async(inputs: {email: string, password: string}): Promise<{ firstname: string, lastname: string, email: string, business: UserBusiness, firstLogin: boolean, isAdmin: boolean, isFullAdmin: boolean }> => {
  const { email, password } = inputs
  const loginRequest = await customFetch.post(API_LOGIN_URL, {email, password})
  const loginData = await loginRequest.json()
  const userRequest = await customFetch.get(API_ME_URL)
  const userData = await userRequest.json()
  return Object.assign({}, userData, loginData)
}

export const handleLogout = async(): Promise<Response> => {
  const logoutRequest = await customFetch.get(API_LOGOUT_URL)
  return logoutRequest
}

export const handleVerifyUser = async(emailVerifyKey: string): Promise<Response> => {
  const request = await customFetch.post(API_VERIFY_URL, {
    emailVerifyKey
  })
  return request
}

export const handleResetPassword = async(inputs: {email: string}): Promise<Response> => {
  const { email } = inputs
  const request = await customFetch.post(API_PASSWORD_URL, {
    email
  })
  return request
}

export const verifyResetPassword = async(key: string): Promise<Response> => {
  const request = await customFetch.get(`${API_PASSWORD_URL}?emailResetKey=${key}`)
  return request
}

export const updateResetPassword = async(inputs: {emailResetKey: string, password: string}): Promise<Response> => {
  const { emailResetKey, password } = inputs
  const request = await customFetch.patch(API_PASSWORD_URL, {
    emailResetKey,
    password
  })
  return request
}

export const updateUser = async(user: {}): Promise<Response> => {
  const request = await customFetch.patch(API_ME_URL, {...user})
  return request
}
