import PremiumTag from 'component/PremiumTag'
import React, { FC, useState, useCallback, ReactNode, useMemo, useEffect, useImperativeHandle, MutableRefObject } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { selectors } from 'store'
import DetectOutside from 'utils/hooks/DetectOustide'

interface OptionType {
  value: string,
  label: string,
  icon?: string | ReactNode,
  iconNb?: number
}

interface OptionGroupType {
  label: string,
  options: OptionType[]
}

interface InputSelectProps {
  isPremiumOnly?: boolean,
  options: (OptionType|OptionGroupType)[],
  globalLabel?: string,
  defaultOption?: OptionType,
  placeholder: string,
  searchPlaceholder?: string,
  isSearchable?: boolean,
  onChange?: (name: string, value: string) => void,
  onClickPremium?: () => void
  error?: {message: string} | null,
  iconRight?: ReactNode | string,
  className?: string,
  name?: string,
  registerInForm?: boolean,
  selectRef?: MutableRefObject<any>,
  isDisabled?: boolean,
}

const InputSelect: FC<InputSelectProps> = ({name, options, globalLabel, defaultOption, searchPlaceholder, placeholder, onChange, error, isSearchable = false, iconRight, isPremiumOnly = false, className = '', onClickPremium, registerInForm = false, selectRef, isDisabled = false}) => {
  const inputName = name ? name : 'select'
  const formContext = useFormContext()
  const [isFormRegistered, setIsFormRegistered] = useState(false)

  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const [value, setValue] = useState<{label: string, value: string, icon?: any, iconNb?: number} | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const isNotAllowed = useMemo(() => isPremiumOnly && !isBusinessPremium, [isPremiumOnly, isBusinessPremium])

  const setFormValue = useMemo(() => formContext?.setValue, [formContext])
  const formRegister = useMemo(() => formContext?.register, [formContext])
  const formUnregister = useMemo(() => formContext?.unregister, [formContext])

  const onClickDropdown = useCallback(() => {
    if (isNotAllowed) {
      if(onClickPremium) onClickPremium();
      return;
    }
    setIsOpen(!isOpen)
  }, [isOpen, isNotAllowed, onClickPremium])

  useImperativeHandle(selectRef, () => ({
    resetSelect() {
      setIsOpen(false)
      setValue(undefined)
    },
  }))

  const onSelectChange = useCallback((optionValue) => {
    setIsOpen(false)
    setValue(optionValue)
    if (onChange) onChange(optionValue.label, optionValue.value)
  }, [onChange])

  const onSelectBlur = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (defaultOption && defaultOption.value) {
      setValue(defaultOption)
    }
  }, [defaultOption])

  useEffect(() => {
    if (registerInForm && setFormValue && isFormRegistered) {
      setFormValue(inputName, value?.value)
    }
  }, [value, registerInForm, setFormValue, inputName, isFormRegistered])

  useEffect(() => {
    if (formRegister) {
      setIsFormRegistered(true)
      formRegister(inputName)
    }

    return () => {
      if (formUnregister) {
        setIsFormRegistered(false)
        formUnregister(inputName)
      }
    }
  }, [formRegister, formUnregister, inputName])

  const errorClass = error && error.message ? 'has-error' : ''
  const extraClasses = className && className ? className : ''

  const renderIconRight = useMemo(() => {
    if (!iconRight) return <></>
    return typeof iconRight === 'string' ? <span className={`icon ${iconRight}`}></span> : iconRight
  }, [iconRight])

  const icon = value?.icon
  const iconNb = value?.iconNb !== undefined ? value?.iconNb : 1

  return (
    <DetectOutside className={`input-select ${isSearchable ? 'input-select--search' : ''} ${errorClass} ${extraClasses}`} callback={() => setIsOpen(false)}>
      <div>
        <div className={`select ${errorClass}`}>
          <button className="select__field ft-left" onClick={onClickDropdown} type="button">
            <div className="f f-direction-column">
              {globalLabel && <span className="select__label">{globalLabel}</span>}
              <span className=""> <React.Fragment>{renderIcon(icon, iconNb)}</React.Fragment>{value ? value.label : placeholder }</span>
            </div>
            {renderIconRight}
            {isNotAllowed && <PremiumTag />}
          </button>
        </div>
        <div className={`input-select__list ${isOpen ? 'is-opened' : ''}`}>
          <Select
            name={inputName}
            defaultValue={defaultOption}
            components={{DropdownIndicator, Option: CustomOption, IndicatorSeparator: null, ...(!isSearchable ? {Control} : {}) }}
            onBlur={onSelectBlur}
            className="input-select__dropdown"
            classNamePrefix="input-select"
            onChange={onSelectChange}
            options={!isDisabled ? options : []}
            placeholder={searchPlaceholder}
            isSearchable={isSearchable}
            isClearable={false}
            menuIsOpen={!isDisabled}
            tabSelectsValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            value={value}
          />
        </div>
        {(error && error.message)  && <div className="input__error">{error.message}</div> }
      </div>
    </DetectOutside>
  )
}

const renderIcon = (icon: string | React.ReactNode, iconNb = 1) => {
  const icons = []
  if (!icon) return <span />
  for (let i = 0; i < iconNb; i++) {
    icons.push(icon === 'string' ? <span key={i} className={`icon ${icon}`}></span> :  <React.Fragment key={i}>{icon}</React.Fragment>)
  }
  return icons
}

const { Option } = components;
const CustomOption: FC<any> = (props) => {
  const { icon } = props.data
  const iconNb = props.data.iconNb !== undefined ? props.data.iconNb : 1

  return (
    <Option {...props}>
      <React.Fragment>{renderIcon(icon, iconNb)}</React.Fragment>
      <span>{props.data.label}</span>
    </Option>
  )
}

const Control: FC<any> = () => (<></>)

const DropdownIndicator: FC<{}> = () => (
  <span className="icon icon-search"></span>
)

export default InputSelect
