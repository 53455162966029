import Button from 'component/Buttons/Button'
import DropdownActions from 'component/Buttons/DropdownActions'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'store'
import { MenuType, MENU_STATUS} from 'store/Menus'
import { useTypedSelector } from 'store/store'
import { formatFullDate } from 'utils/helpers'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { RouteUrls } from 'utils/routers/constants'

interface CardMenuProps {
  menu: MenuType,
  hasActions?: boolean
}

const CardMenu: FC<CardMenuProps> = ({menu, hasActions = false }) => {
  const { t } = useTranslation('menus')
  const { showModal } = useContext(ModalContext)

  const isOnline = useMemo(() => menu.status === MENU_STATUS.VISIBLE, [menu])
  const products = useMemo(() => menu.totalProducts, [menu])
  const categories = useMemo(() => menu.totalCategories, [menu])
  const schedule = useTypedSelector((state) => selectors.menus.schedule(state, menu.id))
  const dispatch = useDispatch()
  const createdAt = useMemo(()=> new Date(menu.createdAt), [menu.createdAt])

  const deleteMenu = useCallback(() => {
    dispatch(actions.menus.removeMenu({id: menu.id}))
  }, [menu, dispatch])

  const modalDeleteConfirmationProps = useMemo(() => ({
    title: t('menus:menuDeleteConfirmationModal:title', {
      menuName: menu.name
    }),
    desc: t('menus:menuDeleteConfirmationModal:desc'),
    cancelCTAText: t('menus:menuDeleteConfirmationModal:cancelCTA'),
    confirmCTAText: t('menus:menuDeleteConfirmationModal:confirmCTA'),
    onConfirm: deleteMenu,
  }), [deleteMenu, t, menu])

  return (
    <div className="card-menu">
      <div className="card-menu__title">
        <h3 className="ft-600 mb-1">{menu.name}</h3>
        {schedule.days.length ?
          <div className="card-menu__schedule  c-cream-500 f-inline f-ai-center">
            <span className="f-inline f-ai-center mr-2">
              <span className="icon icon-calendar mr-1 c-cream-500" />
              <span>{schedule.days}</span>
            </span>
            {schedule.hours &&
              <span className="f-inline f-ai-center">
                <span className="icon icon-clock mr-1 c-cream-500" />
                <span>{schedule.hours}</span>
              </span>
            }
          </div>
        : <span className="c-gray-600">{t('menus:menu.noOpenDays')}</span>
        }
        <div className="mt-1 c-gray-600">{t('menu.createdAt')} : {formatFullDate(createdAt)}</div>
      </div>
      <div className="card-menu__cell">
        <span className={`label label--light ${isOnline ? 'label--green' : 'label--red'}`}>{isOnline ? t('menu.status.online') : t('menu.status.offline')}</span>
      </div>
      <div className="card-menu__cell">
        <span>{t('menu.products', {count: products})}</span>
      </div>
      <div className="card-menu__cell">
        <span>{t('menu.categories', {count: categories})}</span>
      </div>
      <div className={`card-menu__right ${!hasActions ? 'card-menu__right--no-action' : ''}`}>
        <Button isPrimary={!isOnline} text={isOnline ? t('menu.edit') : t('menu.finalizeMenu')} link={`${RouteUrls.MenuDetails}/${menu.id}`} innerClasses="min-width-max" />
      </div>
      {hasActions &&
        <div className="f f-ai-center">
          <DropdownActions>
            <li>
              <button className="c-red ft-700" onClick={() => showModal(ModalDeleteConfirmation, {...modalDeleteConfirmationProps})}><span className="icon icon-trash mr-2 icon-16"></span>{t('menu.delete')}</button>
              </li>
          </DropdownActions>

        </div>
      }
    </div>
  )
}

export default CardMenu
