import React, { FC, useCallback } from 'react'

interface LayoutModalRightProps {
  children: React.ReactNode,
  footerContent?: React.ReactNode,
  onRequestClose: (noHistory?: boolean) => void
  className?: string,
  title: string,
  isLoading?: boolean
}

export const LayoutModalRight: FC<LayoutModalRightProps> = ({ onRequestClose, ...otherProps }) => {
  const {isLoading = false} = otherProps
  const handleOnRequestClose = useCallback(() => {
    if (isLoading) {
      return;
    }
    onRequestClose()
  }, [isLoading, onRequestClose])

  return (
    <>
      <div className={`overlay ${isLoading ? 'is-loading' : ''}`} onClick={handleOnRequestClose} />
      <div className={`modal ${otherProps.className || ''} ${isLoading ? 'is-loading' : ''}`}>
        <div className="modal__header">
          <h2 className="ft-center" dangerouslySetInnerHTML={{__html: otherProps.title}} />
          <button className="modal__close icon icon-close icon-20 c-cream-500" onClick={handleOnRequestClose} />
        </div>
        <div className="modal__body">
          <div className="modal__content">{otherProps.children}</div>
        </div>
        {otherProps.footerContent && (<div className="modal__footer ft-right"> {otherProps.footerContent}</div>)}
      </div>
    </>
  )
}
