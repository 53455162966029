import Loader from 'component/Loader'
import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps {
  className?: string,
  innerClasses?: string,
  link?: string | null,
  iconLeft?: string | React.ReactNode | null,
  iconRight?: string | React.ReactNode | null,
  type?: 'submit' | 'reset' | 'button',
  text?: string,
  role?: string,
  isDisabled?: boolean,
  isLarge?: boolean,
  isLoading?: boolean,
  isFull?: boolean,
  isPrimary?: boolean,
  isExternal?: boolean,
  form?: string | undefined,
  onClick?: (params: any) => void,
}

const Button: FC<ButtonProps> = ({
  className = '',
  innerClasses = '',
  iconLeft = null,
  iconRight = null,
  type = 'button',
  role = 'button',
  text = '',
  form = undefined,
  link = null,
  isExternal = false,
  isDisabled = false,
  isLoading = false,
  isLarge = false,
  isFull = false,
  isPrimary = false,
  onClick
}) => {

  const iconClassName = iconLeft ? 'btn--icon ' : ''
  const largeClassName = isLarge ? 'btn--large ' : ''
  const primaryClassName = isPrimary ? 'btn--primary ' : ''
  const disabledClassName = isDisabled ? 'is-disabled ' : ''
  const fullClassName = isFull ? 'btn--full ' : ''
  const classNameCondition = `${iconClassName}${largeClassName}${primaryClassName}${disabledClassName}${fullClassName}`

  const hasClickAction = onClick && !isDisabled && !isLoading;

  const iconContent = (icon: string | React.ReactNode | null) => (
      typeof icon === 'string' ? (
        <i className={`icon ${icon}`}></i>
      ) : (
        <i>{icon}</i>
      )
    )

  const iconLeftContent = useMemo(() => (
    iconLeft ? iconContent(iconLeft) : null
  ), [iconLeft])

  const iconRightContent = useMemo(() => (
    iconRight ? iconContent(iconRight) : null
  ), [iconRight])

  const textContent = useMemo(() => <span className={`${innerClasses }`}>{text}</span>, [text, innerClasses])

  if (!link) {
    return (
      <button
        form={form}
        role={role}
        type={type}
        className={`btn ${className || ''} ${classNameCondition}`}
        onClick={hasClickAction ? onClick : () => {}}
      >
        {isLoading && <Loader />}
        {iconLeftContent}
        {textContent}
        {iconRightContent && <span className={`ml-2 ${innerClasses }`}>{iconRightContent}</span>}
      </button>
    )
} else {
    if (isExternal) {
      return (
        <a href={link}
          target="_new"
          className={`btn ${className || ''} ${classNameCondition}`}
          onClick={hasClickAction ? onClick : () => {}}
        >
          {iconLeftContent}
          {textContent}
          {iconRightContent && <span className={`ml-2 ${innerClasses }`}>{iconRightContent}</span>}
        </a>
      )
    } else {
      return (
        <Link
          className={`btn ${className || ''} ${classNameCondition}`}
          onClick={hasClickAction ? onClick : () => {}}
          to={link}
        >
          {iconLeftContent}
          {textContent}
          {iconRightContent && <span className={`ml-2 ${innerClasses}`}>{iconRightContent}</span>}
        </Link>
      )
    }
  }
}

export default Button
