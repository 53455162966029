import { call, all, put, SagaReturnType, takeEvery, select, race, take } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { defaultLanguage } from 'store/Translations';
import * as services from './services';
import { BusinessType } from 'store/Business';

export function* fetchBusiness(action: ReturnType<typeof actions.business.fetchBusiness>) {
  const { isAdminFetch = false, isManaging = true, id } = action.payload
  try {
    const request: SagaReturnType<typeof services.getBusiness> = yield call(services.getBusiness, id)
    yield put(actions.business.setBusiness({business: request.business}))
    if (request.business && request.business.languages && request.business.languages.length) {
      const lang = request.business.languages.find(lang => lang.id !== defaultLanguage)
      if (lang) {
        yield put(actions.translations.setLang({ lang: lang.id }))
      }
    }
    if (isAdminFetch) {
      yield put(actions.business.adminFetchBusinessSuccess({ isManaging }))
    } else {
      yield put(actions.business.fetchBusinessSuccess())
    }
  } catch(e) {
    yield put(actions.business.fetchBusinessError())
  }
}

export function* fetchPublicBusiness(action: ReturnType<typeof actions.business.fetchPublicBusiness>) {
  // const { slug } = action.payload;
  try {
    // yield put(actions.products.setIsLoadingDatasList({isLoading: true}));
    const request: SagaReturnType<typeof services.getPublicBusiness> = yield call(services.getPublicBusiness, action.payload.slug);
    // yield put(actions.products.setIsLoadingDatasList({isLoading: false}));
    yield put(actions.business.setFoundLinkableBusiness({business: request.business}));
  } catch(e) {
    yield put(actions.business.setFoundLinkableBusiness({}));
    // yield put(actions.menus.setIsLoadingDatasList({isLoading: false}));
  }
}

export function* updateBusinessTheme(action: ReturnType<typeof actions.business.updateBusinessTheme>) {
  const { id, theme } = action.payload
  try {
    const request: SagaReturnType<typeof services.patchBusinessTheme> = yield call(services.patchBusinessTheme, id, theme)
    yield put(actions.business.setBusiness({business: request.business}))
    yield put(actions.business.updateBusinessThemeSuccess({business: request.business}))
  } catch(e: any) {
    yield put(actions.business.updateBusinessThemeError(e))
  }
}

export function* createLogoFile(action: ReturnType<typeof actions.business.createLogoFile>) {
  const { id, imageFile, type } = action.payload
  try {
    const CSRFToken: string = yield select(selectors.user.CSRFToken)
    const request: SagaReturnType<typeof services.postFile>= yield call(services.postFile, CSRFToken, id, imageFile, type)
    yield put(actions.business.createLogoFileSuccess({key: request.key}))
  } catch(e: any) {
    yield put(actions.business.createLogoFileError({error: e}))
  }
}

export function* updateBusinessLogo(action: ReturnType<typeof actions.business.updateBusinessLogo>) {
  const { id, imageFileLogo, imageFileCover, logoUrl, coverUrl } = action.payload;
  try {
    yield call(services.patchBusinessSocialNetworks, id, undefined, undefined, undefined, logoUrl, coverUrl);
    if (imageFileLogo !== undefined && imageFileLogo !== null) {
      yield put(actions.business.createLogoFile({ id, imageFile: imageFileLogo, type: 'logo' }))
      const [error, _success]: any[] = yield race([
        take(actions.business.createLogoFileError),
        take(actions.business.createLogoFileSuccess)
      ])
      if (error) yield put(actions.business.updateBusinessLogoError({ error }))
    }
    if (imageFileCover !== undefined && imageFileCover !== null) {
      yield put(actions.business.createLogoFile({ id, imageFile: imageFileCover, type: 'cover' }))
      const [error, _success]: any[] = yield race([
        take(actions.business.createLogoFileError),
        take(actions.business.createLogoFileSuccess)
      ])
      if (error) yield put(actions.business.updateBusinessLogoError({ error }))
    }
    yield put(actions.business.updateBusinessLogoSuccess())
    yield put(actions.business.fetchBusiness({id}))
  } catch(e: any) {
    yield put(actions.business.updateBusinessLogoError(e))
  }
}

export function* updateBusinessSocialNetworks(action: ReturnType<typeof actions.business.updateBusinessSocialNetworks>) {
  const { id, facebookId, twitterId, instagramId } = action.payload;
  try {
    yield call(services.patchBusinessSocialNetworks, id, facebookId, twitterId, instagramId);
    yield put(actions.business.updateBusinessSocialNetworksSuccess())
    yield put(actions.business.fetchBusiness({id}))
  } catch(e: any) {
    yield put(actions.business.updateBusinessSocialNetworksError(e))
  }
}

export function* updateBusinessColors(action: ReturnType<typeof actions.business.updateBusinessColors>) {
  const { id, theme, backgroundColor, actionColor } = action.payload
  try {
    const request: SagaReturnType<typeof services.patchBusinessColors> = yield call(services.patchBusinessColors, id, theme, backgroundColor, actionColor)

    yield put(actions.business.setBusiness({business: request.business}))
    yield put(actions.business.updateBusinessColorsSuccess({business: request.business}))
  } catch(e: any) {
    yield put(actions.business.updateBusinessColorsError(e))
  }
}

export function* updateConfigAddress(action: ReturnType<typeof actions.business.updateConfigAddress>) {
  const { id, config } = action.payload
  try {
    yield call(services.patchBusinessConfigAddress, id, config)
    const req: SagaReturnType<typeof services.getBusiness>  = yield call(services.getBusiness, id)
    yield put(actions.business.setBusiness({ business: req.business }))
    yield put(actions.business.updateConfigAddressSuccess())
  } catch(e: any) {
    yield put(actions.business.updateConfigAddressError(e))
  }
}

export function* updateBusinessStory(action: ReturnType<typeof actions.business.updateBusinessStory>) {
  const { id, estYear, imageFileStory, config } = action.payload;
  try {
    yield call(services.patchBusinessStory, id, estYear, config);
    if (imageFileStory !== undefined && imageFileStory !== null) {
      yield put(actions.business.createLogoFile({ id, imageFile: imageFileStory, type: 'story' }));
      const [error, _success]: any[] = yield race([
        take(actions.business.createLogoFileError),
        take(actions.business.createLogoFileSuccess)
      ])
      if (error) yield put(actions.business.updateBusinessLogoError({ error }));
    }

    yield put(actions.business.updateBusinessStorySuccess());
    yield put(actions.business.fetchBusiness({id}));
  } catch(e: any) {
    yield put(actions.business.updateBusinessStoryError(e));
  }
}

export function* updatePaymentMethods(action: ReturnType<typeof actions.business.updatePaymentMethods>) {
  const { id, config } = action.payload;
  try {
    yield call(services.patchBusinessPaymentMethods, id, config);
    const req: SagaReturnType<typeof services.getBusiness>  = yield call(services.getBusiness, id);
    yield put(actions.business.setBusiness({ business: req.business }));
    yield put(actions.business.updatePaymentMethodsSuccess());
  } catch(e: any) {
    yield put(actions.business.updatePaymentMethodsError(e));
  }
}

export function* updateExtras(action: ReturnType<typeof actions.business.updateExtras>) {
  const { id, websiteUrl, config } = action.payload
  try {
    yield call(services.patchBusinessExtras, id, websiteUrl, config)
    const req: SagaReturnType<typeof services.getBusiness>  = yield call(services.getBusiness, id)
    yield put(actions.business.setBusiness({ business: req.business }))
    yield put(actions.business.updateExtrasSuccess())
  } catch(e: any) {
    yield put(actions.business.updateExtrasError(e))
  }
}

export function* updateBusinessSettings(action: ReturnType<typeof actions.business.updateBusinessSettings>) {
  const { id, settings } = action.payload
  try {
    yield call(services.patchBusinessSettings, id, settings)
    const req: SagaReturnType<typeof services.getBusiness>  = yield call(services.getBusiness, id)
    yield put(actions.business.setBusiness({ business: req.business }))
    yield put(actions.business.updateBusinessSettingsSuccess())
  } catch(e: any) {
    yield put(actions.business.updateBusinessSettingsError(e))
  }
}

export function* updateSeasonalThemes(action: ReturnType<typeof actions.business.updateSeasonalThemes>) {
  const { id, config } = action.payload;
  try {
    yield call(services.patchBusinessSeasonalThemes, id, config);
    const req: SagaReturnType<typeof services.getBusiness> = yield call(services.getBusiness, id);
    yield put(actions.business.setBusiness({ business: req.business }));
    yield put(actions.business.updateSeasonalThemesSuccess());
  } catch(e: any) {
    yield put(actions.business.updateSeasonalThemesError(e));
  }
}

export function* addLinkedBusinesses(action: ReturnType<typeof actions.business.addLinkedBusinesses >) {
  const { linkedBusinessIds } = action.payload;

  try {
    for (const linkedBusinessId in linkedBusinessIds) {
      yield call(services.postLinkedBusiness, linkedBusinessIds[linkedBusinessId]);
    }

    yield put(actions.business.addLinkedBusinessesSuccess());
  } catch(e: any) {
    yield put(actions.business.addLinkedBusinessesError(e));
  }
}

export function* removeLinkedBusinesses(action: ReturnType<typeof actions.business.removeLinkedBusinesses>) {
  const { linkedBusinessIds } = action.payload;

  try {
    for (const linkedBusinessId in linkedBusinessIds) {
      yield call(services.deleteLinkedBusiness, linkedBusinessIds[linkedBusinessId]);
    }

    yield put(actions.business.removeLinkedBusinessesSuccess());
  } catch(e: any) {
    yield put(actions.business.removeLinkedBusinessesError(e));
  }
}

export function* manageLinkedBusinesses(action: ReturnType<typeof actions.business.manageLinkedBusinesses >) {
  const { removalIds, additionIds } = action.payload;
  const business: BusinessType = yield select(selectors.business.business);
  let hasError = false;

  try {
    if (additionIds.length) {
      yield put(actions.business.addLinkedBusinesses({ linkedBusinessIds: additionIds }));
      const [addError, _addSuccess]: any[] = yield race([
        take(actions.business.addLinkedBusinessesError),
        take(actions.business.addLinkedBusinessesSuccess)
      ])
      if (addError) {
        yield put(actions.business.manageLinkedBusinessesError(addError));
        hasError = true;
      }
    }

    if (removalIds.length) {
      yield put(actions.business.removeLinkedBusinesses({ linkedBusinessIds: removalIds }));
      const [removeError, _removeSuccess]: any[] = yield race([
        take(actions.business.removeLinkedBusinessesError),
        take(actions.business.removeLinkedBusinessesSuccess)
      ])
      if (removeError) {
        yield put(actions.business.manageLinkedBusinessesError(removeError));
        hasError = true;
      }
    }

    yield put(actions.business.fetchBusiness({id: business.id}));
    if (!hasError) {
      yield put(actions.business.manageLinkedBusinessesSuccess());
    }
  } catch (e: any) {
    yield put(actions.business.fetchBusiness({id: business.id}));
    yield put(actions.business.manageLinkedBusinessesError(e));
  }
}

export function* root() {
  yield all([
    takeEvery(actions.business.fetchBusiness, fetchBusiness),
    takeEvery(actions.business.fetchPublicBusiness, fetchPublicBusiness),
    takeEvery(actions.business.updateBusinessTheme, updateBusinessTheme),
    takeEvery(actions.business.updateBusinessColors, updateBusinessColors),
    takeEvery(actions.business.updateBusinessLogo, updateBusinessLogo),
    takeEvery(actions.business.updateBusinessSocialNetworks, updateBusinessSocialNetworks),
    takeEvery(actions.business.createLogoFile, createLogoFile),
    takeEvery(actions.business.updateConfigAddress, updateConfigAddress),
    takeEvery(actions.business.updateBusinessStory, updateBusinessStory),
    takeEvery(actions.business.updatePaymentMethods, updatePaymentMethods),
    takeEvery(actions.business.updateExtras, updateExtras),
    takeEvery(actions.business.updateBusinessSettings, updateBusinessSettings),
    takeEvery(actions.business.updateSeasonalThemes, updateSeasonalThemes),
    takeEvery(actions.business.addLinkedBusinesses, addLinkedBusinesses),
    takeEvery(actions.business.removeLinkedBusinesses, removeLinkedBusinesses),
    takeEvery(actions.business.manageLinkedBusinesses, manageLinkedBusinesses),
  ])
}
