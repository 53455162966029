import Home from 'page/Onboarding/Home'
import Login from 'page/Login'
import Dashboard from 'page/Dashboard'
import Menus from 'page/Dashboard/Menus/index'
import MenuDetails from 'page/Dashboard/MenuDetails'
import Account from 'page/Dashboard/Account'
import Verify from 'page/Tmp/Verify'
import Import from 'page/Tmp/Import'
import BusinessType from 'page/Onboarding/BusinessType'
import CreationOption from 'page/Onboarding/CreationOption'
import Password from 'page/Password'
import AddCategory from 'page/Onboarding/AddCategory'
import AddProduct from 'page/Onboarding/AddProduct'
import Register from 'page/Onboarding/Register'
import EmailVerification from 'page/Onboarding/EmailVerification'
import Admin from 'page/Dashboard/Admin'
import ImportMenu from 'page/Onboarding/ImportMenu'
import ImportMenuUrl from 'page/Onboarding/ImportMenuUrl'
import ImportMenuFiles from 'page/Onboarding/ImportMenuFiles'

export enum RouteUrls {
  Home = '/',
  BusinessType = '/business-type',
  CreationOption = '/creation-option',
  AddCategory = '/add-category',
  AddProduct = '/add-product',
  Register = '/register',
  Import = '/import',
  ImportMenu = '/import-menu',
  ImportMenuPhotos = '/import-menu-photos',
  ImportMenuFiles = '/import-menu-files',
  ImportMenuUrl = '/import-menu-url',
  Payment = '/payment',
  EmailVerification = '/email-verification',
  Login = '/login',
  VerifyUser = '/verify',
  ResetPassword = '/reset-password',
  Dashboard = '/dashboard',
  Menus = '/dashboard/menus',
  Categories = '/dashboard/categories',
  Products = '/dashboard/products',
  Translations = '/dashboard/translations',
  TranslationsCategories = '/dashboard/translations/categories',
  TranslationsSubcategories = '/dashboard/translations/subcategories',
  TranslationsProducts = '/dashboard/translations/products',
  MenuDetails = '/dashboard/menu',
  Account = '/dashboard/account',
  Admin = '/dashboard/admin',
  Statistics = '/dashboard/admin/statistics',
  Store = '/dashboard/admin/store',
  Businesses = '/dashboard/admin/businesses',
  MenuImports = '/dashboard/admin/menu-imports',
  PrivacyPolicy = '/privacy-policy',
  Terms = "/terms-of-use"
}

export const ROUTE_PATHS =  {
  home: RouteUrls.Home,
  businessType: RouteUrls.BusinessType,
  creationOption: RouteUrls.CreationOption,
  addCategory: RouteUrls.AddCategory,
  addProduct: RouteUrls.AddProduct,
  register: RouteUrls.Register,
  import: RouteUrls.Import,
  importMenu: RouteUrls.ImportMenu,
  importMenuPhotos: RouteUrls.ImportMenuPhotos,
  importMenuFiles: RouteUrls.ImportMenuFiles,
  importMenuUrl: RouteUrls.ImportMenuUrl,
  payment: RouteUrls.Payment,
  emailVerification: RouteUrls.EmailVerification,
  login: RouteUrls.Login,
  verifyUser: RouteUrls.VerifyUser,
  resetPassword: RouteUrls.ResetPassword,
  dashboard: RouteUrls.Dashboard,
  menus: RouteUrls.Menus,
  categories: RouteUrls.Categories,
  products: RouteUrls.Products,
  translations: RouteUrls.Translations,
  translationsCategories: RouteUrls.TranslationsCategories,
  translationsSubcategories: RouteUrls.TranslationsSubcategories,
  translationsProducts: RouteUrls.TranslationsProducts,
  menuDetails: `${RouteUrls.MenuDetails}/:id(\\d+)`,
  account: RouteUrls.Account,
  admin: RouteUrls.Admin,
  statistics: RouteUrls.Statistics,
  store: RouteUrls.Store,
  businesses: RouteUrls.Businesses,
  menuImports: RouteUrls.MenuImports,
  privacyPolicy: RouteUrls.PrivacyPolicy,
  terms: RouteUrls.Terms
}

export enum Langs {
  Default = 'ja',
  En = 'en'
}

export const PUBLIC_ROUTES = [
  {
    page: Login,
    path: ROUTE_PATHS.login,
  },
  {
    page: Password,
    path: ROUTE_PATHS.resetPassword,
  },
  {
    page: Verify,
    path: ROUTE_PATHS.verifyUser,
  },
  {
    page: Home,
    path: ROUTE_PATHS.home,
  },
  {
    page: BusinessType,
    path: ROUTE_PATHS.businessType,
  },
  {
    page: CreationOption,
    path: ROUTE_PATHS.creationOption,
  },
  {
    page: ImportMenu,
    path: ROUTE_PATHS.importMenu,
  },
  {
    page: ImportMenuUrl,
    path: ROUTE_PATHS.importMenuUrl,
  },
  {
    page: ImportMenuFiles,
    path: ROUTE_PATHS.importMenuFiles,
  },
  {
    page: ImportMenuFiles,
    path: ROUTE_PATHS.importMenuPhotos,
  },
  {
    page: AddProduct,
    path: ROUTE_PATHS.addProduct,
  },
  {
    page: AddCategory,
    path: ROUTE_PATHS.addCategory,
  },
  {
    page: Register,
    path: ROUTE_PATHS.register,
  },
  {
    page: EmailVerification,
    path: ROUTE_PATHS.emailVerification,
  },
  {
    page: Import,
    path: ROUTE_PATHS.import,
  },
  {
    page: Home,
    path: ROUTE_PATHS.privacyPolicy
  },
  {
    page: Home,
    path: ROUTE_PATHS.terms
  }
]


export const DASHBOARD_ROUTES = [
  {
    page: Dashboard,
    path: ROUTE_PATHS.dashboard,
  },
  {
    page: Account,
    path: ROUTE_PATHS.account,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.menus,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.categories,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.translations,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.store,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.translationsCategories,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.translationsProducts,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.translationsSubcategories,
  },
  {
    page: Menus,
    path: ROUTE_PATHS.products,
  },
  {
    page: MenuDetails,
    path: ROUTE_PATHS.menuDetails,
  },
]

export const ADMIN_ROUTES = [
  {
    page: Admin,
    path: ROUTE_PATHS.admin
  },
  {
    page: Admin,
    path: ROUTE_PATHS.statistics
  },
  {
    page: Admin,
    path: ROUTE_PATHS.businesses
  },
  {
    page: Admin,
    path: ROUTE_PATHS.menuImports
  }
]

export const ROUTES = [
  ...PUBLIC_ROUTES,
  ...DASHBOARD_ROUTES,
  ...ADMIN_ROUTES
]
