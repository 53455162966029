import React, { FC, useCallback, useContext, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { getError, validationPatterns } from 'utils/helpers'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import ModalLogin from 'component/Modals/ModalLogin'
import { matchPath, useLocation } from 'react-router'
import { RouteUrls } from 'utils/routers/constants'

interface ModalForgotPasswordProps {
  hideClose?: boolean,
  overlayClassname?: string
  onRequestClose: () => void
}

interface Inputs {
  email: string
}

const ModalForgotPassword: FC<ModalForgotPasswordProps> = (props) => {
  const { onRequestClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation(['login', 'common'])
  const [globalError, setGlobalError] = useState('')
  const [isSuccess, setSuccess] = useState(false)

  const isLoginPage = matchPath(useLocation().pathname, {
    path: RouteUrls.Login,
    exact: true,
    strict: false
  })

  const isResetPasswordPage = matchPath(useLocation().pathname, {
    path: RouteUrls.ResetPassword,
    exact: true,
    strict: false
  })

  const { showModal } = useContext(ModalContext)

  const { handleSubmit, register, errors } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const onChangeFormValue = useCallback((name, value) => {
    setGlobalError('')
  }, [])

  const onSubmit = (payload: Inputs) => dispatch(actions.user.resetPassword(payload))

  const onSuccess = useCallback((action) => {
    setSuccess(true)
  }, [])

  const onError = useCallback((action) => {
    setGlobalError(action.payload.message)
  }, [])

  useSagaTakeEvery(actions.user.resetPasswordSuccess, onSuccess)
  useSagaTakeEvery(actions.user.resetPasswordError, onError)

  const requiredEmailTranslation = getError('required')

  const handleOnClose = useCallback(() => {
    if (isLoginPage || isResetPasswordPage) {
      return;
    }
    onRequestClose()
  }, [onRequestClose, isLoginPage, isResetPasswordPage])
  

  return (
    <LayoutModal 
      onRequestClose={handleOnClose} 
      showBack={true} 
      onRequestBack={()=>{ showModal(ModalLogin, {overlayClassname: props.overlayClassname})}} 
      hideClose={props.hideClose} 
      overlayClassname={props.overlayClassname} 
      className="modal--sm" >
      <h1 className="mb-2 ft-center">{t('login:forgotPasswordModal.title')}</h1>
      {isSuccess ? (
        <p className="ft-primary mb-1 ft-center">{t(`login:success.reset`)}</p>
      ) : (
        <>
          <p className="ft-primary mb-4 ft-center">{t('login:forgotPasswordModal.description')}</p>
          <form onSubmit={handleSubmit((onSubmit))}>
            {globalError && (
              <div className="c-red ft-tertiary mb-2 ft-700 ft-center">
                {getError(globalError)}
              </div>
            )}
            <InputText 
              name="email"
              autocomplete="email"
              type="email"
              className="mb-4" 
              placeholder={t('login:forgotPasswordModal:inputEmail')} 
              inputRef={register({
                required: requiredEmailTranslation,
                pattern: {
                  value: validationPatterns.email,
                  message: getError('email')
                }
              })}
              required={false}
              error={errors.email}
              handleChange={onChangeFormValue}
            />
            <Button className="f f-jc-center h5" text={t('login:forgotPasswordModal:button')} isFull={true} isPrimary={true} type="submit" />
          </form>
        </>
      )}
      
    </LayoutModal>
  )
}

export default ModalForgotPassword
