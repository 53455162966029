import React, { FC, useCallback, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { useTranslation } from 'react-i18next'
import LayoutDashboardOnboarding from 'component/Layouts/LayoutDashboardOnboarding'
import LayoutDashboardOnboardingPremium from 'component/Layouts/LayoutDashboardOnboardingPremium'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import i18next from 'i18next'

interface ModalWelcomeOnboardingProps {
  onRequestClose: () => void,
}

const ModalWelcomeOnboarding: FC<ModalWelcomeOnboardingProps> = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['dashboard'])

  const length = 5
  const [step, setStep] = useState(1)

  const goToPrev = () => setStep(step => step - 1)
  const goToNext = () =>  setStep(step => step + 1)

  const handleClose = useCallback(() => {
    dispatch(actions.user.setIsFirstLogin({isFirstLogin: false}))
    onRequestClose()
  }, [onRequestClose, dispatch])

  return (
    <LayoutModal
      onRequestClose={() => false}
      hideClose={true}
      className={`modal--flow ${step === 5 ? "is-large" :""}`}
    >
      {step === 1 && <LayoutDashboardOnboarding step={step} length={length} title={t('onboarding.step_1.title')} description={t('onboarding.step_1.desc')} img={`static/images/onboarding/step-1_${i18next.language}.png`} onNextButton={goToNext} />}
      {step === 2 && <LayoutDashboardOnboarding step={step} length={length} title={t('onboarding.step_2.title')} description={t('onboarding.step_2.desc')} img={`static/images/onboarding/step-2_${i18next.language}.png`} onPrevButton={goToPrev} onNextButton={goToNext} />}
      {step === 3 && <LayoutDashboardOnboarding step={step} length={length} title={t('onboarding.step_3.title')} description={t('onboarding.step_3.desc')} img={`static/images/onboarding/step-3_${i18next.language}.png`} onPrevButton={goToPrev} onNextButton={goToNext} />}
      {step === 4 && <LayoutDashboardOnboarding step={step} length={length} title={t('onboarding.step_4.title')} description={t('onboarding.step_4.desc')} img={`static/images/onboarding/step-4_${i18next.language}.png`} onPrevButton={goToPrev} onNextButton={goToNext} />}
      {step === 5 && <LayoutDashboardOnboardingPremium step={step} length={length} title={t('onboarding.step_5.title')} description={t('onboarding.step_5.desc')} onPrevButton={goToPrev} onRequestClose={handleClose} />}
    </LayoutModal>
  )
}
export default ModalWelcomeOnboarding
