import React, { FC, useCallback, useState, useEffect } from 'react'
import { CategoryType } from 'store/Categories'
import { ProductsType } from 'store/Products'
import MenuPreviewCardProduct from '../MenuPreviewCardProduct'

interface MenuPreviewListCategoryProps {
  categories: CategoryType[],
  isPremium: boolean,
  previewLang?: string
}

const MenuPreviewListCategory: FC<MenuPreviewListCategoryProps> = ({ categories, isPremium, previewLang='ja' }) => {

  const [hasPhoto, setHasPhoto] = useState(false)

  useEffect(() => {
    setHasPhoto(false)
    for (const category of categories) {
      if (category.photoUrl && category.photoUrl.length > 0) {
        setHasPhoto(true)
        break;
      }
    }
  }, [categories])

  const renderProductList = useCallback((products?: ProductsType[]) => {
    if (products && products.length) {
      return (
        <ul className="mt-3">
          {products.map((product) => <MenuPreviewCardProduct key={product.id} isPremium={isPremium} product={product} previewLang={previewLang} />)}
        </ul>
      )
    }
    return <></>
  }, [isPremium, previewLang])

  return (
    <ul className="container menu-list-category">
      {categories.map((category) => (
        <li className="menu-list-category__item" key={category.id}>
          {(hasPhoto) ?
            <div className="menu-list-category__card__img">
              <h2 className="heading-category h3 ft-900 c-gray-800">{ category.linkedBusiness ? category.linkedBusiness.name : category.i18n?.name }</h2>
              {(category.photoUrl && category.photoUrl.length) && <img src={`${process.env.REACT_APP_PICTURE_URL}/${category.photoUrl.replace('original', '250')}`} alt="" />}
            </div>
          :
            <h2 className="heading-category h3 ft-900 c-gray-800">{category.i18n?.name}</h2>
          }
          <p className="c-gray-600 pre-wrap mt-1 ft-center">{ category.linkedBusiness ? category.i18n?.name : category.i18n?.description }</p>
          {category.linkedBusiness && <p className="ft-center ft-underline c-primary">
            <span>See the full menu</span>
          </p>}
          {renderProductList(category.products)}
          <ul className="menu-list-subcategory">
            {category.subCategories.map((subCategory) => (
              <li className="menu-list-subcategory__item" key={subCategory.id}>
                <h3 className="heading-subcategory ft-700">{subCategory.i18n?.name}</h3>
                {renderProductList(subCategory.products)}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default MenuPreviewListCategory
