import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import TextArea from 'component/Form/Inputs/TextArea';
import { AdminBusiness } from 'store/Admin';
import { useForm } from 'react-hook-form';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';

interface Inputs {
}

interface ModalStoryProps {
  business: AdminBusiness,
  onRequestClose: () => void,
}

const ModalStory: FC<ModalStoryProps> = ({ business, onRequestClose }) => {
  const dispatch = useDispatch();

  const formId = useMemo(() => 'modal-businessnote-form', []);
  const { showStickyBanner } = useContext(StickyBannerContext);

  const [businessNote, setBusinessNote] = useState<string>(business.businessNote ? business.businessNote : '');

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form: Inputs) => {
    setIsLoading(true);
    const payload = { id: business.id, business: { businessNote } };
    dispatch(actions.admin.updateAdminBusiness(payload));
  }, [dispatch, business, businessNote])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false);
    onRequestClose();
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    console.log(BannerError);
    showStickyBanner(BannerError);
    setIsLoading(false);
  }, [showStickyBanner])

  const onChangeInput = useCallback((name, value) => {
    clearErrors()
    if (name === 'businessnote') {
      setBusinessNote(value ? value : '');
    }
  }, [clearErrors, setBusinessNote])

  useSagaTakeEvery(actions.admin.updateAdminBusinessSuccess, onSuccessEdition);
  useSagaTakeEvery(actions.admin.updateAdminBusinessError, onErrorEdition);

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text="Cancel" />
      <Button isLoading={isLoading} isDisabled={isLoading} text="Save changes" type="submit" form={formId} isPrimary={true} />
    </>
  ), [onRequestClose, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title="Business Note"
      onRequestClose={onRequestClose}
      className="modal--business-note"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <TextArea
            maxLength={300}
            defaultValue={businessNote}
            onChange={onChangeInput}
            className="input--material textarea-tall mb-1"
            label="Business Note"
            name="businessnote"
          />
        </form>
    </LayoutModal>
  )
}

export default ModalStory
