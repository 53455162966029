import React, { useRef, useEffect } from 'react'

// Hook that alerts clicks outside of the passed ref
function useDetectOutside(ref: any, callback: Function) {
  // Alert if clicked on outside of element
  function handleClickOutside(event: Event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

// Component that alerts if you click outside of it
export default function DetectOutside(props: any) {
  const wrapperRef = useRef(null)
  useDetectOutside(wrapperRef, props.callback)

  return (
    <div className={props.className || ''} ref={wrapperRef}>
      {props.children}
    </div>
  )
}
