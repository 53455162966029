import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'store'
import { checkExtension, getError } from 'utils/helpers'
import CardFile from 'component/Cards/CardFile'
import PremiumTag from 'component/PremiumTag'

interface InputImageFileValidate {
  maxBytes?: number,
  type?: string,
  htmlAccept?: string,
}

interface InputImageFileProps {
  validate?: InputImageFileValidate,
  className?: string
  cardClassName?: string
  name?: string
  title: string
  ctaText: string
  handleOnChange?: (name: string, file: File | null, image: { url: string, name: string }) => void
  inputRef?: any
  defaultImage?: {url: string, name: string }
  displayImage?: {url: string, name: string }, // Unlike defaultImage, this can be re-rendered
  disabled?: boolean
  required?: boolean
  isPremiumOnly?: boolean,
  description?: string,
  onClickPremium?: () => void
}

const InputImageFile: FC<InputImageFileProps> = (props) => {
  const {
    title,
    ctaText,
    cardClassName,
    className,
    name = '',
    description,
    handleOnChange,
    inputRef = null,
    validate,
    defaultImage = { url: '', name: '' },
    displayImage,
    required,
    disabled = false,
    isPremiumOnly = false,
    onClickPremium
  } = props

  const [_file, setFile] = useState<File | null>()
  const [image, setImage] = useState<{ url: string, name: string }>(defaultImage)
  const [error, setError] = useState<{message: string} | null>(null)

  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const showInputFile = useMemo(() => !((image && image.url.length > 0) || (displayImage && displayImage.url.length > 0)), [image, displayImage]);
  const isNotAllowed = useMemo(() => isPremiumOnly && !isBusinessPremium, [isPremiumOnly, isBusinessPremium])

  const onClickOverlay = useCallback(() => {
    if (onClickPremium) {
      onClickPremium()
    }
  }, [onClickPremium])

  const onInputChange = useCallback((e: any) => {
    if (!isNotAllowed) {
      const input = e.target
      setError(null)

      if (input && input.files && input.files[0]) {
        setImage({url: '', name: ''})
        setFile(null)

        const uploadedFile = input.files[0]

        if (validate) {
          if(validate.maxBytes && uploadedFile.size > validate.maxBytes) {
            return setError({ message: getError('fileSize') })
          }
          if(validate.type && !checkExtension(uploadedFile.name, validate.type)) {
            return setError({ message: getError('file_extension') })
          }
        }

        const newImage = {
          name: uploadedFile.name,
          url: URL.createObjectURL(uploadedFile)
        }

        setFile(uploadedFile)
        setImage(newImage)
        // dispatch(actions.app.uploadPicture({ file: uploadedFile, fileType  }))
        if (handleOnChange) handleOnChange(name, uploadedFile, newImage)
      }
    }
  }, [handleOnChange, name, validate, isNotAllowed])

  const classes = `${className ? className : ''}`
  const inputClass = `${ error && error.message ? 'has-error' : ''}`


  const onClickDeleteImage = useCallback(() => {
    setFile(null)
    setImage({url: '', name: ''})
    if (handleOnChange) handleOnChange(name, null, {url: '', name: ''})
  }, [handleOnChange, name])

  return (
    <>
      <div className={`input-file ${classes} ${inputClass}${error && error.message ? ' has-error' : ''}${showInputFile ? '' : ' is-uploaded'}${disabled ? ' is-disabled' : ''}`}>
        <div className="input-file__content">
          {isNotAllowed && <button className="input-file__overlay" onClick={onClickOverlay} type="button" />}
          {isNotAllowed && <PremiumTag />}
          <div className="mb-1">
            <span className="ft-600">{title}</span>
          </div>
          {description && <p className="ft-tertiary c-black-500 mb-1 ml-1 mr-1">{description}</p>}
          {(error && error.message) && <div className="input__error mb-2">{error.message}</div>}
          {!disabled && <span className="btn btn--small">{ctaText}</span>}
          {!isNotAllowed &&
            <input name={name ? name : 'input-file'} id={name ? name : 'input-file'} type="file" accept={validate && validate.htmlAccept} ref={inputRef} required={required} disabled={disabled} onChange={onInputChange} />
          }
        </div>
      </div>
      { (!showInputFile) && <CardFile className={cardClassName} onClickDeleteImage={onClickDeleteImage} image={displayImage || image} htmlFor={name ? name : 'input-file'} disabled={disabled} /> }
    </>
  )
}

export default InputImageFile
