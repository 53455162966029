import React, { FC, useState, useMemo, useCallback } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputImgRadio from 'component/Form/Inputs/InputImgRadio'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { BusinessTheme } from 'store/Business'

interface ModalConfigThemeProps {
  onRequestClose: () => void
}

const ModalConfigTheme: FC<ModalConfigThemeProps> = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menuDetails'])
  const business = useSelector(selectors.business.business)
  const isDarkTheme = useSelector(selectors.business.isDarkTheme)
  const [theme, setTheme] = useState<BusinessTheme>(isDarkTheme ? BusinessTheme.DARK :  BusinessTheme.LIGHT)

  const onSave = useCallback(() => {
    dispatch(actions.business.setTheme({theme}))
    dispatch(actions.business.updateBusinessTheme({id: business.id, theme }))
    onRequestClose()
  }, [dispatch, theme, business, onRequestClose])

  const footerContent = useMemo(() => (
    <>
      <Button onClick={onRequestClose} text={t('menuDetails:themeEditionModal:cancel')} />
      <Button onClick={onSave} text={t( 'menuDetails:themeEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, onSave])

  const onChangeValue = useCallback((name, value) => {
    setTheme(value)
  }, [])

  return (
    <LayoutModal 
      title={t('menuDetails:themeEditionModal:title')} 
      onRequestClose={onRequestClose}
      className="modal--menu-edition"
      footerContent={footerContent}
      >
      <InputImgRadio imgSrc="/static/images/menu-theme-light.png" title={t('menuDetails:themeEditionModal:inputLight:title')} desc={t('menuDetails:themeEditionModal:inputLight:desc')} onChange={onChangeValue} defaultChecked={!isDarkTheme ? true : false} name="theme" value={BusinessTheme.LIGHT} id="light-theme" />
      <InputImgRadio className="mt-2" imgSrc="/static/images/menu-theme-dark.png" title={t('menuDetails:themeEditionModal:inputDark:title')} desc={t('menuDetails:themeEditionModal:inputDark:desc')}  onChange={onChangeValue} defaultChecked={isDarkTheme ? true : false} name="theme" value={BusinessTheme.DARK} id="dark-theme" />
    </LayoutModal>
  )
}

export default ModalConfigTheme
