import React, { FC, useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { LayoutModalRight } from 'component/Layouts/LayoutModalRight';
// import { useTranslation } from 'react-i18next';
import { AdminBusiness } from 'store/Admin';
import { generateQrCodePdfUrl } from 'utils/helpers/QRcodeUtils';
import Button from 'component/Buttons/Button';
import { ModalContext } from 'utils/hooks/ModalBehavior';

interface ModalQRCodePDFProps {
  business: AdminBusiness,
  onRequestClose: () => void
}

const ModalQRCodePDF: FC<ModalQRCodePDFProps> = ({business, onRequestClose}) => {

  // const { t } = useTranslation(['dashboard']);

  const cols = useMemo(() => ['A', 'B', 'C', 'D', 'E'], []);
  const rows = useMemo(() => ['1', '2', '3', '4', '5', '6', '7', '8'], []);

  const [labels, setLabels] = useState(Array(rows.length).fill(Array(cols.length).fill(false)));

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [marginTop, setMarginTop] = useState(0);

  const _rememberLastLabel = useCallback(() => {
    localStorage.setItem('lastPdfLabelMarginTop', `${marginTop}`);

    for (let row = labels.length - 1; row >= 0; row--) {
      for (let col = labels[row].length - 1; col >= 0; col--) {
        if (labels[row][col]) {
          localStorage.setItem('lastPdfLabel', `${cols[col]}:${rows[row]}`);
          return;
        }
      }
    }
  }, [labels, cols, rows, marginTop]);

  const _setNextLabel = useCallback(() => {
    const lastLabel = localStorage.getItem('lastPdfLabel');
    if (lastLabel) {
      const lastLabelXY = lastLabel.split(':');
      if (lastLabelXY.length === 2) {
        let newCol = cols.indexOf(lastLabelXY[0]) + 1;
        let newRow = rows.indexOf(lastLabelXY[1]);
        if (newCol > cols.length - 1) { // Go to start of next row if no more cols
          newCol = 0;
          newRow += 1;
          if (newRow > rows.length - 1) { // Return to start if no more rows
            newRow = 0;
          }
        }

        let newLabels = JSON.parse(JSON.stringify(labels)); // Clone
        newLabels[newRow][newCol] = true;
        setLabels(newLabels);
      }
    }
  }, [labels, setLabels, rows, cols]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);

      const lastPdfLabelMarginTop = localStorage.getItem('lastPdfLabelMarginTop');
      if (lastPdfLabelMarginTop) {
        setMarginTop(parseInt(lastPdfLabelMarginTop));
      }

      _setNextLabel();
    }
  }, [_setNextLabel, isFirstLoad, setIsFirstLoad, setMarginTop])

  const handleToggleLabelClick = useCallback((rkey: number, ckey: number) => {
    let newLabels = JSON.parse(JSON.stringify(labels)); // Clone
    newLabels[rkey][ckey] = !newLabels[rkey][ckey]; // Toggle
    setLabels(newLabels);
  }, [labels, setLabels]);

  const { hideModal } = useContext(ModalContext);

  const handleMarginTopChange = useCallback((ev) => {
    setMarginTop(parseInt(ev.target.value));
  }, [setMarginTop]);

  const handlePdfButtonClick = useCallback( async () => {
    const fileurl = `${process.env.REACT_APP_API_URL}/v1/media/qr/${business.slug}`;
    const qrCodeDownloadUrl = await generateQrCodePdfUrl(fileurl, business.slug, labels, true, { marginTop: (marginTop / 1000) });

    const element = document.createElement("a");

    element.href = qrCodeDownloadUrl.url;
    // element.download = qrCodeDownloadUrl.name;
    element.target = "_blank";
    element.rel = "noreferrer";
    document.body.appendChild(element); // Required for FireFox

    element.click(); // Simulate link click
    _rememberLastLabel();
    hideModal();
  }, [business, labels, _rememberLastLabel, hideModal, marginTop]);

  return (
    <LayoutModalRight
      onRequestClose={onRequestClose}
      className="modal--right modal--qrcode"
      title="Create QR Code PDF"
    >
      <div className="modal__container">
        <div className="modal__download-wrapper">
          <div className="f f-center mb-1">
            <div>
              <table style={{borderCollapse: "separate"}}>
                <tbody>
                {rows.map((row, rkey) => (
                  <tr key={rkey}>
                  {cols.map((col, ckey) => (
                    <td key={`${ckey}${rkey}`} className="cursor-p" onClick={() => {handleToggleLabelClick(rkey, ckey)}}>
                      {
                        !labels[rkey][ckey] ?
                        <span className="c-gray-400">{col}:{row}</span>
                        :
                        <span className="icon icon-qrcode-landing icon-20 c-gray-700"></span>
                      }
                    </td>
                  ))}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal__extra-wrapper">
          <p className="ft-primary mt-3 mb-2 c-gray-600 ft-500">Makes a printable labels PDF.</p>
          <p className="ft-primary mt-2 mb-2 c-gray-600 ft-500">For A4 sheet with 30mm×30mm labels</p>
          <p className="ft-primary mt-2 mb-2 c-gray-600 ft-500">Print adjustments<br/>
            <span className="ml-2 ft-secondary">
              <label>Top margin: <input className="ft-right" type="number" step="10" min="-9990" max="9990" onChange={handleMarginTopChange} value={marginTop} />μm</label>
            </span>
          </p>
          <Button className="mt-2 mb-2" text="Open PDF" isPrimary={true} onClick={handlePdfButtonClick} iconLeft="icon-menu" isFull={true} />
        </div>
      </div>
    </LayoutModalRight>
  )
}

export default ModalQRCodePDF
