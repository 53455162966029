import Button from 'component/Buttons/Button'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { importPrice, IMPORT_MENU_CHOICE } from 'store/Onboarding'
import { useTypedSelector } from 'store/store'
import { getSymbolFromCurrency } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

const ImportMenu: FC = (props: any) => {
  const history = useHistory()
  const dispatch  = useDispatch()
  const { t } = useTranslation(['onboarding'])

  const backUrl = RouteUrls.CreationOption
  const importMenu = useSelector(selectors.onboarding.importMenu)
  const [importMenuOptionValue, setImportMenuOptionValue] = useState(importMenu)

  const optionName = 'importMenu'

  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 4, 'import'))

  const { handleSubmit, setValue, register } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  const onChangeOption = useCallback((id) => {
    setValue(optionName, id)
    setImportMenuOptionValue(id)
  }, [optionName, setValue])
  
  const onSubmit = useCallback((data) => {
    dispatch(actions.onboarding.triggerSetImportMenu({importMenu: data.importMenu}))
    if (data.importMenu === IMPORT_MENU_CHOICE.files) {
      history.push(RouteUrls.ImportMenuFiles)
    } else if (data.importMenu === IMPORT_MENU_CHOICE.photos) {
      history.push(RouteUrls.ImportMenuPhotos)
    } else {
      history.push(RouteUrls.ImportMenuUrl)
    }
  }, [dispatch, history])

  useEffect(() => {
    register(optionName)
  }, [register, optionName])

  useEffect(() => {
    setValue(optionName, importMenu)
    setImportMenuOptionValue(importMenu)
  }, [optionName, setValue, importMenu])

  const renderInputOption = useCallback((id, value, icon) => (
    <div className="ft-left input-radio--menu radio radio--btn radio--custom mb-2">
      <input name={optionName} type="radio" id={id} defaultValue={importMenu} checked={importMenuOptionValue === value} onChange={() => onChangeOption(value)}/>
      <label htmlFor={id}>
        <div className="f f-ai-center">
          <span className={`icon icon-${icon} icon-20 c-cream-500 mr-2`}></span>
          <div className="ft-600 c-gray-800 ft-up">{t(`onboarding:import:${id}`)}</div>
        </div>
      </label>
    </div>
  ), [t, importMenuOptionValue, importMenu, onChangeOption])

  return (
    <LayoutSideBar pageName="import-menu">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__content--form-onboarding">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <span className="label label--purple">{importPrice} {getSymbolFromCurrency('JPA')}</span>
              <h1 className="ft-900 mt-2 mb-2">{t('onboarding:import:title')}</h1>
              <p>{t('onboarding:import:desc')}</p>
              <p className="mt-1">{t('onboarding:import:subdesc')}</p>
              <p className="mt-1 mb-4">※ {t('onboarding:import:mention')}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderInputOption('inputPhotos', IMPORT_MENU_CHOICE.photos, 'computer')}
              {renderInputOption('inputFile', IMPORT_MENU_CHOICE.files, 'file')}
              {renderInputOption('inputUrl', IMPORT_MENU_CHOICE.url, 'link')}
              <div className="ft-center">
                <Button className="mt-3" isPrimary={true} type="submit" text={t('onboarding:import:next')} /> 
              </div>
            </form>
          </div>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default ImportMenu
