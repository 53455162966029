import { useCallback, useEffect } from 'react'
import { Action } from 'redux'
import { takeEvery, takeLatest } from 'redux-saga/effects'
import { sagaMiddleware } from 'store/store'
/**
 * @param {Generator} generatorFn
 */
export const useSaga = (generatorFn: any) => {
  useEffect(() => {
    const task = sagaMiddleware.run(function* fn() {
      yield generatorFn()
    })
    return () => {
      task.cancel()
    }
  }, [generatorFn])
}
/**
 * @param {Function} effect
 * @param {string} action
 * @param {Function} callback
 */
export const useSagaEffect = (effect: any, action: string | Action, callback: (params?: any) => void) => {
  const callbackFn = useCallback(
    function* fn() {
      yield effect(action, callback)
    },
    [effect, action, callback],
  )
  useSaga(callbackFn)
}
/**
 * @param {string} action
 * @param {Function} callback
 */
export const useSagaTakeEvery = (action: string | Action, callback : (params?: any) => void) => {
  useSagaEffect(takeEvery, action, callback)
}
/**
 * @param {string} action
 * @param {Function} callback
 */
export const useSagaTakeLatest = (action: string | Action, callback: (params?: any) => void) => {
  useSagaEffect(takeLatest, action, callback)
}