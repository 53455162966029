import React, { FC } from 'react'

const Loader: FC<{className?: string}> = ({className}) => (
  <div className={`loader ${className ? className : ''}`}>
    <span />
    <span />
    <span />
  </div>
)

export default Loader