import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ErrorType } from '../../api/helpers/customFetch'
import { ProductsType } from 'store/Products';
import produce from 'immer';

export interface SubcategoryType {
  id: number,
  photoUrl: string,
  parentCategoryId?: number,
  products: ProductsType[],
  i18n: {
    name: string,
    description: string,
    translator?: string | null,
  },
}

export interface CategoryType extends SubcategoryType {
  subCategories: SubcategoryType[],
  linkedBusinessId?: number | null,
  linkedBusiness?: any,
}

export interface CategoriesState {
  isLoadingDatasList: boolean,
  data: {
    categories: CategoryType[]
  }
}

const initialState : CategoriesState = {
  isLoadingDatasList: false,
  data: {
    categories: []
  }
}

export const slice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {
    setCategories: (state: CategoriesState, action: PayloadAction<{ categories: CategoryType[] }>) => {
      state.data.categories = action.payload.categories &&  action.payload.categories.length ?  action.payload.categories : []
    },
    addCategory: (state: CategoriesState, action: PayloadAction<{ category: CategoryType }>) => {
      state.data.categories.push(action.payload.category)
    },
    addSubcategory: (state: CategoriesState, action: PayloadAction<{ category: SubcategoryType }>) => {
      const { category } = action.payload
      if (category.parentCategoryId !== undefined) {
        const parentCategoryIndex = state.data.categories.findIndex(c => c.id ===  category.parentCategoryId)
        state.data.categories[parentCategoryIndex].subCategories.push(category)
      }
    },
    setIsLoadingDatasList:( state: CategoriesState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatasList = action.payload.isLoading
    },
    fetchCategories: (state: CategoriesState) => undefined,
    fetchCategory: (state: CategoriesState, action: PayloadAction<{ id: number }>) => undefined,
    removeCategory: (state: CategoriesState, action: PayloadAction<{ id: number }>) => undefined,
    removeCategorySuccess: (state: CategoriesState) => undefined,
    removeCategoryError: (state: CategoriesState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateCategory: (state: CategoriesState, action: PayloadAction<{
      id: number,
      category: {
        name: string,
        description: string,
        photoUrl?: string,
        parentCategoryId?: number,
        linkedBusinessId?: number | null,
        linkedBusiness?: any,
      },
      imageFile?: File,
      menuId?: number
    }>) => undefined,
    updateCategorySuccess: (state: CategoriesState, action: PayloadAction<{ id: number }>) => undefined,
    updateCategoryError: (state: CategoriesState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    createCategory: (state: CategoriesState, action: PayloadAction<{
      category: {
        name: string,
        description: string,
        photoUrl?: string,
        parentCategoryId?: number,
        linkedBusinessId?: number | null,
        linkedBusiness?: any,
      }, menuId?: number, imageFile?: File, withFetch?: boolean
    }>) => undefined,
    createCategorySuccess: (state: CategoriesState, action: PayloadAction<{ id: number }>) => undefined,
    createCategoryError: (state: CategoriesState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    createCategoryFile: (state: CategoriesState, action: PayloadAction<{ file: File, id: number }>) => undefined,
    createCategoryFileSuccess: (state: CategoriesState, action: PayloadAction<{ key: string }>) => undefined,
    createCategoryFileError: (state: CategoriesState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
  }
});

// Selectors
const getRoot = (state: RootState) => state.categories
const getCategory = (state: RootState, id: number) => getRoot(state).data.categories.find((category) => category.id === id)
const categories = (state: RootState) => getRoot(state).data.categories
const isLoadingDatasList = (state: RootState) => getRoot(state).isLoadingDatasList
const photoUrl = (state: RootState, id: number) => {
  const category = getCategory(state, id)
  if (!category) return null
  if (!category.photoUrl) return null
  return `${process.env.REACT_APP_PICTURE_URL}/${category.photoUrl}`
}
const photoUrlId = (state: RootState, id: number) => {
  const category = getCategory(state, id)
  if (!category) return null
  if (!category.photoUrl) return ''
  return category.photoUrl
}

const photoUrlForCategory = (state: RootState, category?: CategoryType) => {
  if (!category) return null
  if (!category.photoUrl) return null
  return `${process.env.REACT_APP_PICTURE_URL}/${category.photoUrl}`
}
const photoUrlIdForCategory = (state: RootState, category?: CategoryType) => {
  if (!category) return null
  if (!category.photoUrl) return ''
  return category.photoUrl
}


const getSubcategory = (state: RootState, id: number): null | SubcategoryType  => {
  let subcategory = null;
  getRoot(state).data.categories.forEach((category) => {
    category.subCategories.forEach(sub => {
      if (sub.id === id) subcategory = sub
    })
  })
  return subcategory
}

const subCategories = (state: RootState, id: number) => {
  const category = getCategory(state, id)
  if (!category) return []
  return category.subCategories ? category.subCategories : []
}

const getUnselectedCategories = (state: RootState, ids: number[]) => produce(getRoot(state).data.categories, categories => categories.filter((category) => ids.indexOf(category.id) < 0))

const getUnselectedSubCategories = (state: RootState, ids: number[], parentCategoryId?: number) => {
  if (parentCategoryId !== undefined && parentCategoryId >= 0) {
    const parentCategory = getCategory(state, parentCategoryId)
    const subcategories = parentCategory?.subCategories.filter((subcategory) => ids.indexOf(subcategory.id) < 0)
    return subcategories && subcategories.length ? subcategories : []
  }
  return []
}

export const selectors = {
  category: getCategory,
  subcategory: getSubcategory,
  categories,
  isLoadingDatasList,
  photoUrl,
  photoUrlId,
  photoUrlForCategory,
  photoUrlIdForCategory,
  subCategories,
  unselectedCategories: getUnselectedCategories,
  unselectedSubCategories: getUnselectedSubCategories,
};

// reducer / actions
export const { reducer, actions } = slice;
