import Button from 'component/Buttons/Button'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { AdminMenuImport, ADMIN_MENU_IMPORT_STATUS } from 'store/Admin'
import { formatDate } from 'utils/helpers'

const CardMenuImport: FC<AdminMenuImport> = ({
  createdAt,
  business,
  status,
  menuImportFiles,
  id
}) => {
  const { t } = useTranslation('admin')
  const dispatch = useDispatch()

  const createdAtDate = new Date(createdAt)
  const createdAtStr = `${createdAtDate.getFullYear()}/${formatDate(
    createdAtDate.getUTCMonth()
  )}/${formatDate(createdAtDate.getDate())}`

  const handleButtonClick = () => {
    dispatch(actions.admin.updateMenuImportStatus({ id }))
  }

  const isStatusProcessed = status === ADMIN_MENU_IMPORT_STATUS.PROCESSED

  return (
    <div className="card-menu-import">
      <div className="card-menu-import__title f f-ai-center">
        <h3 className="ft-600">{business.name}</h3>
      </div>
      <div className="card-menu-import__cell">
        <span>
          {t('createdAt')} : {createdAtStr}
        </span>
      </div>
      <div
        className={`${
          isStatusProcessed ? 'card-menu-import__right' : 'card-menu-import__cell'
        }`}
      >
        <span
          className={`label label--light ${isStatusProcessed ? 'label--green' : 'label--orange'}`}
        >
          {isStatusProcessed
            ? t('menuImportStatus.processed')
            : t('menuImportStatus.pending')}
        </span>
      </div>
      {!isStatusProcessed && (
        <div className="card-menu-import__right">
          <Button
            isPrimary={true}
            onClick={handleButtonClick}
            text={t('process')}
          />
        </div>
      )}
      <div className="card-menu-import__links f f-direction-column mt-2">
        <span className="mb-1">{t('importedFiles')} :</span>
        {menuImportFiles.map((menuImportFile, index) => {
          const url = menuImportFile.fileMimeType !== 'url' ? `${process.env.REACT_APP_PICTURE_URL}/${menuImportFile.fileUrl}` : menuImportFile.fileUrl
          return (
            <a key={index} target="blank" href={url}>
              {url}
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default CardMenuImport
