import React, { FC, useMemo, useCallback, useEffect, useState, useContext, useRef } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import InputSelect from 'component/Form/Inputs/InputSelect'
import Loader from 'component/Loader'
import { useTypedSelector } from 'store/store'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'

interface ModalConfigTranslationsProps {
  onRequestClose: () => void
}

const ModalConfigTranslations: FC<ModalConfigTranslationsProps> = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menuDetails', 'common'])
  const { showStickyBanner } = useContext(StickyBannerContext)

  const [isLoading, setIsLoading] = useState(false)
  const businessTranslationLanguages = useSelector(selectors.business.translationLanguages)
  const [languageList, setLanguageList] = useState(businessTranslationLanguages)
  const translationLanguages = useTypedSelector(state => selectors.languages.translationLanguages(state, languageList))
  const isLoadingDatasList = useSelector(selectors.languages.isLoadingDatasList)

  const getIcon = useCallback((lang) => <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`}  alt="" />, [])
  const getTranslationLabel = useCallback((lang) => t(`common:languages:${lang.toLowerCase()}`), [t])

  const languageOptions = useMemo(() => translationLanguages.map((lang) => ({value: lang, label: getTranslationLabel(lang), icon: getIcon(lang) })), [translationLanguages, getTranslationLabel, getIcon])
  const [selectedLanguage, setSelectedLanguage] = useState('')

  const hasSelectedLanguage = useMemo(() => selectedLanguage.length > 0, [selectedLanguage])

  const selectRef = useRef<any>(null)

  const onSave = useCallback(() => {
    setIsLoading(true);
    const removals = businessTranslationLanguages.filter((bussinessLang) => languageList.indexOf(bussinessLang) < 0)
    const additions = languageList.filter((bussinessLang) => businessTranslationLanguages.indexOf(bussinessLang) < 0)

    dispatch(actions.languages.manageLanguages({removalIds: removals, additionIds: additions}));
  }, [dispatch, businessTranslationLanguages, languageList])

  const onChangeSelect = useCallback((name, value: string) => {
    setSelectedLanguage(value)
  }, [])

  const onDeleteLang = useCallback((lang) => {
    setLanguageList(prevState => prevState.filter(item => item !== lang))
  }, [])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:translationEditionModal:cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={onSave} text={t('menuDetails:translationEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, onSave, isLoading])

  const onAddLanguage = useCallback((lang) => {
    setLanguageList(prevState => [...prevState, lang])
    setSelectedLanguage('')
    if (selectRef.current !== null) {
      selectRef.current.resetSelect()
    }
  }, [])

  const onManageLanguagesSuccess = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onManageLanguagesError = useCallback(() => {
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  useEffect(() => {
    dispatch(actions.languages.fetchLanguages())
  }, [dispatch])

  useSagaTakeEvery(actions.languages.manageLanguagesSuccess, onManageLanguagesSuccess)
  useSagaTakeEvery(actions.languages.manageLanguagesError, onManageLanguagesError)

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:translationEditionModal:title')}
      onRequestClose={onRequestClose}
      className="modal--config-translations modal--md"
      footerContent={footerContent}
      >
      <div className="c-gray-600">{t('menuDetails:translationEditionModal:desc')}</div>
      <ul className="list list--languages mt-3">
        {languageList.map((lang, i) => (
          <div className="list__item f f-jc-space-b" key={lang}>
            <div>
              {getIcon(lang)}
              <span className="list__text" >{getTranslationLabel(lang)}</span>
            </div>
            <button className="c-primary" onClick={() => onDeleteLang(lang)}>{t('menuDetails:translationEditionModal:delete')}</button>
          </div>
        ))}
      </ul>
      <hr className="mt-3 mb-3" />
      <div>
        {isLoadingDatasList ?
          <Loader />
          :
          <>
            {(languageOptions.length > 0) &&
              <div className="f f-ai-center">
                <InputSelect className="mr-2" selectRef={selectRef} isSearchable={false} placeholder={t('menuDetails:translationEditionModal:inputLanguage:title')}  options={languageOptions} onChange={onChangeSelect} />
                <Button onClick={() => onAddLanguage(selectedLanguage)} isDisabled={!hasSelectedLanguage} iconLeft="icon icon-plus c-cream-500" text={t('menuDetails:translationEditionModal:add')} />
              </div>
            }
          </>
        }
      </div>
    </LayoutModal>
  )
}

export default ModalConfigTranslations
