import React, {
  FC,
  useState,
} from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { useTranslation } from 'react-i18next'
import LayoutWelcomeKit from 'component/Layouts/LayoutWelcomeKit'
import { useSelector } from 'react-redux';
import { selectors } from 'store'
import Button from 'component/Buttons/Button'
import i18next from 'i18next'

interface ModalMenuWelcomeKitProps {
  onRequestClose: () => void
}

const ModalMenuWelcomeKit: FC<ModalMenuWelcomeKitProps> = ({
  onRequestClose,
}) => {
  const { t } = useTranslation(['menus', 'common'])

  const [step, setStep] = useState(1)
  const goToNext = () => setStep((step) => step + 1)

  const business = useSelector(selectors.business.business)
  const menuURL = `${process.env.REACT_APP_DIGITO_WEBSITE}/business/${business.slug}`;
  const qrcodeURL = `${process.env.REACT_APP_PICTURE_URL}/${business.qrcodeUrl}`;
  const qrFilename = `qr_${business.slug}.png`;

  const orderButton = (
    <Button className="mt-2 mb-2" text={t('menuModalWelcomeKit.step_2.accept_button')} isPrimary={true} isExternal={true} link={`https://www.cognitoforms.com/DIGITOMENU2/DIGITO%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%82%AD%E3%83%83%E3%83%88%E6%B3%A8%E6%96%87%E6%9B%B82`} />
  )

  return (
    <LayoutModal
      onRequestClose={onRequestClose}
      className="modal--flow modal--welcome-kit modal--overflow modal--lg"
    >
      {step === 1 && (
        <LayoutWelcomeKit
          uppertitle={t('menuModalWelcomeKit.step_1.uppertitle')}
          title={t('menuModalWelcomeKit.step_1.title')}
          description={t('menuModalWelcomeKit.step_1.desc')}
          img={`/static/images/menu-online/bg-congratulation_${i18next.language}.png`}
          onNextButton={goToNext}
          buttonContent={t('menuModalWelcomeKit.step_1.button')}
          qrCode={
            <img
              src={qrcodeURL}
              className="qrcode-image"
              width={256}
              height={256}
              alt="QR code"
            />
          }
        >
          <a className="btn ft-600" href={`${qrcodeURL}?response-content-disposition=attachment;%20${qrFilename}`} download={qrFilename}>
            <span className="icon icon-download c-cream-500 icon-20 mr-1" />
            {t('menuModalWelcomeKit.step_1.download_button')}
          </a>
          <p className="ft-primary mt-3 mb-2 ft-500 c-cream-500">{t('menuModalWelcomeKit.step_1.desc2')}</p>
          <a className="ft-500 c-primary" href={menuURL} target="_new" rel="noopener">
            <span className="icon icon-link mr-1" />
            {menuURL}
          </a>
        </LayoutWelcomeKit>
      )}
      {step === 2 && (
        <LayoutWelcomeKit
          uppertitle={t('menuModalWelcomeKit.step_2.uppertitle')}
          title={t('menuModalWelcomeKit.step_2.title')}
          description={t('menuModalWelcomeKit.step_2.desc')}
          img="/static/images/menu-online/welcome-kit.jpg"
          onLeaveButton={onRequestClose}
          submitButton={orderButton}
          buttonContent={t('menuModalWelcomeKit.step_2.accept_button')}
        >
          <ul className="ft-primary">
            <li className="mb-1">
              <span className="icon icon-check c-green-600 mr-2" />
              {t('menuModalWelcomeKit.step_2.list.kit_1')}
            </li>
            <li className="mb-1">
              <span className="icon icon-check c-green-600 mr-2" />
              {t('menuModalWelcomeKit.step_2.list.kit_2')}
            </li>
            <li className="mb-1">
              <span className="icon icon-check c-green-600 mr-2" />
              {t('menuModalWelcomeKit.step_2.list.kit_3')}
            </li>
            <li className="mb-1">
              <span className="icon icon-check c-green-600 mr-2" />
              {t('menuModalWelcomeKit.step_2.list.kit_4')}
            </li>
            <li className="mb-1">
              <span className="icon icon-check c-green-600 mr-2" />
              {t('menuModalWelcomeKit.step_2.list.kit_5')}
            </li>
          </ul>
          <p className="ft-primary mt-2 mb-1 ft-500 c-cream-500">{t('menuModalWelcomeKit.step_2.desc2')}</p>
        </LayoutWelcomeKit>
      )}
    </LayoutModal>
  )
}

export default ModalMenuWelcomeKit
