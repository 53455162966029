import React, { FC, useMemo, useCallback, useState, useContext } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from 'react-hook-form'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'
// import { getError, validationPatterns } from 'utils/helpers'

interface Inputs {
  facebookId: string,
  twitterId: string,
  instagramId: string,
  text: string
}

interface ModalSocialEditionProps {
  onRequestClose: () => void,
  menuId: number
}

const ModalSocialEdition: FC<ModalSocialEditionProps> = ({onRequestClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menuDetails', 'common'])
  const formId = useMemo(() => 'modal-social-edition-form', [])

  const { showStickyBanner } = useContext(StickyBannerContext)

  const business = useSelector(selectors.business.business)
  const facebookId = useSelector(selectors.business.facebookId);
  const [facebookHandle, setFacebookHandle] = useState(facebookId);
  const twitterId = useSelector(selectors.business.twitterId);
  const [twitterHandle, setTwitterHandle] = useState(twitterId);
  const instagramId = useSelector(selectors.business.instagramId);
  const [instagramHandle, setInstagramHandle] = useState(instagramId);

  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, errors, register, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form: Inputs) => {
    setIsLoading(true);
    const payload = {
      id: business.id,
      instagramId: form.instagramId, twitterId: form.twitterId, facebookId: form.facebookId
    };
    dispatch(actions.business.updateBusinessSocialNetworks(payload));
  }, [dispatch, business.id])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdtion = useCallback(() => {
    console.log(BannerError)
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  const onBlurInputText = useCallback((name, value, setValue) => {
    var newValue = value.replace(/^ */, '') // Left trim
      .replace(/^https?:\/\/.*?\//i, '') // Hostname
      .replace(/^@/, '') // @ prefix
      .replace(/&.*?=.*$/, '') // Any secondary query params
      .replace(/\/.*?$/, '') // After slash
      .replace(/ *$/, ''); // Right trim

    if (value !== newValue) {
      setValue(newValue);
    }

    if (name === 'facebookId') {
      setFacebookHandle(newValue);
    }

    if (name === 'twitterId') {
      setTwitterHandle(newValue);
    }

    if (name === 'instagramId') {
      setInstagramHandle(newValue);
    }
  }, [setFacebookHandle, setTwitterHandle, setInstagramHandle])

  const onChangeInputText = useCallback(() => {
    clearErrors();
  }, [clearErrors])

  useSagaTakeEvery(actions.business.updateBusinessSocialNetworksSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updateBusinessSocialNetworksError, onErrorEdtion)

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} text={t( 'menuDetails:ctas.save')}  type="submit" form={formId} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:socialEditionModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <InputText
            maxLength={80}
            defaultValue={facebookId}
            handleChange={onChangeInputText}
            handleBlur={onBlurInputText}
            className="input--material mb-1"
            label={t('menuDetails:socialEditionModal.inputFacebook')}
            name="facebookId"
            placeholder={t('menuDetails:socialEditionModal.inputFacebook')}
            inputRef={register({})}
            error={errors.facebookId} />
          <pre className="ml-3">{facebookHandle && <span>https://www.facebook.com/{facebookHandle}</span>}&nbsp;</pre>
          <InputText
            maxLength={50}
            defaultValue={twitterId}
            handleChange={onChangeInputText}
            handleBlur={onBlurInputText}
            className="input--material mb-1"
            label={t('menuDetails:socialEditionModal.inputTwitter')}
            name="twitterId"
            placeholder={t('menuDetails:socialEditionModal.inputTwitter')}
            inputRef={register({})}
            error={errors.twitterId} />
          <pre className="ml-3">{twitterHandle && <span>https://twitter.com/{twitterHandle}</span>}&nbsp;</pre>
          <InputText
            maxLength={72}
            defaultValue={instagramId}
            handleChange={onChangeInputText}
            handleBlur={onBlurInputText}
            className="input--material mb-1"
            label={t('menuDetails:socialEditionModal.inputInstagram')}
            placeholder={t('menuDetails:socialEditionModal.inputInstagram')}
            name="instagramId"
            inputRef={register({})}
            error={errors.instagramId} />
          <pre className="ml-3">{instagramHandle && <span>https://www.instagram.com/{instagramHandle}</span>}&nbsp;</pre>
        </form>

    </LayoutModal>
  )
}

export default ModalSocialEdition
