import { customFetch } from 'api/helpers/customFetch'
import { API_REGISTER_URL, API_RESEND_VERIFY_URL, API_IMPORT_URL, API_CHECKOUT_IMPORT_URL } from 'api/helpers/routes'
import { OnboardingBusinessRegisterType, OnboardingUserRegisterType } from '.'

export const register = async (user: OnboardingUserRegisterType, business: OnboardingBusinessRegisterType): Promise<{businessId: number}> => {
  const request = await customFetch.post(API_REGISTER_URL, { 
    user,
    business
  })
  const response = await request.json();
  return { businessId: response.businessId }
}


export const resendVerifyEmail = async (email: string): Promise<Response> => {
  const request = await customFetch.post(API_RESEND_VERIFY_URL, { 
    email
  })
  return request
}

export const createImport = async (): Promise<{id: number}> => {
  const request = await customFetch.post(API_IMPORT_URL, null)
  const response = await request.json();
  return { id: response.id }
}


export const postImportUrl = async (id: number, url: string): Promise<Response> => {
  const request = await customFetch.post(`${API_IMPORT_URL}/${id}/url`, { url })
  return request
}

export const postImportFile = async (CSRFToken: string, id: number, files: File[]): Promise<Response> => {
  const formData = new FormData()
  files.forEach(file => formData.append('file', file))
  const request = await fetch(`${API_IMPORT_URL}/${id}/files`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': CSRFToken,
    },
    body: formData
  })
  return request
}


export const postImportStripeCheckout = async (menuImportId: number): Promise<{sessionId: string}> => {
  const request = await customFetch.post(`${API_CHECKOUT_IMPORT_URL}/${menuImportId}`, {}) // Create checkout session
  const response = await request.json()
  return { sessionId: response.id }
}