import React, { FC, useCallback } from 'react'
import FormTranslation from 'component/Form/FormTranslation';
import { useForm, FormProvider } from "react-hook-form";
import { OriginalWithTranslations } from 'store/Translations';
import { useDispatch } from 'react-redux';
import { actions } from 'store';

interface FormListTranslationProps {
  type: string,
  language: string,
  items: OriginalWithTranslations[],
}

const FormListTranslation: FC<FormListTranslationProps> = ({type, items, language}) => {
  const dispatch = useDispatch()
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  
  const onSubmit = useCallback((data: any) => {
    switch(type) {
      case 'categories':
        dispatch(actions.translations.updateCategories({languageId: language, categories: data.categories}))
        break;
      case 'subcategories':
        dispatch(actions.translations.updateCategories({languageId: language, categories: data.subcategories}))
        break
      case 'products':
        dispatch(actions.translations.updateProducts({languageId: language, products: data.products}))
        break
    }
  }, [language, type, dispatch])

  return (
    <form className="form" onSubmit={formMethods.handleSubmit(onSubmit)} id={`translation-${type}`}>
      <FormProvider {...formMethods}>
        { items.map((item, index) => (item && item.original) && <FormTranslation type={type} index={index} key={`${type}-${item.translation.id}`} original={item.original} translation={item.translation} />)}
      </FormProvider>
    </form>
  )
}

export default FormListTranslation