import DashboardMiddleware from 'api/DashboardMiddleware';
import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { actions, selectors } from 'store';
import { ADMIN_ROUTES, DASHBOARD_ROUTES, PUBLIC_ROUTES } from 'utils/routers/constants';
import { ModalProvider } from 'utils/hooks/ModalBehavior'
import ModalRoot from 'utils/hooks/ModalBehavior/modal-root'
import StickyBannerRoot from 'utils/hooks/StickyBannerBehavior/sticky-banner-root';
import { StickyBannerProvider } from 'utils/hooks/StickyBannerBehavior';

const EmptyComponent: FC = () => <div></div>

const App: FC = () => {
  const isReady = useSelector(selectors.app.isReady)
  const userConnected = useSelector(selectors.user.connected)
  const userAdmin = useSelector(selectors.user.isAdmin)
  const hasPendingMenuImports = useSelector(selectors.business.hasPendingMenuImports)
  const isManagingBusiness = useSelector(selectors.admin.managingBusiness)
  const dispatch = useDispatch()  

  const renderRoute = useCallback((path: string, component: React.ComponentType, index: string) => <Route exact path={path} component={component} key={index} />, [])

  const publicRouteComponents = useMemo(() => 
    PUBLIC_ROUTES.map((route: any, index: number) => renderRoute(route.path, route.page, `${route.path}-${index}`)), [renderRoute]
  )

  const dashboardRoutesComponents = useMemo(() => 
    DASHBOARD_ROUTES.map((route: any, index: number) => {
      const component = userConnected ? route.page : EmptyComponent
      return renderRoute(route.path, component, `${route.path}-${index}`)
    })
  , [userConnected, renderRoute])

  const adminRoutesComponents = useMemo(
    () =>
      ADMIN_ROUTES.map((route: any, index: number) => {
        const component = userAdmin ? route.page : EmptyComponent
        return renderRoute(route.path, component, `${route.path}-${index}`)
      }),
    [userAdmin, renderRoute]
  )

  useEffect(() => {
    dispatch(actions.app.init())
  }, [dispatch])

  return (
    isReady ?
      <Router>
        <div className={`App ${hasPendingMenuImports && !isManagingBusiness ? 'has-pending-menu-imports' : ''}`}>
          <header className="App-header">
          </header>
          <Switch>
            <StickyBannerProvider>
              <ModalProvider>
              
                <ModalRoot />
                <StickyBannerRoot />
                <DashboardMiddleware>
                  {publicRouteComponents}
                  {dashboardRoutesComponents}
                  {adminRoutesComponents}
                </DashboardMiddleware>
              </ModalProvider>
            </StickyBannerProvider>
          </Switch>
        </div>
      </Router>
    : 
    <></>
  );
}

export default App;
