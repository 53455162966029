import CardCustomization from 'component/Cards/CardCustomization';
import ModalConfigColors from 'component/Modals/ModalConfigColors';
import ModalConfigTheme from 'component/Modals/ModalConfigTheme';
import ModalConfigTranslations from 'component/Modals/ModalConfigTranslations';
import ModalLogoEdition from 'component/Modals/ModalLogoEdition';
import ModalSocialEdition from 'component/Modals/ModalSocialEdition';
import ModalPremium from 'component/Modals/ModalPremium';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { ModalContext } from 'utils/hooks/ModalBehavior';

interface MenuCustomizationProps {
  className?: string,
  menuId: number
}

const MenuCustomization: FC<MenuCustomizationProps> = ({ menuId, className}) => {
  const { t } = useTranslation(['menuDetails', 'common'])
  const { showModal } = useContext(ModalContext)
  const isDarkTheme = useSelector(selectors.business.isDarkTheme)
  const actionColor = useSelector(selectors.business.actionColor)
  const backgroundColor = useSelector(selectors.business.backgroundColor)
  const isPremium = useSelector(selectors.business.isPremium)
  const facebookId = useSelector(selectors.business.facebookId);
  const twitterId = useSelector(selectors.business.twitterId);
  const instagramId = useSelector(selectors.business.instagramId);
  const bizCfg = useSelector(selectors.business.businessConfig);
  const businessTranslationLanguages = useSelector(selectors.business.translationLanguages)

  const listTranslations = useMemo(() => businessTranslationLanguages.map((lang) => t(`common:languages:${lang.toLowerCase()}`)).join(', '), [businessTranslationLanguages, t])

  const showModalTheme = useCallback(( ) => showModal(ModalConfigTheme), [showModal])

  const showModalLogoEdition = useCallback(( ) => showModal(ModalLogoEdition), [showModal])
  const showModalSocialEdition = useCallback(( ) => showModal(ModalSocialEdition), [showModal])

  const showModalTranslationEdition = useCallback(( ) => showModal(ModalConfigTranslations), [showModal])

  const showModalColors = useCallback(( ) => {
    if (isPremium) {
      showModal(ModalConfigColors, {menuId})
    } else {
      showModal(ModalPremium)
    }
  }, [showModal, menuId, isPremium])

  return (
    <div className={className ? className : ''} id="MenuCustomization">
      <div className="f f-jc-space-b mb-1">
        <h2>{t('menuDetails:customization:title')}</h2>
      </div>
      <div className="c-gray-600">{t('menuDetails:customization:desc')}</div>
      <div className="mt-3">
        {!isPremium && <CardCustomization
          title={t('menuDetails:customization:menuTheme')}
          value={isDarkTheme ? t('menuDetails:customization:theme:dark') : t('menuDetails:customization:theme:light')}
          onClickEdit={showModalTheme}
        />}
        <CardCustomization
          title={t('menuDetails:customization.logo')}
          value={[
            (bizCfg.logoUrl || bizCfg.coverUrl) ? <span key="logoCoverUrl" className="icon icon-image icon-16" /> : null,
          ]}
          showInfo={(bizCfg.logoUrl || bizCfg.coverUrl) ? true : false}
          className="card-customization--logo-sns"
          onClickEdit={showModalLogoEdition}
        />
        <CardCustomization
          title={t('menuDetails:customization.social')}
          value={[
            facebookId ? <span key="facebookId" className="icon icon-facebook icon-16" /> : null,
            twitterId ? <span key="twitterId" className="icon icon-twitter icon-16" /> : null,
            instagramId ? <span key="instagramId" className="icon icon-insta-fill icon-16" /> : null,
          ]}
          className="card-customization--logo-sns"
          onClickEdit={showModalSocialEdition}
        />
        <CardCustomization
          title={t('menuDetails:customization.mainColor')}
          value={[
            <span key="backgroundColor" className="card-customization__color" style={{backgroundColor}} />,
            <span key="actionColor" className="card-customization__color" style={{backgroundColor: actionColor}} />
          ]}
          onClickEdit={showModalColors}
          isPremiumOnly={true}
        />
        <CardCustomization title={t('menuDetails:customization:translations')} value={listTranslations} onClickEdit={showModalTranslationEdition} isPremiumOnly={true} />
      </div>
    </div>
  )
}

export default MenuCustomization;
