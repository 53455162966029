import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { LayoutModalRight } from 'component/Layouts/LayoutModalRight'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import InputSelect from 'component/Form/Inputs/InputSelect'
import { useTranslation } from 'react-i18next'
import { MENU_STATUS } from 'store/Menus'
import MenuPreviewWrapper from 'component/MenuPreviewWrapper'
import { defaultLanguage } from 'store/Translations'

interface ModalPrevisualizeProps {
  onRequestClose: () => void
}

const ModalPrevisualize: FC<ModalPrevisualizeProps> = ({onRequestClose}) => {
  const { t } = useTranslation(['menus'])
  const dispatch = useDispatch()
  const menus = useSelector(selectors.menus.menus)
  const businessLanguages = useSelector(selectors.business.languages)
  const defaultMenusOptions = useMemo(() => menus.map(menu => ({
    label: menu.name,
    value: menu.id.toString(),
    icon: <span className={`label label--light ft-none mr-2 ${menu.status === MENU_STATUS.VISIBLE ? 'label--green' : 'label--red'}`}>
        {menu.status === MENU_STATUS.VISIBLE ? t('menus:menu:status:online') : t('menus:menu:status:offline')}
      </span>
  })), [menus, t])

  const defaultLanguagesOptions = useMemo(() => businessLanguages.map(lang => ({
    label: lang.id,
    value: lang.id,
    icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.id.toLowerCase()}.png`}  alt="" />
  })), [businessLanguages])

  const [menuId, setMenuId] = useState(parseInt(defaultMenusOptions[0].value))
  const [lang, setLang] = useState(defaultLanguagesOptions && defaultLanguagesOptions.length && defaultLanguagesOptions[0].value ? defaultLanguagesOptions[0].value : defaultLanguage);
  const langOption = useMemo(() => {
    return {
      label: lang,
      value: lang,
      icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`} alt="" />
    }
  }, [lang]);

  const onChangeMenuId = useCallback((name, value) => {
    const id = parseInt(value)
    setMenuId(id)
  }, [])

  const onChangeLang = useCallback((name, value) => {
    setLang(value);
  }, [setLang])

  useEffect(() => {
    dispatch(actions.menuDetails.fetchMenu({id: menuId, lang}))
  }, [dispatch, menuId, lang])

  return (
    <LayoutModalRight
      onRequestClose={onRequestClose}
      className="modal--right modal--previsualize"
      title="Menu preview"
      >
        <div className="f">
          <InputSelect defaultOption={defaultMenusOptions[0]} placeholder="" searchPlaceholder={t('menuDetails:menuProductEditionModal:inputProduct:searchPlaceholder')} options={defaultMenusOptions} onChange={onChangeMenuId} />
          <InputSelect className="ml-2" defaultOption={langOption} placeholder="" searchPlaceholder={t('menuDetails:menuProductEditionModal:inputProduct:searchPlaceholder')} options={defaultLanguagesOptions} onChange={onChangeLang} />
        </div>
        <div className="modal__preview">
          <MenuPreviewWrapper menuId={menuId} lang={lang} />
        </div>
    </LayoutModalRight>
  )
}

export default ModalPrevisualize
