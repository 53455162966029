import i18next from "i18next";
import { BUSINESS_TYPE, prefectures } from "store/Business";

export const groupBy = (array: any[], prop: string) => {
  return array.reduce(function (acc, obj) {
    const key = obj[prop];
    if(!acc[key]){
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const orderArrayByKey = (baseArray: string[], key: string) => (a: any, b: any) => baseArray.indexOf(a[key]) > baseArray.indexOf(b[key]) ? 1 : -1

export function checkExtension (fileName: string, type: string) {
  const regex = new RegExp(".("+type+")$")
  if (!fileName.toLocaleLowerCase().match(regex)) return false
  return true
}

export function checkImageSize(file: any, rule: any, callback: Function, error: Function) {
  const reader = new FileReader();
  reader.onload = function (e) {
    var img: any = document.createElement('img');
    img.onload = function() {
      if (img.width < rule.minWidth || img.height < rule.minHeight) {
        return error()
      }
      return callback()
    };
    img.src = reader.result;
  };
  reader.readAsDataURL(file);
};



export function fileConvertSize(aSize: string) {
  const size = Math.abs(parseInt(aSize, 10))
  const def: any[] = [
    [1, 'octets'],
    [1024, 'ko'],
    [1024 * 1024, 'Mo'],
    [1024 * 1024 * 1024, 'Go'],
    [1024 * 1024 * 1024 * 1024, 'To'],
  ]
  for (var i = 0; i < def.length; i++) {
    if (size < def[i][0])
      return (size / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1]
  }
}

export function getStringExtension(text: string) {
  return text.split('.').pop()
}

export function removeStringExtension(text: string) {
  return text.substring(0, text.indexOf('.'))
}

export function getSymbolFromCurrency(currency: string) {
  switch(currency) {
    case "JPY": return "円"
    case "EUR": return "€"
    case "USD": return "$"
    case "GBP": return "£"
    default: return "円"
  }
}

export function getNumberFormated(number: any) {
  return new Intl.NumberFormat('ja-JP').format(number)
}

export const findIndexById = (array: any[], id: number) => {
  const item = array.find((item) => item.id === id)
    return {
      item,
      index: item ? array.indexOf(item) : -1,
    }
}

export const switchItemIndex = (array: any[], item: any, index: number, targetIndex: number) => {
  const newArray = [...array]
  newArray.splice(index, 1)
  newArray.splice(targetIndex, 0, item)
  return newArray
}

export const validationPatterns = {
  email:  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  price: /^-?\d+\.?\d*$/,
  zipCode: /^\d{3}-?\d{4}$/,
  telephone: /^[+ ()[\]0-9xX*,:/.-]{3,}$/,
  notEmptyString: /^(?!\s*$).+/
}

export const getError = (key: string|undefined) => {
  if (i18next.exists(`common:errors:${key}`)) {
    return i18next.t(`common:errors:${key}`)
  } else {
    return i18next.t(`common:errors:error_unknow`);
  }
}

export const formatDate = (number: number) => {
  if (number < 10) {
    return `0${number}`
  }
  return number
}

export const formatFullDate = (d: Date) => {
  return `${d.getFullYear()}/${formatDate(d.getMonth() + 1)}/${formatDate(d.getDate())}`;
}

export const removeTrailingZeros = (number: string) => {
  return Number(number).toString()
}

export const listPrefectures = (lang: string) => {
  return prefectures.map((prefecture) => ({
    i18n: i18next.t(`common:prefectures:${prefecture}`, { lang: lang || 'ja' }),
    value: prefecture
  }))
}

export const listBusinessTypes = () => {
  return Object.values(BUSINESS_TYPE).map((type) => {
    return {
      label: i18next.t(`common:businessTypes:${type.toLowerCase()}`),
      value: type
    }
  })
}
