import React, { FC, useMemo, useCallback, useState, useContext } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import TextArea from 'component/Form/Inputs/TextArea';
import InputText from 'component/Form/Inputs/InputText';
import InputImageFile from 'component/Form/Inputs/InputImageFile'
import { useForm } from 'react-hook-form'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'

interface Inputs {
}

interface ModalStoryProps {
  onRequestClose: () => void,
  menuId: number
}

const ModalStory: FC<ModalStoryProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails']);
  const formId = useMemo(() => 'modal-story-form', []);

  const { showStickyBanner } = useContext(StickyBannerContext);

  const business = useSelector(selectors.business.business);
  const [estYear, setEstYear] = useState<string>(business.estYear ? business.estYear : '');
  const [story, setStory] = useState<string>(business.businessConfig && business.businessConfig?.i18n?.story ? business.businessConfig.i18n.story : '');
  const storyUrl = useSelector(selectors.business.storyUrl);
  const storyUrlId = useSelector(selectors.business.storyUrlId);

  const [fileStory, setFileStory] = useState<File | null>()
  const [imageStory, setImageStory] = useState<{url: string, name: string}>({ url: storyUrl || '', name: '' });

  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form: Inputs) => {
    setIsLoading(true);
    const imageFileStory = fileStory ? { imageFileStory: fileStory } : {};
    const storyUrl = imageStory.url.length > 0 ? storyUrlId : '';
    const payload = {
      id: business.id,
      estYear: estYear,
      ...imageFileStory,
      config: {
        story  : story,
        storyUrl,
      }
    };
    dispatch(actions.business.updateBusinessStory(payload));
  }, [dispatch, business.id, story, estYear, fileStory, imageStory, storyUrlId])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    console.log(BannerError)
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  const onChangeInputFileStory = useCallback((name, file, imageFile) => {
    setImageStory(imageFile);
    setFileStory(file);
  }, [setImageStory, setFileStory])

  const onChangeInput = useCallback((name, value) => {
    clearErrors()
    switch(name) {
      case 'story':
        setStory(value ? value : '');
        break;
      case 'estYear':
        setEstYear(value ? value : '');
        break;
      default:
        break;
    }
  }, [clearErrors, setStory, setEstYear])

  useSagaTakeEvery(actions.business.updateBusinessStorySuccess, onSuccessEdition);
  useSagaTakeEvery(actions.business.updateBusinessStoryError, onErrorEdition);

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} text={t( 'menuDetails:ctas.save')}  type="submit" form={formId} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:storyModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <p className="ft-secondary">{t('menuDetails:storyModal.inputStory.description')}</p>
          <TextArea
            maxLength={300}
            defaultValue={story}
            onChange={onChangeInput}
            className="input--material textarea-tall mb-1"
            label={t('menuDetails:storyModal.inputStory.label')}
            name="story"
          />
          <InputText
            defaultValue={estYear}
            type="number"
            min={578}
            max={new Date().getFullYear()}
            maxLength={4}
            className="input--material mb-1"
            placeholder={t('menuDetails:storyModal.inputEstYear.label')}
            label={t('menuDetails:storyModal.inputEstYear.label')}
            name="estYear"
            handleChange={onChangeInput}
          />
          <p className="ft-secondary">{t('menuDetails:storyModal.inputPhoto.description')}</p>
          <InputImageFile
            name="storyfile"
            title={t('menuDetails:storyModal.inputPhoto.empty')}
            ctaText={t('menuDetails:storyModal.inputPhoto.cta')}
            defaultImage={imageStory}
            handleOnChange={onChangeInputFileStory}
            cardClassName="mb-1"
            className="mb-1"
            description={`${t('menuDetails:storyModal.inputPhoto.conditions')}`}
            validate={{
              type: "jpg|jpeg|png",
              maxBytes: 1024 * 1024 * 10
            }}
          />
        </form>

    </LayoutModal>
  )
}

export default ModalStory
