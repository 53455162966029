import React, { FC, useCallback, useContext, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { actions } from 'store'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import BannerInfo from 'component/Banners/BannerInfo'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { getError } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

interface ModalResetPasswordProps {
  hideClose?: boolean,
  overlayClassname?: string
  onRequestClose: () => void
}

interface Inputs {
  newPassword: string,
  confirmedPassword: string
}

const ModalResetPassword: FC<ModalResetPasswordProps> = (props) => {
  const { onRequestClose } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['login'])
  const { showStickyBanner } = useContext(StickyBannerContext)
  const [globalError, setGlobalError] = useState('')
  const query = new URLSearchParams(useLocation().search)
  const key = query.get('reset_key')

  const { handleSubmit, register, errors } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const onChangeFormValue = useCallback((name, value) => {
    setGlobalError('')
  }, [])

  const onSubmit = (payload: Inputs) =>  {
    if (payload.newPassword !== payload.confirmedPassword) {
      setGlobalError('inputConfirmNewPassword')
      return;
    }

    if (key) {
      dispatch(actions.user.updateResetPassword({
        password: payload.newPassword,
        emailResetKey: key
      }))
    }
  }

  const isResetPasswordPage = matchPath(useLocation().pathname, {
    path: RouteUrls.ResetPassword,
    exact: true,
    strict: false
  })

  const onSuccess = useCallback((action) => {
    history.push("/login")
    showStickyBanner(BannerInfo, { message: t(`login:success:resetPassword`)})
  }, [history, showStickyBanner, t])

  const onError = useCallback((action) => {
    setGlobalError(action.payload.message)
  }, [])

  useSagaTakeEvery(actions.user.updateResetPasswordSuccess, onSuccess)
  useSagaTakeEvery(actions.user.updateResetPasswordError, onError)
  
  const requiredPasswordTranslation = getError('required')

  const handleOnClose = useCallback(() => {
    if (isResetPasswordPage) {
      return;
    }
    onRequestClose()
  }, [onRequestClose, isResetPasswordPage])


  return (
    <LayoutModal onRequestClose={handleOnClose} hideClose={props.hideClose} overlayClassname={props.overlayClassname} className="modal--sm">
      <h1 className="mb-2 ft-center">{t('login:resetPasswordModal.title')}</h1>
      <form onSubmit={handleSubmit((onSubmit))}>

        {globalError && (
          <div className="c-red ft-tertiary mb-2 ft-700 ft-center">
            {getError(globalError)}
          </div>
        )}

        <InputText 
          maxLength={80} 
          minLength={8} 
          className="mb-1" 
          placeholder={t('login:resetPasswordModal:inputNewPassword')} type="password"
          name="newPassword"
          inputRef={register({
            required: requiredPasswordTranslation
          })}
          autocomplete="off"
          required={false}
          error={errors.newPassword}
          handleChange={onChangeFormValue}
        />
        <InputText 
          maxLength={80} 
          minLength={8} 
          className="mb-4" 
          placeholder={t('login:resetPasswordModal:inputConfirmPassword')} 
          type="password"
          name="confirmedPassword"
          inputRef={register({
            required: requiredPasswordTranslation
          })}
          autocomplete="off"
          required={false}
          error={errors.newPassword}
          handleChange={onChangeFormValue}
        />
        <Button className="f f-jc-center h5" text={t('login:resetPasswordModal.button')} isFull={true} isPrimary={true} type="submit"  />
      </form>
    </LayoutModal>
  )
}

export default ModalResetPassword
