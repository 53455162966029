import ModalPremium from 'component/Modals/ModalPremium'
import PremiumTag from 'component/PremiumTag'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from 'store'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface CardCustomizationProps {
  className?: string,
  title: string,
  value?: string | any[],
  icon?: React.ReactNode,
  isPremiumOnly?: boolean,
  onClickEdit: () => void,
  showInfo?: boolean
}

const CardCustomization: FC<CardCustomizationProps> = ({className, title, icon, value, isPremiumOnly, onClickEdit, showInfo=true}) => {
  const { showModal } = useContext(ModalContext)
  const { t } = useTranslation(['menuDetails'])
  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const isNotAllowed = useMemo(() => isPremiumOnly && !isBusinessPremium, [isPremiumOnly, isBusinessPremium])

  const onClickPremiumTag = useCallback(() => {
    showModal(ModalPremium)
  }, [showModal])

  const hasValue = value || (Array.isArray(value) && value.length);

  return (
    <div className={`card-customization ${className ? className : ''}`}>
      <div className="card-customization__left">
        <div className={`ft-600 ft-primary card-customization__title ${!showInfo ? 'card-customization__title--no-info' : ''}`}>{title}</div>
        { showInfo && <div className="ft-secondary c-gray-600 f f-ai-center">{
          hasValue ? (Array.isArray(value) ? value : [value]).map(val => val ? val : null) : t('menuDetails:customization:empty')
        } {icon ? icon : null}</div>}
      </div>
      <div className="card-customization__right">
        {isNotAllowed && <PremiumTag onClick={onClickPremiumTag} className="card-customization__premium" />}
        {!isNotAllowed && <button className="ml-1 ft-700 c-gray-800 ft-small ml-3" onClick={onClickEdit}>{t('menuDetails:customization:edit')}</button>}
      </div>
    </div>
  )
}

export default CardCustomization
