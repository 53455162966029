import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'

const i18nHelper = () => new Promise((resolve, reject) => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      react: {
        useSuspense: false,
      },
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      defaultNS: 'common',
      ns: ['common', 'dashboard', 'menus', 'login', 'menuDetails', 'onboarding', 'legals'],
      fallbackLng: ['en', 'ja', 'de', 'es', 'fr', 'ko', 'zh'],
      debug: false,
      interpolation: {
        escapeValue: false
      },
      detection: {
        order: [
          'querystring', 'localStorage', 'htmlTag',
        ],
        caches: ['localStorage'],
        htmlTag: document.documentElement,
      },
    }).then(() => resolve(''))
})

export default i18nHelper
