import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TranslationType } from 'store/Translations'
import InputText from '../Inputs/InputText'
import TextArea from '../Inputs/TextArea'

interface FormTranslationProps {
  type: string,
  index: number,
  original: TranslationType & { photoUrl: string | null},
  translation: TranslationType
}

const FormTranslation: FC<FormTranslationProps> = ({index, type, original, translation}) => {
  const { t } = useTranslation(['menus'])
  const { setValue, register, unregister } = useFormContext()
  const [isFormRegistered, setIsFormRegistered] = useState(false)
  const keys = useMemo(() => ({ id: `[${type}][${index}].id`, name: `[${type}][${index}].name`, description: `[${type}][${index}].description` }), [type, index])
  const defaultName = translation.i18n ? translation.i18n.name : ''
  const defaultDescription = translation.i18n ? translation.i18n.description : ''
  const isProofread = !!(translation.i18n && translation.i18n.translator && translation.i18n.translator === 'Proofread');
  
  const inputName = t(`menus:translations:inputName:${type}`)

  useEffect(() => {
    setIsFormRegistered(true)
    register(`[${type}][${index}].id`)
    register(`[${type}][${index}].description`)
    register(`[${type}][${index}].name`)

    return () => {
      setIsFormRegistered(false)
      unregister(`[${type}][${index}].id`)
      unregister(`[${type}][${index}].description`)
      unregister(`[${type}][${index}].name`)
    }
  }, [register, unregister, index, type])

  useEffect(() => {
    if (isFormRegistered) {
      setValue(keys.id, translation.id)
      setValue(keys.name, defaultName)
      setValue(keys.description, defaultDescription)
    }
  }, [setValue, keys, index, type, defaultDescription, defaultName, isFormRegistered, translation])

  const onChangeInputText = useCallback((name, value) => {
    if (name === keys.name) {
      setValue(keys.name, value)
    } else {
      setValue(keys.description, value)
    }
  }, [setValue, keys])
  
  return (
    <div className="form-translation">
      <input type="hidden" name={keys.id} value={translation.id} />
      <div className="form-translation__left">
        {original.photoUrl && <div className="form-translation__img"><img src={original.photoUrl} alt="" /></div>}
        <div>
          <div className="ft-600 h3">{original.i18n && original.i18n.name}</div>
          {type !== 'subcategories' && <div className="ft-primary pre-wrap">{original.i18n && original.i18n.description}</div>}
        </div>
      </div>
      <div className="form-translation__right">
        {isProofread && <div className="form-translation__proofread">{t('menus:proofreadLabel')} ✔</div>}
        <InputText 
          required={true} 
          maxLength={255} 
          className="input--material" 
          label={inputName} 
          id={keys.name} 
          placeholder={inputName} 
          defaultValue={defaultName} 
          name={keys.name} 
          handleChange={onChangeInputText} />
        {type !== 'subcategories' && <TextArea 
          className="input--material mt-1" 
          label={t('menus:translations:inputDesc:title')} 
          defaultValue={defaultDescription} 
          name={keys.description} 
          id={keys.description} 
          onChange={onChangeInputText}/>}
      </div>
    </div>
  )
}

export default FormTranslation
