import React, { FC, useState } from 'react'
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import Switch from 'component/Form/Inputs/Switch';

interface LayoutDashboardOnboardingProps {
  step: number
  length?: number
  title: string
  description: string
  img?: string
  onPrevButton?: () => void
  onNextButton?: () => void
  onRequestClose?: () => void
}

const LayoutDashboardOnboarding: FC<LayoutDashboardOnboardingProps> = ({step, length, title, description, img, onPrevButton, onNextButton, onRequestClose}) => {

  const { t } = useTranslation(['dashboard']);

  const dispatch = useDispatch()

  const [isMonthlySelected, setIsMonthlySelected] = useState(true)
  const [price, setPrice] = useState('2,500')

  const onClickBtnUpgrade = () => {
    dispatch(actions.app.subscribeFromStripe({period: isMonthlySelected ? 'month' : 'year'}))
  }

  const handleOnRequestClose = () => onRequestClose && onRequestClose()

  const handleSwitchToggle = () => {
    setIsMonthlySelected(!isMonthlySelected)
    setPrice(isMonthlySelected ? '2,000' : '2,500')
  }

  const secondTitle = <div className="f f-direction-column f-jc-end">
    <p className="mb-0">{t('onboarding.billed_yearly')}</p>
    <p className="mb-0">{t('onboarding.savings')}</p>
  </div>

  return (
    <div className="f ">
      <div className="modal--flow__text-wrapper plans-step">
        <div>
          <span className="ft-600 ft-up c-cream-500">{t('onboarding.steps', {current: step, length})}</span>
          <h2 className="modal--flow__title mt-5" dangerouslySetInnerHTML={{__html: title}}></h2>
          <p className="ft-primary mt-3 mb-4 ft-500 c-cream-500">{description}</p>
          <div className="modal--flow__buttons f f-jc-space-b">
            {onPrevButton && <Button iconLeft="icon-arrow-left c-cream-500" text="" onClick={onPrevButton} />}
            {onNextButton && <Button text={t('onboarding.continue')} isPrimary={!onPrevButton} onClick={onNextButton} iconRight={`icon-arrow-right icon-20 ${step !== 1 ? 'c-cream-500' : ''}`} />}
          </div>
        </div>
      </div>
      {img && <div className="modal--flow__image-wrapper" style={{backgroundImage: `url(${img})`}} />}
      {step === length && (
        <div className="f f-direction-column f-jc-space-b modal--flow__pricing-block">
          <div className="f f-ai-center f-jc-center modal--flow__pricing-switch">
            <Switch className={`period-switch f f-jc-center f-ai-center ${isMonthlySelected ? 'monthly' : 'yearly'}`} title={t('onboarding.billed_monthly')} secondTitle={secondTitle} value={isMonthlySelected} name="test" handleChange={handleSwitchToggle} />
          </div>
          <div className="f">
            <div className="modal--flow__pricing-wrapper">
              <div className="modal--flow__pricing">
                <div>
                  <div className="label label--grey mb-3">{t('onboarding.starter_label')}</div>
                  <div className="pricing mt-3 ft-600">
                    <div className="price__value">0</div>
                  </div>
                  <div className="mb-3">
                    <hr />
                  </div>
                  <ul>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.free.feature_1')}</div></li>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.free.feature_2')}</div></li>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.free.feature_3')}</div></li>
                  </ul>
                </div>
                <Button text={t('onboarding.start_free')} iconRight="icon-arrow-right icon-20" onClick={handleOnRequestClose} />
              </div>
            </div>
            <div className="modal--flow__pricing-wrapper">
              <div className="modal--flow__pricing">
                <div>
                  <div className="label label--purple">{t('onboarding.premium_label')}</div>
                  <div className="pricing mt-3 ft-600">
                    <div className="price__value">{price}</div>
                    <div className="price__frequency">{`${t('onboarding.payment_frequency')}, ${isMonthlySelected ? t('onboarding.billed_monthly').toLowerCase() : t('onboarding.billed_yearly').toLowerCase() + ' (24,000円)'}`}</div>
                    <p className="price__offer">{t(`onboarding.payment_offer${!isMonthlySelected ? '_yearly' : ''}`)}</p>
                  </div>
                  <div className="mb-3">
                    <hr />
                  </div>
                  <ul>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.premium.feature_1')}</div></li>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.premium.feature_2')}</div></li>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.premium.feature_3')}</div></li>
                    <li className="modal--flow__checklist ft-500"><span className="icon icon-check c-green-600 mr-1" /><div>{t('onboarding.features.premium.feature_4')}</div></li>
                  </ul>
                </div>
                <Button text={t('onboarding.start_premium')} isPrimary={true} onClick={onClickBtnUpgrade} />
                <p className="mb-0 price__disclaimer">{t('onboarding.excluding_taxes')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LayoutDashboardOnboarding
