import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { useSagaTakeLatest } from 'utils/hooks/useSagaEffects'
import { RouteUrls } from 'utils/routers/constants'

const CardManagedBusiness = () => {
  const { t } = useTranslation('admin')
  const business = useSelector(selectors.business.business)
  const user = useSelector(selectors.user.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleButtonClick = () => {
    dispatch(actions.admin.fetchManagedBusiness({ id: user.business.id, isManaging: false }))
  }

  const onFetchManagedBusinessSuccess = () => {
    dispatch(actions.admin.setManagingBusiness({ managingBusiness: false }))
    dispatch(actions.business.fetchBusiness({id: user.business.id}))
    history.push(RouteUrls.Businesses)
  }

  useSagaTakeLatest(actions.admin.fetchManagedBusinessSuccess, onFetchManagedBusinessSuccess)

  return (
    <div className="card-managed-business">
      <span className="card-plan__title f-inline f-ai-center ft-secondary ft-700 mb-2">
        {t('disclaimer', { businessName: business.name})}
      </span>
      <Button onClick={handleButtonClick} isPrimary={true} text={t('stop')} />
    </div>
  )
}
export default CardManagedBusiness
