import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CategoryType, SubcategoryType } from 'store/Categories';
import { ErrorType } from 'api/helpers/customFetch';
import produce from "immer"
import { defaultLanguage } from 'store/Translations';
import { ProductsType } from 'store/Products';

export enum MODE {
  draft = 'DRAFT',
  save = 'SAVE'
}

export interface DraggingProductInfo {
  categoryId: number,
  subcategoryId?: number
}

export interface MenuDetailsState {
  isLoadingDatas: boolean,
  isSavingDatas: boolean,
  id: number,
  mode: MODE,
  untoggledCategories: number[],
  apiQueue: { endpoint: string, params: {} }[],
  data: {
    lang: string,
    menu: CategoryType[]
  }
}

const initialState : MenuDetailsState = {
  isLoadingDatas: false,
  isSavingDatas: false,
  untoggledCategories: [],
  apiQueue: [],
  mode: MODE.save,
  id: -1,
  data: {
    lang: 'JA',
    menu: [],
  }
}

export const slice = createSlice({
  name: 'MenuDetails',
  initialState,
  reducers: {
    addApiCallToQueue: (state: MenuDetailsState, action: PayloadAction<{ endpoint: string, params: {} }>) => {
      state.apiQueue.push(action.payload)
    },
    removeQueue: (state: MenuDetailsState) => {
      state.apiQueue = []
      state.mode = MODE.draft
    },
    setMode: (state: MenuDetailsState, action: PayloadAction<{mode: MODE}>) => {
      state.mode = action.payload.mode
    },
    addUntoggledCategory: (state: MenuDetailsState, action: PayloadAction<{ id: number }>) => {
      if (state.untoggledCategories.indexOf(action.payload.id) < 0) {
        state.untoggledCategories.push(action.payload.id)
      }
    },
    removeUntoggledCategory: (state: MenuDetailsState, action: PayloadAction<{ id: number }>) => {
      const index = state.untoggledCategories.indexOf(action.payload.id)
      if (index >= 0) {
        state.untoggledCategories.splice(index, 1)
      }
    },
    setMenu: (state: MenuDetailsState, action: PayloadAction<{ id: number, menu: CategoryType[], lang?: string }>) => {
      const { id, lang, menu } = action.payload
      state.id = id
      state.data.lang = lang ? lang : defaultLanguage
      state.data.menu = menu
    },
    switchProductCategory: (state: MenuDetailsState, action: PayloadAction<{productId: number, origin: DraggingProductInfo, target: DraggingProductInfo, order: number }>) => {
      const { productId, origin, target, order } = action.payload
      state.data.menu = switchProductCategory(state.data.menu, productId, origin, target, order)
    },
    switchCategoryOrder: (state: MenuDetailsState, action: PayloadAction<{categoryId: number, subcategoryId?: number, order: number }>) => {
      const { categoryId, subcategoryId, order } = action.payload
      state.data.menu = switchCategoryOrder(state.data.menu, categoryId, order, subcategoryId)
    },
    setIsLoadingDatas:( state: MenuDetailsState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatas = action.payload.isLoading
    },
    fetchMenu: (state: MenuDetailsState, action: PayloadAction<{ id: number, lang?: string }>) => undefined,
    triggerAddCategory:  (state: MenuDetailsState, action: PayloadAction<{ id: number, categoryId: number, parentCategoryId?: number }>) => undefined,
    addCategory: (state: MenuDetailsState, action: PayloadAction<{ category: CategoryType}>) => {
      state.data.menu = addCategory(state.data.menu, action.payload.category)
    },
    addSubcategory: (state: MenuDetailsState, action: PayloadAction<{ category: SubcategoryType, parentCategoryId: number }>) => {
      const { category, parentCategoryId } = action.payload
      state.data.menu = addSubcategory(state.data.menu, category, parentCategoryId)
    },
    addCategorySuccess: (state: MenuDetailsState, action: PayloadAction<{ categoryId: number, id: number }>) => undefined,
    addCategoryError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    triggerAddProduct:  (state: MenuDetailsState, action: PayloadAction<{ id: number, productId: number, categoryId: number, parentCategoryId?: number }>) => undefined,
    addProduct: (state: MenuDetailsState, action: PayloadAction<{ product: ProductsType, categoryId: number, parentCategoryId?: number }>) => {
      const { categoryId, parentCategoryId, product } = action.payload
      const subcategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? categoryId : undefined
      const realCategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? parentCategoryId : categoryId

      state.data.menu = addProduct(state.data.menu, product, realCategoryId, subcategoryId)
    },
    updateProduct: (state: MenuDetailsState, action: PayloadAction<{id: number, product: ProductsType, categoryId: number, parentCategoryId?: number }>) => {
      const { categoryId, parentCategoryId, product } = action.payload
      const subcategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? categoryId : undefined
      const realCategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? parentCategoryId : categoryId

      state.data.menu = updateProduct(state.data.menu, product, realCategoryId, subcategoryId)
    },
    updateCategory: (state: MenuDetailsState, action: PayloadAction<{ id: number, category: CategoryType}>) => {
      state.data.menu = updateCategory(state.data.menu, action.payload.category)
    },
    updateSubcategory:  (state: MenuDetailsState, action: PayloadAction<{ category: SubcategoryType, parentCategoryId: number }>) => {
      const { category, parentCategoryId } = action.payload
      state.data.menu = updateSubcategory(state.data.menu, category, parentCategoryId)
    },
    addProductSuccess: (state: MenuDetailsState) => undefined,
    addProductError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateCategoryOrder: (state: MenuDetailsState, action: PayloadAction<{ id: number, categoryId: number, order: number } >) => undefined,
    updateCategoryOrderSuccess: (state: MenuDetailsState) => undefined,
    updateCategoryOrderError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateProductOrder: (state: MenuDetailsState, action: PayloadAction<{ id: number, categoryId: number, newCategoryId?: number, productId: number, order: number, mode: MODE.draft } >) => undefined,
    updateProductOrderSuccess: (state: MenuDetailsState) => undefined,
    updateProductOrderError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    removeCategory: (state: MenuDetailsState, action: PayloadAction<{ id: number, categoryId: number, parentCategoryId?: number} >) => {
      const { categoryId, parentCategoryId } = action.payload

      const subcategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? categoryId : undefined
      const realCategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? parentCategoryId : categoryId

      state.data.menu = removeCategory(state.data.menu, realCategoryId, subcategoryId)
    },
    removeCategorySuccess: (state: MenuDetailsState) => undefined,
    removeCategoryError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    removeProduct: (state: MenuDetailsState, action: PayloadAction<{ id: number, categoryId: number, productId: number, parentCategoryId?: number} >) => {
      const { productId, categoryId, parentCategoryId } = action.payload

      const subcategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? categoryId : undefined
      const realCategoryId =  parentCategoryId !== undefined && parentCategoryId > 0  ? parentCategoryId : categoryId

      state.data.menu = removeProductCategory(state.data.menu, productId, realCategoryId, subcategoryId)
    },
    removeProductSuccess: (state: MenuDetailsState) => undefined,
    removeProductError: (state: MenuDetailsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    saveChanges: (state: MenuDetailsState) => {
      state.isSavingDatas = true;
    },
    saveChangesSuccess: (state: MenuDetailsState) => {
      state.isSavingDatas = false;
    }
  }
});

const getRoot = (state: RootState) => state.menuDetails
const id = (state: RootState) => getRoot(state).id
const menu = (state: RootState) => getRoot(state).data.menu
const isLoadingDatas = (state: RootState) => getRoot(state).isLoadingDatas
const isSavingDatas = (state: RootState) => getRoot(state).isSavingDatas
const getCategory = (state: RootState, id: number) => getRoot(state).data.menu.find(category => category.id === id)
const mode = (state: RootState) => getRoot(state).mode
const isDraftMode = (state: RootState) => mode(state) === MODE.draft
const apiQueue = (state: RootState) => getRoot(state).apiQueue

const isCategoryUntoggled = (state: RootState, id: number)  => {
  const menuDetails = getRoot(state)
  return menuDetails.untoggledCategories.indexOf(id) >= 0
}

const nbTotalProducts = (state: RootState, id: number, parentCategoryId?: number) => {
  const menu = state.menuDetails.data.menu
  const categoryId = parentCategoryId && parentCategoryId >= 0 ? parentCategoryId : id
  const category = menu.find(category => category.id === categoryId)
  const subCategory = parentCategoryId && parentCategoryId ? category?.subCategories.find(subcategory => subcategory.id === id) : null

  const relativeCategory = subCategory ? subCategory : category
  let nbProducts = 0
  if (relativeCategory && relativeCategory?.products) {
    nbProducts = relativeCategory?.products.length;
    if ('subCategories' in relativeCategory) {
      (relativeCategory as CategoryType).subCategories.forEach(subcategory => {
        if (subcategory && subcategory.products) {
          nbProducts += subcategory.products.length
        }
      })
    }
  }
  return nbProducts
}


const selectedCategoriesIds = (state: RootState) => {
  const menu = getRoot(state).data.menu
  return menu.map((category => category.id))
}

const selectedSubcategoriesIds = (state: RootState) => {
  const menu = getRoot(state).data.menu
  const ids: number[] = []
  menu.forEach((category) => {
    category.subCategories.map((subCategory) => ids.push(subCategory.id))
  })
  return ids
}

const selectedProductsIds = (state: RootState) => {
  const menu = getRoot(state).data.menu
  const ids: number[] = []
  menu.forEach((category) => {
    category.products && category.products.forEach((product) => ids.push(product.id))
    category.subCategories.forEach((subCategory) => subCategory.products && subCategory.products.forEach((product) => ids.push(product.id)))
  })
  return ids
}

const addProduct = (menuDetails: CategoryType[], product: ProductsType, categoryId: number, subcategoryId?: number) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(category => category.id === categoryId)
    const category = categories[categoryIndex]

    if (subcategoryId !== undefined && subcategoryId >= 0) {
      const subcategoryIndex = category.subCategories.findIndex(subcategory => subcategory.id === subcategoryId)
      if ((subcategoryIndex === undefined || subcategoryIndex < 0)) {
        return categories
      }
      categories[categoryIndex].subCategories[subcategoryIndex].products.push(product)
    } else {
      categories[categoryIndex].products.push(product)
    }

    return categories
  })
}

const updateProduct = (menuDetails: CategoryType[], product: ProductsType, categoryId: number, subcategoryId?: number) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(category => category.id === categoryId)
    const category = categories[categoryIndex]

    if (subcategoryId !== undefined && subcategoryId >= 0) {
      const subcategoryIndex = category.subCategories.findIndex(subcategory => subcategory.id === subcategoryId)
      if ((subcategoryIndex === undefined || subcategoryIndex < 0)) {
        return categories
      }
      const subcategories = categories[categoryIndex].subCategories[subcategoryIndex]
      const productIndex = subcategories.products.findIndex(p => p.id === product.id)
      categories[categoryIndex].subCategories[subcategoryIndex].products.splice(productIndex, 1, product)
    } else {
      const productIndex = category.products.findIndex(p => p.id === product.id)
      categories[categoryIndex].products.splice(productIndex, 1, product)
    }

    return categories
  })
}

const addSubcategory = (menuDetails: CategoryType[], category: SubcategoryType, parentCategoryId: number) => {
  return produce(menuDetails, categories => {
    if (parentCategoryId !== undefined && parentCategoryId >= 0) {
      const categoryIndex = categories.findIndex(category => category.id === parentCategoryId)

      categories[categoryIndex].subCategories.push(produce(category, (cat) => {
        cat.products = []
        return cat
      }))
    }
    return categories
  })
}

const addCategory = (menuDetails: CategoryType[], category: CategoryType) => {
  return produce(menuDetails, categories => {
    categories.push(produce(category, (cat) => {
      cat.subCategories = []
      cat.products = []
      return cat
    }))
    return categories
  })
}

const updateCategory = (menuDetails: CategoryType[], category: CategoryType) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(cat => cat.id === category.id)
    const formerCategory = categories[categoryIndex]
    const newCategory = {
      ...category,
      products: formerCategory.products,
      subCategories: formerCategory.subCategories
    }
    categories.splice(categoryIndex, 1, newCategory)
    return categories
  })
}

const updateSubcategory = (menuDetails: CategoryType[], category: SubcategoryType, parentCategoryId: number) => {
  return produce(menuDetails, categories => {
    if (parentCategoryId !== undefined && parentCategoryId >= 0) {
      const categoryIndex = categories.findIndex(category => category.id === parentCategoryId)
      const subcategoryIndex = categories[categoryIndex].subCategories.findIndex(cat => cat.id === category.id)
      const formerSubcategory = categories[categoryIndex].subCategories[subcategoryIndex]

      const newSubcategory = {...category, products: formerSubcategory.products }

      categories[categoryIndex].subCategories.splice(subcategoryIndex, 1, newSubcategory)
    }
    return categories
  })
}

const removeProductCategory = (menuDetails: CategoryType[], productId: number, categoryId: number, subcategoryId?: number) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(category => category.id === categoryId)
    const category = categories[categoryIndex]

    if (categoryIndex < 0) {
      return categories
    }


    if (subcategoryId !== undefined && subcategoryId >= 0) {
      const subcategoryIndex = category.subCategories.findIndex(subcategory => subcategory.id === subcategoryId)
      const subcategory = category.subCategories[subcategoryIndex];

      const productIndex = subcategory.products.findIndex(product => product.id === productId)
      if ((subcategoryIndex === undefined || subcategoryIndex < 0) || (productIndex === undefined || productIndex < 0)) {
        return categories
      }
      categories[categoryIndex].subCategories[subcategoryIndex].products.splice(productIndex, 1)
    } else {

      const productIndex = category.products.findIndex(product => product.id === productId)
      if ((productIndex === undefined || productIndex < 0)) {
        return categories
      }
      categories[categoryIndex].products.splice(productIndex, 1)
    }

    return categories
  })
}

const removeCategory = (menuDetails: CategoryType[], categoryId: number, subcategoryId?: number) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(category => category.id === categoryId)
    const category = categories[categoryIndex]

    if (subcategoryId !== undefined && subcategoryId >= 0) {
      const subcategoryIndex = category.subCategories.findIndex(subcategory => subcategory.id === subcategoryId)
      categories[categoryIndex].subCategories.splice(subcategoryIndex, 1)
      return
    }

    categories.splice(categoryIndex, 1)
  })
}

const switchProductCategory = (menuDetails: CategoryType[], id: number, origin: DraggingProductInfo, target: DraggingProductInfo, order: number) => {
  return produce(menuDetails, categories => {
    const targetCategoryIndex = categories.findIndex(category => category.id === target.categoryId)
    const originCategoryIndex = origin.categoryId === target.categoryId ? targetCategoryIndex : categories.findIndex(category => category.id === origin.categoryId)

    const targetCategory = categories[targetCategoryIndex]
    const originCategory = categories[originCategoryIndex]

    const targetSubcategoryIndex = target.subcategoryId !== undefined && target.subcategoryId >= 0 ? targetCategory.subCategories.findIndex(subcategory => subcategory.id === target.subcategoryId) : -1
    const originSubcategoryIndex = target.subcategoryId === origin.subcategoryId ? targetSubcategoryIndex : originCategory.subCategories.findIndex(subcategory => subcategory.id === origin.subcategoryId)

    const targetSubcategory = targetSubcategoryIndex >= 0 ? targetCategory.subCategories[targetSubcategoryIndex] : null
    const originSubcategory = originSubcategoryIndex >= 0 ? originCategory.subCategories[originSubcategoryIndex] : null

    const productList = originSubcategory ? originSubcategory.products : originCategory.products
    const productIndex = productList?.findIndex((product) => product.id === id)
    const product = productList && productIndex !== undefined && productIndex >= 0 && productList.length ? productList[productIndex] : null

    if (!product || !productList || !productList.length || productIndex === undefined) {
      return categories
    }


    if (originSubcategory) {
      categories[originCategoryIndex]?.subCategories[originSubcategoryIndex]?.products?.splice(productIndex, 1)
    } else {
      categories[originCategoryIndex].products?.splice(productIndex, 1)
    }

    if (targetSubcategory) {
      categories[targetCategoryIndex]?.subCategories[targetSubcategoryIndex]?.products?.splice(order, 0, product)
    } else {
      categories[targetCategoryIndex]?.products?.splice(order, 0, product)
    }

    return categories
  })
}

const switchCategoryOrder = (menuDetails: CategoryType[], id: number, order: number, subcategoryId?: number) => {
  return produce(menuDetails, categories => {
    const categoryIndex = categories.findIndex(category => category.id === id)
    const category = categories[categoryIndex]

    const subcategoryIndex = subcategoryId !== undefined && subcategoryId >= 0 ? category.subCategories.findIndex(subcategory => subcategory.id === subcategoryId) : -1
    const subcategory = subcategoryIndex >= 0 ? category.subCategories[subcategoryIndex] : null

    if (subcategory) {
      categories[categoryIndex]?.subCategories.splice(subcategoryIndex, 1)
      categories[categoryIndex]?.subCategories.splice(order, 0, subcategory)
    } else {
      categories.splice(categoryIndex, 1)
      categories.splice(order, 0, category)
    }
    return categories
  })
}

export const selectors = {
  id,
  menu,
  mode,
  apiQueue,
  isDraftMode,
  isCategoryUntoggled,
  category: getCategory,
  nbTotalProducts,
  isLoadingDatas,
  isSavingDatas,
  selectedCategoriesIds,
  selectedSubcategoriesIds,
  selectedProductsIds
};

// reducer / actions
export const { reducer, actions } = slice;
