import React, { FC } from 'react'

interface LayoutContentProps {
  className?: string,
  children: React.ReactNode
}

const LayoutContent: FC<LayoutContentProps> = ({children, className = ''}) => {
  return (
    <div className={`page__content ${className}`}>
      <div className="page__body">
        {children}
      </div>
    </div>
  )
}

export default LayoutContent