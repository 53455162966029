import { customFetch } from 'api/helpers/customFetch'
import { API_MENUS_URL, API_WELCOME_KIT } from 'api/helpers/routes'
import { MenuType, MenuAvailaibility, MENU_STATUS } from './index'

export const getMenus = async (): Promise<{menus: MenuType[]}> => {
  const request = await customFetch.get(API_MENUS_URL)
  const response = await request.json() 
  return { menus: response }
}

export const postMenu = async (menu: {name: string, menuAvailabilities: MenuAvailaibility[]}): Promise<{id: string}> => {
  const request = await customFetch.post(API_MENUS_URL, { 
    name: menu.name, 
    menuAvailabilities: menu.menuAvailabilities 
  })
  const response = await request.json() 
  return { id: response.id }
}

export const patchMenu = async (menu: { name: string, menuAvailabilities: MenuAvailaibility[] }, id: number): Promise<Response|null> => {
  const request = await customFetch.patch(`${API_MENUS_URL}/${id}`, { 
    name: menu.name, 
    menuAvailabilities: menu.menuAvailabilities 
  })
  return request
}

export const patchMenuStatus = async (id: number, status: MENU_STATUS): Promise<Response|null> => {
  const request = await customFetch.patch(`${API_MENUS_URL}/${id}`, { 
    status: status 
  })
  return request
}


export const deleteMenu = async (id: number): Promise<Response> => {
  const request = await customFetch.delete(`${API_MENUS_URL}/${id}`)
  const res = await request.json() 
  return res
}

export const postWelcomeKitClaim = async (shippingAddress: {address: string, city: string, prefecture: string, zipcode: string}): Promise<Response> => {
  const request = await customFetch.post(`${API_WELCOME_KIT}`, shippingAddress)
  return request
}