import React, { FC } from 'react'

interface TabProps {
  slug: string,
  activeTab: string,
  children: React.ReactNode
}

const Tab: FC<TabProps> = ({ slug, activeTab, children }) => {
  return (
    slug === activeTab ? <>{children}</> : <></>
  )
}

export default Tab