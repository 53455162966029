import React, { FC } from 'react'
import { CategoryType } from 'store/Categories'

interface MenuPreviewHorizontalListCategoryProps {
  categories: CategoryType[]
  isPremium: boolean
}

const MenuPreviewHorizontalListCategory: FC<MenuPreviewHorizontalListCategoryProps> = ({ categories, isPremium }) => {

  return (
    <div className="menu-list-horizontal-category--dashboard">
      <ul className="menu-list-horizontal-category__body">
        {categories.map((category) => {
          return <li key={category.id}><button className="tag">{ category.linkedBusiness ? category.linkedBusiness.name : category.i18n?.name }</button></li>
        })}
      </ul>
    </div>
)}

export default MenuPreviewHorizontalListCategory
