import React, { FC } from 'react'
import { getError } from 'utils/helpers'

interface BannerErrorProps {
  type?: string,
  message?: string,
  className?: string,
  onRequestClose: () => void
}

const BannerError: FC<BannerErrorProps> = ({message, onRequestClose, type, className = ''}) => {
  const errorMessage = message ? message : getError(type)

  return (
    <div className={`banner--error ${className}`}>
      <div className="f f-jc-space-b f-ai-center">
        <span className="ft-500 h5" dangerouslySetInnerHTML={{__html: errorMessage }} />
        <button className="icon icon-close icon-24 c-error-500" onClick={() => onRequestClose()} />
      </div>
    </div>
  )
}

export default BannerError