import Button from 'component/Buttons/Button'
import InputText from 'component/Form/Inputs/InputText'
import TopBar from 'component/Headers/TopBar'
import BottomBar from 'component/Footers/BottomBar';
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import ModalLegals from 'component/Modals/ModalLegals'
import ModalLogin from 'component/Modals/ModalLogin'
import i18next from 'i18next'
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { actions, selectors } from 'store'
import { getError, validationPatterns } from 'utils/helpers'
import { ModalConsumer, ModalContext } from 'utils/hooks/ModalBehavior'
import { RouteUrls, ROUTE_PATHS } from 'utils/routers/constants'

const Home: FC = (props: any) => {
  const history = useHistory()
  const dispatch  = useDispatch()
  const { t } = useTranslation(['login', 'onboarding'])
  const { showModal } = useContext(ModalContext)

  const location = useLocation();
  
  useEffect(()=>{
    if (location.pathname === ROUTE_PATHS.privacyPolicy) {
      showModal(ModalLegals, {type: 'privacyPolicy'})
    } else if (location.pathname === ROUTE_PATHS.terms) {
      showModal(ModalLegals, {type: 'tscs'})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  
  
  const businessName = useSelector(selectors.onboarding.businessName)
  
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });
  
  useEffect(()=>{
    i18next.on('languageChanged', () => {
      formMethods.clearErrors()
    })
  }, [formMethods])
  
  const onSubmit = useCallback((data) => {
    dispatch(actions.onboarding.triggerSetBusinessName({ name: data.businessName.trim() }))
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get('ref');
    if (refParam) {
      dispatch(actions.onboarding.triggerSetReferrer({ ref: refParam.trim() }));
    }

    history.push(RouteUrls.BusinessType)
  }, [dispatch, history, location])

  const loginContent = useMemo(() => (
    <ModalConsumer>
      {({ showModal }) => (
        <div className="ft-600 c-gray-800 mt-3">
          <Trans i18nKey="login:account.title" values={{ cta: t('login:account.cta') }}>
            content
            <button className="c-primary" type="button" onClick={() => {showModal(ModalLogin);return false;}}>cta</button>
          </Trans>
        </div>
      )}
    </ModalConsumer>
  ), [t])

  const onChangeInput = useCallback(() => {
    formMethods.clearErrors()
  }, [formMethods])

  return (
    <LayoutSideBar pageName="home">
      <TopBar />
      <LayoutContent className="page__content--form-onboarding page__padding-on-wrapper">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <h1 className="ft-900 mt-1 mb-4" dangerouslySetInnerHTML={{__html: t('onboarding:businessName:title')}}></h1>
              <img src="/static/images/home.svg" alt="" />
            </div>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                  <InputText
                    defaultValue={businessName}
                    maxLength={80}
                    handleChange={onChangeInput}
                    error={formMethods.errors.businessName}
                    inputRef={formMethods.register({
                      required: {
                        value: true,
                        message: getError('required')
                      },
                      pattern: {
                        value: validationPatterns.notEmptyString,
                        message: getError('required')
                      }
                    })}
                    className="input--large input--float ft-left" id="businessName" name="businessName"
                    placeholder={t('onboarding:businessName:inputBusinessName:placeholder')}
                    label={t('onboarding:businessName:inputBusinessName:title')} />
                    <div className="ft-center">
                      <Button className="mt-5" isPrimary={true} type="submit" text={t('onboarding:businessName:next')} />
                      {loginContent}
                    </div>
              </form>
          </div>
        </div>
        <BottomBar />
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default Home
