import BannerInfo from 'component/Banners/BannerInfo';
import Button from 'component/Buttons/Button';
import CardMenu from 'component/Cards/CardMenu';
import TopBarDashboard from 'component/Headers/TopBarDashboard';
import LayoutContent from 'component/Layouts/LayoutContent';
import LayoutSideBar from 'component/Layouts/LayoutSideBar';
import Loader from 'component/Loader';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import ModalWelcomeOnboarding from 'component/Modals/ModalWelcomeOnboarding';
import { ModalContext } from 'utils/hooks/ModalBehavior';
import { Link } from 'react-router-dom';
import ModalPrevisualize from 'component/Modals/ModalPrevisualize';
import ModalQRCode from 'component/Modals/ModalQRCode';
import ModalOrderDisplayKit from 'component/Modals/ModalOrderDisplayKit';
import ToolTip from 'component/ToolTip';
import { MENU_STATUS } from 'store/Menus';
import ModalMenuEdition from 'component/Modals/ModalMenuEdition';

const Dashboard: FC = () => {
  const { t } = useTranslation(['dashboard', 'menus'])
  const isFirstLogin = useSelector(selectors.user.isFirstLogin)
  const businessName = useSelector(selectors.business.name)
  const businessLocation = useSelector(selectors.business.location)
  const menus = useSelector(selectors.menus.menus)
  const menusQuantity = useSelector(selectors.menus.menusQuantity)
  const isLoadingDataList = useSelector(selectors.menus.isLoadingDatasList)
  const hasVisibleMenus = useSelector(selectors.menus.hasVisibleMenus)
  const hasUnfinalizedMenus = useSelector(selectors.menus.hasUnfinalizedMenus)
  const hasPendingMenuImports = useSelector(selectors.business.hasPendingMenuImports)
  const isManagingBusiness = useSelector(selectors.admin.managingBusiness)
  const [unfinalizedMenuId, setUnfinalizedMenuId] = useState<number>()
  const dispatch = useDispatch()

  const [canShowModal, setCanShowModal] = useState(true)
  const { showModal } = useContext(ModalContext)

  const onClickPrevisualize = useCallback(() => {
    showModal(ModalPrevisualize)
  }, [showModal])

  const onClickQRCode = useCallback(() => {
    showModal(ModalQRCode)
  }, [showModal])

  const onClickOrderDisplayKit = useCallback(() => {
    showModal(ModalOrderDisplayKit)
  }, [showModal])

  const topBarLeftContent = useMemo(() => (
    <div className="f-inline f-ai-center">
      <span className="icon icon-pin icon-16 mr-1 c-cream-500"></span>
      <p className="c-cream-500 ft-500 mb-0">{businessLocation}</p>
    </div>
  ), [businessLocation])

  const topBarRightContent = useMemo(() => (
    <>
      <Button text={t('dashboard:ctas.previsualize')} iconLeft="icon-phone1 c-cream-500" onClick={onClickPrevisualize} innerClasses="min-width-fit" />
      {!menusQuantity ?
         <ToolTip
          className="tooltip--sm tooltip--arrow"
          title={t('dashboard:getQRCode.tooltip.title')}
          desc={t('dashboard:getQRCode.tooltip.description')}
          triggerItem={<Button text={t('dashboard:ctas.qr_code')}
          iconLeft="icon-qrcode"
          isDisabled={true}
          className="btn--qrcode"
          onClick={onClickQRCode} />}
          />
        :
        <Button text={t('dashboard:ctas.qr_code')} iconLeft="icon-qrcode c-cream-500" isDisabled={menusQuantity === 0 || !hasVisibleMenus} className="btn--qrcode" onClick={onClickQRCode} innerClasses="min-width-fit" />
      }
      <Button text={t('dashboard:ctas.order_display_kit')} iconLeft="icon-pre-order c-cream-500" onClick={onClickOrderDisplayKit} innerClasses="min-width-fit" />
    </>
  ), [t, onClickPrevisualize, onClickQRCode, onClickOrderDisplayKit, menusQuantity, hasVisibleMenus])

  const showModalMenuEdition = useCallback(() => {
    showModal(ModalMenuEdition)
  }, [showModal])

  useEffect(() => {
    dispatch(actions.menus.fetchMenus())
  }, [dispatch])

  useEffect(() => {
    if (isFirstLogin && canShowModal) {
      showModal(ModalWelcomeOnboarding)
      setCanShowModal(false)
    }
  }, [isFirstLogin, showModal, canShowModal])

  useEffect(()=>{
    const unfinalizedMenu = menus.find(menu => menu.status !== MENU_STATUS.VISIBLE)
    setUnfinalizedMenuId(unfinalizedMenu?.id)
  }, [menus])

  const unfinalizedMenuMessage = <Trans
      i18nKey="menus:finalizeMenuInfo"
      values={{ finalize: t('menus:finalize') }}
    >
      content{' '}
      <Link
        className="ml-1 mr-1 ft-underline"
        to={`/dashboard/menu/${unfinalizedMenuId}`}
      >
        finalize
      </Link>{' '}
      after
    </Trans>


  const pendingMenuImportMessage = <p className="mb-0">{t('menus:pendingMenuImports')}</p>

  return (
    <>
      <LayoutSideBar pageName="dashboard" type="dashboard">
        <TopBarDashboard
          title={businessName}
          leftContent={topBarLeftContent}
          rightContent={topBarRightContent} />
        <LayoutContent>
          <div className="f f-jc-space-b mb-2">
            <h2>{t('menus:title')}</h2>
            <button onClick={showModalMenuEdition} className="link c-primary ft-600 f-inline f-ai-center"><span className="icon icon-plus icon-16 mr-1"></span>{t('menus:menu:create')}</button>
          </div>
          {hasUnfinalizedMenus && !(hasPendingMenuImports && !isManagingBusiness) && <BannerInfo message={unfinalizedMenuMessage} className="mb-2" />}
          {(hasPendingMenuImports && !isManagingBusiness) && <BannerInfo message={pendingMenuImportMessage} className="mb-2" />}
          {isLoadingDataList ? <Loader className="ft-center" /> : menus.map((menu, key) => <CardMenu hasActions={false} menu={menu} key={menu.id} />)}
        </LayoutContent>
      </LayoutSideBar>
    </>
  );
}

export default Dashboard;
