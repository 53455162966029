import React, { FC, useCallback, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button';
import InputCheckbox from 'component/Form/Inputs/InputCheckbox';

interface ModalDeleteConfirmationProps {
  onRequestClose: () => void,
  onConfirm: () => void,
  title: string,
  desc?: string | React.ReactNode,
  cancelCTAText: string,
  confirmCTAText: string,
  doubleConfirmText?: string,
}

const ModalDeleteConfirmation: FC<ModalDeleteConfirmationProps> = ({onRequestClose, onConfirm, title, desc, cancelCTAText, confirmCTAText, doubleConfirmText}) => {

  const [isDisabled, setIsDisabled] = useState(!!doubleConfirmText);

  const onChangeConfirmationCheckbox = useCallback((name: string | undefined, value: boolean) => {
    setIsDisabled(!value);
  }, [setIsDisabled])

  const onClickConfirm = useCallback(() => {
    onRequestClose()
    onConfirm()
  }, [onRequestClose, onConfirm])

  return (
    <LayoutModal
      onRequestClose={onRequestClose}
      className="modal--delete-confirmation modal--sm ft-center"
      >
      <h1>{title}</h1>
      {desc && <p className="mt-1">{desc}</p>}
      {doubleConfirmText && <div className="f f-jc-center mt-2"><InputCheckbox
        className="ft-left checkbox--inline"
        onChange={onChangeConfirmationCheckbox}
        name="confirmationCheckbox"
        label={doubleConfirmText}
      /></div>}
      <div className="mt-4">
        <Button text={cancelCTAText} onClick={onRequestClose} />
        <Button className="btn--red" text={confirmCTAText} onClick={onClickConfirm} isDisabled={isDisabled} />
      </div>
    </LayoutModal>
  )
}

export default ModalDeleteConfirmation
