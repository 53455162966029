import { call, select, all, SagaReturnType, put, takeEvery, race, take } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { CREATION_CHOICE, IMPORT_MENU_CHOICE, OnboardingState, UploadFile } from '.';
import * as services from './services';
import * as appServices from '../App/services';
import { validationPatterns } from 'utils/helpers';

export const onboardingLocalStorageName = 'digitomenuOnboarding';
export const verificationLocalStorageName = 'digitomenuVerificationlocalStorage';
export const importIdLocalStorageName = 'digitomenuImportIdlocalStorage';

export interface OnboardingLocalStorageType {
  onboarding: OnboardingState
  date: Date
}

export function* updateOnboardingLocalStorage () {
  const onboarding: OnboardingState = yield select(selectors.onboarding.onboarding)
  const savedOnboarding = {
    ...onboarding,
    data: {
      ...onboarding.data,
      import: {
        ...onboarding.data.import,
        files: []
      }
    }
  }
  yield localStorage.setItem(onboardingLocalStorageName, JSON.stringify({
    onboarding: savedOnboarding,
    date: Date.now()
  }));
  yield localStorage.setItem(verificationLocalStorageName, JSON.stringify({
    email: onboarding.verificationEmail,
    date: Date.now()
  }));
}

const is48HoursLater = (baseDate: Date) => {
  const date = new Date(baseDate)
  const now = new Date()
  const oneDay = 60 * 60 * 48 * 1000
  return now.getTime() - date.getTime() > oneDay
}


export function* getOnboardingLocalStorage () {
  const onboardingLocalStorage: string = yield localStorage.getItem(onboardingLocalStorageName)
  if (onboardingLocalStorage && onboardingLocalStorage.length) {
    const data: OnboardingLocalStorageType = JSON.parse(onboardingLocalStorage)
    if (!is48HoursLater(data.date)) {
      yield put(actions.onboarding.setOnboarding({onboarding: data.onboarding}))
    } else {
      yield call(clearOnboardingData)
    }
  } else {
    yield call(clearOnboardingData)
  }
}

export function* getVerificationLocalStorage () {
  const emailLocalStorage: string = yield localStorage.getItem(verificationLocalStorageName)
  if (emailLocalStorage && emailLocalStorage.length) {
    const data: { email: string, date: Date } = JSON.parse(emailLocalStorage)
    if (validationPatterns.email.test(data.email)) {
      yield put(actions.onboarding.setVerificationEmail({ email: data.email }))
    }
  }
}

export function* getImportIdLocalStorage () {
  const importIdLocalStorage: string = yield localStorage.getItem(importIdLocalStorageName)
  if (importIdLocalStorage && importIdLocalStorage.length) {
    const data: { importId: number, date: Date } = JSON.parse(importIdLocalStorage)
    yield put(actions.onboarding.setImportId({ importId: data.importId }))
  }
}


export function* updateImportIdLocalStorage () {
  const importId: OnboardingState = yield select(selectors.onboarding.importId)
  yield localStorage.setItem(importIdLocalStorageName, JSON.stringify({
    importId: importId,
    date: Date.now()
  }));
}

export function* clearOnboardingData () {
  yield localStorage.removeItem(onboardingLocalStorageName);
}

export function* clearVerificationData () {
  yield localStorage.removeItem(verificationLocalStorageName);
}

export function* clearImportData () {
  yield localStorage.removeItem(importIdLocalStorageName);
}

export function* createProducts(products: any): any {
  const errors = [];
  if (products.length) {
    for (let product of products) {
      yield put(actions.products.createProduct({ product, withFetch: false }))
    }
    for (let _product of products) {
      const [_success, error]: any = yield race([
        take(actions.products.createProductSuccess),
        take(actions.products.createProductError),
      ])
      if (error) errors.push(error)
    }
  }
  return errors
}

export function* createCategories(categories: any): any {
  const errors = [];
  if (categories.length) {
    for (let category of categories) {
      yield put(actions.categories.createCategory({ category, withFetch: false }))
    }
    for (let _category of categories) {
      const [_success, error]: any = yield race([
        take(actions.categories.createCategorySuccess),
        take(actions.categories.createCategoryError),
      ])
      if (error) errors.push(error)
    }
  }
  return errors
}

export const getFilesUrlAsFilesObject = async(files: UploadFile[]) => {
  const fileList: File[] = []
  for (const key in files) {
    const file = files[key]
    const objectFile: File = await fetch(file.url).then(r => r.blob()).then(blobFile => new File([blobFile], file.name, { type: file.type }))
    URL.revokeObjectURL(file.url);
    fileList.push(objectFile)
  }
  return fileList
}

export function* goToImportStripeCheckout (action: ReturnType<typeof actions.onboarding.goToImportStripeCheckout>) {
  const { importId } = action.payload
  try {
    if (importId >= 0) {
      yield put(actions.onboarding.goToImportStripeCheckoutError())
    }

    const checkoutRequest: SagaReturnType<typeof services.postImportStripeCheckout> = yield call(services.postImportStripeCheckout, importId)
    if (checkoutRequest && checkoutRequest.sessionId) {
      yield call(appServices.redirecToStripeCheckout, checkoutRequest.sessionId)
    } else {
      yield put(actions.onboarding.goToImportStripeCheckoutError())
    }
  } catch(e) {
    yield put(actions.onboarding.goToImportStripeCheckoutError())
  }
}


export function* register(action: ReturnType<typeof actions.onboarding.register>) {
  const { user, business } = action.payload
  try {
    yield call(services.register, user, business)
    const creationOption: CREATION_CHOICE = yield select(selectors.onboarding.creationOption)
    if (creationOption === CREATION_CHOICE.scratch) {
      // const products: any[] = yield select(selectors.onboarding.products)
      // const categories: any[] = yield select(selectors.onboarding.categories)
      yield call(clearOnboardingData)
      yield put(actions.onboarding.registerSuccess())
      // const [_productErrors, _categoriesErrors]: [SagaReturnType<typeof createProducts>,  SagaReturnType<typeof createCategories>]  = yield all([
      //   call(createProducts, products),
      //   call(createCategories, categories)
      // ])
    } else {
      const importOption: IMPORT_MENU_CHOICE = yield select(selectors.onboarding.importMenu)
      const request: SagaReturnType<typeof services.createImport>= yield call(services.createImport)
      const importId = request.id
      const isUrl = importOption === IMPORT_MENU_CHOICE.url

      yield put(actions.onboarding.setImportId({importId: importId}))
      yield call(updateImportIdLocalStorage)

      if (isUrl) {
        const url: string = yield select(selectors.onboarding.importUrl)
        yield call(services.postImportUrl, importId, url)
        const checkoutRequest: SagaReturnType<typeof services.postImportStripeCheckout> = yield call(services.postImportStripeCheckout, importId)
        yield call(appServices.redirecToStripeCheckout, checkoutRequest.sessionId)
      } else {
        const CSRFToken: string = yield select(selectors.user.CSRFToken)
        const files: UploadFile[] = yield select(selectors.onboarding.importFiles)
        const objectFiles: SagaReturnType<typeof getFilesUrlAsFilesObject> = yield call(getFilesUrlAsFilesObject, files)
        yield call(services.postImportFile, CSRFToken, importId, objectFiles)

        yield put(actions.onboarding.goToImportStripeCheckout({importId}))
      }
      yield call(clearOnboardingData)
      yield put(actions.onboarding.registerSuccess())
    }
  } catch(e: any) {
    yield put(actions.onboarding.registerError(e))
  }
}

export function* resendVerifyEmail(action: ReturnType<typeof actions.onboarding.resendVerifyEmail>) {
  const { email  } = action.payload
  try {
    yield call(services.resendVerifyEmail, email)
    yield put(actions.onboarding.resendVerifyEmailSuccess())
  } catch(e: any) {
    yield put(actions.onboarding.resendVerifyEmailError(e))
  }
}

export function* triggerSetBusinessName(action: ReturnType<typeof actions.onboarding.triggerSetBusinessName>) {
  yield put(actions.onboarding.setBusinessName(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetReferrer(action: ReturnType<typeof actions.onboarding.triggerSetReferrer>) {
  yield put(actions.onboarding.setReferrer(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetBusinessType(action: ReturnType<typeof actions.onboarding.triggerSetBusinessType>) {
  yield put(actions.onboarding.setBusinessType(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetBusinessInfo(action: ReturnType<typeof actions.onboarding.triggerSetBusinessInfo>) {
  yield put(actions.onboarding.setBusinessInfo(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetUserInfo(action: ReturnType<typeof actions.onboarding.triggerSetUserInfo>) {
  yield put(actions.onboarding.setUserInfo(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetCreationOption(action: ReturnType<typeof actions.onboarding.triggerSetCreationOption>) {
  yield put(actions.onboarding.setCreationOption(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetCategories(action: ReturnType<typeof actions.onboarding.triggerSetCategories>) {
  yield put(actions.onboarding.setCategories(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetProducts(action: ReturnType<typeof actions.onboarding.triggerSetProducts>) {
  yield put(actions.onboarding.setProducts(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetImportMenu(action: ReturnType<typeof actions.onboarding.triggerSetImportMenu>) {
  yield put(actions.onboarding.setImportMenu(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* triggerSetImportMenuUrl(action: ReturnType<typeof actions.onboarding.triggerSetImportMenuUrl>) {
  yield put(actions.onboarding.setImportMenuUrl(action.payload))
  yield call(updateOnboardingLocalStorage)
}

export function* root() {
  yield all([
    takeEvery(actions.onboarding.triggerSetBusinessName, triggerSetBusinessName),
    takeEvery(actions.onboarding.triggerSetReferrer, triggerSetReferrer),
    takeEvery(actions.onboarding.triggerSetBusinessType, triggerSetBusinessType),
    takeEvery(actions.onboarding.triggerSetBusinessInfo, triggerSetBusinessInfo),
    takeEvery(actions.onboarding.triggerSetUserInfo, triggerSetUserInfo),
    takeEvery(actions.onboarding.triggerSetCreationOption, triggerSetCreationOption),
    takeEvery(actions.onboarding.triggerSetImportMenu, triggerSetImportMenu),
    takeEvery(actions.onboarding.triggerSetCategories, triggerSetCategories),
    takeEvery(actions.onboarding.triggerSetProducts, triggerSetProducts),
    takeEvery(actions.onboarding.triggerSetImportMenuUrl, triggerSetImportMenuUrl),
    takeEvery(actions.onboarding.register, register),
    takeEvery(actions.onboarding.resendVerifyEmail, resendVerifyEmail),
    takeEvery(actions.onboarding.goToImportStripeCheckout, goToImportStripeCheckout)
  ])
}
