import React, { FC, createContext, useState, useCallback, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router'

interface StickyBannerType {
  component: null,
  props: {}
}

interface StickyBannerContextType {
  component: any,
  props: {className?: string},
  showStickyBanner: (component: any, bannerProps?: {}) => void,
  hideStickyBanner: () => void,
}

export const StickyBannerContext = createContext<StickyBannerContextType>({
  component: null,
  props: {},
  showStickyBanner: (component: any) => {},
  hideStickyBanner: () => {},
})

interface StickyBannerType {
  component: null,
  props: {}
}

export const StickyBannerProvider: FC = ({children}) => {
  const history = useHistory()
  const [banner, setBanner] = useState<StickyBannerType>({props: {}, component: null})

  const showStickyBanner = useCallback((component: any, props = {}) => {
    setBanner({ component: component, props })
  }, [])

  const hideStickyBanner = useCallback(() => {
    const banner = { component: null, props: {} }
    setBanner(banner);
  }, [])

  const value = useMemo(() => ({
    component: banner.component,
    props: banner.props,
    showStickyBanner,
    hideStickyBanner
  }), [banner, showStickyBanner, hideStickyBanner])

  useEffect(() => {
    const historyListener = history.listen(() => {
      hideStickyBanner()
    });
    return () => historyListener()
  }, [history, hideStickyBanner])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (banner) {
      timeout = setTimeout(() => hideStickyBanner(), 8000)
    }
    return () => clearTimeout(timeout)
  }, [banner, hideStickyBanner])

  return (
    <StickyBannerContext.Provider value={value}>
      {children}
    </StickyBannerContext.Provider>
  )
}
export const StickyBannerConsumer = StickyBannerContext.Consumer
