import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from 'api/helpers/customFetch'
import i18next from 'i18next'
import { defaultLanguage } from 'store/Translations'
import { RootState } from '../store'

export interface LanguageType {
  id: string,
  name: string
}

export interface LanguageState {
  isLoadingDatasList: boolean,
  data: {
    languages: LanguageType[]
  }
}

const initialState : LanguageState = {
  isLoadingDatasList: false,
  data: {
    languages: [],
  },
}

export const slice = createSlice({
  name: 'Languages',
  initialState,
  reducers: {
    setIsLoadingDatasList:( state: LanguageState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatasList = action.payload.isLoading
    },
    setLanguages: (state: LanguageState, action: PayloadAction<{languages: LanguageType[]}>) => {
      state.data.languages = action.payload.languages
    },
    fetchLanguages: (state: LanguageState) => undefined,
    addLanguages: (state: LanguageState, action: PayloadAction<{languageIds: string[]}>) => undefined,
    addLanguagesSuccess: (state: LanguageState) => undefined,
    addLanguagesError: (state: LanguageState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    removeLanguages: (state: LanguageState, action: PayloadAction<{languageIds: string[]}>) => undefined,
    removeLanguagesSuccess: (state: LanguageState) => undefined,
    removeLanguagesError: (state: LanguageState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    manageLanguages: (state: LanguageState, action: PayloadAction<{removalIds: string[], additionIds: string[]}>) => undefined,
    manageLanguagesSuccess: (state: LanguageState) => undefined,
    manageLanguagesError: (state: LanguageState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
  }
})

// Selectors
const getRoot = (state: RootState) => state.languages
const translationLanguages = (state: RootState, languageList: string[]) => {
  return getRoot(state).data.languages
  .filter(lang => lang.id !== defaultLanguage && languageList.indexOf(lang.id) < 0)
  .map((lang) => lang.id)
}
const labelLanguage = (state: RootState, lang: string) => i18next.t(`common:languages:${lang.toLowerCase()}`)

const isLoadingDatasList = (state: RootState) => getRoot(state).isLoadingDatasList

export const selectors = {
  translationLanguages,
  isLoadingDatasList,
  labelLanguage
}

// reducer / actions
export const { reducer, actions } = slice