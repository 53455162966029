import { all, call, put, SagaReturnType, takeEvery } from "@redux-saga/core/effects";
import { actions } from "store";
import * as services from './services'

export function* fetchStatistics(action: ReturnType<typeof actions.admin.fetchStatistics>) {
  try {
    const { slug } = action.payload;
    const request : SagaReturnType<typeof services.getStatistics> = yield call(services.getStatistics, slug);
    yield put(actions.admin.setStatistics({statistics: request.statistics}));
  } catch (e: any) {
    yield put(actions.admin.fetchStatisticsError(e));
  }
}

export function* fetchBusinesses(action: ReturnType<typeof actions.admin.fetchBusinesses>) {
    try {
        const request : SagaReturnType<typeof services.getBusinesses> = yield call(services.getBusinesses)
        yield put(actions.admin.setBusinesses({businesses: request.businesses}))
    } catch (e: any) {
        yield put(actions.admin.fetchBusinessesError(e))
    }
}

export function* fetchMenuImports(action: ReturnType<typeof actions.admin.fetchMenuImports>) {
    try {
        const request : SagaReturnType<typeof services.getMenuImports> = yield call(services.getMenuImports)
        yield put(actions.admin.setMenuImports({menuImports: request.menuImports}))
    } catch (e: any) {
        yield put(actions.admin.fetchMenuImportsError(e))
    }
}

export function* fetchManagedBusiness(action: ReturnType<typeof actions.admin.fetchManagedBusiness>) {
    const { isManaging = true, id } = action.payload
    try {
        yield call(services.getManagedBusiness, id)
        yield put(actions.admin.fetchManagedBusinessSuccess({ id, isManaging }))
    } catch (e: any) {
        yield put(actions.admin.fetchManagedBusinessError(e))
    }
}

export function* updateMenuImportStatus(action: ReturnType<typeof actions.admin.updateMenuImportStatus>) {
    try {
        yield call(services.updateMenuImportStatus, action.payload.id)
        yield put(actions.admin.fetchMenuImports())
    } catch (e: any) {
        yield put(actions.admin.updateMenuImportStatusError(e))
    }
}

export function* updateAdminBusiness(action: ReturnType<typeof actions.admin.updateAdminBusiness>) {
  try {
    yield call(services.patchAdminBusiness, action.payload.id, action.payload.business);
    yield put(actions.admin.updateAdminBusinessSuccess());
    yield put(actions.admin.fetchBusinesses());
  } catch(e: any) {
    yield put(actions.admin.updateAdminBusinessError({error: e}));
  }
}

export function* resendVerificationEmail(action: ReturnType<typeof actions.admin.resendVerificationEmail>) {
  const { business } = action.payload;

  try {
    yield call(services.postResendVerification, business.id);
    yield put(actions.admin.fetchBusinesses());
  } catch (e: any) {
    yield put(actions.admin.resendVerificationEmailError(e));
  }
}

export function* updateDemoFlag(action: ReturnType<typeof actions.admin.updateDemoFlag>) {
  const { business, isDemo } = action.payload;

  try {
    yield call(services.patchDemoFlag, business.id, isDemo);
    yield put(actions.admin.fetchBusinesses());
    yield put(actions.admin.updateDemoFlagSuccess());
  } catch(e) {
  }
}

export function* root() {
  yield all([
    takeEvery(actions.admin.fetchBusinesses, fetchBusinesses),
    takeEvery(actions.admin.fetchStatistics, fetchStatistics),
    takeEvery(actions.admin.fetchMenuImports, fetchMenuImports),
    takeEvery(actions.admin.fetchManagedBusiness, fetchManagedBusiness),
    takeEvery(actions.admin.updateMenuImportStatus, updateMenuImportStatus),
    takeEvery(actions.admin.updateAdminBusiness, updateAdminBusiness),
    takeEvery(actions.admin.resendVerificationEmail, resendVerificationEmail),
    takeEvery(actions.admin.updateDemoFlag, updateDemoFlag),
  ])
}
