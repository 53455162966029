import React, { FC, useCallback, useContext, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { getError, validationPatterns } from 'utils/helpers'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import TextArea from 'component/Form/Inputs/TextArea'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import ModalRequestSent from '../ModalRequestSent'

interface ModalContactProps {
  hideClose?: boolean,
  onRequestClose: () => void
}

export interface InputsContactForm {
  email?: string
  firstname: string
  lastname: string
  phone: string
  company: string
  subject: string
  message: string
}

const ModalContact: FC<ModalContactProps> = (props) => {
  const { onRequestClose } = props
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)
  const { t } = useTranslation(['login', 'common'])
  const [globalError, setGlobalError] = useState('')

  const { handleSubmit, register, errors } = useForm<InputsContactForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const onChangeFormValue = useCallback((name, value) => {
    setGlobalError('')
  }, [])

  const onSubmit = (payload: InputsContactForm) => dispatch(actions.app.sendContact(payload))

  const onSuccess = useCallback(() => {
    onRequestClose()
    showModal(ModalRequestSent)
  }, [onRequestClose, showModal])


  const onError = useCallback((action) => {
    setGlobalError(action.payload.message)
  }, [])

  useSagaTakeEvery(actions.app.sendContactSuccess, onSuccess)
  useSagaTakeEvery(actions.app.sendContactError, onError)

  return (
    <LayoutModal onRequestClose={onRequestClose} hideClose={props.hideClose} className="modal--contact modal--flow">
      <div className="f">

        <div className="modal--flow__image-wrapper" style={{backgroundImage: `url(/static/images/contact.svg)`}} />
        <div className="modal--flow__text-wrapper">
          <div>
            <p className="ft-tertiary ft-up ft-600 c-cream-500 mb-2">{t('common:contactModal:sup')}</p>
            <h2 className="mb-4">{t('common:contactModal:title')}</h2>

            <form onSubmit={handleSubmit((onSubmit))}>
              {globalError && (
                <div className="c-red ft-tertiary mb-2 ft-700 ft-center">
                  {getError(globalError)}
                </div>
              )}

              <div className="f">
                <InputText 
                  maxLength={255} 
                  className="f-1 input--material mb-1" 
                  placeholder={t('common:contactModal:firstname')} 
                  label={t('common:contactModal:firstname')} 
                  name="firstname" 
                  handleChange={onChangeFormValue} 
                  inputRef={register({
                    required: {
                      value: true,
                      message: getError('required')
                    } 
                  })} error={errors.firstname} />
                <InputText 
                  maxLength={255} 
                  className="f-1 input--material mb-1" 
                  placeholder={t('common:contactModal:lastname')} 
                  label={t('common:contactModal:lastname')} 
                  name="lastname" 
                  handleChange={onChangeFormValue} 
                  inputRef={register({
                    required: {
                      value: true,
                      message: getError('required')
                    } 
                  })} error={errors.lastname} />
              </div>

              <div className="f">
                <InputText 
                  maxLength={255} 
                  className="f-1 input--material mb-1" 
                  placeholder={t('common:contactModal:phone')} 
                  label={t('common:contactModal:phone')} 
                  name="phone" 
                  handleChange={onChangeFormValue} 
                  inputRef={register({
                    required: {
                      value: true,
                      message: getError('required')
                    } 
                  })} error={errors.phone} />
                <InputText 
                  name="email"
                  autocomplete="email"
                  type="email"
                  className="f-1 input--material mb-1" 
                  placeholder={t('common:contactModal:email')} 
                  label={t('common:contactModal:email')} 
                  inputRef={register({
                    pattern: {
                      value: validationPatterns.email,
                      message: getError('email')
                    }
                  })}
                  required={false}
                  error={errors.email}
                  handleChange={onChangeFormValue}
                />
              </div>

              <InputText 
                maxLength={255} 
                className="f-1 input--material mb-1" 
                placeholder={t('common:contactModal:company')} 
                label={t('common:contactModal:company')} 
                name="company" 
                handleChange={onChangeFormValue} 
                inputRef={register({
                  required: {
                    value: true,
                    message: getError('required')
                  } 
                })} error={errors.company} />

              <InputText 
                maxLength={255} 
                className="f-1 input--material mb-1" 
                placeholder={t('common:contactModal:subject')} 
                label={t('common:contactModal:subject')} 
                name="subject" 
                handleChange={onChangeFormValue} 
                inputRef={register({
                  required: {
                    value: true,
                    message: getError('required')
                  } 
                })} error={errors.subject} />

              <TextArea 
                className="f-1 input--material" 
                label={t('common:contactModal:message')} 
                name="message" 
                onChange={onChangeFormValue} 
                textAreaRef={register({
                  required: {
                    value: true,
                    message: getError('required')
                  } 
                })} error={errors.message} />

              <Button className="mt-3 btn--full" text={t('common:contactModal:cta')} isPrimary={true} type="submit" />
            </form>
          </div>
        </div>
      </div>
    </LayoutModal>
  )
}

export default ModalContact
