import Button from 'component/Buttons/Button'
import InputText from 'component/Form/Inputs/InputText'
import TextArea from 'component/Form/Inputs/TextArea'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import ToolTip from 'component/ToolTip'
import i18next from 'i18next'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { useTypedSelector } from 'store/store'
import { getError, validationPatterns } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

const AddCategory: FC = (props: any) => {
  const dispatch  = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['common', 'onboarding'])

  const backUrl = RouteUrls.CreationOption
  const maxNbCategories = 3
  
  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 4, 'category'))

  const categories = useSelector(selectors.onboarding.categories)
  const [nbCategories, setNbCategories] = useState(categories.length)
  const [defaultValues, setDefaultValues] = useState(categories)

  const renderedCategories = useMemo(() => [...new Array(nbCategories)].map(() => 'category'), [nbCategories])


  const { handleSubmit, register, clearErrors, getValues, setValue, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    i18next.on('languageChanged', () => {
      clearErrors()
    })
  }, [clearErrors])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  const onChangeInput = useCallback(() => {
    clearErrors()
  }, [clearErrors])

  const onClickAddCategory = useCallback(() => {
    setNbCategories(prevState => prevState < maxNbCategories ? prevState + 1 : prevState)
  }, [])
  
  const onSubmit = useCallback((data) => {
    const categories: { name: string, description: string }[] = Object.values(data.categories)
    dispatch(actions.onboarding.triggerSetCategories({ categories: categories }))
    history.push(RouteUrls.AddProduct)
  }, [dispatch, history])

  const onClickDeleteCategory = useCallback((key) => {
    setNbCategories(prevState => prevState - 1)
    const currentValues = Object.values(getValues().categories) as {name: string, description: string, price: number}[]
    const newValues = currentValues
    for (let i = key; i < currentValues.length; i++) {
      if (i >= key && currentValues.length > 1) {
        const nextIndex = i + 1;
        if (nextIndex < currentValues.length) {
          const nextElement = currentValues[i + 1];
          newValues[i] = { ...nextElement }
        } else {
          newValues[i] = { name: '', description: '', price: -1 }
        }
      } else if (currentValues.length === 1) {
        newValues[i] = { name: '', description: '', price: -1}
      }
      setValue(`categories[${key}].name`, newValues[i].name)
      setValue(`categories[${key}].description`, newValues[i].description)
      setValue(`categories[${key}].price`, newValues[i].price >= 0 ? newValues[i].price : '')
    }
    setDefaultValues(newValues)
  }, [getValues, setValue]);

  useEffect(() => {
    setNbCategories((prevState) => !prevState ? 1  : prevState)
  }, [nbCategories])

  const onClickSkip = useCallback(() => {
    history.push(RouteUrls.Register)
  }, [history])

  const renderCategoryForm = useCallback((index: number) => (
    <div className="mb-3" key={index}>
      <InputText 
        maxLength={255}
        defaultValue={defaultValues[index] ? defaultValues[index].name : ''} 
        className="input--material mb-1 ft-left" 
        label={t('onboarding:addCategory:inputCategoryName')} 
        placeholder={t('onboarding:addCategory:inputCategoryName')} 
        name={`categories[${index}].name`}
        handleChange={onChangeInput}
        error={errors?.categories?.[index]?.name}
        inputRef={(
          register({
            required: {
              value: true,
              message: getError('name')
            },
            pattern: {
              value: validationPatterns.notEmptyString,
              message: getError('name')
            }
          }))} 
        />
      <TextArea 
        className="mb-1"
        defaultValue={defaultValues[index] ? defaultValues[index].description : ''} 
        onChange={onChangeInput} 
        label={t('onboarding:addCategory:inputCategoryDescription')} 
        name={`categories[${index}].description`}
        textAreaRef={register()} />
      <div className="ft-right mb-2">
        <button type="button" className="ft-700 c-primary" onClick={() => onClickDeleteCategory(index)}>{t('common:upload.delete')}</button>
      </div>
      <hr/>
    </div>
  ), [t, register, onChangeInput, onClickDeleteCategory, defaultValues, errors])

  return (
    <LayoutSideBar pageName="add-category">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__content--form-onboarding">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <h1 className="ft-900 mt-3 mb-2 f-inline">
                {t('onboarding:addCategory:title')} 
                <ToolTip className="tooltip--left" title={t('onboarding:addCategory:tooltip:title')} desc={t('onboarding:addCategory:tooltip:desc')} triggerItem={<button className="icon icon-tooltip c-cream-500 icon-20 tooltip__trigger" />} />
              </h1>
              <p className="h4 mb-4 c-gray-700">{t('onboarding:addCategory:description')}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderedCategories.map((categories, index) => renderCategoryForm(index))}
              {(nbCategories < maxNbCategories) && <Button iconLeft="icon-plus c-cream-500" onClick={onClickAddCategory} type="button" text={t('onboarding:addCategory:add')} />}
              <div className="ft-center">
                <Button className="mt-5 mr-2" type="button" text={t('onboarding:addProduct:skip')} onClick={onClickSkip} /> 
                <Button className="mt-5" isPrimary={true} type="submit" text={t('onboarding:addCategory:next')} /> 
              </div>
            </form>
          </div>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default AddCategory
