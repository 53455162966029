import Button from 'component/Buttons/Button'
import ModalPremium from 'component/Modals/ModalPremium'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface CardSettingsPlanProps {
  className?: string
}

const CardSettingsPlan: FC<CardSettingsPlanProps> = ({className}) => {
  const dispatch = useDispatch()
  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const { t } = useTranslation('dashboard')
  const { showModal } = useContext(ModalContext)

  const onClickBtnUpgrade = () => {
    showModal(ModalPremium)
  }

  const onClickBtnManage = () => {
    dispatch(actions.app.manageAccountFromStripe())
  }

  return (
    isBusinessPremium ? 
      <div className={`card-settings-plan ${className ? className : ''}`}>
        <div className="">
          <h4 className="ft-600"><img className="mr-1" width="20" src="/static/images/ic_rocket.png" alt="" />{t('account:premiumPlan:title')}</h4>
        </div>
        <button className="c-primary ft-700" onClick={onClickBtnManage}>{t('account:premiumPlan:manage')}</button>
      </div>
    :
    <div className={`card-settings-plan ${className ? className : ''}`}>
      <div className="">
        <h4 className="ft-600"><img className="mr-1" width="20" src="/static/images/ic_rocket.png" alt="" />{t('account:freePlan:title')}</h4>
        <div className="c-gray-600 h5 mt-1">{t('account:freePlan:desc')}</div>
      </div>
      <Button text={t('account:freePlan:upgrade')} className="btn--purple" onClick={onClickBtnUpgrade} />
    </div>
  )
}

export default CardSettingsPlan