import MenuPreview from 'component/MenuPreview';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { BusinessTheme } from 'store/Business';
import { useTypedSelector } from 'store/store';
import { getSymbolFromCurrency } from 'utils/helpers';

interface MenuPreviewWrapperProps {
  menuId: number,
  theme?: BusinessTheme.DARK | BusinessTheme.LIGHT,
  backgroundColor?: string,
  actionColor?: string,
  sizes?: {
    maxMobileHeight: number,
    minGapHeight: number
  },
  lang?: string
}

const MenuPreviewWrapper: FC<MenuPreviewWrapperProps> = ({menuId, sizes, theme, actionColor, backgroundColor, lang}) => {

  const categories = useSelector(selectors.menuDetails.menu)
  const businessConfig = useSelector(selectors.business.businessConfig)
  const businessName = useSelector(selectors.business.name)
  const menu = useTypedSelector((state) => selectors.menus.menu(state, menuId))
  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const schedule = useTypedSelector((state) => selectors.menus.schedule(state, menuId))
  const businessLanguages = useSelector(selectors.business.languages)

  const logoUrl = useTypedSelector(state => selectors.business.logoUrl(state, 150))
  const instagramUrl = useSelector(selectors.business.instagramId)
  const twitterUrl = useSelector(selectors.business.twitterId)
  const facebookUrl = useSelector(selectors.business.facebookId)

  const [isScaled, setIsScaled] = useState(false)

  const $pageRight = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState<number>(1)
  const [config, setConfig] = useState(businessConfig)

  const $preview = useRef<HTMLDivElement>(null)

  const minGapHeight = sizes && sizes.minGapHeight ? sizes.minGapHeight : 72
  const maxMobileHeight = sizes && sizes.maxMobileHeight ? sizes.maxMobileHeight :  720
  const minSize = minGapHeight + maxMobileHeight

  const onResize = useCallback(() => {
    if ($pageRight.current && $pageRight.current.clientHeight < minSize) {
      const newScale = Math.round($pageRight.current.clientHeight / minSize * 10) / 10
      setScale(newScale)
      setIsScaled(newScale < 1 ? true : false)
    } else {
      setScale(1)
      setIsScaled(false)
    }
  }, [minSize])

  const onPreviewBodyRef = useCallback((ref) => {
    if (ref) {
      ($preview.current as null) = ref.querySelector('.menu')
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () =>  window.removeEventListener('resize', onResize);
  }, [onResize])

  useEffect(() => {
    onResize()
  }, [onResize])

  useEffect(() => {
    if (theme && theme.length) {
      setConfig((prevState) => ({...prevState, theme }))
    }
  }, [theme])

  useEffect(() => {
    if (actionColor && actionColor.length) {
      setConfig((prevState) => ({ ...prevState, actionColor }))
    }
  }, [actionColor])

  useEffect(() => {
    if (backgroundColor && backgroundColor.length) {
      setConfig((prevState) => ({...prevState, backgroundColor }))
    }
  }, [backgroundColor])

  useEffect(() => {
    if ($preview.current) {
      $preview.current.style.setProperty('--actionColor', config.actionColor);
      $preview.current.style.setProperty('--backgroundColor', config.backgroundColor);
    }
  }, [config])

  useEffect(() => {
    setConfig(businessConfig)
  }, [businessConfig])

  const businessProps = {
    logoUrl,
    instagramUrl,
    twitterUrl,
    facebookUrl,
    name: businessName,
    isPremium: isBusinessPremium,
    languages: businessLanguages
  }

  const defaultSelects = {
    lang: lang ? lang : businessLanguages[0].id
  }

  return (
    <div className="menu-preview-wrapper">
      <div className="menu-preview-wrapper__body" ref={$pageRight}>
        <div className={`menu-preview-wrapper__mobile ${isScaled ? 'is--scaled' : ''}`} style={scale < 1 ? {zoom: scale} : {}}>
          <div className="menu-preview-wrapper__preview">
            <div className="menu-preview-wrapper__preview__body" ref={onPreviewBodyRef}>
              {menu && <MenuPreview defaultSelects={defaultSelects} business={businessProps} config={config} menu={menu} categories={categories} isPremium={isBusinessPremium} schedule={`${schedule.days}`} currency={getSymbolFromCurrency('JPA')} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuPreviewWrapper;
