import {FC, useCallback, useEffect } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { DASHBOARD_ROUTES, PUBLIC_ROUTES, ROUTES, RouteUrls } from 'utils/routers/constants';
import { actions, selectors } from 'store';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { useDispatch, useSelector } from 'react-redux';
import { MODE } from 'store/MenuDetails';

type DashboardMiddlewareProps = {
  children: any
}

const DashboardMiddleware: FC<DashboardMiddlewareProps> = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const checkRouteMatch = useCallback((routes: {path: string}[]) => routes.find((route) => matchPath(history.location.pathname, {
    path: route.path,
    exact: true,
    strict: false
  })), [history])

  const isDashboardPage = checkRouteMatch(DASHBOARD_ROUTES)
  const isPublicPage = checkRouteMatch(PUBLIC_ROUTES)
  const isRoute = checkRouteMatch(ROUTES)
  const isConnected = useSelector(selectors.user.connected)

  useSagaTakeEvery(actions.user.fetchUserError, useCallback(() => {
    if (isDashboardPage) {
      history.push(RouteUrls.Login)
    }
  }, [isDashboardPage, history]))

  useSagaTakeEvery(actions.user.fetchUserSuccess, useCallback(() => {
    if (isPublicPage) {
      history.push(RouteUrls.Dashboard)
    }
  }, [isPublicPage, history]))

  useEffect(() => {
    if (!isRoute) {
      history.push(isConnected ? RouteUrls.Dashboard : RouteUrls.Home)
    }
  }, [history, isRoute, isConnected])
  
  useEffect(() => {
    if (isConnected && isPublicPage) {
      history.push(RouteUrls.Dashboard)
    }
  }, [isConnected, isPublicPage, history])

  useEffect(() => {
    const historyListener = history.listen(() => {
      dispatch(actions.menuDetails.setMode({ mode: MODE.save }))
    });
    return () => historyListener()
  }, [history, dispatch])

  return children
}

export default DashboardMiddleware