import { call, all, SagaReturnType, put, takeEvery, select } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { CategoryType, SubcategoryType } from 'store/Categories';
import { ProductsType } from 'store/Products';
import { MODE } from '.';
import * as menuServices from './services';

export function* fetchMenu(action: ReturnType<typeof actions.menuDetails.fetchMenu>) {
  const { id, lang } = action.payload
  yield put(actions.menuDetails.setIsLoadingDatas({isLoading: true}))
  try {
    const request: SagaReturnType<typeof menuServices.getMenu> = yield call(menuServices.getMenu, {id, lang })
    yield put(actions.menuDetails.setIsLoadingDatas({isLoading: false}))
    yield put(actions.menuDetails.setMenu({menu: request.menu, id, lang}))
  } catch(e) {
  }
}

export function* addCategory(action: ReturnType<typeof actions.menuDetails.triggerAddCategory>) {
  const { id, categoryId, parentCategoryId } = action.payload

  const isSubcategory = parentCategoryId !== undefined && parentCategoryId >= 0
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const postCategoryInMenuParams = {
    menuId: id,
    categoryId
  }

  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'postCategoryInMenu', params: postCategoryInMenuParams }))

    if (isSubcategory) {
      const category: SubcategoryType =  yield select(state => selectors.categories.subcategory(state, categoryId))
      yield put(actions.menuDetails.addSubcategory({ parentCategoryId: parentCategoryId!, category }))
    } else {
      const category: CategoryType =  yield select(state => selectors.categories.category(state, categoryId))
      yield put(actions.menuDetails.addCategory({ category }))
    }
    yield put(actions.menuDetails.addCategorySuccess({id, categoryId}))
  } else {
    try {
      yield call(menuServices.postCategoryInMenu, postCategoryInMenuParams)
      yield put(actions.menuDetails.addCategorySuccess({id, categoryId}))
      yield put(actions.menuDetails.fetchMenu({ id }))
    } catch(e: any) {
      yield put(actions.menuDetails.addCategoryError({error: e}))
    }
  }
}


export function* addProduct(action: ReturnType<typeof actions.menuDetails.triggerAddProduct>) {
  const { id, productId, parentCategoryId, categoryId } = action.payload
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const postCategoryInMenuParams = {
    menuId: id,
    categoryId,
    productId
  }

  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'postProductInMenu', params: postCategoryInMenuParams }))
    const product: ProductsType = yield select(state => selectors.products.getProduct(state, productId))
    yield put(actions.menuDetails.addProduct({ product, parentCategoryId, categoryId }))
    yield put(actions.menuDetails.addProductSuccess())

  } else {
    try {
      yield call(menuServices.postProductInMenu, postCategoryInMenuParams)
      yield put(actions.menuDetails.addProductSuccess())
      yield put(actions.menuDetails.fetchMenu({ id }))
    } catch(e: any) {
      yield put(actions.menuDetails.addProductError({error: e}))
    }
  }
}

export function* removeCategory(action: ReturnType<typeof actions.menuDetails.removeCategory>) {
  const { id, categoryId } = action.payload
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const deleteProductParams = {
    menuId: id,
    categoryId
  }

  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'deleteCategory', params: deleteProductParams }))
    yield put(actions.menuDetails.removeCategorySuccess())
  } else {
    try {
      yield call(menuServices.deleteCategory, deleteProductParams)
      yield put(actions.menuDetails.removeCategorySuccess())
      yield put(actions.menuDetails.fetchMenu({ id }))
    } catch(e: any) {
      yield put(actions.menuDetails.removeCategoryError(e))
    }
  }
}

export function* removeProduct(action: ReturnType<typeof actions.menuDetails.removeProduct>) {
  const { id, categoryId, productId } = action.payload
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const deleteProductParams = {
    menuId: id,
    categoryId,
    productId
  }

  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'deleteProduct', params: deleteProductParams }))
    yield put(actions.menuDetails.removeProductSuccess())
  } else {
    try {
      yield call(menuServices.deleteProduct, deleteProductParams)
      yield put(actions.menuDetails.removeProductSuccess())
      yield put(actions.menuDetails.fetchMenu({ id }))
    } catch(e: any) {
      yield put(actions.menuDetails.removeProductError(e))
    }
  }
}

export function* updateCategoryOrder(action: ReturnType<typeof actions.menuDetails.updateCategoryOrder>) {
  const { id, categoryId, order } = action.payload

  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const patchCategoryOrderParams = {
    menuId: id,
    categoryId,
    orderNum: order
  }

  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'patchCategoryOrder', params: patchCategoryOrderParams }))
    yield put(actions.menuDetails.updateCategoryOrderSuccess())
  } else {
    try {
      yield call(menuServices.patchCategoryOrder, patchCategoryOrderParams)
      yield put(actions.menuDetails.updateCategoryOrderSuccess())
      const request: SagaReturnType<typeof menuServices.getMenu> = yield call(menuServices.getMenu, {id: action.payload.id})
      yield put(actions.menuDetails.setMenu({menu: request.menu, id: action.payload.id}))
    } catch(e: any) {
      yield put(actions.menuDetails.updateCategoryOrderError({error: e}))
    }
  }
}

export function* updateProductOrder(action: ReturnType<typeof actions.menuDetails.updateProductOrder>) {
  const { id, productId, categoryId, newCategoryId, order } = action.payload

  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const pathProductOrderParams = {
    menuId: id,
    categoryId,
    productId,
    orderNum: order,
    newCategoryId
  }
  if (isDraftMode) {
    yield put(actions.menuDetails.addApiCallToQueue({endpoint: 'patchProductOrder', params: pathProductOrderParams }))
    yield put(actions.menuDetails.updateProductOrderSuccess())
  } else {
    try {
      yield call(menuServices.patchProductOrder, pathProductOrderParams)
      yield put(actions.menuDetails.updateProductOrderSuccess())
      const request: SagaReturnType<typeof menuServices.getMenu> = yield call(menuServices.getMenu, { id })
      yield put(actions.menuDetails.setMenu({menu: request.menu, id: action.payload.id}))
    } catch(e: any) {
      yield put(actions.menuDetails.updateProductOrderError({error: e}))
    }
  }
}

export function* saveChanges(action: ReturnType<typeof actions.menuDetails.saveChanges>) {
  const apiQueue: { endpoint: string, params: {}}[] = yield select(selectors.menuDetails.apiQueue)
  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)

  if (isDraftMode) {
    for (const key in apiQueue) {
      const object = apiQueue[key]

      /* @ts-ignore  */
      yield call(menuServices[object.endpoint], object.params)
    }
    yield put(actions.menuDetails.saveChangesSuccess())
    yield put(actions.menuDetails.removeQueue())
    yield put(actions.menuDetails.setMode({mode: MODE.draft}))
  } else {
    yield put(actions.menuDetails.saveChangesSuccess())
  }
}

export function* root() {
  yield all([
    takeEvery(actions.menuDetails.fetchMenu, fetchMenu),
    takeEvery(actions.menuDetails.triggerAddCategory, addCategory),
    takeEvery(actions.menuDetails.updateCategoryOrder, updateCategoryOrder),
    takeEvery(actions.menuDetails.updateProductOrder, updateProductOrder),
    takeEvery(actions.menuDetails.removeCategory, removeCategory),
    takeEvery(actions.menuDetails.removeProduct, removeProduct),
    takeEvery(actions.menuDetails.triggerAddProduct, addProduct),
    takeEvery(actions.menuDetails.saveChanges, saveChanges),
  ])
}
