import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import { ErrorType } from 'api/helpers/customFetch'
import produce from "immer";

export enum PRODUCT_STATUS {
  DRAFT = 'DRAFT',
  VISIBLE = 'VISIBLE',
}

export interface DietOption {
  id: number, i18n: {name: string}
}

export interface MenuProductType {
  menuId: number,
  categoryId: number,
  productId: number,
  orderNum: number,
  menu: {
    businessId: number,
  }
}

export interface ProductsType {
  id: number,
  business?: any,
  photoUrl: string|null,
  price: string,
  currency: string,
  spiciness: number|null,
  status: PRODUCT_STATUS,
  createdAt: string,
  diets: DietOption[],
  menuProducts: MenuProductType[],
  isDisused: boolean,
  i18n: {
    name: string,
    description: string,
    translator?: string | null,
  },
  isOnline: boolean,
  isSoldOut?: boolean
}

export interface ProductsState {
  isLoadingDatasList: boolean,
  dietOptions: {id: number, i18n: {name: string}}[]
  data: {
    products: ProductsType[],
    loadedProductsFromLinkedBusinessIds: number[],
  }
}

const initialState: ProductsState = {
  isLoadingDatasList: false,
  dietOptions: [],
  data: {
    products: [],
    loadedProductsFromLinkedBusinessIds: [],
  }
}

export const slice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    setIsLoadingDatasList:( state: ProductsState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatasList = action.payload.isLoading
    },
    addProduct: (state: ProductsState, action: PayloadAction<{ product: ProductsType }>) => {
      state.data.products.push(action.payload.product)
    },
    setProducts: (state: ProductsState, action: PayloadAction<{ products: ProductsType[] }>) => {
      state.data.products = action.payload.products && action.payload.products.length
        ? action.payload.products.map(p => (
          {
            ...p,
            isDisused: !p.menuProducts.find(pf => !!(pf.menu && pf.menu.businessId))
          }
          ))
        : [];

      state.data.loadedProductsFromLinkedBusinessIds = []; // reset this
    },
    appendLinkedProducts: (state: ProductsState, action: PayloadAction<{ products: ProductsType[], business_id: number }>) => {
      const hasLoadThisLinkedProducts =  !!state.data.loadedProductsFromLinkedBusinessIds.find(bid => bid === action.payload.business_id);
      const newProducts = (action.payload.products && action.payload.products.length ? action.payload.products : []);

      if (!hasLoadThisLinkedProducts) {
        state.data.loadedProductsFromLinkedBusinessIds.push(action.payload.business_id);
        state.data.products.push(...newProducts);
      } else { // Only insert new products
        newProducts.forEach(newProd => {
          if (!state.data.products.find(prod => prod.id === newProd.id)) {
            state.data.products.push(newProd);
          }
        });
      }
    },
    setDietOptions: (state: ProductsState, action: PayloadAction<{ dietOptions: {id: number, i18n: {name: string}}[] }>) => {
      state.dietOptions = action.payload.dietOptions
    },
    fetchProducts: (state: ProductsState) => undefined,
    fetchProduct: (state: ProductsState, action: PayloadAction<{ id: number }>) => undefined,
    fetchLinkedProducts: (state: ProductsState, action: PayloadAction<{ business_id: number }>) => undefined,
    removeProduct: (state: ProductsState, action: PayloadAction<{ id: number }>) => undefined,
    updateProduct: (state: ProductsState, action: PayloadAction<{ id: number,
      product: {
        name: string,
        price: number,
        description: string,
        photoUrl?: string,
        spiciness?: number,
        diets?: {id: number, i18n: {name: string}}[]
      },
      categoryId?: number,
      parentCategoryId?: number,
      menuId?: number,
      imageFile?: File }>) => undefined,
    updateProductSuccess: (state: ProductsState, action: PayloadAction<{ id: number }>) => undefined,
    updateProductError: (state: ProductsState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateProductSold: (state: ProductsState, action: PayloadAction<{
      product: ProductsType
      menuId: number,
      categoryId: number,
      parentCategoryId?: number,
      isSoldOut: boolean
    }>) => undefined,
    createProduct: (state: ProductsState, action: PayloadAction<{
      product: {
        name: string,
        price: number,
        description: string,
        spiciness?: number,
        photoUrl?: string,
        diets?: {id: number, i18n: {name: string}}[] },
        menuId?: number,
        categoryId?: number,
        parentCategoryId?: number,
        imageFile?: File,
        withFetch?: boolean
      }>) => undefined,
    createProductFile: (state: ProductsState, action: PayloadAction<{ file: File, id: number }>) => undefined,
    createProductFileSuccess: (state: ProductsState, action: PayloadAction<{ key: string }>) => undefined,
    createProductFileError: (state: ProductsState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    createProductSuccess: (state: ProductsState, action: PayloadAction<{ product: ProductsType }>) => undefined,
    createProductError: (state: ProductsState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    fetchDietOptions: (state: ProductsState, action: PayloadAction<{lang: string}>) => undefined,
  }
});

// Selectors
const getRoot = (state: RootState) => state.products;
const getProduct = (state: RootState, id: number) => getRoot(state).data.products.find((product) => product.id === id)
const products = (state: RootState) => getRoot(state).data.products
const isLoadingDatasList = (state: RootState) => getRoot(state).isLoadingDatasList
const spiciness = (state: RootState, id: number) => {
  const product = getProduct(state, id)
  if (!product) return null
  if (!product.spiciness) return null
  return product.spiciness
}
const getDiets = (state: RootState) => getRoot(state).dietOptions

const productDiets = (state: RootState, diets: {id: number}[]): DietOption[] => {
  if (!diets.length) {
    return []
  }
  const dietList = getDiets(state)
  const productDiets: DietOption[] = []
  diets.forEach((diet) => {
    const dietOption = dietList.find(d => d.id === diet.id)
    if (dietOption) {
      productDiets.push(dietOption)
    }
  })
  return productDiets
}

const photoUrl = (state: RootState, id: number) => {
  const product = getProduct(state, id)
  if (!product) return null
  if (!product.photoUrl) return null
  return `${process.env.REACT_APP_PICTURE_URL}/${product.photoUrl}`
}

const photoUrlId = (state: RootState, id: number) => {
  const product = getProduct(state, id)
  if (!product) return null
  if (!product.photoUrl) return ''
  return product.photoUrl
}

const photoUrlForProduct = (state: RootState, product?: ProductsType) => {
  if (!product) return null
  if (!product.photoUrl) return null
  return `${process.env.REACT_APP_PICTURE_URL}/${product.photoUrl}`
}

const photoUrlIdForProduct = (state: RootState, product?: ProductsType) => {
  if (!product) return null
  if (!product.photoUrl) return null
  return product.photoUrl
}

const getUnselectedProducts = (state: RootState, ids: number[], businessId?: number) => produce(getRoot(state).data.products, products => products.filter((product) => {
  const isNotUsedYet = ids.indexOf(product.id) < 0;
  const linkedProductBusinessId = product.business && product.business.id;
  return isNotUsedYet &&
  ((!businessId && !linkedProductBusinessId) || (businessId === linkedProductBusinessId));
}))

export const selectors = {
  products,
  productDiets,
  spiciness,
  diets: getDiets,
  getProduct,
  photoUrlForProduct,
  photoUrlIdForProduct,
  isLoadingDatasList,
  photoUrl,
  photoUrlId,
  unselectedProducts: getUnselectedProducts,
};

// reducer / actions
export const { reducer, actions } = slice;
