import Button from 'component/Buttons/Button'
import LanguagePicker from 'component/LanguagePicker'
import ModalContact from 'component/Modals/ModalContact'
import i18next from 'i18next'
import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useHistory } from 'react-router-dom'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import { ROUTE_PATHS, Langs } from 'utils/routers/constants'

const SideBar: FC = () => {
  const history = useHistory()
  const pathName = useMemo(() => history.location.pathname, [history])
  const { t } = useTranslation(['onboarding'])

  const lastStep = 3;
  const steps = useMemo(() => ([
    [ROUTE_PATHS.home],
    [ROUTE_PATHS.businessType],
    // [ROUTE_PATHS.creationOption],
    // [ROUTE_PATHS.addCategory, ROUTE_PATHS.addProduct, ROUTE_PATHS.importMenu,  ROUTE_PATHS.importMenuFiles, ROUTE_PATHS.importMenuPhotos, ROUTE_PATHS.importMenuUrl],
    [
      ROUTE_PATHS.register,
      // ROUTE_PATHS.payment
    ],
    [ROUTE_PATHS.emailVerification],
  ]), [])

  const currentStep = useMemo(() => {
    const step = steps.findIndex((pathList, i) => {
      const path = pathList.find((stepPath) => matchPath(pathName, { path: stepPath, exact: true, strict: true }));
      return path !== null && path !== undefined;
    })
    return step ? step + 1 : 1;
  }, [steps, pathName])

  const languageOptions = useMemo(() => Object.values(Langs).map((lang) => ({label: t(`common:shortLanguages:${lang.toLowerCase()}`), value: lang, icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`}  alt="" />})), [t])
  const defaultLanguage = useMemo(() => languageOptions.find((lang) => lang.value === i18next.language), [languageOptions])

  const onChangeLang = useCallback((option) => {
    i18next.changeLanguage(option.value)
  }, [])

  const renderListItem = useCallback((stepIndex) => {
    const isActive = currentStep === stepIndex ? 'is-active ' : '';
    const isPassed = currentStep > stepIndex ? 'is-passed ' : '';
    return (
      <li className={`nav__item ft-600 c-gray-800 ${isActive}${isPassed}`} key={stepIndex}>
        <span className="nav__icon"><i className="icon icon-check c-cream-100"/></span>
        {t(`onboarding:steps.step_${stepIndex}`)}
      </li>
    )
  }, [t, currentStep])

  return (
    <header className="sidebar no-mobile">
      <div>
        <div className="f f-jc-space-b">
          <a href={`${process.env.REACT_APP_DIGITO_WEBSITE}/${i18next.language !== 'ja' ? i18next.language : ''}`} className="sidebar__logo">
            <img src={'/static/images/logo.svg'} alt={t('dashboard:title')} />
          </a>
          <LanguagePicker options={languageOptions} defaultOption={defaultLanguage} onChangeLang={onChangeLang} />
        </div>
        <div className="sidebar__nav">
          <ul className="nav nav--onboarding">
            {steps.map((_step, i) => i < lastStep ? renderListItem(i + 1) : <span key={i+1}></span>)}
          </ul>
      </div>
      </div>
      <ModalConsumer>
        {({ showModal }) => (<Button className="btn--cream btn--full mt-8" onClick={() => showModal(ModalContact)} text={t('onboarding:contact:title')} />)}
      </ModalConsumer>
    </header>
  )
}

export default SideBar
