import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import ModalForgotPassword from 'component/Modals/ModalPassword/ModalForgotPassword'
import ModalResetPassword from 'component/Modals/ModalPassword/ModalResetPassword'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions } from 'store'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'

const Password: FC<any> = () => {

  const dispatch = useDispatch()
  const { showStickyBanner } = useContext(StickyBannerContext)
  const { showModal } = useContext(ModalContext)
  const [ canShowModal, setCanShowModal ] = useState(true)

  const [isSuccess, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const query = new URLSearchParams(useLocation().search)
  const key = query.get('reset_key')

  useEffect(() => {
    if (key) {
      dispatch(actions.user.verifyResetPassword({key}))
    } else {
      setIsLoading(false)
    }
  }, [dispatch, key])

  const onError = useCallback((e) => {
    setIsLoading(false)
    showStickyBanner(BannerError, { type: e.payload.message })
  }, [showStickyBanner])

  const onSuccess = useCallback(() => {
    showModal(ModalResetPassword, { 
      hideClose: true,
      overlayClassname: "overlay--primary"
    })
    setIsLoading(false)
    setSuccess(true)
  }, [showModal])

  useSagaTakeEvery(actions.user.verifyResetPasswordError, onError)
  useSagaTakeEvery(actions.user.verifyResetPasswordSuccess, onSuccess)

  useEffect(() => {
    if (canShowModal && !isSuccess && !isLoading) {
      setCanShowModal(false)
      showModal(ModalForgotPassword, { 
        hideClose: true,
        overlayClassname: "overlay--primary"
      })
    }
  }, [isSuccess, canShowModal,isLoading,  showModal])

  return <></>
}

export default Password
