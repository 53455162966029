import React, { FC, useCallback, useMemo, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { getSymbolFromCurrency } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import Switch from 'component/Form/Inputs/Switch'

interface ModalPremiumProps {
  onRequestClose: () => void
}

const ModalPremium: FC<ModalPremiumProps> = ({onRequestClose}) => {
  const { t } = useTranslation(['common', 'dashboard'])
  const dispatch = useDispatch()

  const [isMonthlySelected, setIsMonthlySelected] = useState(true)
  const [price, setPrice] = useState('2,500')

  const onClickBtnUpgrade = useCallback(() => {
    dispatch(actions.app.subscribeFromStripe({period: isMonthlySelected ? 'month' : 'year'}))
  }, [dispatch, isMonthlySelected])

  const handleSwitchToggle = useCallback(() => {
    setIsMonthlySelected(!isMonthlySelected)
    setPrice(isMonthlySelected ? '2,000' : '2,500')
  }, [isMonthlySelected])

  const handleMaybeLater = useCallback(() => {
    onRequestClose();
    return false;
  }, [onRequestClose])

  const secondTitle = useMemo(
    () => (
      <div className="f f-direction-column f-jc-end">
        <p className="mb-0">{t('dashboard:onboarding.billed_yearly')}</p>
        <p className="mb-0">{t('dashboard:onboarding.savings')}</p>
      </div>
    ),
    [t]
  )

  const footerContent = useMemo(() => (
    <>
      <Switch className={`period-switch c-gray-600 f f-jc-center f-ai-center ${isMonthlySelected ? 'monthly' : 'yearly'}`} title={t('dashboard:onboarding.billed_monthly')} secondTitle={secondTitle} value={isMonthlySelected} name="test" handleChange={handleSwitchToggle} />
      <div className="f f-ai-center">
        <div className="f f-direction-column f-ai-end mr-3">
          <div className="f">
            <div className="h1 ft-600">{price}</div>
            <div className="ft-left ml-1">
              <div className="c-cream-500 h5 ft-900">{getSymbolFromCurrency('JPA')}</div>
            </div>
          </div>
          <div className="ft-secondary ft-600 c-gray-600">{t('dashboard:onboarding:payment_frequency')}</div>
          <div className="ft-secondary ft-600 c-gray-600">{isMonthlySelected ? `${t('dashboard:onboarding.billed_monthly').toLowerCase()} (${t('dashboard:onboarding.excluding_taxes_short')})` : `${t('dashboard:onboarding.billed_yearly').toLowerCase()} (24,000円, ${t('dashboard:onboarding.excluding_taxes_short')})`}</div>
        </div>
        <Button onClick={handleMaybeLater} text={t('common:modalPremium:cancel')} />
        <Button onClick={onClickBtnUpgrade} text={t('common:modalPremium:unlock')} isPrimary={true}  />
      </div>
    </>
  ), [t, handleMaybeLater, onClickBtnUpgrade, handleSwitchToggle, isMonthlySelected, price, secondTitle])

  return (
    <LayoutModal
      footerContent={footerContent}
      onRequestClose={onRequestClose}
      className="modal--premium modal--lg"
      >
      <div className="modal__left">
        <img width="297" className="modal__img" src="/static/images/modal-premium.png" alt=""/>
      </div>
      <div className="modal__right">
        <h1>{t('common:modalPremium:title')}</h1>
        <div className="c-primary ft-600 mt-1">{ t(`common:modalPremium.desc${!isMonthlySelected ? '_yearly' : ''}`) }</div>
        <ul className="mt-5">
          <li><span className="icon icon-check c-green-600 mr-2" />{t('common:modalPremium:benefits:free')}</li>
          <li className="mt-3"><span className="icon icon-check c-green-600 mr-2" />{t('common:modalPremium:benefits:colors')}</li>
          <li className="mt-3"><span className="icon icon-check c-green-600 mr-2" />{t('common:modalPremium:benefits:translation')}</li>
          <li className="mt-3"><span className="icon icon-check c-green-600 mr-2" />{t('common:modalPremium:benefits:products')}</li>
        </ul>
      </div>
    </LayoutModal>
  )
}

export default ModalPremium
