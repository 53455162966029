import ModalPremium from 'component/Modals/ModalPremium'
import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from 'store'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface CardPlanProps {
}

const CardPlan: FC<CardPlanProps> = () => {
  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const { t } = useTranslation('dashboard')
  const { showModal } = useContext(ModalContext)

  const onClickBtn = useCallback(() => {
    showModal(ModalPremium)
  }, [showModal])
  
  return (
    <div className="card-plan">
      <span className="card-plan__title ft-600 ft-secondary ft-700"><img className="card-plan__icon" src="/static/images/ic_rocket.png" alt="" /> {isBusinessPremium ? t('premiumPlan.title') : t('freePlan.title')}</span>
      {!isBusinessPremium && 
        <>
          <p className="mt-1 mb-0 c-cream-500 ft-500 ft-secondary">{t('freePlan.description')}</p>
          <button onClick={onClickBtn} className="card-plan__cta ft-500 ft-secondary c-cream-500">{t('freePlan.cta')}</button>
        </>
      }
    </div>
  )
}

export default CardPlan