import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import { ErrorType } from 'api/helpers/customFetch'
import { BUSINESS_TYPE } from "store/Business";

export enum UserRole {
  admin = 'admin',
  business = 'business',
  customer = 'customer',
}

export enum UserPlan {
  free = 'free',
  premium = 'premium',
}

export interface UserBusiness {
  id: number,
  name: string,
}

export interface UserState {
  data: {
    firstname: string,
    lastname: string,
    email: string,
    optinEmail: number,
    business: UserBusiness,
    isFirstLogin: boolean,
    isAdmin: boolean,
    isFullAdmin: boolean
  }
  CSRFToken: string | null,
  connected: boolean,
  errors: string,
}

const initialState : UserState = {
  data: {
    firstname: '',
    lastname: '',
    email: '',
    optinEmail: 1,
    business: { id: 0, name: ''},
    isFirstLogin: false,
    isAdmin: false,
    isFullAdmin: false
  },
  CSRFToken: null,
  connected: false,
  errors: '',
}

export const slice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setCSRFToken: (state: UserState, action: PayloadAction<{ CSRFToken: string }>) => {
      state.CSRFToken = action.payload.CSRFToken
    },
    setConnected: (state: UserState, action: PayloadAction<{ connected: boolean }>) => {
      state.connected = action.payload.connected
    },
    setUser: (state: UserState, action: PayloadAction<{ firstname: string, lastname: string, email: string, isFirstLogin?: boolean, optinEmail?: number, business: UserBusiness, isAdmin: boolean, isFullAdmin: boolean  }>) => {
      const {firstname, lastname, email, business, optinEmail = 1, isAdmin, isFullAdmin } = action.payload
      state.data = { firstname, lastname, email, isFirstLogin: state.data.isFirstLogin, business, optinEmail, isAdmin, isFullAdmin }
      state.connected = true
    },
    setIsFirstLogin: (state: UserState, action: PayloadAction<{ isFirstLogin: boolean }>) => {
      state.data.isFirstLogin = action.payload.isFirstLogin
    },
    setLoginErrors: (state: UserState, action: PayloadAction<{ error: string }>) => {
      state.errors = ''
      state.errors = action.payload.error
    },
    login: (state: UserState, action: PayloadAction<{ email: string, password: string }>) => undefined,
    loginSuccess: (state: UserState) => undefined,
    loginError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    logOut: (state: UserState) => undefined,
    fetchUser:(state: UserState, action: PayloadAction<{ id : number }>) => undefined,
    fetchUserSuccess:(state: UserState) => undefined,
    fetchUserError:() => undefined,
    fetchCSRFToken:(state: UserState) => undefined,
    updateUser:(state: UserState, action: PayloadAction<{ firstname: string, lastname: string, email: string, optinEmail: number }>) => undefined,
    updateUserSuccess:(state: UserState) => undefined,
    updateUserError:(state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updatePassword:(state: UserState, action: PayloadAction<{ newPassword: string, oldPassword: string }>) => undefined,
    updatePasswordSuccess:(state: UserState) => undefined,
    updatePasswordError:(state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateSettings: (state: UserState, action: PayloadAction<{
      user: { firstname: string, lastname: string,  email: string, optinEmail: number },
      business: { id: number, settings: {
        name: string,
        zipcode: string,
        telephone: string,
        businessType: BUSINESS_TYPE,
        address: string,
        prefecture: string,
        city: string
      } }
    }>) => undefined,
    updateSettingsSuccess: () => undefined,
    updateSettingsError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    verifyUser: (state: UserState, action: PayloadAction<{ verifyKey: string}>) => undefined,
    verifyUserSuccess: () => undefined,
    verifyUserError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    resetPassword: (state: UserState, action: PayloadAction<{ email: string }>) => undefined,
    resetPasswordSuccess: (state: UserState) => undefined,
    resetPasswordError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateResetPassword: (state: UserState, action: PayloadAction<{ emailResetKey: string, password: string}>) => undefined,
    updateResetPasswordSuccess: (state: UserState) => undefined,
    updateResetPasswordError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    verifyResetPassword: (state: UserState, action: PayloadAction<{ key: string}>) => undefined,
    verifyResetPasswordSuccess: () => undefined,
    verifyResetPasswordError: (state: UserState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    reloadUserInfo: () => undefined
  }
});

// Selectors
const getRoot = (state: RootState) => state.user;
const user = (state: RootState) => getRoot(state).data;
const CSRFToken = (state: RootState) => getRoot(state).CSRFToken;
const connected = (state: RootState) => getRoot(state).connected;
const isAdmin = (state: RootState) => getRoot(state).data.isAdmin
const isFullAdmin = (state: RootState) => getRoot(state).data.isFullAdmin;
const business = (state: RootState) => getRoot(state).data.business;
const errors = (state: RootState) => getRoot(state).errors;
const fullName = (state: RootState) => getRoot(state).data.firstname + " " + getRoot(state).data.lastname;
const isFirstLogin = (state: RootState) => {
  const searchStr = window.location.search.slice(1);
  const searchParams = new URLSearchParams(searchStr);
  const devParam = searchParams.get('dev');
  const isSimulatedFirstLogin = typeof devParam === 'string' && devParam === 'showWelcome';
  return getRoot(state).data.isFirstLogin || isSimulatedFirstLogin;
}
const optinEmail = (state: RootState) => getRoot(state).data.optinEmail === 1;

export const selectors = {
  user,
  CSRFToken,
  errors,
  fullName,
  connected,
  isAdmin,
  isFullAdmin,
  isFirstLogin,
  business,
  optinEmail
};

// reducer / actions
export const { reducer, actions } = slice;
