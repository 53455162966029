import React, { FC, useCallback } from 'react'

interface TabBarProps {
  activeTab: string,
  items: {slug: string, label: string, route: string}[],
  onChangeTab: (slug: string, route: string) => void
}

const TabBar: FC<TabBarProps> = ({ items, onChangeTab, activeTab }) => {
  const handleOnClick = useCallback((slug, route) => {
    onChangeTab(slug, route)
  }, [onChangeTab])

  return (
    <nav className="navbar tabbar">
      <ul className="navbar__links tabbar__body">
        {items.map((item, key) => (
          <li key={item.slug}>
            <button className={`tabbar__item ${activeTab === item.slug ? 'is-active' : ''}`} onClick={() => handleOnClick(item.slug, item.route)}>{item.label}</button>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default TabBar