import Button from 'component/Buttons/Button'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { useTypedSelector } from 'store/store'
import { RouteUrls } from 'utils/routers/constants'

const CreationOption: FC = (props: any) => {
  const dispatch  = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['common', 'onboarding'])

  const backUrl = RouteUrls.BusinessType
  const creationOption = useSelector(selectors.onboarding.creationOption)
  const [creationOptionValue, setCreationOptionValue] = useState(creationOption)
  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 3))

  const optionName = 'creationOption'

  const { handleSubmit, setValue, register } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])
  
  const onSubmit = useCallback((data) => {
    dispatch(actions.onboarding.triggerSetCreationOption({creationOption: data.creationOption}))

    if (data.creationOption === 'scratch') {
      history.push(RouteUrls.Register)
    } else {
      history.push(RouteUrls.ImportMenu)
    }
  }, [dispatch, history])

  const onChangeOption = useCallback((id) => {
    setValue(optionName, id)
    setCreationOptionValue(id)
  }, [optionName, setValue])

  useEffect(() => {
    register(optionName)
  }, [register, optionName])

  useEffect(() => {
    setValue(optionName, creationOption)
    setCreationOptionValue(creationOption)
  }, [optionName, setValue, creationOption])

  const renderInputOption = useCallback((id) => (
    <div className="ft-left input-radio--menu radio radio--btn radio--custom mb-2">
      <input name={optionName} type="radio" id={id} defaultValue={creationOption} checked={creationOptionValue === id} onChange={() => onChangeOption(id)}/>
      <label htmlFor={id}>
        <div className="input-radio__left">
          <h3 className="ft-600 c-gray-800">{t(`onboarding:creationOption:${id}:title`)}</h3>
          <p className="c-gray-600 mt-1 mb-0">{t(`onboarding:creationOption:${id}:description`)}</p>
        </div>
        <div>
          <span className={`label ${id === 'scratch' ? 'label--light' : ''} label--${id === 'scratch' ? 'orange' : 'purple'}`}>{t(`onboarding:creationOption:${id}:tag`)}</span>
        </div>
      </label>
    </div>
  ), [t, creationOptionValue, creationOption, onChangeOption])

  return (
    <LayoutSideBar pageName="creation-option">
      <TopBar backLink={backUrl} />
      <LayoutContent>
        <div className="ft-center">
          <div>
            <h1 className="ft-900 mt-3 mb-5">{t('onboarding:creationOption:title')}</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            { renderInputOption('scratch')} 
            { renderInputOption('import')} 
            <div className="ft-center">
              <Button className="mt-3" isPrimary={true} type="submit" text={t('onboarding:creationOption:next')} /> 
            </div>
          </form>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default CreationOption
