import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Switch from 'component/Form/Inputs/Switch'
import InputTime from 'component/Form/Inputs/InputTime'

import { AVAILABILITY_DAYS, hours, MenuAvailaibility } from 'store/Menus'
import { getError } from 'utils/helpers'

interface ScheduleRowProps {
  day: AVAILABILITY_DAYS,
  onChange: (schedule: MenuAvailaibility[], day: string) => void,
  onRemove: (key: number | null) => void,
  avaibility: MenuAvailaibility[]
}

const ScheduleRow: FC<ScheduleRowProps> = ({avaibility, day, onChange, onRemove}) => {
  const emptyTimeSchedule: MenuAvailaibility = useMemo(() => ({
    day: day,
    hourOpen: '10:00',
    hourClose: '20:00'
  }), [day])
  const { t } = useTranslation(['menus', 'common'])
  const [isDayOpen, setIsDayOpen] = useState(false)
  const schedule = useMemo(() => avaibility.length > 0 ? avaibility : [], [avaibility])

  const addSecondHours = useCallback((schedule) => {
    const newSchedule = [...schedule, emptyTimeSchedule]
    return newSchedule
  }, [emptyTimeSchedule])

  const onSwitchChange = useCallback((name, value) => {
    onChange(value ? [emptyTimeSchedule] : [], day)
  }, [emptyTimeSchedule, onChange, day])

  const onClickAddHours = useCallback(() => {
    const newSchedule = addSecondHours(schedule)
    onChange(newSchedule, day)
  }, [schedule, addSecondHours, onChange, day])

  const onClickRemoveHours = useCallback(() => {
    onRemove(schedule[1].key ? schedule[1].key : null)
  }, [schedule, onRemove])

  const onChangeTime = useCallback((key: number, type: hours, time: string) => {
    const newSchedule = [...schedule]
    newSchedule[key][type] = time
    newSchedule[key].error = null
    onChange(newSchedule, day)
  }, [schedule, onChange, day])

  useEffect(() => {
    setIsDayOpen(schedule.length > 0? true : false)
  }, [schedule])
  

  const hasErrors = useMemo(() => schedule.filter((date) => date.error && date.error.length).length > 0, [schedule])

  const hasSecondHours = useMemo(() => isDayOpen && schedule.length >= 2, [isDayOpen, schedule])
  const hasOnlyFirstHours = useMemo(() => isDayOpen && schedule.length < 2, [isDayOpen, schedule])

  const renderInputTime = useCallback((day, hours, name, error, key) => (
    <InputTime error={error} value={hours} name={`time-${day}-${name}`} inputKey={key} realName={name} onChange={onChangeTime}/>
  ), [onChangeTime])

  return (
    <div className="schedule-row">
      <div className="schedule-row__left">
        <span className="schedule-row__day h5 ft-700 c-gray-800">{t(`menus:menuEditionModal:availabilities.${day.toLowerCase()}`)}</span>
        <Switch title={t(`menus:menuEditionModal:${isDayOpen ? 'open' : 'closed'}`)} name={`switch-${day}-open`} value={isDayOpen} handleChange={onSwitchChange} />
      </div>
      <div className="schedule-row__right">
        <div className="schedule-row__time">
          {isDayOpen && schedule.map((date, key) => (
              <div className="schedule-row__item f f-ai-center" key={key}>
                {renderInputTime(date.day, date.hourOpen, hours.hourOpen, date.error, key)}
                <div className="mr-2 ml-2">-</div>
                {renderInputTime(date.day, date.hourClose, hours.hourClose, date.error, key)}
              </div >
          ))}
        </div>
        {hasErrors && <span className="schedule-row__errors">{getError('sameHours')}</span>}
        {(hasSecondHours) && <button onClick={onClickRemoveHours} type="button" className="icon icon-close icon-16 c-cream-500"></button>}
        {(hasOnlyFirstHours) && <button onClick={onClickAddHours} type="button" className="schedule-row__btn c-primary ft-600">{t('menus:menuEditionModal:addHours')}</button>}
      </div>
    </div>
  )
}

export default ScheduleRow
