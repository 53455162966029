import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ErrorType } from '../../api/helpers/customFetch'
import i18next from 'i18next';
import { groupBy } from 'utils/helpers';

export enum hours {
  hourOpen = 'hourOpen',
  hourClose = 'hourClose'
}

export enum AVAILABILITY_DAYS {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

const availabilityDaysArray = Object.keys(AVAILABILITY_DAYS)

export enum MENU_STATUS {
  DRAFT = 'DRAFT',
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
}

export interface MenuAvailaibility {
  day: AVAILABILITY_DAYS,
  hourOpen: string,
  hourClose: string,
  key?: number,
  error?: string | null
}

export interface MenuType {
  id: number,
  businessId: number,
  name: string,
  status: MENU_STATUS,
  menuAvailabilities: MenuAvailaibility[],
  totalProducts: number,
  totalCategories: number,
  createdAt: string
}

export interface MenusState {
  isLoadingDatasList: boolean,
  data: {
    isFirstMenu: boolean
    menus: MenuType[]
  }
}

const initialState : MenusState = {
  isLoadingDatasList: false,
  data: { 
    menus: [],
    isFirstMenu: true
  }
}

export const slice = createSlice({
  name: 'Menus',
  initialState,
  reducers: {
    setMenus: (state: MenusState, action: PayloadAction<{ menus: MenuType[] }>) => {
      state.data.menus = action.payload.menus && action.payload.menus.length ? action.payload.menus : []
    },
    setIsLoadingDatasList:( state: MenusState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatasList = action.payload.isLoading
    },
    fetchMenus: (state: MenusState) => undefined,
    removeMenu: (state: MenusState, action: PayloadAction<{ id: number }>) => undefined,
    updateMenu: (state: MenusState, action: PayloadAction<{ id: number, menu: {name: string, menuAvailabilities: MenuAvailaibility[]} }>) => undefined,
    updateMenuSuccess: (state: MenusState) => undefined,
    updateMenuError: (state: MenusState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateMenuStatus: (state: MenusState, action: PayloadAction<{ id: number, status: MENU_STATUS}>) => undefined,
    updateMenuStatusSuccess: (state: MenusState) => undefined,
    updateMenuStatusError: (state: MenusState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    createMenu: (state: MenusState, action: PayloadAction<{ menu: {name: string, menuAvailabilities: MenuAvailaibility[] } }>) => undefined,
    createMenuSuccess: (state: MenusState) => undefined,
    createMenuError: (state: MenusState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    claimWelcomeKit: (state: MenusState, action: PayloadAction<{shippingAddress: {address: string, city: string, prefecture: string, zipcode: string}}>) => undefined,
    claimWelcomeKitSuccess: (state: MenusState) => undefined,
    claimWelcomeKitError: (state: MenusState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
  }
});

const getMinifiedDay = (day: string) => i18next.t(`menus:minifiedDays.${day}`)

const getMinifiedScheduleDays = (menuAvailabilitiesKeys: string[]) => {
  const openDays: string[] = []
  let nbOpenPeriods = 0, firstDay = '', lastDay = '';

  if (menuAvailabilitiesKeys.length === 7) {
    firstDay = getMinifiedDay(availabilityDaysArray[0].toLowerCase())
    lastDay = getMinifiedDay(availabilityDaysArray[6].toLowerCase())
    return `${firstDay} - ${lastDay}`
  }

  if (menuAvailabilitiesKeys.length === 1) {
    return getMinifiedDay(
      availabilityDaysArray[
        availabilityDaysArray.indexOf(menuAvailabilitiesKeys[0])
      ].toLowerCase()
    )
  }

  let previousDayIndex = availabilityDaysArray.length - 1;
  let wasOpenYesterday = menuAvailabilitiesKeys.indexOf(availabilityDaysArray[previousDayIndex]) >= 0;

  availabilityDaysArray.forEach((day, dayIndex) => {
    const weekDay = day.toLowerCase()
    const isOpenToday = menuAvailabilitiesKeys.indexOf(day) !== -1

    if (isOpenToday && openDays.indexOf(weekDay) === -1) openDays.push(weekDay)
    if (!wasOpenYesterday && isOpenToday) {
      nbOpenPeriods++
      firstDay = weekDay
    } else if (wasOpenYesterday && !isOpenToday) {
      lastDay = availabilityDaysArray[previousDayIndex].toLowerCase()
    };

    wasOpenYesterday = isOpenToday
    previousDayIndex = dayIndex
  })

  return nbOpenPeriods < 2 ? `${getMinifiedDay(firstDay)} - ${getMinifiedDay(lastDay)}` : openDays.map(item => getMinifiedDay(item)).join(', ')
}

const getHours = (schedule: MenuAvailaibility) => {
  return  `${schedule.hourOpen.slice(0,5)} - ${schedule.hourClose.slice(0,5)}`
}

const getMinifiedScheduleHours = (menuAvailabilities: {}) => {
  let hasSameHours = true, hours = ''
  const availabilities: [MenuAvailaibility][] = Object.values(menuAvailabilities)
  

  if (availabilities.length <= 1 && availabilities.length > 0) {
    availabilities.forEach((availability) => {
      availability.forEach((a, index) => {
        const currentHour = getHours(a)
        hours = index > 0 ?  `${hours}, ${currentHour}` : currentHour
      })
    })
    return hours
  }
  availabilities.forEach((availability: any, index: number) => {
    const nextAvailability = (index  + 1) < availabilities.length ? availabilities[index  + 1] : null
    if (nextAvailability && nextAvailability.length) {
      if (nextAvailability.length !== availability.length) {
        hasSameHours = false
      } else {
        availability.forEach((schedule: MenuAvailaibility, index: number) => {
          const currentHour = getHours(schedule)
          hours = index > 0 ?  `${hours}, ${currentHour}` : currentHour
          if (schedule.hourOpen !== nextAvailability[index].hourOpen || schedule.hourClose !== nextAvailability[index].hourClose) {
            hasSameHours = false
          }
        })
      }
    }
  });

  return hasSameHours ? hours : i18next.t('menus:menu.hoursDependent')
}

// Selectors
const getRoot = (state: RootState) => state.menus
const menus = (state: RootState) => getRoot(state).data.menus
const menusQuantity = (state: RootState) => getRoot(state).data.menus.length
const isFirstMenu = (state: RootState) => getRoot(state).data.isFirstMenu;
const getMenu = (state: RootState, id: number) => getRoot(state).data.menus.find((menu) => menu.id === id)
const isLoadingDatasList = (state: RootState) => getRoot(state).isLoadingDatasList
const hasVisibleMenus = (state: RootState) => getRoot(state).data.menus.filter((menu) => {
  return menu.status === MENU_STATUS.VISIBLE
} ).length > 0
const isOnline = (state: RootState, id: number) => {
  const menu = getMenu(state, id)
  if (!menu) {
    return false
  }
  return menu.status === MENU_STATUS.VISIBLE
}
const isDraft = (state: RootState, id: number) => {
  const menu = getMenu(state, id)
  if (!menu) {
    return false
  }
  return menu.status === MENU_STATUS.DRAFT
}
const isInvisible = (state: RootState, id: number) => {
  const menu = getMenu(state, id)
  if (!menu) {
    return false
  }
  return menu.status === MENU_STATUS.INVISIBLE
}
const hasUnfinalizedMenus = (state: RootState) => getRoot(state).data.menus.filter((menu) => menu.status !== MENU_STATUS.VISIBLE ).length > 0

const schedule = (state: RootState, id: number): {days: string, hours: string}=> {
  const menu = getMenu(state, id)
  if (!menu || !menu.menuAvailabilities.length) return { days: '', hours: '' }
  const menuAvailabilities = groupBy(menu.menuAvailabilities, 'day')
  return {
    days: getMinifiedScheduleDays(Object.keys(menuAvailabilities)),
    hours: getMinifiedScheduleHours(menuAvailabilities)
  }
}
const isOneMenuAlreadyOnline = (state: RootState) =>
  getRoot(state).data.menus.reduce((acc, menu) => {
    if (
      menu.status === MENU_STATUS.VISIBLE ||
      menu.status === MENU_STATUS.INVISIBLE
    ) {
      acc = true
    }
    return acc
  }, false)


export const selectors = {
  menus,
  menusQuantity,
  isLoadingDatasList,
  hasUnfinalizedMenus,
  menu: getMenu,
  schedule,
  isOnline,
  isDraft,
  isInvisible,
  hasVisibleMenus,
  isFirstMenu,
  isOneMenuAlreadyOnline
};

// reducer / actions
export const { reducer, actions } = slice;