import React, { FC } from 'react'

interface BannerInfoProps {
  type?: 'green' | 'red',
  message: string | JSX.Element,
  className?: string
}

const BannerInfo: FC<BannerInfoProps> = ({type = 'green', message, className = ''}) => {
  return (
    <div className={`banner-info banner-info--${type} ${className}`}>
      <span className="banner-info__icon icon icon-info icon-20"></span>
      {typeof message === 'string' ? (
        <span
          className="ft-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        message
      )}
    </div>
  )
}

export default BannerInfo