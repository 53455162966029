import React, { FC } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { RouteUrls } from 'utils/routers/constants'

interface ModalPremiumPaymentSuccessProps {
  onRequestClose: () => void
  hasSucceeded: boolean
}

const ModalPremiumPaymentSuccess: FC<ModalPremiumPaymentSuccessProps> = (props) => {
  const { onRequestClose, hasSucceeded } = props

  const { t } = useTranslation(['dashboard'])

  const title = hasSucceeded ? t('dashboard:premiumModal.success.title') : t('dashboard:premiumModal.failure.title')
  const description = hasSucceeded ? t('dashboard:premiumModal.success.description') : t('dashboard:premiumModal.failure.description')

  return (
    <LayoutModal onRequestClose={onRequestClose} className={!hasSucceeded ? 'modal--sm' : 'modal--md'}>
      <div className="ft-center">
        {hasSucceeded && <img src="/static/images/rocket.svg" alt="Rocket taking off" />}
      </div>
      <h1 className="mb-2 mt-5 ft-center ">{title}</h1>
      <p className="ft-center mb-4">{description}</p>
      <div className="f f-jc-center">
        <Button className="h5" text={t('dashboard:premiumModal.cta')} isPrimary={true} link={RouteUrls.Menus} onClick={onRequestClose} />
      </div>
    </LayoutModal>
  )
}

export default ModalPremiumPaymentSuccess
