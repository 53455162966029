import React, { FC } from 'react'

interface TopBarDashboardProps {
  title: string,
  leftContent?: React.ReactNode,
  rightContent?: React.ReactNode,
  tabBarContent?: React.ReactNode
}

const TopBarDashboard: FC<TopBarDashboardProps> = ({title, leftContent = <></>, rightContent = <></>, tabBarContent = null}) => {
  return (
    <header className={`topbar-dashboard ${tabBarContent ? 'topbar-dashboard--tap' : ''}`}>
      <div className="topbar-dashboard__top">
        <div>
          <h1>{title}</h1>
          <div className="topbar-dashboard__left">
            {leftContent}
          </div>
        </div>
        <div className="topbar-dashboard__right">
          {rightContent}
        </div>
      </div>
      {tabBarContent && 
        <div className="topbar-dashboard__bottom">
          {tabBarContent}
        </div>
      }
    </header>
  )
}

export default TopBarDashboard