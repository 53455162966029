import React, { FC, useCallback, useContext, useMemo } from 'react'
import CardMenuProduct from 'component/Cards/CardMenuProduct'
import { Droppable } from "react-beautiful-dnd"
import { DraggableTypes } from 'component/MenuOverview'
import { ProductsType } from 'store/Products'
import { useTranslation } from 'react-i18next'
import ModalMenuProductEdition from 'component/Modals/ModalMenuProductEdition'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface ListMenuProductsProps {
  products?: ProductsType[],
  parentCategoryId?: number,
  categoryName: string,
  categoryId: number,
  menuId: number,
  nbTotalProducts?: number,
  linkedBusinessId?: number | null,
}

const ListMenuProducts: FC<ListMenuProductsProps> = ({products, menuId, parentCategoryId, categoryId, categoryName, nbTotalProducts, linkedBusinessId}) => {
  const { t } = useTranslation(['menuDetails'])
  const { showModal } = useContext(ModalContext)

  const showModalMenuProductEdition = useCallback(() => {
    showModal(ModalMenuProductEdition, { categoryName, menuId, categoryId, parentCategoryId, categoryLinkedBusinessId: linkedBusinessId })
  }, [showModal, categoryName, menuId, categoryId, parentCategoryId, linkedBusinessId])

  const renderEmptyCard = useMemo(() => (
    <div className="card-menu-category__empty">
      {(!nbTotalProducts) ? <div>{t('menuDetails:overview:emptyProducts')}</div> : <div></div> }
      <button
        className="c-primary ft-600 f-inline f-ai-center"
        onClick={showModalMenuProductEdition}
      >
        <span className="icon icon-plus icon-20  mr-1"></span>
        {t('menuDetails:overview:add:product')}
      </button>
    </div>
  ), [showModalMenuProductEdition, t, nbTotalProducts])

  const renderProductList = useMemo(() => {
    if (products && products.length) {
      return (
        <>
          { products.map((product, order) => (product && product.id) && <CardMenuProduct menuId={menuId} categoryId={categoryId} key={`p${product.id}`} order={order} parentCategoryId={parentCategoryId} product={product} />
          )}
        </>
      )
    }
    return <></>
  }, [products, menuId, categoryId, parentCategoryId])

  const droppableProductZoneId = useMemo(() => `${parentCategoryId ? `droppableProductZone-c${parentCategoryId}-sc${categoryId}` : `droppableProductZone-c${categoryId}`}${linkedBusinessId ? `-lb${linkedBusinessId}` : ''}`, [categoryId, parentCategoryId, linkedBusinessId])

    return (
      <Droppable
        droppableId={droppableProductZoneId}
        type={DraggableTypes.Product}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ul>
              {renderProductList}
              {renderEmptyCard}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    )
}

export default ListMenuProducts
