import { call, all, put, SagaReturnType, takeLatest, takeEvery, race, take, fork } from 'redux-saga/effects'
import { actions } from 'store';
import { RouteUrls } from 'utils/routers/constants';
import * as services from './services'
import * as onboardingSagas from '../Onboarding/saga'

export function* reloadUserInfo() {
  yield fork(fetchUserInfo)
}

export function* fetchCSRFToken() {
  try {
    const token: SagaReturnType<typeof services.getCSRFToken> = yield call(services.getCSRFToken)
    console.debug(`Successfully fetched CSRF Token`)
    yield put(actions.user.setCSRFToken({CSRFToken: token.crumb}))
    yield put(actions.app.setIsReady({isReady: true}))
  } catch (e) {
  }
}

export function* fetchUserInfo() {
  try {
    const user: SagaReturnType<typeof services.getUserInfos> = yield call(services.getUserInfos)
    if (!user) {
      yield put(actions.user.fetchUserError())
    } else {
      yield put(actions.user.setUser(user))
      yield put(actions.business.fetchBusiness({id: user.business.id}))
      yield put(actions.user.fetchUserSuccess())
    }
  } catch (e) {
    yield put(actions.user.fetchUserError())
  }
}

export function* login(action: ReturnType<typeof actions.user.login>) {
  try {
    const user: SagaReturnType<typeof services.handleLogin> = yield call(services.handleLogin, action.payload)
    yield put(actions.user.setIsFirstLogin({ isFirstLogin: user.firstLogin }))
    yield put(actions.user.loginSuccess())
    yield put(actions.user.setUser(user))
    yield put(actions.business.fetchBusiness({id: user.business.id}))
  } catch (e: any) {
    yield put(actions.user.loginError(e))
  }
}

export function* logOut(action: ReturnType<typeof actions.user.logOut>) {
  try {
    yield call(services.handleLogout)
    window.location.replace(RouteUrls.Login)
    yield put(actions.user.setConnected({connected: false}))
  } catch (e: any) {
    yield put(actions.user.loginError(e))
  }
}

export function* verify(action: ReturnType<typeof actions.user.verifyUser>) {
  try {
    yield call(services.handleVerifyUser, action.payload.verifyKey)
    yield put(actions.user.verifyUserSuccess())
    yield call(onboardingSagas.clearVerificationData)
    yield call(onboardingSagas.clearImportData)
    yield put(actions.user.setConnected({connected: false}))
  } catch (e: any) {
    yield put(actions.user.verifyUserError(e))
  }
}

export function* resetPassword(action: ReturnType<typeof actions.user.resetPassword>) {
  try {
    yield call(services.handleResetPassword, action.payload)
    yield put(actions.user.resetPasswordSuccess())
  } catch (e: any) {
    yield put(actions.user.resetPasswordError(e))
  }
}

export function* updateResetPassword(action: ReturnType<typeof actions.user.updateResetPassword>) {
  try {
    yield call(services.updateResetPassword, action.payload)
    yield put(actions.user.updateResetPasswordSuccess())
  } catch (e: any) {
    yield put(actions.user.updateResetPasswordError(e))
  }
}

export function* verifyResetPassword(action: ReturnType<typeof actions.user.verifyResetPassword>) {
  try {
    yield call(services.verifyResetPassword, action.payload.key)
    yield put(actions.user.verifyResetPasswordSuccess())
  } catch (e: any) {
    yield put(actions.user.verifyResetPasswordError(e))
  }
}

export function* updateUser(action: ReturnType<typeof actions.user.updateUser>) {
  try {
    yield call(services.updateUser, action.payload)
    yield put(actions.user.updateUserSuccess())
  } catch (e: any) {
    yield put(actions.user.updateUserError(e))
  }
}

export function* updatePassword(action: ReturnType<typeof actions.user.updatePassword>) {
  try {
    yield call(services.updateUser, action.payload)
    yield put(actions.user.updatePasswordSuccess())
  } catch (e: any) {
    yield put(actions.user.updatePasswordError(e))
  }
}

export function* updateSettings(action: ReturnType<typeof actions.user.updateSettings>): any {
  const { user, business } = action.payload
  try {
    yield put(actions.user.updateUser({...user}))
    yield put(actions.business.updateBusinessSettings({id: business.id, settings: business.settings}))

    const [updateUser, updateBusiness] = yield all([
      race([
        take(actions.user.updateUserSuccess),
        take(actions.user.updateUserError)
      ]),
      race([
        take(actions.business.updateBusinessSettingsSuccess),
        take(actions.business.updateBusinessSettingsError)
      ])
    ])

    const req: SagaReturnType<typeof services.getUserInfos> = yield call(services.getUserInfos)
    yield put(actions.user.setUser(req))

    const [_updateUserSuccess, updateUserError] = updateUser
    const [_updateBusinessSuccess, updateBusinessError] = updateBusiness

    if (updateUserError || updateBusinessError) {
      yield put(actions.user.updateSettingsError(updateBusinessError ? updateBusinessError : updateUserError))
      return;
    }
    yield put(actions.user.updateSettingsSuccess())
  } catch (e: any) {
    yield put(actions.user.updateSettingsError(e))
  }
}

export function* root() {
  yield all([
    takeLatest(actions.user.login, login),
    takeEvery(actions.user.logOut, logOut),
    takeLatest(actions.user.resetPassword, resetPassword),
    takeLatest(actions.user.updateResetPassword, updateResetPassword),
    takeLatest(actions.user.verifyResetPassword, verifyResetPassword),
    takeLatest(actions.user.verifyUser, verify),
    takeEvery(actions.user.updateUser, updateUser),
    takeEvery(actions.user.updatePassword, updatePassword),
    takeEvery(actions.user.updateSettings, updateSettings),
    takeEvery(actions.user.reloadUserInfo, reloadUserInfo)
  ])
}
