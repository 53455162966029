import BannerError from 'component/Banners/BannerError'
import CardMenuImport from 'component/Cards/CardMenuImport'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'

const TabMenuImports = () => {
  const menuImports = useSelector(selectors.admin.menuImports)
  const { showStickyBanner } = useContext(StickyBannerContext)

  const onUpdateError = () => {
    showStickyBanner(BannerError)
  }

  useSagaTakeEvery(actions.admin.updateMenuImportStatusError, onUpdateError)

  return (
    <section>
      {menuImports.map((menuImport) => (
        <CardMenuImport
          key={menuImport.id}
          business={menuImport.business}
          id={menuImport.id}
          menuImportFiles={menuImport.menuImportFiles}
          status={menuImport.status}
          createdAt={menuImport.createdAt}
        />
      ))}
    </section>
  )
}

export default TabMenuImports
