import React, { FC, useState, useMemo, useCallback } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { BusinessTheme } from 'store/Business'
import MenuPreviewWrapper from 'component/MenuPreviewWrapper'
import InputColorRadio from 'component/Form/Inputs/InputColorRadio'

const colors = {
  classic: [
    '#ffffff', '#000000'
  ],
  light: [
    '#efe4e8', '#e8e3dd', '#eef2df',
    '#e4efed', '#e2e7f2', '#f4edf2',
    '#f2dbda', '#f9f3df', '#edf6e5',
    '#dee1f0', '#ece9f2', '#ebebed'
  ],
  dark: [
    '#4D161A', '#603C1B', '#384B16',
    '#164540', '#1B2959', '#501935',
    '#5A291A', '#604E0D', '#133922',
    '#183A51', '#381C5D', '#5A1925'
  ],
  actionColors: [
    '#A62931', '#FE964A', '#75A12A',
    '#299388', '#3454C2', '#AE2F71',
    '#C45330', '#D1A815', '#227844',
    '#2E7AAE', '#7536CB', '#C52E4B'
  ]
}

interface ModalConfigColorsProps {
  onRequestClose: () => void,
  menuId: number
}

const ModalConfigColors: FC<ModalConfigColorsProps> = ({onRequestClose, menuId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menuDetails'])
  const business = useSelector(selectors.business.business)
  const isDarkTheme = useSelector(selectors.business.isDarkTheme)
  const configBackgroundcolor = useSelector(selectors.business.backgroundColor)
  const configActionColor = useSelector(selectors.business.actionColor)

  const [theme, setTheme] = useState<BusinessTheme>(isDarkTheme ? BusinessTheme.DARK :  BusinessTheme.LIGHT)
  const [actionColor, setActionColor] = useState(configActionColor)
  const [backgroundColor, setBackgroundColor] = useState(configBackgroundcolor)

  const onSave = useCallback(() => {
    dispatch(actions.business.setColors({theme, actionColor, backgroundColor}))
    dispatch(actions.business.updateBusinessColors({id: business.id, theme, actionColor, backgroundColor }))
    onRequestClose()
  }, [dispatch, theme, business, actionColor, backgroundColor, onRequestClose])

  const footerContent = useMemo(() => (
    <>
      <Button onClick={onRequestClose} text={t('menuDetails:colorsEditionModal:cancel')} />
      <Button onClick={onSave} text={t( 'menuDetails:colorsEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, onSave])

  const onChangeValue = useCallback((name, type, value) => {
    if (type === BusinessTheme.DARK) {
      setTheme(BusinessTheme.DARK)
      setBackgroundColor(value)
    } else {
      setTheme(BusinessTheme.LIGHT)
      setBackgroundColor(value)
    }
  }, [])

  const onChangeActionColor = useCallback((name, type, value) => {
    setActionColor(value)
  }, [])

  const renderBackgroundColors = useCallback((colors: string[], isDarkColor) => {
    return (
      <div className="">
        {colors.map((color, index) => <InputColorRadio key={`${color}-${index}`} className="mt-1 mr-1" value={color} color={color} type={isDarkColor ? BusinessTheme.DARK : BusinessTheme.LIGHT} name="color" defaultChecked={backgroundColor === color} id={color} onChange={onChangeValue} />)}
      </div>
    )
  }, [backgroundColor, onChangeValue])

  return (
    <LayoutModal
      title={t('menuDetails:colorsEditionModal:title')}
      onRequestClose={onRequestClose}
      className={(menuId ? 'modal--config-colors' : 'modal--md')}
      footerContent={footerContent}
      >
        <div className="f">
          <div className="modal__left">
            <h3>{t('menuDetails:colorsEditionModal:backgroundColors:title')}</h3>
            <div className="c-gray-600 mt-1">{t('menuDetails:colorsEditionModal:backgroundColors:desc')}</div>

            <h4 className="ft-600 mt-2">{t('menuDetails:colorsEditionModal:backgroundColors:classic')}</h4>
            <div className="">
              <InputColorRadio className="mt-1 mr-1" value={colors.classic[0]} color={colors.classic[0]} name="color" onChange={onChangeValue} type={BusinessTheme.LIGHT} defaultChecked={backgroundColor === colors.classic[0]} id={colors.classic[0]} />
              <InputColorRadio className="mt-1 mr-1" value={colors.classic[1]} onChange={onChangeValue} color={colors.classic[1]} name="color" type={BusinessTheme.DARK} defaultChecked={backgroundColor === colors.classic[1]} id={colors.classic[1]} />
            </div>

            <h4 className="ft-600 mt-2">{t('menuDetails:colorsEditionModal:backgroundColors:light')}</h4>
            {renderBackgroundColors(colors.light, false)}

            <h4 className="ft-600 mt-2">{t('menuDetails:colorsEditionModal:backgroundColors:dark')}</h4>
            {renderBackgroundColors(colors.dark, true)}

            <h3 className="mt-3">{t('menuDetails:colorsEditionModal:actionColors:title')}</h3>
            <div className="c-gray-600 mt-1">{t('menuDetails:colorsEditionModal:actionColors:desc')}</div>
            <div className="">
              {colors.actionColors.map((color, index) => <InputColorRadio key={`${color}-${index}`} className="mt-1 mr-1" type="actionColor" id={color} name="actionColor" value={color} color={color} onChange={onChangeActionColor} defaultChecked={actionColor === color} />)}
            </div>
          </div>
          {menuId && <div className="modal__right">
            <MenuPreviewWrapper theme={theme} backgroundColor={backgroundColor} actionColor={actionColor} menuId={menuId} sizes={{minGapHeight: 35, maxMobileHeight: 610}}/>
          </div>}
        </div>
    </LayoutModal>
  )
}

export default ModalConfigColors
