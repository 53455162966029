import DropdownActions from 'component/Buttons/DropdownActions'
import ModalCategoryEdition from 'component/Modals/ModalCategoryEdition'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import ModalSubcategoryEdition from 'component/Modals/ModalSubcategoryEdition'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'store'
import { CategoryType } from 'store/Categories'
import { useTypedSelector } from 'store/store'
import { ModalConsumer, ModalContext } from 'utils/hooks/ModalBehavior'
import CardSubcategory from '../CardSubcategory'

interface CardCategoryProps {
  category: CategoryType,
  hasActions?: boolean,
  isOpen?: boolean
}

const CardCategory: FC<CardCategoryProps> = ({ category, isOpen }) => {
  const { t } = useTranslation(['menus'])
  const dispatch = useDispatch()
  const [isToggled, setIsToggled] = useState(isOpen !== undefined ? isOpen : false)
  const photoUrl = useTypedSelector(state => selectors.categories.photoUrl(state, category.id))
  const { showModal } = useContext(ModalContext)

  const linkedBusinessName = useMemo(() => ('linkedBusiness' in category && category.linkedBusiness && category.linkedBusiness.name) ? category.linkedBusiness.name : null, [category]);

  const onDuplicateCategory = useCallback(() => {
    dispatch(actions.categories.createCategory({ category: {
      name: `${category.i18n?.name} (${t('menus:copy')})`,
      description: category.i18n?.description,
      parentCategoryId: category.parentCategoryId,
      photoUrl: category.photoUrl
    } }))
  }, [category, dispatch, t])

  const deleteCategory = useCallback(() => {
    dispatch(actions.categories.removeCategory({ id: category.id }))
  }, [category, dispatch])

  const modalDeleteConfirmationProps = useMemo(() => ({
    title: t('menus:categoryDeleteConfirmationModal:title', {
      categoryName: category.i18n?.name
    }),
    desc: t('menus:categoryDeleteConfirmationModal:desc'),
    cancelCTAText: t('menus:categoryDeleteConfirmationModal:cancelCTA'),
    confirmCTAText: t('menus:categoryDeleteConfirmationModal:confirmCTA'),
    onConfirm: deleteCategory,
  }), [deleteCategory, t, category])

  const onClickToggle = useCallback(() => {
    setIsToggled(!isToggled)
  }, [isToggled])

  return (
    <div className="card-category">
      <button onClick={onClickToggle} className={`card-category__toggle icon icon-20 c-gray-800 ${isToggled ? 'icon-arrow-down' : 'icon-arrow-right'} ${category.subCategories.length > 0 ? 'card-category__toggle-subcategory' : ''}`} />
      <div className="card-category__body">
        <div className="f f-jc-space-b f-ai-center">
          <div className="f">
            {photoUrl &&  <img className="card-category__thumbnail mr-2" src={photoUrl} alt="" />}
            <div className="card-category__content">
              <h3 className="ft-600 mb-1">{ linkedBusinessName || category.i18n?.name }</h3>
              <div className="c-gray-600 pre-wrap">{category.i18n?.description}</div>
            </div>
          </div>
          <DropdownActions iconClassName="icon-more icon-24 c-gray-800">
            <li>
              <button className="c-gray-800 ft-600" onClick={() => showModal(ModalCategoryEdition, { category })}><span className="icon icon-edit mr-2 icon-16 c-cream-500"></span>{t('menus:category:edit')}</button>
            </li>
            <li style={{display: 'none'}}><button className="c-gray-800 ft-600" onClick={onDuplicateCategory}>{t('menus:category:duplicate')}</button></li>
            <li>
              <button className="c-red ft-600" onClick={() => showModal(ModalDeleteConfirmation, { ...modalDeleteConfirmationProps })}><span className="icon icon-trash mr-2 icon-16"></span>{t('menus:category:delete')}</button>
            </li>
          </DropdownActions>
        </div>
        {isToggled &&
          <div className="card-category__footer">
            {category.subCategories.map((subcategory) => <CardSubcategory subcategory={subcategory} categoryName={category.i18n?.name} key={subcategory.id} />)}
            <div className="ft-right mt-2">
              <ModalConsumer>
                {({ showModal }) => ( <button className="c-primary ft-600" onClick={() => showModal(ModalSubcategoryEdition, { categoryId: category.id, categoryName: category.i18n?.name })}>{t('menus:category:createSubcategory')}</button> )}
              </ModalConsumer>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default CardCategory
