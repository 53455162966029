import TabBar from 'component/Headers/TabBar';
import TopBarDashboard from 'component/Headers/TopBarDashboard';
import LayoutContent from 'component/Layouts/LayoutContent';
import LayoutSideBar from 'component/Layouts/LayoutSideBar';
import Tab from 'component/Tabs/Tab';
import TabCategories from 'component/Tabs/TabCategories';
import TabProducts from 'component/Tabs/TabProducts'
import TabStore from 'component/Tabs/TabStore';
import TabMenus from 'component/Tabs/TabMenus';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import { useHistory } from 'react-router';
import { RouteUrls } from 'utils/routers/constants';
import TabTranslations from 'component/Tabs/TabTranslations';
import InputSelect from 'component/Form/Inputs/InputSelect';
import Button from 'component/Buttons/Button';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation';
import { ModalContext } from 'utils/hooks/ModalBehavior';
import i18next from 'i18next';

const Menus: FC = () => {
  const { t } = useTranslation(['menus', 'common'])

  const [isLoading, setIsLoading] = useState(false)
  const [isResetLoading, setIsResetLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [activeSubTab, setActiveSubTab] = useState('')
  const lang = useSelector(selectors.translations.lang)

  const isPremium = useSelector(selectors.business.isPremium)
  const translationLanguages = useSelector(selectors.business.translationLanguages)

  const dispatch = useDispatch()
  const history = useHistory()

  const  { showStickyBanner } = useContext(StickyBannerContext)
  const  { showModal } = useContext(ModalContext)

  const getIcon = useCallback((lang) => <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`}  alt="" />, [])
  const getTranslationLabel = useCallback((lang) => t(`common:languages:${lang.toLowerCase()}`), [t])

  const languageOptions = useMemo(() => translationLanguages.map((lang) => ({value: lang, label: getTranslationLabel(lang), icon: getIcon(lang) })), [translationLanguages, getTranslationLabel, getIcon])
  const languageDefaultOption = useMemo(() => languageOptions.find((option) => option.value === lang), [languageOptions, lang])

  const tabBarItems = useMemo(() => {
    const tabs = [
      {label: t('menus:tabBar.menus'), slug: 'menus', route: RouteUrls.Menus},
      {label: t('menus:tabBar.categories'), slug: 'categories', route: RouteUrls.Categories},
      {label: t('menus:tabBar.products'), slug: 'products', route: RouteUrls.Products },
    ]
    if (isPremium && translationLanguages.length) tabs.push({label: t('menus:tabBar.translations'), slug: 'translations', route: RouteUrls.Translations} )
    tabs.push({label: t('menus:tabBar.store'), slug: 'store', route: RouteUrls.Store });
    return tabs
  }, [t, isPremium, translationLanguages])

  const translationSubTabBarItems = useMemo(() => ([
    {label: t('menus:translationsSubTabBar.categories'), slug: 'categories'},
    {label: t('menus:translationsSubTabBar.subcategories'), slug: 'subcategories'},
    {label: t('menus:translationsSubTabBar.products'), slug: 'products'}
  ]), [t])

  const onChangeTab = useCallback((slug: string, route: string) => {
    history.push(route)
  }, [history])

  const onChangeTranslationSelect = useCallback((name, value: string) => {
    dispatch(actions.translations.setLang({lang: value}))
  }, [dispatch])

  const onChangeSubTab = useCallback((slug: string) => {
    setActiveSubTab(slug)
  }, [])

  const onSubmitTranslationForm = useCallback(() => {
    setIsLoading(true)
  }, [])

  const resetTranslations = useCallback(() => {
    dispatch(actions.translations.resetTranslations({languageId: lang, type: activeSubTab }))
    setIsResetLoading(true)
  }, [dispatch, activeSubTab, lang])

  const modalResetTranslationConfirmationProps = useMemo(() => ({
    title: t('menus:resetConfirmationModal:title'),
    desc: t('menus:resetConfirmationModal:desc'),
    cancelCTAText: t('menus:resetConfirmationModal:cancelCTA'),
    confirmCTAText: t('menus:resetConfirmationModal:confirmCTA'),
    onConfirm: resetTranslations,
  }), [resetTranslations, t])

  const onClickReset = useCallback(() => {
    showModal(ModalDeleteConfirmation, {...modalResetTranslationConfirmationProps})
  }, [showModal, modalResetTranslationConfirmationProps])

  const onTranslationSuccess = useCallback(() => {
    setIsLoading(false)
    setIsResetLoading(false)
  }, [])

  const onTranslationError = useCallback((action) => {
    showStickyBanner(BannerError, {
      type: action.payload.error.message === 'translation_unknow' ? 'translation_unknow' : 'unknown'
    })
    setIsLoading(false)
    setIsResetLoading(false)
  }, [showStickyBanner])

  useEffect(() => {
    dispatch(actions.menus.fetchMenus())
    dispatch(actions.categories.fetchCategories())
    dispatch(actions.products.fetchProducts())
    dispatch(actions.products.fetchDietOptions({lang: i18next.language.toUpperCase()}))
  }, [dispatch])

  useEffect(() => {
    if(lang && isPremium) {
      dispatch(actions.translations.fetchContent({languageId: lang}))
    }
  }, [dispatch, lang, isPremium])

  useEffect(() => {
    const item = tabBarItems.find(item => history.location.pathname.indexOf(item.route) >= 0)
    if (item) {
      setActiveTab(item?.slug)
    } else {
      setActiveTab(tabBarItems[0].slug)
    }
  }, [history, tabBarItems, translationSubTabBarItems])

  useEffect(() => {
    if (activeTab === 'translations') {
      setActiveSubTab(translationSubTabBarItems[0].slug)
    }
  }, [activeTab, translationSubTabBarItems])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      dispatch(actions.products.fetchDietOptions({lang: i18next.language.toUpperCase()}))
    })
  }, [dispatch])

  useSagaTakeEvery(actions.translations.updateCategories, onSubmitTranslationForm)
  useSagaTakeEvery(actions.translations.updateProducts, onSubmitTranslationForm)

  useSagaTakeEvery(actions.translations.updateCategoriesSuccess, onTranslationSuccess)
  useSagaTakeEvery(actions.translations.updateProductsSuccess, onTranslationSuccess)
  useSagaTakeEvery(actions.translations.resetTranslationsSuccess, onTranslationSuccess)

  useSagaTakeEvery(actions.translations.updateProductsError, onTranslationError)
  useSagaTakeEvery(actions.translations.updateCategoriesError, onTranslationError)
  useSagaTakeEvery(actions.translations.resetTranslationsError, onTranslationError)

  const translationSubTabBarContent = useMemo(() => (
    <div className="page__subtabbar">
      <div className="page__subtabbar__left f f-ai-center">
        <nav className="tabbar tabbar--translations">
          <ul className="tabbar__body">
            {translationSubTabBarItems.map(item => (
              <li key={item.slug} className={`tabbar__item ${activeSubTab === item.slug ? 'is-active' : ''}`} >
                <button key={item.slug} className="tabbar__button ft-700" onClick={() => onChangeSubTab(item.slug)}>{item.label}</button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="f f-ai-center f-jc-right">
        <InputSelect defaultOption={languageDefaultOption} className="mr-2 ml-3" isSearchable={false} placeholder={t('menus:translationsSubTabBar:inputLanguages:placeholder')} options={languageOptions} onChange={onChangeTranslationSelect} />
      </div>
      <div className="f f-ai-center f-jc-right f-1">
        <Button isLoading={isResetLoading} isDisabled={isLoading || isResetLoading} text={t('menus:translationsSubTabBar:reset')} iconLeft="icon-reset icon-20 c-cream-500" onClick={onClickReset} />
        <Button isLoading={isLoading} isDisabled={isLoading || isResetLoading} isPrimary={true} text={t('menus:translationsSubTabBar:save')} type="submit" form={`translation-${activeSubTab}`} />
      </div>
    </div>
  ), [translationSubTabBarItems, activeSubTab, languageOptions, languageDefaultOption, isLoading, isResetLoading, onChangeTranslationSelect, onChangeSubTab, onClickReset, t])

  const tabBarContent = useMemo(() => (
    <>
      <TabBar items={tabBarItems} onChangeTab={onChangeTab} activeTab={activeTab} />
      {activeTab === 'translations' && translationSubTabBarContent}
    </>
  ), [tabBarItems, onChangeTab, activeTab, translationSubTabBarContent])

  return (
    <LayoutSideBar pageName={activeTab} type="dashboard">
      <TopBarDashboard
        title={t('menus:topBar.title')}
        tabBarContent={tabBarContent} />
      <LayoutContent>
        <Tab slug='menus' activeTab={activeTab}><TabMenus /></Tab>
        <Tab slug='categories' activeTab={activeTab}><TabCategories /></Tab>
        <Tab slug='products' activeTab={activeTab}><TabProducts /></Tab>
        <Tab slug='store' activeTab={activeTab}><TabStore /></Tab>
        <Tab slug='translations' activeTab={activeTab}><TabTranslations activeTab={activeSubTab} language={lang} /></Tab>
      </LayoutContent>
    </LayoutSideBar>
  );
}

export default Menus;
