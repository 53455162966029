import { call, all, put, SagaReturnType, takeEvery, race, take, select } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { BusinessType } from 'store/Business';
import * as services from './services';

export function* fetchLanguages(action: ReturnType<typeof actions.languages.fetchLanguages >) {
  try {
    yield put(actions.languages.setIsLoadingDatasList({isLoading: true}))
    const request: SagaReturnType<typeof services.getLanguages> = yield call(services.getLanguages)
    yield put(actions.languages.setIsLoadingDatasList({isLoading: false}))

    yield put(actions.languages.setLanguages({languages: request.languages}))
  } catch(e) {
  }
}

export function* addLanguages(action: ReturnType<typeof actions.languages.addLanguages >) {
  const { languageIds } = action.payload
  try {
    for (const languageId in languageIds) {
      yield call(services.postLanguage, languageIds[languageId])
    }
    yield put(actions.languages.fetchLanguages())
    yield put(actions.languages.addLanguagesSuccess())
  } catch(e: any) {
    yield put(actions.languages.addLanguagesError(e))
  }
}

export function* removeLanguages(action: ReturnType<typeof actions.languages.removeLanguages>) {
  const { languageIds } = action.payload

  try {
    for (const languageId in languageIds) {
      yield call(services.deleteLanguage, languageIds[languageId])
    }
    yield put(actions.languages.removeLanguagesSuccess())
    yield put(actions.languages.fetchLanguages())
  } catch(e: any) {
    yield put(actions.languages.removeLanguagesError(e))
  }
}

export function* manageLanguages(action: ReturnType<typeof actions.languages.manageLanguages >) {
  const { removalIds, additionIds } = action.payload
  const business: BusinessType = yield select(selectors.business.business)
  let hasError = false;
  try {
    if (additionIds.length) {
      yield put(actions.languages.addLanguages({ languageIds: additionIds }))
      const [addError, _addSuccess]: any[] = yield race([
        take(actions.languages.addLanguagesError),
        take(actions.languages.addLanguagesSuccess)
      ])
      if (addError){
        yield put(actions.languages.manageLanguagesError(addError))
        hasError = true
      }
    }

    if (removalIds.length) {
      yield put(actions.languages.removeLanguages({ languageIds: removalIds }))
      const [removeError, _removeSuccess]: any[] = yield race([
        take(actions.languages.removeLanguagesError),
        take(actions.languages.removeLanguagesSuccess)
      ])
      if (removeError) {
        yield put(actions.languages.manageLanguagesError(removeError))
        hasError = true
      }
    }

    yield put(actions.business.fetchBusiness({id: business.id}))
    if (!hasError) {
      yield put(actions.languages.manageLanguagesSuccess())
    }
  } catch (e: any) {
    yield put(actions.business.fetchBusiness({id: business.id}))
    yield put(actions.languages.manageLanguagesError(e))
  }
}

export function* root() {
  yield all([
    takeEvery(actions.languages.fetchLanguages, fetchLanguages),
    takeEvery(actions.languages.addLanguages, addLanguages),
    takeEvery(actions.languages.removeLanguages, removeLanguages),
    takeEvery(actions.languages.manageLanguages, manageLanguages),
  ])
}
