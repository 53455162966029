import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import App from './component/App';
import i18nHelper from './i18n';
import store from './store/store';
import { Provider } from 'react-redux';

i18nHelper().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
})