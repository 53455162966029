import { customFetch } from 'api/helpers/customFetch'
import {
  API_DIETS_URL,
  API_PRODUCTS_URL,
  API_BUSINESS_URL,
} from 'api/helpers/routes'
import { ProductsType } from './index'

export const getProducts = async (): Promise<{products: ProductsType[]}> => {
  const request = await customFetch.get(API_PRODUCTS_URL)
  const response = await request.json()
  return { products: response }
}

export const getProduct = async (id: number): Promise<{product: ProductsType}> => {
  const request = await customFetch.get(`${API_PRODUCTS_URL}/${id}`)
  const response = await request.json()
  return { product: response }
}

export const getLinkedProducts = async (business_id: number): Promise<{products: ProductsType[]}> => {
  const request = await customFetch.get(`${API_BUSINESS_URL}/${business_id}/products`);
  const response = await request.json();
  return { products: response };
}

export const postProduct = async (product: {name: string, price: number, description: string, spiciness?: number, photoUrl?: string, diets?: {id: number, i18n: {name: string}}[]|[]}) => {
  const request = await customFetch.post(API_PRODUCTS_URL, {
    name: product.name,
    description: product.description,
    price: product.price,
    spiciness: product.spiciness,
    photoUrl: product.photoUrl && product.photoUrl.length ? product.photoUrl : '',
    ...(product.diets && product.diets.length ? { diets: product.diets }: {})
  })
  const response = await request.json()
  return { id: response.id }
}

export const postFile = async (CSRFToken: string, id: number, file: File): Promise<{key: string}> => {
  const formData = new FormData()
  formData.append('file', file)

  const request = await fetch(`${API_PRODUCTS_URL}/${id}/files`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': CSRFToken,
    },
    body: formData,
  })

  const response = await request.json()
  return { key: response.key }
}

export const patchProduct = async (product: {name: string, price: number, description: string, photoUrl?: string, spiciness?: number, diets?: {id: number, i18n: {name: string}}[]}, id: number): Promise<Response|null> => {
  const request = await customFetch.patch(`${API_PRODUCTS_URL}/${id}`, {
    name: product.name,
    description: product.description,
    price: product.price,
    spiciness: product.spiciness,
    photoUrl: product.photoUrl && product.photoUrl.length ? product.photoUrl : '',
    diets: product.diets && product.diets.length ? product.diets : []
  })
  return request
}

export const deleteProduct = async (id: number): Promise<Response> => {
  const request = await customFetch.delete(`${API_PRODUCTS_URL}/${id}`)
  const res = await request.json()
  return res
}

export const updateProductSold = async (id: number, isSoldOut: boolean): Promise<Response> => {
  const request = await customFetch.patch(`${API_PRODUCTS_URL}/${id}/sold`, {
    isSoldOut
  })
  return request
}

export const getDietOptions = async (lang: string): Promise<{diets: {id: number, i18n: {name: string}}[]}> => {
  const request = await customFetch.get(API_DIETS_URL, {'Accept-Language': lang})
  const response = await request.json()
  return { diets: response }
}
