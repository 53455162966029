import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import InputText from 'component/Form/Inputs/InputText';
import InputCheckbox from 'component/Form/Inputs/InputCheckbox';
import InputSelect from 'component/Form/Inputs/InputSelect';
import { useForm } from 'react-hook-form';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';
import { getError, validationPatterns, listPrefectures } from 'utils/helpers';

interface Inputs {
  isSameAddress   : boolean,
  storeAddress    : string,
  storeCity       : string,
  storePrefecture : string,
  storeZipcode    : string,
  isSameTelephone : boolean,
  storeTelephone  : string,
}

interface ModalConfigAddressProps {
  onRequestClose: () => void,
}

const ModalConfigAddress: FC<ModalConfigAddressProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails']);
  const formId = useMemo(() => 'modal-config-address-form', []);

  const { showStickyBanner } = useContext(StickyBannerContext);

  const business = useSelector(selectors.business.business);
  const [isSameAddress, setIsSameAddress] = useState(useSelector(selectors.business.isSameAddress) ? true : false);
  const storeAddress = useSelector(selectors.business.storeAddress);
  const storeCity = useSelector(selectors.business.storeCity);
  const [storePrefecture, setStorePrefecture] = useState(useSelector(selectors.business.storePrefecture));
  const storeZipcode = useSelector(selectors.business.storeZipcode);

  const [isSameTelephone, setIsSameTelephone] = useState(useSelector(selectors.business.isSameTelephone) ? true : false);
  const storeTelephone = useSelector(selectors.business.storeTelephone);

  const bizPrefecture = useSelector(selectors.business.prefecture);
  const prefectures = useMemo(()=>listPrefectures(i18next.language), []);
  const prefecturesOptions = useMemo(() => prefectures.map((p) => ({value: p.value, label: p.i18n})), [prefectures]);
  const valuePrefectureOption = useMemo(() => prefecturesOptions.find((p) => p.value === storePrefecture), [prefecturesOptions, storePrefecture]);
  const defaultPrefectureOption = useMemo(() => prefecturesOptions.find((p) => p.value === bizPrefecture), [prefecturesOptions, bizPrefecture]);

  const { handleSubmit, register, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form) => {
    dispatch(actions.business.updateConfigAddress({
      id: business.id,
      config: {
        isSameAddress: isSameAddress,
        storeAddress: form.storeAddress,
        storeCity: form.storeCity,
        storePrefecture: storePrefecture || (defaultPrefectureOption || prefecturesOptions[0]).value,
        storeZipcode: form.storeZipcode,
        isSameTelephone: isSameTelephone,
        storeTelephone: form.storeTelephone
      }
    }));
  }, [dispatch, business.id, isSameAddress, storePrefecture, isSameTelephone, defaultPrefectureOption, prefecturesOptions]);

  const onSuccessEdition = useCallback(() => {
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    showStickyBanner(BannerError)
  }, [showStickyBanner])

  const onAddressSwitchChange = useCallback((name, value) => {
    setIsSameAddress(value ? true : false);
  }, [setIsSameAddress])

  const onTelephoneSwitchChange = useCallback((name, value) => {
    setIsSameTelephone(value ? true : false);
  }, [setIsSameTelephone])

  const onChangeInputText = useCallback(() => {
    clearErrors();
  }, [clearErrors])

  const onChangeInputSelect = useCallback((name, value) => {
    setStorePrefecture(value);
    clearErrors();
  }, [clearErrors])

  useSagaTakeEvery(actions.business.updateConfigAddressSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updateConfigAddressError, onErrorEdition)

  const footerContent = useMemo(() => (
    <>
      <Button onClick={onRequestClose} text={t('menuDetails:ctas:cancel')} />
      <Button onClick={onSubmit} text={t('menuDetails:ctas:save')} type="submit" form={formId} isPrimary={true} />
    </>
  ), [t, onRequestClose, onSubmit, formId])

  return (
    <LayoutModal
      title={t('menuDetails:addressModal:title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <h4 className="ft-600 mb-2">{t('menuDetails:addressModal.inputAddr.heading')}</h4>
          <div className="mb-1">
            <span className="h5 ft-700 c-gray-800"></span>
            <InputCheckbox
              label={t(`menuDetails:addressModal.isSameAddress`)}
              name="isSameAddress"
              defaultValue={isSameAddress}
              onChange={onAddressSwitchChange}
            />
          </div>
          <div className="mosaic mosaic--2 mosaic--gap-8 mb-1">
            {isSameAddress && <InputText
              disabled={true}
              defaultValue={business.address}
              className="input--material mb-1 ft-left readonly"
              label={t('menuDetails:addressModal.inputAddress')}
              placeholder={t('menuDetails:addressModal.inputAddress')}
              name="storeAddressReadonly"
            />}
            {!isSameAddress && <InputText
              maxLength={200}
              defaultValue={storeAddress}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:addressModal.inputAddress')}
              placeholder={t('menuDetails:addressModal.inputAddress')}
              name="storeAddress"
              handleChange={onChangeInputText}
              inputRef={register()}
            />}
            {isSameAddress && <InputText
              disabled={true}
              defaultValue={business.city}
              className="input--material mb-1 ft-left readonly"
              label={t('menuDetails:addressModal.inputCity')}
              placeholder={t('menuDetails:addressModal.inputCity')}
              name="storeCityReadonly"
            />}
            {!isSameAddress && <InputText
              maxLength={100}
              defaultValue={storeCity}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:addressModal.inputCity')}
              placeholder={t('menuDetails:addressModal.inputCity')}
              name="storeCity"
              handleChange={onChangeInputText}
              inputRef={register()}
            />}
            {isSameAddress && <InputText
              disabled={true}
              defaultValue={business.zipcode}
              className="input--material mb-1 ft-left readonly"
              label={t('menuDetails:addressModal.inputZipCode')}
              placeholder={t('menuDetails:addressModal.inputZipCode')}
              name="storeZipcodeReadonly"
            />}
            {!isSameAddress && <InputText
              maxLength={10}
              defaultValue={storeZipcode}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:addressModal.inputZipCode')}
              placeholder={t('menuDetails:addressModal.inputZipCode')}
              name="storeZipcode"
              handleChange={onChangeInputText}
              inputRef={(
                register({
                  pattern: { value: validationPatterns.zipCode, message: getError('inputZipCode') }
                }))}
            />}
            {isSameAddress && <InputText
              disabled={true}
              defaultValue={(defaultPrefectureOption || prefecturesOptions[0]).label}
              className="input--material mb-1 ft-left readonly"
              label={t('menuDetails:addressModal.inputPrefecture')}
              placeholder={t('menuDetails:addressModal.inputPrefecture')}
              name="storePrefectureReadonly"
            />}
            {!isSameAddress && <InputSelect
              registerInForm={true}
              globalLabel={t('menuDetails:addressModal.inputPrefecture')}
              name="storePrefecture"
              defaultOption={valuePrefectureOption || defaultPrefectureOption || prefecturesOptions[0]}
              placeholder={t('menuDetails:addressModal.inputPrefecture')}
              options={prefecturesOptions}
              onChange={onChangeInputSelect}
            />}
          </div>
          <h4 className="ft-600 mb-2">{t('menuDetails:addressModal.inputPhone.heading')}</h4>
          <div className="mb-1">
            <span className="h5 ft-700 c-gray-800"></span>
            <InputCheckbox
              label={t(`menuDetails:addressModal.isSameTelephone`)}
              name="isSameTelephone"
              defaultValue={isSameTelephone}
              onChange={onTelephoneSwitchChange}
            />
          </div>
          <div className="mb-1">
            {isSameTelephone && <InputText
              disabled={true}
              defaultValue={business.telephone}
              className="input--material mb-1 ft-left readonly"
              label={t('menuDetails:addressModal.inputTelephone')}
              placeholder={t('menuDetails:addressModal.inputTelephone')}
              name="storeTelephoneReadonly"
            />}
            {!isSameTelephone && <InputText
              maxLength={20}
              defaultValue={storeTelephone}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:addressModal.inputTelephone')}
              placeholder={t('menuDetails:addressModal.inputTelephone')}
              name="storeTelephone"
              handleChange={onChangeInputText}
              inputRef={register({pattern: validationPatterns.telephone})}
            />}
          </div>
        </form>
    </LayoutModal>
  )
}

export default ModalConfigAddress
