import Button from 'component/Buttons/Button'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import i18next from 'i18next'
import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { matchPath, useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { importPrice, UploadFile } from 'store/Onboarding'
import { useTypedSelector } from 'store/store'
import { checkExtension, getError, getSymbolFromCurrency } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

const ImportMenuFiles: FC = (props: any) => {
  const history = useHistory()
  const isPhotoImport = useMemo(() => matchPath(history.location.pathname, {
    path: RouteUrls.ImportMenuPhotos,
    exact: true,
    strict: false
  }), [history])

  const maxMB = 1
  const maxBytes = 1000 * 10000 * maxMB // 10MB in bytes
  
  const fileType = useMemo(() => isPhotoImport ? 'image/png, image/jpeg' : '.doc,.docx,application/pdf,.pdf' , [isPhotoImport])
  const fileTypeValidation = useMemo(() => isPhotoImport ? 'png|jpeg|jpg' : 'doc|docx|pdf', [isPhotoImport])
  const translationKey = useMemo(() => isPhotoImport ? 'importPhotos' : 'importFiles', [isPhotoImport])

  const dispatch  = useDispatch()
  const { t } = useTranslation(['onboarding'])

  const backUrl = RouteUrls.ImportMenu
  const [files, setFiles] = useState<UploadFile[]>([])

  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 4, 'import'))

  const { handleSubmit, register, errors, setValue, clearErrors, setError, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    i18next.on('languageChanged', () => {
      clearErrors()
    })
  }, [clearErrors])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])


  useEffect(()=>{
    if (files.length > 6) {
      setError('inputFile', {message: getError('maxFileNumberReached')})
    }
  }, [files.length, setError])

  
  const onSubmit = useCallback((data) => {
    if (!data.inputFile || !data.inputFile.length) {
      setError('inputFile', {message: getError('required')})
      return;
    }
    dispatch(actions.onboarding.setFiles({ files: data.inputFile }))
    history.push(RouteUrls.Register)
  }, [dispatch, history, setError])

  const removeFile = useCallback((id: string) => {
    clearErrors("inputFile")
    setFiles(prevState => {
      return [...prevState]?.filter(fileItem => fileItem.id !== id)
    })
  }, [clearErrors])

  const onChangeFiles = useCallback((e) => {
    const input = e.target
    clearErrors()

    Object.values(input.files as File).forEach((file: File) => {
      if (file.size > maxBytes) {
        return setError('inputFile', {message: getError('fileSize')})
      }
      if (!checkExtension(file.name, fileTypeValidation)) {
        return setError('inputFile', {message: getError('file_extension')})
      }

      const newFile = { // Lighter in store than File Object
        id: `${file.name.replace(/ /g,'')}-${file.size}-${file.type}`,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        url: URL.createObjectURL(file) 
      }
      
      setFiles(prevState => {
        const copiedItems = [...prevState]?.filter(fileItem => fileItem.id === newFile.id)
        if (!copiedItems.length) {
          return [...prevState, newFile]
        } else {
          setError('inputFile', {message: getError('fileAlreadyUploaded')})
        }
        return prevState
      })
    })
  }, [maxBytes, setError, clearErrors, fileTypeValidation])

  useEffect(() => {
    setValue('inputFile', files)
  }, [files, setValue, getValues])

  useEffect(() => {
    register('inputFile')
  }, [register])

  const renderFileContent =  useMemo(() => files.map((file, i) => {
    const isPdf = file.type === "application/pdf" || file.type === ".pdf"
    return (
      <div className="card-file-upload" key={i}>
        <div className="f">
          <img width="40" src={isPdf ? '/static/images/pdf.svg' : '/static/images/doc.svg'} alt="" />
          <div className="ml-1 ft-left">
            <div className="ft-600 c-gray-800">{file.name}</div>
            <div className="ft-500 c-gray-600">{`${Math.round(file.size / 1000)}Ko`}</div>
          </div>
        </div>
        <button onClick={() => removeFile(file.id)} className="icon icon-close icon-16 c-cream-500" type="button" />
      </div>
    )
  }), [files, removeFile])

  const renderImageContent =  useMemo(() => (
    <div className="mosaic mosaic--3">
      {files.map((file, i) => (
        <div className="card-file-image" key={i}>
          <img width="40" src={file.url} alt="" />
          <button onClick={() => removeFile(file.id)} className="icon icon-close icon-16 c-cream-500 card-file-image__delete" type="button" />
        </div>
      ))}
    </div>
  ), [files, removeFile])

  return (
    <LayoutSideBar pageName="import-menu-url">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__content--form-onboarding">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <span className="label label--purple">{importPrice} {getSymbolFromCurrency('JPA')}</span>
              <h1 className="ft-900 mt-2 mb-2">{t(`onboarding:import:${translationKey}:title`)}</h1>
              <p>{t(`onboarding:import:${translationKey}:desc`)}</p>
              <p className="mt-1 mb-4">※ {t(`onboarding:import:${translationKey}:mention`)}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="input-file input-file--btn">
                <div className="btn"><span className="icon icon-download c-cream-500 icon-20 mr-1" />{t(`onboarding:import:${translationKey}:select`)} <span className=" ml-1 ft-500 c-gray-600">(Max 10MB / 6 files)</span></div>
                <input name="inputFile" type="file" accept={fileType} multiple onChange={onChangeFiles} />
              </label>
              {errors?.inputFile && <div className="c-red mt-2 ft-secondary">{errors.inputFile.message}</div>}
              <div className="mt-4">
                {isPhotoImport ? renderImageContent : renderFileContent}
              </div>
              <div className="ft-center">
                <Button isDisabled={files.length > 6} className="mt-5" isPrimary={true} type="submit" text={t('onboarding:import:submit')} /> 
              </div>
            </form>
          </div>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default ImportMenuFiles
