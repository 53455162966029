import React, { createContext, FC, useCallback, useMemo, useState } from 'react'

interface ModalType {
  component: null,
  props: {}
}

interface ModalContextType {
  modalHistory: ModalType,
  component: any,
  props: {},
  isOpened: boolean,
  showModal: (component: any, modalProps?: {}, savePrevious?: boolean, historyProps?: {}) => void,
  hideModal: (noHistory?: boolean) => void,
}

export const ModalContext = createContext<ModalContextType>({
  modalHistory: {component: null, props: {}},
  component: null,
  props: {},
  isOpened: false,
  showModal: (component: any) => {},
  hideModal: (noHistory?: boolean) => {},
})

interface ModalType {
  component: null,
  props: {}
}

export const ModalProvider: FC = ({children}) => {
  const [modal, setModal] = useState<ModalType>({props: {}, component: null})
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [modalHistory, setModalHistory] = useState<ModalType>({props: {}, component: null})

  const showModal = useCallback((component: any, props = {}, savePrevious?: boolean, historyProps?: {}) => {
    document.body.classList.add('body--modal')

    setIsOpened(true);
    if (savePrevious) {
      setModalHistory({component: modal.component, props: {...modal.props, ...historyProps}})
    }

    setModal({ component: component, props: {...props, ...historyProps} })
  }, [modal])

  const hideModal = useCallback((noHistory) => {
    document.body.classList.remove('body--modal')

    const modal = { component: null, props: {} }
    setModal(modal)
    setIsOpened(false);

    if (!noHistory && modalHistory.component) {
      showModal(modalHistory.component, modalHistory.props)
    }

    setModalHistory(modal)
  }, [showModal, modalHistory])

  const value = useMemo(() => ({
    modalHistory,
    component: modal.component,
    props: modal.props,
    showModal,
    hideModal,
    isOpened,
  }), [modal, showModal, hideModal, modalHistory, isOpened])

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  )
}
export const ModalConsumer = ModalContext.Consumer
