import { customFetch } from 'api/helpers/customFetch'
import { API_TRANSLATIONS_URL, API_TRANSLATIONS_RESET_URL } from 'api/helpers/routes'
import { TranslationType } from 'store/Translations'

export const getTranslations = async (type: string, languageId: string): Promise<{translations: TranslationType[]}> => {
  const request = await customFetch.get(`${API_TRANSLATIONS_URL}?type=${type}&languageId=${languageId}`)
  const response = await request.json() 
  return { translations: response }
}

export const putTranslations = async (type: string, languageId: string, translations: TranslationType[]): Promise<Response> => {
  const request = await customFetch.put(API_TRANSLATIONS_URL, { 
    type,
    languageId,
    translations: translations
  })
  return request
}

export const postResetTranslations = async (type: string, languageId: string): Promise<Response> => {
  const request = await customFetch.post(API_TRANSLATIONS_RESET_URL, { 
    type,
    languageId
  })
  return request
}