export const BASE_URL = process.env.REACT_APP_API_URL
export const API_URL = `${BASE_URL}/v1/`
export const API_LOGIN_URL = `${API_URL}login`
export const API_LOGOUT_URL = `${API_URL}logout`
export const API_PASSWORD_URL = `${API_URL}password`
export const API_VERIFY_URL = `${API_URL}verify`
export const API_REGISTER_URL = `${API_URL}register`
export const API_RESEND_VERIFY_URL = `${API_URL}resend-verify`
export const API_IMPORT_URL = `${API_URL}me/imports`
export const API_CONTACT = `${API_URL}contact`
export const API_ME_URL = `${API_URL}me`
export const API_CRSF_TOKEN_URL = `${API_URL}crumb`
export const API_USER_URL = `${API_URL}users`
export const API_MENUS_URL = `${API_URL}me/menus`
export const API_PRODUCTS_URL = `${API_URL}me/products`
export const API_DIETS_URL = `${API_URL}diets`
export const API_BUSINESS_URL = `${API_URL}me/businesses`
export const API_PUBLIC_BUSINESS_URL = `${API_URL}businesses`
export const API_LINKEDBUSINESS_URL = `${API_URL}me/linkedbusinesses`
export const API_CATEGORIES_URL = `${API_URL}me/categories`
export const API_UPLOAD_URL = `${API_URL}upload`
export const API_CHECKOUT_SUBSCRIPTION_URL = `${API_URL}checkout/subscription`
export const API_CHECKOUT_IMPORT_URL = `${API_URL}checkout/import`
export const API_BILLING_URL = `${API_URL}billing`
export const API_LANGUAGES_URL = `${API_URL}languages`
export const API_LANGUAGES_ME_URL = `${API_URL}me/languages`
export const API_TRANSLATIONS_URL = `${API_URL}me/translations`
export const API_TRANSLATIONS_RESET_URL = `${API_URL}me/translations/reset`
export const API_WELCOME_KIT = `${API_URL}me/welcome-kit`
export const API_ADMIN_BUSINESSES = `${API_URL}admin/businesses`
export const API_ADMIN_STATISTICS = `${API_URL}admin/statistics`
export const API_ADMIN_MENU_IMPORTS = `${API_URL}admin/imports`
export const API_ADMIN_MANAGE_BUSINESS = `${API_URL}businesses`
