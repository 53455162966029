import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { SubcategoryType } from 'store/Categories'
import { actions } from 'store'
import Button from 'component/Buttons/Button'
import { useDispatch } from 'react-redux'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'
import { getError } from 'utils/helpers'

interface ModalSubcategoryEditionProps {
  onRequestClose: (noHistory?: boolean) => void,
  categoryId: number,
  categoryName: string,
  subcategory?: SubcategoryType
  menuId?: number
}

interface Inputs {
  name: string
}

const ModalSubcategoryEdition: FC<ModalSubcategoryEditionProps> = ({onRequestClose, subcategory, categoryId, categoryName, menuId }) => {
  const { showStickyBanner } = useContext(StickyBannerContext)
  const { register, errors, handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });
  const isEditMode = useMemo(() => subcategory && subcategory.id ? true : false,[subcategory])
  const formId = useMemo(() => 'modal-subcategory-edition-form', [])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const { t } = useTranslation(['menus', 'common'])

  const onSubmit = useCallback((form) => {
    const newCategory = {...form, parentCategoryId: categoryId}
    setIsLoading(true)
    if (isEditMode && subcategory && subcategory.id) {
      dispatch(actions.categories.updateCategory({ id: subcategory.id, category: newCategory, ...(menuId ? { menuId, withFetch: false } : {}) }))
    } else {
      dispatch(actions.categories.createCategory({ category: newCategory, ...(menuId ? { menuId, withFetch: false } : {}) }))
    }
  }, [subcategory, categoryId, dispatch, isEditMode, menuId])

  const onSuccessEdition = useCallback((action) => {
    if (menuId !== undefined) {
      dispatch(actions.menuDetails.removeUntoggledCategory({ id: action.payload.id }))
    }
    setIsLoading(false)
    onRequestClose(true)
  }, [dispatch, onRequestClose, menuId])

  const onErrorEdtion = useCallback(() => {
    setIsLoading(false)
    showStickyBanner(BannerError)
  }, [showStickyBanner])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menus:subcategoryEditionModal:cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} type="submit" form={formId} text={t(isEditMode ? 'menus:subcategoryEditionModal:saveEdit' : 'menus:subcategoryEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading, isEditMode])

  useSagaTakeEvery(actions.categories.createCategorySuccess, onSuccessEdition)
  useSagaTakeEvery(actions.categories.updateCategorySuccess, onSuccessEdition)

  useSagaTakeEvery(actions.categories.createCategoryError, onErrorEdtion)
  useSagaTakeEvery(actions.categories.updateCategoryError, onErrorEdtion)

  return (
    <LayoutModal 
      isLoading={isLoading}
      title={isEditMode ? t('menus:subcategoryEditionModal:title:edit') : t('menus:subcategoryEditionModal:title:add', {categoryName: categoryName})} onRequestClose={onRequestClose} 
      className="modal--category-edition"
      footerContent={footerContent}
      >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <InputText 
          maxLength={255} 
          defaultValue={subcategory?.i18n.name} 
          className="input--material mb-1" 
          placeholder={t('menus:subcategoryEditionModal:inputName')} 
          label={t('menus:subcategoryEditionModal:inputName')} 
          name="name" 
          inputRef={register({
            required: {
              value: true, 
              message: getError('name')
            }
          })} 
          error={errors.name} />
      </form>
    </LayoutModal>
  )
}
export default ModalSubcategoryEdition
