import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { RouteUrls } from 'utils/routers/constants'

const Import: FC<any> = () => {
  const history = useHistory()

  useEffect(() => {
    const query = new URLSearchParams(history.location.search)
    const key = query.get('payment')
    history.push(RouteUrls.EmailVerification, {
      search: key && key === 'failed' ? '?payment=failed' : '',
    })
  }, [history])

  return <></>
}

export default Import
