import React, { FC, useCallback, useContext, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { actions } from 'store'
import Button from 'component/Buttons/Button'
import { useDispatch } from 'react-redux'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'
import { getError } from 'utils/helpers'
import BannerInfo from 'component/Banners/BannerInfo'

interface ModalChangePasswordProps {
  onRequestClose: () => void,
}

interface Inputs {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
}

const ModalChangePassword: FC<ModalChangePasswordProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const { showStickyBanner } = useContext(StickyBannerContext)

  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['account', 'common', 'login'])

  const { register, handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form) => {
    if (form.currentPassword === form.newPassword) {
      setError('inputNewPassword')
      return;
    } else if (form.newPassword !== form.confirmNewPassword) {
      setError('inputConfirmNewPassword')
      return;
    }
    setIsLoading(true)
    dispatch(actions.user.updatePassword({oldPassword: form.currentPassword, newPassword: form.newPassword}))
  }, [dispatch])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
    showStickyBanner(BannerInfo, {message: t('login:success.updatePassword')})
  }, [onRequestClose, showStickyBanner, t])

  const onErrorEdtion = useCallback(() => {
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  const onChangeValue = useCallback(() => {
    setError('')
  }, [])

  useSagaTakeEvery(actions.user.updatePasswordSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.user.updatePasswordError, onErrorEdtion)

  return (
    <LayoutModal 
      isLoading={isLoading}
      onRequestClose={onRequestClose} 
      className="modal--change-password modal--sm"
      >
      <h1 className="ft-center mb-5 mt-2">{t('account:changePasswordModal:title')}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText className="input--material mb-3" type="password" autocomplete="off" placeholder={t('account:changePasswordModal:inputCurrentPassword')} label={t('account:changePasswordModal:inputCurrentPassword')} name="currentPassword" inputRef={register()} required={true}  handleChange={onChangeValue} />
        
        <InputText className="input--material mb-1" type="password" autocomplete="off" placeholder={t('account:changePasswordModal:inputNewPassword')} label={t('account:changePasswordModal:inputNewPassword')} name="newPassword" inputRef={register()} required={true} handleChange={onChangeValue} error={(error === 'inputNewPassword' && {message: getError('inputNewPassword')}) || !!(error === 'inputConfirmNewPassword')} minLength={8} maxLength={80} />

        <InputText className="input--material mb-4" type="password" autocomplete="off" placeholder={t('account:changePasswordModal:inputConfirmNewPassword')} label={t('account:changePasswordModal:inputConfirmNewPassword')} name="confirmNewPassword" inputRef={register()} required={true}  error={error === 'inputConfirmNewPassword' && {message: getError('inputConfirmNewPassword')}} handleChange={onChangeValue} minLength={8}  maxLength={80} />
        <Button isLoading={isLoading} isDisabled={isLoading} type="submit" text={t('account:changePasswordModal:title')} isPrimary={true} className="btn--full mb-2" />
      </form>
    </LayoutModal>
  )
}
export default ModalChangePassword
