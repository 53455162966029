import BannerError from 'component/Banners/BannerError'
import TopBar from 'component/Headers/TopBar';
import BottomBar from 'component/Footers/BottomBar';
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import ModalImportPaymentFailed from 'component/Modals/ModalImportPaymentFailed'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { useTypedSelector } from 'store/store'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { RouteUrls } from 'utils/routers/constants'

const Home: FC = (props: any) => {
  const history = useHistory()
  const dispatch  = useDispatch()
  const { t } = useTranslation(['login', 'onboarding'])
  const { showStickyBanner } = useContext(StickyBannerContext)
  const { showModal } = useContext(ModalContext)

  const [isResent, setIsResent] = useState(false);
  const [canShowErrorModal, setCanShowErrorModal] = useState(true);
  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 6))
  const email = useSelector(selectors.onboarding.verificationEmail)

  const onClickSendEmail = useCallback(() => {
    setIsResent(false);
    dispatch(actions.onboarding.resendVerifyEmail({email}))
  }, [dispatch, email])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(RouteUrls.Register)
    }
  }, [isReadyForStep, history])

  useEffect(() => {
    const query = new URLSearchParams(history.location.search)
    const key = query.get('payment')
    if (key === 'failed' && canShowErrorModal) {
      showModal(ModalImportPaymentFailed)
      setCanShowErrorModal(false)
    }
  }, [canShowErrorModal, history, showModal])

  const onError = useCallback(() => {
    showStickyBanner(BannerError)
  }, [showStickyBanner])

  const onSuccess = useCallback(() => {
    setIsResent(true);
  }, [])

  useSagaTakeEvery(actions.onboarding.resendVerifyEmailError, onError)
  useSagaTakeEvery(actions.onboarding.goToImportStripeCheckoutError, onError)
  useSagaTakeEvery(actions.onboarding.resendVerifyEmailSuccess, onSuccess)

  return (
    <LayoutSideBar pageName="email-verification">
      <TopBar />
      <LayoutContent className="page__content--form-onboarding page__padding-on-wrapper">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <h1 className="ft-900 mt-3 mb-5">{t('onboarding:emailVerification.title')}</h1>
              <img src="/static/images/email-verification.svg" alt="" />
              <p>{t('onboarding:emailVerification:description')}</p>
              <p className="mt-2">
                <Trans i18nKey="onboarding:emailVerification:emailAction" values={{ send: t('onboarding:emailVerification:send') }}>
                    content
                    <button onClick={onClickSendEmail} className="c-primary ft-700">send</button>
                </Trans>
                  {isResent && <i className="icon icon-check c-green-600"/>}
              </p>
              <h2 className="ft-900 mt-4 mb-2 f-inline">{ t('onboarding:emailVerification.dontmiss') }</h2>
              <div className="f f-center" data-theme="dark">
                <a href="https://www.instagram.com/digitomenu/" target="_blank" rel="noreferrer" title="Instagram" className="menu__icon"><span className="icon icon-insta-fill icon-24 c-gray-200" /></a>
                <a href="https://www.facebook.com/digitomenu" target="_blank" rel="noreferrer" title="Facebook" className="menu__icon"><span className="icon icon-facebook icon-24 c-gray-200" /></a>
              </div>
            </div>
          </div>
        </div>
        <BottomBar />
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default Home
