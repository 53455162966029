import React, { FC, useCallback, useEffect, useState } from 'react'

interface SwitchProps {
  className?: string
  name?: string
  title?: string
  getValue?: (name: string|undefined, value: boolean) => void
  handleChange?: (name: string|undefined, value: boolean) => void
  inputRef?: () => void
  error?: {message: string}
  defaultValue?: boolean,
  value?: boolean,
  disabled?: boolean
  required?: boolean
  description?: string
  secondTitle?: string | JSX.Element
}

const Switch: FC<SwitchProps> = (props) => {
  const {
    className,
    name,
    title,
    getValue,
    handleChange,
    inputRef = null,
    error,
    defaultValue,
    value,
    disabled = false,
    required = false,
    description,
    secondTitle
  } = props

  const [checked, setChecked] = useState<boolean>(defaultValue || false)

  const onInputChange = useCallback((value: boolean) => {
    setChecked(value)
    if (getValue) getValue(name, value)
    if (handleChange) handleChange(name, value)
  }, [getValue, handleChange, name])

  const classes = `${className ? className : ''} ${
    error ? 'has-error' : ''
  }`

  useEffect(() => {
    if (value) {
      setChecked(value)
    }
  }, [value])

  return (
    <div className={`switch ${classes}`}>
      {secondTitle &&
        (typeof secondTitle === 'string' ? (
          <p className="second-title mb-0 mr-2">{secondTitle}</p>
        ) :  React.cloneElement(secondTitle,{className: 'second-title mb-0 mr-2'}) )}
      <input
        type="checkbox"
        id={name}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onInputChange(e.target.checked)}
        ref={inputRef}
        required={required}
      />
      <label htmlFor={name}>{title}</label>
      {error && <div>{error.message}</div>}
      {description && <p>{description}</p>}
    </div>
  )
}

export default Switch
