import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { selectors } from 'store';
import { ProductsType } from 'store/Products'
import { useTypedSelector } from 'store/store';
import { getNumberFormated, getSymbolFromCurrency } from 'utils/helpers'

interface MenuPreviewCardProductProps {
  product: ProductsType,
  isPremium: boolean,
  previewLang?: string
}

const MenuPreviewCardProduct: FC<MenuPreviewCardProductProps> = ({ product, isPremium, previewLang='ja' }) => {
  const { t } = useTranslation(['menus'])
  const photoUrl =  product.photoUrl && product.photoUrl.length ? `${process.env.REACT_APP_PICTURE_URL}/${product.photoUrl}`: null
  const hasDiets = product.diets?.length > 0
  const diets = useTypedSelector(state => selectors.products.productDiets(state, product.diets))

  const spicinessIcons = useMemo(() => {
    const icons = []
    const spiciness = product.spiciness ? product.spiciness : 0
    for (let i = 0; i < spiciness; i++) {
      icons.push(<span key={i} className="icon icon-spice icon-16" />)
    }
    return icons

  }, [product.spiciness ])

  const mapSpicinessDegreeToWord = useMemo(() => {
    switch (Number(product.spiciness)) {
      case 1:
        return t('menus:productEditionModal.spicinessOptionsShort.mild', {lng: previewLang})
      case 2:
        return t('menus:productEditionModal.spicinessOptionsShort.medium', {lng: previewLang})
      case 3:
        return t('menus:productEditionModal.spicinessOptionsShort.hot', {lng: previewLang})
      default:
        break
    }
  }, [product.spiciness, t, previewLang])


  return (
    <li className="menu-card-product">
      <div>
      {(isPremium && photoUrl) && <img className="menu-card-product__img" src={photoUrl} alt="" />}
      <div className="menu-card-product__body">
        {product.isSoldOut && !product.business ? <span className="label label--sold ft-up mb-1">{t('menus:product:soldout')}</span> : ''}
        <h5 className="ft-900 mb-1 c-gray-800">{product.i18n?.name}</h5>
        {(product.spiciness && product.spiciness > 0) && <div className="c-red ft-700 ft-tertiary mt-1 f-ai-center">{spicinessIcons} <span className="ml-1">{mapSpicinessDegreeToWord}</span></div>}
        {hasDiets ? <p className="mt-0 ft-700 ft-tertiary mt-1 f-ai-center">{diets.map((diet, i) => <span key={diet.id}>{i > 0 ? ', ' : ''} {t(`menus:diets.diet_${diet.id}`, {lng: previewLang})}</span>)}</p> : <></>}
        <div className="h5 c-gray-600 mt-1">{getNumberFormated(product.price)} {getSymbolFromCurrency(product.currency)}</div>
      </div>
      {!product.business && <button className={`menu-card-product__btn ${product.isSoldOut ? 'is-disabled' : ''}`}><span className="icon icon-plus" /></button>}
      </div>
      <p className="ft-secondary mb-0 mt-1 ">
        { product.i18n?.description && product.i18n.description.length > 40 ? (
          <>
          {product.i18n.description.substring(0, 40)}...
          <span className="menu-card__view ft-600">{t('menus:product.view', {lng: previewLang})}</span>
          </>
        ) : ( product.i18n?.description ) }
      </p>
    </li>
  )
}

export default MenuPreviewCardProduct
