import React, { FC } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalConsumer } from 'utils/hooks/ModalBehavior';
import ModalContact from 'component/Modals/ModalContact';

interface BottomBarProps {

}

const BottomBar: FC<BottomBarProps> = () => {
  const { t } = useTranslation(['onboarding']);

  return (
    <footer className="bottombar bg-cream-100 mt-2 is-mobile">
      <div className="ft-center">
        <p>{t('onboarding:contact.footer.title')}</p>
        <p>
          <ModalConsumer>
            {({ showModal }) => (<a href="#/" className="c-primary" onClick={() => showModal(ModalContact)}>{ t('onboarding:contact.footer.contactus') }</a>)}
          </ModalConsumer>
          <span>{ t('onboarding:contact.footer.orchaton') }</span><a href="https://lin.ee/xD8hn4W" target="_blank" rel="noreferrer" title="LINE"><span className="icon icon-line icon-24 c-gray-700" /></a>
        </p>
      </div>
    </footer>
  )
}

export default BottomBar
