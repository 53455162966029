import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from 'api/helpers/customFetch'
import { RootState } from '../store'
import { selectors as globalSelectors } from '../index'

export const defaultLanguage = 'JA'

export interface TranslationType {
  id: number,
  i18n: {
    name: string,
    description: string,
    translator?: string | null,
  }
}

export interface TranslationState {
  isLoadingDatasList: boolean,
  data: {
    lang: string,
    categories: TranslationType[],
    subCategories: TranslationType[],
    products: TranslationType[],
  }
}

const initialState : TranslationState = {
  isLoadingDatasList: false,
  data: {
    lang: '',
    categories: [],
    subCategories: [],
    products: [],
  },
}

export const slice = createSlice({
  name: 'Translations',
  initialState,
  reducers: {
    setIsLoadingDatasList:( state: TranslationState, action: PayloadAction<{isLoading: boolean}>) => {
      state.isLoadingDatasList = action.payload.isLoading
    },
    setLang: (state: TranslationState, action: PayloadAction<{lang: string}>) => {
      state.data.lang = action.payload.lang
    },
    setCategories: (state: TranslationState, action: PayloadAction<{categories: TranslationType[]}>) => {
      state.data.categories = action.payload.categories
    },
    setSubCategories: (state: TranslationState, action: PayloadAction<{subCategories: TranslationType[]}>) => {
      state.data.subCategories = action.payload.subCategories
    },
    setProducts: (state: TranslationState, action: PayloadAction<{products: TranslationType[]}>) => {
      state.data.products = action.payload.products
    },
    fetchContent: (state: TranslationState, action: PayloadAction<{languageId: string}>) => undefined,
    fetchCategories: (state: TranslationState, action: PayloadAction<{languageId: string}>) => undefined,
    fetchSubCategories: (state: TranslationState, action: PayloadAction<{languageId: string}>) => undefined,
    fetchProducts: (state: TranslationState, action: PayloadAction<{languageId: string}>) => undefined,
    updateCategories: (state: TranslationState, action: PayloadAction<{languageId: string, categories: TranslationType[]}>) => undefined,
    updateCategoriesSuccess: (state: TranslationState) => undefined,
    updateCategoriesError: (state: TranslationState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateProducts: (state: TranslationState, action: PayloadAction<{languageId: string, products: TranslationType[]}>) => undefined,
    updateProductsSuccess: (state: TranslationState) => undefined,
    updateProductsError: (state: TranslationState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    resetTranslations: (state: TranslationState, action: PayloadAction<{languageId: string, type: string}>) => undefined,
    resetTranslationsSuccess: (state: TranslationState) => undefined,
    resetTranslationsError: (state: TranslationState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
  }
})

// Selectors
const getRoot = (state: RootState) => state.translations
const isLoadingDatasList = (state: RootState) => getRoot(state).isLoadingDatasList
const lang = (state: RootState) =>  getRoot(state).data.lang

const translation = (state: RootState, type: string, id: number) => {
  const root = getRoot(state)
  switch (type) {
    case 'categories':
      const original = globalSelectors.categories.category(state, id)
      const translation = root.data.categories.find(category => category.id === id)
      return { original, translation }
    default: 
    return {original: null, translation: null}
  }
}


export interface OriginalWithTranslations {
  original: TranslationType & { photoUrl: string }
  translation: TranslationType
}

const categoriesTranslations = (state: RootState): any[] => {
  return getRoot(state).data.categories.map((translation) => {
    const category = globalSelectors.categories.category(state, translation.id)
    const original = {
      id: category?.id, 
      i18n: {
        name: category?.i18n.name, 
        description: category?.i18n.description,
        translator: category?.i18n.translator
      }, 
      photoUrl: globalSelectors.categories.photoUrl(state, translation.id)
    }
    return { original, translation }
  })
}

const subcategoriesTranslations = (state: RootState): any[] => {
  return getRoot(state).data.subCategories.map((translation) => {
    const category = globalSelectors.categories.subcategory(state, translation.id)
    const original = {
      id: category?.id, 
      i18n: {
        name: category?.i18n.name, 
        description: category?.i18n.description,
        translator: category?.i18n.translator
      }, 
      photoUrl: ''
    }
    return { original, translation }
  })
}

const productsTranslations = (state: RootState): any[] => {
  return getRoot(state).data.products.map((translation) => {
    const product = globalSelectors.products.getProduct(state, translation.id)
    const original = {
      id: product?.id, 
      i18n: {
        name: product?.i18n.name, 
        description: product?.i18n.description,
        translator: product?.i18n.translator
      }, 
      photoUrl: globalSelectors.products.photoUrl(state, translation.id)
    }
    return { original, translation }
  })
}

export const selectors = {
  isLoadingDatasList,
  translation,
  lang,
  categoriesTranslations,
  productsTranslations,
  subcategoriesTranslations
}

// reducer / actions
export const { reducer, actions } = slice
