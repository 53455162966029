import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from 'api/helpers/customFetch'
import { RootState } from 'store/store'

export enum ADMIN_BUSINESS_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface AdminBusiness {
  id: number
  name: string
  slug: string
  address: string
  city: string
  prefecture: string
  zipcode: string
  telephone: string
  businessType: string
  stripeCustomerId: string
  affiliateNumber: string
  referrer?: string,
  freemiumCode?: string,
  facebookId: string
  twitterId: string
  instagramId: string
  createdAt: string
  activatedAt: string,
  welcomeKitSentAt: string,
  firstPublishedAt: string,
  letterSentAt: string,
  snsPostedAt: string,
  isPhotoshootRequired: boolean,
  photoshotAt: string,
  businessNote: string,
  isEvent: boolean,
  qrcodeUrl: string,
  isDemo : boolean,
  status: ADMIN_BUSINESS_STATUS
  users: [
    {
      email: string,
      firstname: string,
      lastname: string
    }
  ]
  menus: [
    {
      name: string
      createdAt: string
      status?: string
    }
  ]
  businessConfig: {
    isPremiumSubscription: boolean
  },
  businessStatistics?: {
    monthCommencing: string,
    directs: number,
    referrals: number,
  }
}

export enum ADMIN_MENU_IMPORT_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
}

export interface AdminMenuImport {
  id: number
  status: ADMIN_MENU_IMPORT_STATUS
  menuImportFiles: {
    id: number
    menuImportId: number
    fileUrl: string
    fileMimeType: string
  }[],
  createdAt: string,
  business: {
    id: number
    name: string
  }
}

export interface AdminStatisticsEntry {
  period: Date,
  sum: number,
  ref: any,
}

export interface AdminStatistics {
  total: {
    daily: AdminStatisticsEntry[],
    weekly: AdminStatisticsEntry[],
    monthly: AdminStatisticsEntry[],
  },
}

export interface AdminState {
  statistics: AdminStatistics,
  businesses: AdminBusiness[],
  menuImports: AdminMenuImport[],
  managingBusiness: boolean
}

const initialState: AdminState = {
  statistics: {
    total: {
      daily: [],
      weekly: [],
      monthly: [],
    },
  },
  businesses: [],
  menuImports: [],
  managingBusiness: false
}

export const slice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    setStatistics: (
      state: AdminState,
      action: PayloadAction<{ statistics: AdminStatistics }>
    ) => {
      state.statistics = action.payload.statistics;
    },
    fetchStatistics: (state: AdminState, action: PayloadAction<{ slug?: string }>) => undefined,
    fetchStatisticsError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    setBusinesses: (
      state: AdminState,
      action: PayloadAction<{ businesses: AdminBusiness[] }>
    ) => {
      state.businesses =
        action.payload.businesses && action.payload.businesses.length
          ? action.payload.businesses
          : []
    },
    fetchBusinesses: (state: AdminState) => undefined,
    fetchBusinessesError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    setMenuImports: (
      state: AdminState,
      action: PayloadAction<{ menuImports: AdminMenuImport[] }>
    ) => {
      state.menuImports =
        action.payload.menuImports && action.payload.menuImports.length
          ? action.payload.menuImports
          : []
    },
    fetchMenuImports: (state: AdminState) => undefined,
    fetchMenuImportsError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    setManagingBusiness: (
      state: AdminState,
      action: PayloadAction<{ managingBusiness: boolean }>
    ) => {
      state.managingBusiness = action.payload.managingBusiness
    },
    fetchManagedBusiness: (state: AdminState, action: PayloadAction<{ id: number, isManaging?: boolean }>) => undefined,
    fetchManagedBusinessError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    fetchManagedBusinessSuccess: (state: AdminState, action: PayloadAction<{ id: number, isManaging: boolean }>) => undefined,
    updateMenuImportStatus: (state: AdminState, action: PayloadAction<{id: number}>) => undefined,
    updateMenuImportStatusError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateAdminBusiness: (state: AdminState, action: PayloadAction<{ id: number, business: {
      welcomeKitSentAt?: string,
      letterSentAt?: string,
      snsPostedAt?: string,
      isPhotoshootRequired?: boolean,
      photoshotAt?: string,
      businessNote?: string,
      isEvent?: boolean,
    } }>) => undefined,
    updateAdminBusinessSuccess: (state: AdminState) => undefined,
    updateAdminBusinessError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    resendVerificationEmail: (state: AdminState, action: PayloadAction<{
      business: AdminBusiness,
    }>) => undefined,
    resendVerificationEmailError: (state: AdminState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    updateDemoFlag: (state: AdminState, action: PayloadAction<{
      business: AdminBusiness,
      isDemo: boolean,
    }>) => undefined,
    updateDemoFlagSuccess: (state: AdminState) => undefined,
  },
})

const getRoot = (state: RootState) => state.admin
const statistics = (state: RootState) => getRoot(state).statistics;
const businesses = (state: RootState) => getRoot(state).businesses
const menuImports = (state: RootState) => getRoot(state).menuImports
const managingBusiness = (state: RootState) => getRoot(state).managingBusiness

export const selectors = {
  statistics,
  businesses,
  menuImports,
  managingBusiness
}

export const { reducer, actions } = slice
