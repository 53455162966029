import TabBar from 'component/Headers/TabBar'
import TopBarDashboard from 'component/Headers/TopBarDashboard'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import Tab from 'component/Tabs/Tab'
import TabMenuImports from 'component/Tabs/TabMenuImports'
import TabBusinesses from 'component/Tabs/TabBusinesses'
import TabStatistics from 'component/Tabs/TabStatistics'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions } from 'store'
import { RouteUrls, ROUTE_PATHS } from 'utils/routers/constants'
import BannerError from 'component/Banners/BannerError'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { selectors } from 'store';

const Admin: FC = () => {
  const { t } = useTranslation('admin')

  const dispatch = useDispatch()
  const history = useHistory()

  const { showStickyBanner } = useContext(StickyBannerContext)

  const isFullAdmin = useSelector(selectors.user.isFullAdmin);

  const [activeTab, setActiveTab] = useState('')

  const tabBarItems = useMemo(() => {
    const tab_stats = {
      label: t('tabBar.statistics'),
      slug: 'statistics',
      route: RouteUrls.Statistics,
    };

    const tab_biz = {
      label: t('tabBar.businesses'),
      slug: 'businesses',
      route: RouteUrls.Businesses,
    };

    const tab_import = {
      label: t('tabBar.menu_imports'),
      slug: 'menuImports',
      route: RouteUrls.MenuImports,
    };

    return isFullAdmin ? [tab_stats, tab_biz, tab_import] : [tab_stats, tab_biz];
  }, [t, isFullAdmin])

  const onChangeTab = useCallback(
    (slug: string, route: string) => {
      history.push(route)
    },
    [history]
  )

  const tabBarContent = useMemo(
    () => (
      <>
        <TabBar
          items={tabBarItems}
          onChangeTab={onChangeTab}
          activeTab={activeTab}
        />
      </>
    ),
    [tabBarItems, onChangeTab, activeTab]
  )

  useEffect(()=> {
    if(history.location.pathname === '/dashboard/admin') {
      history.push(ROUTE_PATHS.statistics)
    };
  }, [history])

  useEffect(() => {
    dispatch(actions.admin.fetchBusinesses())
    if (isFullAdmin) {
      dispatch(actions.admin.fetchMenuImports());
    }
  }, [dispatch, isFullAdmin])

  const onFetchError = () => {
    showStickyBanner(BannerError)
  }

  useSagaTakeEvery(actions.admin.fetchBusinessesError, onFetchError)
  useSagaTakeEvery(actions.admin.fetchMenuImportsError, onFetchError)
  useSagaTakeEvery(actions.admin.fetchStatisticsError, onFetchError);

  useEffect(() => {
    const item = tabBarItems.find(
      (item) => history.location.pathname.indexOf(item.route) >= 0
    )
    if (item) {
      setActiveTab(item?.slug)
    } else {
      setActiveTab(tabBarItems[0].slug)
    }
  }, [history, tabBarItems])

  return (
    <>
      <LayoutSideBar pageName="admin" type="dashboard">
        <TopBarDashboard title={t('title')} tabBarContent={tabBarContent} />
        <LayoutContent>
          <Tab slug="statistics" activeTab={activeTab}><TabStatistics /></Tab>
          <Tab slug="businesses" activeTab={activeTab}><TabBusinesses /></Tab>
          {isFullAdmin && <Tab slug="menuImports" activeTab={activeTab}><TabMenuImports /></Tab>}
        </LayoutContent>
      </LayoutSideBar>
    </>
  )
}

export default Admin
