import DropdownActions from 'component/Buttons/DropdownActions'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import ModalProductEdition from 'component/Modals/ModalProductEdition'
import React, { FC, useCallback, useContext } from 'react'

import { Draggable } from "react-beautiful-dnd"
import { useTranslation } from 'react-i18next/'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { ProductsType } from 'store/Products'
import { getNumberFormated, getSymbolFromCurrency } from 'utils/helpers'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface CardMenuProductProps {
  product: ProductsType,
  order: number,
  menuId: number,
  categoryId: number,
  parentCategoryId?: number
}

const CardMenuProduct: FC<CardMenuProductProps> = ({ product, order, menuId, categoryId, parentCategoryId}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menus', 'menuDetails'])
  const { showModal } = useContext(ModalContext)

  const deleteProduct = useCallback(() => {
    dispatch(actions.menuDetails.removeProduct({ id: menuId, productId: product.id, categoryId, parentCategoryId}))
  }, [dispatch, menuId, categoryId, product.id, parentCategoryId])

  const onClickDeleteProduct = useCallback(() => {
    const modalDeleteConfirmationProps = ({
      title: t('menuDetails:removeProductConfirmationModal:title', {
        productName: product.i18n?.name
      }),
      desc: t('menuDetails:removeProductConfirmationModal:desc'),
      cancelCTAText: t('menuDetails:removeProductConfirmationModal:cancelCTA'),
      confirmCTAText: t('menuDetails:removeProductConfirmationModal:confirmCTA'),
      onConfirm: deleteProduct,
    })
    showModal(ModalDeleteConfirmation, modalDeleteConfirmationProps)
  }, [showModal, deleteProduct, t, product])

  const onClickEditProduct = useCallback(() => {
    showModal(ModalProductEdition, { product, categoryId, menuId, parentCategoryId })
  }, [showModal, product, categoryId, menuId, parentCategoryId])

  const onClickSoldProduct = useCallback(() => {
    dispatch(actions.products.updateProductSold({ product, categoryId, menuId, parentCategoryId, isSoldOut: !product.isSoldOut}))
  }, [dispatch, product, categoryId, menuId, parentCategoryId])

  return (
    <Draggable key={`p${product.id}`} draggableId={`droppableSubcategory-p${product.id}${product.business ? `-lb${product.business.id}` : ''}`} index={order}>
      {(provided, snapshot) => (
        <li ref={provided.innerRef} {...provided.draggableProps}>
          <div className="card-menu-product">
            <div className="card-menu-product__left" >
              <div className="icon icon-drag icon-20 c-gray-400 mr-2" {...provided.dragHandleProps} />
              <h3 className={`ft-primary mr-1 ${product.isSoldOut && 'c-gray-400'}`}>{product.i18n?.name}</h3>
            </div>
            <div className="card-menu-product__right">
              <span className="c-gray-600 ft-500">{getNumberFormated(product.price)} {getSymbolFromCurrency(product.currency)}</span>
              <DropdownActions iconClassName="icon-more icon-20 c-gray-800">
                {!product.business && <li>
                  <button className="ft-700" onClick={onClickEditProduct}><span className="icon icon-edit icon-16 mr-2 c-cream-500"></span>{t('menus:product:edit')}</button>
                </li>}
                {!product.business && <li>
                  <button className="ft-700" onClick={onClickSoldProduct}><span className={`icon ${product.isSoldOut ? "icon-eyes-closed" : "icon-eyes"} icon-16 mr-2 c-cream-500`}></span>{product.isSoldOut ? t('menus:product:hideSoldOut') : t('menus:product:showSoldOut') }</button>
                </li>}
                <li>
                  <button className="c-red ft-700" onClick={onClickDeleteProduct}><span className="icon icon-trash icon-16 mr-2"></span>{t('menuDetails:overview:remove:product')}</button>
                </li>
              </DropdownActions>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  )
}

export default CardMenuProduct
