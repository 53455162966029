import { customFetch } from 'api/helpers/customFetch'
import {
  API_BUSINESS_URL,
  API_PUBLIC_BUSINESS_URL,
  API_LINKEDBUSINESS_URL,
} from 'api/helpers/routes';
import { BusinessTheme, BusinessType } from '.'

export const getBusiness = async (id: number): Promise<{business: BusinessType}> => {
  const request = await customFetch.get(`${API_BUSINESS_URL}/${id}`)
  const response = await request.json()
  return { business: response }
}

export const getPublicBusiness = async (slug: string): Promise<{business: BusinessType}> => {
  const request = await customFetch.get(`${API_PUBLIC_BUSINESS_URL}/${slug}?track=no`);
  const response = await request.json();
  return { business: response };
}

export const patchBusiness = async (id: number, business: BusinessType): Promise<{business: BusinessType}> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    business
  })
  const response = await request.json()
  return { business: response }
}

export const postFile = async (CSRFToken: string, id: number, file: File, fileClass?: string): Promise<{key: string}> => {
  const formData = new FormData()
  formData.append('file', file)

  const request = await fetch(`${API_BUSINESS_URL}/${id}/files${fileClass ? `/${fileClass}` : ''}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': CSRFToken,
    },
    body: formData,
  })

  const response = await request.json()
  return { key: response.key }
}

export const patchBusinessTheme = async (id: number, theme: BusinessTheme): Promise<{business: BusinessType}> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    config: {
      theme
    }
  })
  const response = await request.json()
  return { business: response }
}

export const patchBusinessColors = async (id: number, theme: BusinessTheme, backgroundColor: string, actionColor: string): Promise<{business: BusinessType}> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    config: {
      theme,
      backgroundColor,
      actionColor,
    }
  })
  const response = await request.json()
  return { business: response }
}

export const patchBusinessConfigAddress = async (id: number, config: {
  isSameAddress: boolean,
  storeAddress: string,
  storeCity: string,
  storePrefecture: string,
  storeZipcode: string,
  isSameTelephone: boolean,
  storeTelephone: string
}): Promise<Response> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    config
  })

  return request
}

export const patchBusinessStory = async (id: number, estYear: string, config: {
  story: string
}): Promise<Response> => {
  const parsedEstYear: number | null = parseInt(estYear);

  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    estYear: (isNaN(parsedEstYear) ? null : parsedEstYear),
    config
  });

  return request;
}

export const patchBusinessPaymentMethods = async (id: number, config: {
  canPayCash          : boolean,
  canPayCreditcard    : boolean,
  canPayQrcode        : boolean,
  canPayEmoney        : boolean,
  canPayTransportcard : boolean,
  canPayPointcard     : boolean,
}): Promise<Response> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    config
  });

  return request;
}

export const patchBusinessExtras = async (id: number, websiteUrl: string, config: {
  hasWifi: boolean,
  note: string
}): Promise<Response> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    websiteUrl,
    config
  })

  return request
}

export const patchBusinessSocialNetworks = async (id: number, facebookId?: string, twitterId?: string, instagramId?: string, logoUrl?: string, coverUrl?: string ): Promise<Response> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    facebookId,
    twitterId,
    instagramId,
    config: {
      logoUrl,
      coverUrl
    }
  })
  return request
}

export const patchBusinessSettings = async (id: number, settings: {
  name: string,
  address: string,
  city: string,
  prefecture: string,
  zipcode: string,
  telephone: string,
  businessType: string
}): Promise<Response> => {
  const { name, city, prefecture, address, zipcode, telephone, businessType } = settings
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    name,
    city,
    prefecture,
    address,
    zipcode,
    telephone,
    businessType
  })
  return request
}

export const patchBusinessSeasonalThemes = async (id: number, config: {
  seasonalThemeLunar: boolean,
  seasonalThemeBlossom: boolean,
  seasonalThemeTanabata: boolean,
  seasonalThemeHalloween: boolean,
  seasonalThemeXmas: boolean,
}): Promise<Response> => {
  const request = await customFetch.patch(`${API_BUSINESS_URL}/${id}`, {
    config
  });

  return request;
}

export const postLinkedBusiness = async (linkedBusinessId: number): Promise<Response> => {
  const request = await customFetch.post(API_LINKEDBUSINESS_URL, {
    linkedBusinessId: linkedBusinessId
  })
  return request;
}

export const deleteLinkedBusiness = async (linkedBusinessId: number): Promise<Response> => {
  const request = await customFetch.delete(`${API_LINKEDBUSINESS_URL}/${linkedBusinessId}`)
  return request;
}
