import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'api/helpers/customFetch';
import { InputsContactForm } from 'component/Modals/ModalContact';
import { RootState } from 'store/store';


export interface AppState {
  isReady: boolean,
  upload: {
    progress: number
  }
}

const initialState : AppState = {
  isReady: false,
  upload: {
    progress: 0
  }
}

export const slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    init: (state: AppState) => undefined,
    setIsReady: (state: AppState, action: PayloadAction<{ isReady: boolean }>) =>  {
      state.isReady = action.payload.isReady
    },
    setUploadProgress: (state: AppState, action: PayloadAction<{ progress: number }>) =>  {
      state.upload.progress = action.payload.progress
    },
    uploadPicture: (state: AppState, action: PayloadAction<{ file: File, fileType: string }>)  => undefined,
    uploadPictureSuccess: (state: AppState, action: PayloadAction<{key: string}> )  => undefined,
    uploadPictureError: (state: AppState, action: PayloadAction<{error: ErrorType}>) => undefined,
    subscribeFromStripe: (state: AppState, action: PayloadAction<{period: 'month' | 'year'}>) => undefined,
    subscribeFromStripeSuccess: (state: AppState, action: PayloadAction<{sessionId: string}>) => undefined,
    manageAccountFromStripe: (state: AppState, action: PayloadAction) => undefined,
    sendContact: (state: AppState, action: PayloadAction<InputsContactForm>)  => undefined,
    sendContactSuccess: () => undefined,
    sendContactError: (state: AppState, action: PayloadAction<{error: ErrorType}>) => undefined,
  }
});

const getRoot = (state: RootState) => state.app
const uploadProgress = (state: RootState) => getRoot(state).upload.progress
const isReady = (state: RootState) => getRoot(state).isReady;

export const selectors = {
  uploadProgress,
  isReady,
};

// reducer / actions
export const { reducer, actions } = slice;