import DropdownActions from 'component/Buttons/DropdownActions'
import CardManagedBusiness from 'component/Cards/CardManagedBusiness'
import CardPlan from 'component/Cards/CardPlan'
import LanguagePicker from 'component/LanguagePicker'
import i18next from 'i18next'
import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, matchPath, useHistory } from 'react-router-dom'
import { actions, selectors } from 'store'
import { RouteUrls, ROUTE_PATHS, Langs } from 'utils/routers/constants'

const SideBarDashboard: FC = () => {
  const dispatch = useDispatch()
  const userName = useSelector(selectors.user.fullName)
  const businessName = useSelector(selectors.business.name)
  const userAdmin = useSelector(selectors.user.isAdmin)
  const managingBusiness = useSelector(selectors.admin.managingBusiness)
  const history = useHistory()
  const pathName = useMemo(() => history.location.pathname, [history])
  const { t } = useTranslation(['dashboard', 'common'])

  const languageOptions = useMemo(() => Object.values(Langs).map((lang) => ({label: t(`common:shortLanguages:${lang.toLowerCase()}`), value: lang, icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`}  alt="" />})), [t])
  const defaultLanguage = useMemo(() => languageOptions.find((lang) => lang.value === i18next.language), [languageOptions])

  const renderNavItem = useCallback((label: string, routePathName: string, icon: string, acceptedPaths: string[]) => {
    const hasMatch = acceptedPaths.filter(acceptedPath => matchPath(pathName, { path: acceptedPath, exact: true, strict: false })).length > 0
    return (
      <li className={`nav__item h5 ${hasMatch ? 'is-active' : ''}`}>
        <span className={`nav__icon icon icon-${icon} icon-24`}></span>
        <Link className="ft-600" to={routePathName}>{label}</Link>
      </li>
    )
  }, [pathName])

  const onClickLogOut = useCallback(() => {
    dispatch(actions.user.logOut())
  }, [dispatch])

  const onChangeLang = useCallback((option) => {
    i18next.changeLanguage(option.value)
  }, [])

  return (
    <header className="sidebar-dashboard">
      <div className="sidebar-dashboard__top">
        <div>
          <Link to={RouteUrls.Dashboard} className="sidebar-dashboard__logo">
            <img src={'/static/images/logo.svg'} alt={t('dashboard:title')} />
          </Link>
          <nav className="sidebar-dashboard__nav nav nav--dashboard">
            <ul>
              {renderNavItem(
                t('dashboard:nav.home'),
                RouteUrls.Dashboard,
                'home',
                [ROUTE_PATHS.dashboard]
              )}
              {renderNavItem(t('dashboard:nav.menu'), RouteUrls.Menus, 'menu', [
                ROUTE_PATHS.menus,
                ROUTE_PATHS.menuDetails,
                ROUTE_PATHS.categories,
                ROUTE_PATHS.products,
                ROUTE_PATHS.translations,
              ])}
              {!managingBusiness && renderNavItem(
                t('dashboard:nav.account'),
                RouteUrls.Account,
                'settings',
                [ROUTE_PATHS.account]
              )}
            </ul>
          </nav>
          <nav className="sidebar-dashboard__nav nav nav--dashboard nav--dashboard-admin">
            <ul>
              <li className="nav__item h5">
                <span className="nav__icon icon icon-question icon-24"></span>
                <a href={`https://drive.google.com/file/d/${i18next.language === 'ja' ?  '1f8znfavc55JFJdnXTokFe_-ZZvKxKu_r' : '13UwshVJu2pJRQNK-HPSKZ1fnTgqyZGqU'}/view`} target="_blank" rel="noreferrer" className="ft-600">{ t('dashboard:nav.guidebook') }</a>
              </li>
            </ul>
          </nav>
          {userAdmin && (
            <nav className="sidebar-dashboard__nav nav nav--dashboard nav--dashboard-admin">
              <ul>
                {renderNavItem(
                  t('dashboard:nav.admin'),
                  RouteUrls.Admin,
                  'settings',
                  [ROUTE_PATHS.admin]
                )}
              </ul>
            </nav>
          )}
          <LanguagePicker
            options={languageOptions}
            defaultOption={defaultLanguage}
            onChangeLang={onChangeLang}
          />
        </div>
        {!userAdmin ? <CardPlan /> : managingBusiness && <CardManagedBusiness />}
      </div>
      <div className="sidebar-dashboard__bottom f f-ai-center">
        <div className="img-circle img-circle--40 bg-cream-400 mr-2 f f-ai-center f-jc-center">
          <span className="icon icon-user icon-24 c-cream-500" />
        </div>
        <div className="f-1">
          <div className="c-cream-500 ft-600">{userName}</div>
          <div className="c-cream-500 ft-500">{businessName}</div>
        </div>
        <DropdownActions
          className="dropdown-actions--top dropdown-actions--small"
          iconClassName="icon-logout icon-16 c-gray-800"
        >
          <li>
            <button className="c-red ft-700" onClick={onClickLogOut}>
              {t('common:logOut')}
            </button>
          </li>
        </DropdownActions>
      </div>
    </header>
  )
}

export default SideBarDashboard
