import React, { FC, useCallback } from 'react'

interface InputColorRadioProps {
  className?: string
  name?: string,
  id?: string,
  value: string,
  onChange?: (name: string, type: string, value: boolean) => void
  defaultChecked?: boolean
  color: string,
  type: string
}

const InputColorRadio: FC<InputColorRadioProps> = (props) => {
  const {
    value,
    className = '',
    id = '',
    defaultChecked,
    name,
    onChange,
    color = 'pink',
    type
  } = props

  const inputName = name ? name : ''

  const onChangeValue = useCallback((e) => {
    if (onChange) onChange(inputName, type, e.target.value)
  }, [onChange, inputName, type])


  return (
    <div className={`input-radio input-radio--color radio radio--btn radio--hidden ${className ? className : ''}`}>
      <input name={inputName} type="radio" id={id} onChange={onChangeValue} value={value} defaultChecked={defaultChecked} />
      <label htmlFor={id} style={{backgroundColor: color}} />
    </div>
  )
}

export default InputColorRadio
