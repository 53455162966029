import BannerError from 'component/Banners/BannerError';
import Button from 'component/Buttons/Button';
import CardSettingsPlan from 'component/Cards/CardSettingsPlan';
// import InputCheckbox from 'component/Form/Inputs/InputCheckbox';
import InputSelect from 'component/Form/Inputs/InputSelect';
import InputText from 'component/Form/Inputs/InputText';
import TopBarDashboard from 'component/Headers/TopBarDashboard';
import LayoutContent from 'component/Layouts/LayoutContent';
import LayoutSideBar from 'component/Layouts/LayoutSideBar';
import ModalChangePassword from 'component/Modals/ModalChangePassword';
import ModalPremiumPaymentSuccess from 'component/Modals/ModalPremiumPaymentSuccess';
import i18next from 'i18next';

import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { actions, selectors } from 'store';
import { getError, listBusinessTypes, listPrefectures, validationPatterns } from 'utils/helpers';
import { ModalContext } from 'utils/hooks/ModalBehavior';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';

const Settings: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['dashboard', 'account', 'common'])
  const [isLoading, setIsLoading] = useState(false)
  const [canShowModal, setCanShowModal] = useState(true)

  const { showStickyBanner } = useContext(StickyBannerContext)
  const { showModal } = useContext(ModalContext)

  const user = useSelector(selectors.user.user)
  // const userOptinEmail = useSelector(selectors.user.optinEmail)

  const business = useSelector(selectors.business.business)
  const isBusinessPremium = useSelector(selectors.business.isPremium)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const businessTypesOptions = useMemo(()=> listBusinessTypes(), [i18next.language])
  const businessTypeDefaultValue = useMemo(() => businessTypesOptions.find(type => type.value === business.businessType), [businessTypesOptions, business.businessType])

  const prefecture = useSelector(selectors.business.prefecture)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prefectures = useMemo(()=>listPrefectures(i18next.language), [i18next.language])
  const prefecturesOptions = useMemo(() => prefectures.map((p) => ({value: p.value, label: p.i18n})), [prefectures])
  const defaultPrefectureOption = useMemo(() => prefecturesOptions.find((p) => p.value === prefecture), [prefecturesOptions, prefecture])

  const location = useLocation()

  const onSubmit = useCallback((data) => {
    const user = {...data.user, optinEmail: data.user.optinEmail ? 1 : 0}
    dispatch(actions.user.updateSettings({user, business: {id: business.id, settings: data.business}}))
    setIsLoading(true);
  }, [business.id, dispatch])

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const onSuccess = useCallback(() => {
    setIsLoading(false)
  }, [])

  const onError = useCallback(() => {
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  useEffect(() => {
    if (canShowModal) {
      const paymentStatus = new URLSearchParams(location.search).get('payment')
      if (paymentStatus === 'success') {
        showModal(ModalPremiumPaymentSuccess, { hasSucceeded: true })
      } else if (paymentStatus === 'failed') {
        showStickyBanner(BannerError)
      }
      setCanShowModal(false)
    }
  }, [location.search, showModal, showStickyBanner, canShowModal])


  useSagaTakeEvery(actions.user.updateSettingsSuccess, onSuccess)
  useSagaTakeEvery(actions.user.updateSettingsError, onError)

  const errors = useMemo(() => formMethods.errors, [formMethods])
  const register = useMemo(() => formMethods.register, [formMethods])

  return (
    <LayoutSideBar pageName="account" type="dashboard">
      <TopBarDashboard
        title={t('dashboard:nav:account')}
         />
      <LayoutContent>
        {!isBusinessPremium ? <CardSettingsPlan className="mb-5" /> : <CardSettingsPlan className="mb-5" />}
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <h2 className="ft-900">{t('account:business:title')}</h2>
            <div className="mosaic mosaic--2 mosaic--gap-8 mt-3">
              <InputText maxLength={80} defaultValue={business.name} className="input--material" placeholder={t('account:business:inputName')} label={t('account:business:inputName')} name="business.name" inputRef={register({minLength: 3, maxLength: 80, required: true})} />
              <InputSelect registerInForm={true} globalLabel={t('account:business:inputBusinessType')} name="business.businessType" defaultOption={businessTypeDefaultValue} placeholder={t('account:business:inputBusinessType')} options={businessTypesOptions} />
              <InputText defaultValue={business.address} className="input--material" placeholder={t('account:business:inputAddress')} label={t('account:business:inputAddress')} name="business.address" inputRef={register({maxLength: 80, required: true})} />
              <InputText maxLength={255} defaultValue={business.city} className="input--material" placeholder={t('account:business:inputCity')} label={t('account:business:inputCity')} name="business.city" inputRef={register({maxLength: 255, required: true})} />
              <InputText maxLength={10} defaultValue={business.zipcode} className="input--material" placeholder={t('account:business:inputZipCode')} label={t('account:business:inputZipCode')} name="business.zipcode" inputRef={register({maxLength: 10, pattern: validationPatterns.zipCode, required: true})} error={errors && errors.business && errors.business.zipcode ? {message: getError('inputZipCode')} : null} />
              <InputSelect registerInForm={true} globalLabel={t('account:business:inputPrefecture')} name="business.prefecture" defaultOption={defaultPrefectureOption ? defaultPrefectureOption : prefecturesOptions[0]} placeholder={t('account:business:inputPrefecture')} options={prefecturesOptions} />
              <InputText
                maxLength={20}
                defaultValue={business.telephone}
                className="input--material"
                placeholder={t('account:business:inputTelephone')}
                label={t('account:business:inputTelephone')}
                name="business.telephone"
                inputRef={register({maxLength: 20, pattern: validationPatterns.telephone, required: true})}
                error={errors && errors.business && errors.business.telephone ? {message: getError('telephone')} : null}
              />
            </div>

            <div className="mt-5">
              <h2 className="ft-900">{t('account:user:title')}</h2>
              <div className="mosaic mosaic--2 mosaic--gap-8 mt-3">
                <InputText  maxLength={255} inputRef={register({maxLength: 255, required: true})} defaultValue={user.firstname} className="input--material" placeholder={t('account:user:firstname')} label={t('account:user:firstname')} name="user.firstname" />
                <InputText  maxLength={255} inputRef={register({maxLength: 255, required: true})} defaultValue={user.lastname} className="input--material" placeholder={t('account:user:lastname')} label={t('account:user:lastname')} name="user.lastname" />
                <InputText maxLength={255} inputRef={register({maxLength: 255, required: true, pattern: validationPatterns.email})}  defaultValue={user.email} className="input--material" placeholder={t('account:user:inputEmail')} label={t('account:user:inputEmail')} name="user.email" error={errors && errors.user && errors.user.email ? {message: t('account:user:inputEmail:error')} : null} />
                <div className="f f-ai-end  f-direction-column">
                  <InputText disabled={true} minLength={8} maxLength={20} type="password" defaultValue={'AAAAAAAA'} className="w-100 input--material" placeholder={t('account:user:inputPassword:title')} label={t('account:user:inputPassword:title')} autocomplete="off" />
                  <button type="button" className="mt-1 c-primary ft-secondary" onClick={() => showModal(ModalChangePassword)}>{t('account:user:inputPassword:label')}</button>
                </div>

              </div>
            </div>
            {/* <InputCheckbox defaultValue={userOptinEmail} name="user.optinEmail" id="user.optinEmail" className="mt-3" label={t('account:user:inputOptin')} inputRef={register()} /> */}
            <Button isLoading={isLoading} isDisabled={isLoading} className="mt-3 ft-500" text={t('account:save')} isPrimary={true} type="submit" />
          </form>
        </FormProvider>
      </LayoutContent>
    </LayoutSideBar>
  );
}

export default Settings;
