import React, { FC, useCallback, useEffect, useState } from 'react'

interface TextAreaProps {
  onChange?: (name: string | undefined, value: string | undefined) => void,
  className?: string,
  name?: string,
  id?: string,
  label?: string,
  textAreaRef?: any,
  error?: any,
  maxLength?: number,
  defaultValue?: string
}

const TextArea: FC<TextAreaProps> = ({onChange, className, name, label, textAreaRef, id, error, defaultValue = '', maxLength}) => {
  const inputClass = `${ error && error.message ? 'has-error' : ''}`
  const [value, setValue] = useState(defaultValue);

  const inputChange = useCallback((value: string) => {
    setValue(value)
    if (onChange) onChange(name, value)
  }, [onChange, name])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  

  return (
    <div className={`input input--material ${className ? className : ''}`}>
      <textarea className={inputClass} placeholder="Placeholder" id={id} name={name} onChange={(e) => inputChange(e.target.value)} ref={textAreaRef} maxLength={maxLength} value={value} />
      <label htmlFor={id}>{label}</label>
      {error && (
        <div className="input__error">
          {error.message}
        </div>
      )}
    </div>
  )
}

export default TextArea