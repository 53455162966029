import React, { FC, useContext, useEffect, useState, useCallback } from 'react';
import { LayoutModalRight } from 'component/Layouts/LayoutModalRight'
import { useTranslation } from 'react-i18next'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';
import { useSelector } from 'react-redux'
import { selectors } from 'store'
import { getQRCodeImgUrl, getQRCodePDFURL } from 'utils/helpers/QRcodeUtils';
import Button from 'component/Buttons/Button';

interface ModalQRCodeProps {
  onRequestClose: () => void
}

const ModalQRCode: FC<ModalQRCodeProps> = ({onRequestClose}) => {

  const { t } = useTranslation(['dashboard'])
  const [qrcodeDownload, setQrcodeDownload] = useState({
    name: '',
    url: ''
  });

  const { showStickyBanner } = useContext(StickyBannerContext);
  const business = useSelector(selectors.business.business)

  const menuURL = `${process.env.REACT_APP_DIGITO_WEBSITE}/business/${business.slug}`;
  const qrcodeURL = `${process.env.REACT_APP_PICTURE_URL}/${business.qrcodeUrl}`;
  const qrFilename = `qr_${business.slug}.png`;

  const handleQRCode = useCallback( async () => {
    const qrcodePdfURL = `${process.env.REACT_APP_DIGITO_WEBSITE}/business/${business.slug}`
    const QRcodeImgUrl = await getQRCodeImgUrl(qrcodePdfURL)
    if (!QRcodeImgUrl || !QRcodeImgUrl.length) {
      showStickyBanner(BannerError);
      return;
    }

    const QRCodeDownloadUrl = await getQRCodePDFURL(qrcodePdfURL, business.name)
    setQrcodeDownload(QRCodeDownloadUrl)
  }, [showStickyBanner, business])

  useEffect(() => {
    handleQRCode()
  }, [handleQRCode])

  return (
    <LayoutModalRight
      onRequestClose={onRequestClose}
      className="modal--right modal--qrcode"
      title={t('getQRCode.title')}
    >
      <div className="modal__container">
        <div className="modal__download-wrapper">
          <div className="f f-ai-center mb-2">
            <div className="ft-center mr-2">
              <img src={qrcodeURL} className="qrcode-image" width={144} height={144} alt="QR code" />
              <div><a className="ft-primary c-primary ft-600" href={`${qrcodeURL}?response-content-disposition=attachment;%20${qrFilename}`} download={qrFilename}>
                <span className="icon icon-download c-primary mr-1" />
                {t('getQRCode.download.link')}
              </a></div>
            </div>
            <div className="f-1">
              <h3>{t('getQRCode.download.title')}</h3>
              <p className="ft-primary c-gray-600 ft-500">{t('getQRCode.download.description')}</p>
            </div>
          </div>
          <div>
            <p className="ft-primary c-gray-600 ft-500">{t('getQRCode.download.description2')}</p>
            <a className="card-plan__cta ft-500" href={menuURL} target="_new" rel="noopener">
              <span className="icon icon-link mr-1" />
              {menuURL}
            </a>
            <p className="mt-1">{(qrcodeDownload.name.length > 0) && (
              <a className="ft-500" href={qrcodeDownload.url} download={qrcodeDownload.name}>
                <span className="icon icon-download mr-1" />
                {t('getQRCode.download.link_pdf')}
              </a>
            )}</p>
          </div>
        </div>
        <div className="modal__extra-wrapper">
          <h3 className="mt-3 mb-2">{t('getQRCode.heading')}</h3>
          <p className="ft-primary mb-2 c-gray-600 ft-500">{t('getQRCode.description')}</p>
          <p className="ft-primary mb-1 c-gray-600 ft-500">{t('getQRCode.description2')}</p>
          <ul className="ft-primary mb-3 c-gray-700">
            <li className="mb-1"><span className="icon icon-bulb c-primary mr-1" />{t('getQRCode.list.extra_1')}</li>
            <li className="mb-1"><span className="icon icon-bulb c-primary mr-1" />{t('getQRCode.list.extra_2')}</li>
            <li className="mb-1"><span className="icon icon-bulb c-primary mr-1" />{t('getQRCode.list.extra_3')}</li>
            <li><span className="icon icon-bulb c-primary mr-1" />{t('getQRCode.list.extra_4')}</li>
          </ul>
          <p className="ft-primary c-gray-600 ft-500">{t('getQRCode.description3')}</p>
          <Button className="mt-2 mb-2" text={t('getQRCode.button')} isPrimary={true} isExternal={true} link={`https://www.cognitoforms.com/DIGITOMENU2/DIGITO%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%82%AD%E3%83%83%E3%83%88%E6%B3%A8%E6%96%87%E6%9B%B82`} />
        </div>
      </div>
    </LayoutModalRight>
  )
}

export default ModalQRCode
