import React, { FC, useContext, useEffect, useState } from 'react'
import ModalLogin from 'component/Modals/ModalLogin'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import i18next from 'i18next';

const Login: FC<any> = () => {

  let history = useHistory();
  const { showModal, hideModal } = useContext(ModalContext)
  const [ canShowModal, setCanShowModal ] = useState(true)
  const isConnected = useSelector(selectors.user.connected)

  const query = new URLSearchParams(useLocation().search)
  const originParam = query.get('origin')

  const isFromLandingPage = originParam === 'landing';

  useEffect(() => {
    if (canShowModal) {
       showModal(ModalLogin, {
        onRequestClose: isFromLandingPage ? () => {
          window.location.href = `${process.env.REACT_APP_DIGITO_WEBSITE!}/${i18next.language !== 'ja' ? `${i18next.language}/` : ''}`   
        }: () => {},
        hideClose: !isFromLandingPage ? true : false,
        overlayClassname: "overlay--primary"
      })
      setCanShowModal(false)
    }
  }, [showModal, isFromLandingPage, history.goBack, hideModal, canShowModal])

  useEffect(() => {
    if (isConnected) {
      hideModal()
    }
  }, [isConnected, hideModal])

  return (
    <></>

  )
}

export default Login
