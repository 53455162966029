import React, { FC, useState } from 'react'
import DetectOutside from 'utils/hooks/DetectOustide'

interface DropdownActionsProps {
  children: React.ReactNode,
  className?: string,
  iconClassName?: string,
}

const DropdownActions: FC<DropdownActionsProps> = ({ children, className, iconClassName }) => {

  const [isOpen, setIsOpen] = useState(false)
  const iconClasses = iconClassName ? iconClassName : 'icon-more icon-32 c-gray-800'

  return (
    <DetectOutside callback={() => setIsOpen(false)} className={`dropdown-actions ${className ? className : ''}`}>
      <button className={`icon ${iconClasses}`} onClick={() => setIsOpen(true)}></button>
      {isOpen &&
      <div className="dropdown-actions__body">
        <button className="dropdown-actions__close icon icon-close" onClick={() => setIsOpen(false)} />
        <ul>
          {children}
        </ul>
      </div>
      }
    </DetectOutside>
  )
}

export default DropdownActions