import React, { FC, useCallback, useState } from 'react'

interface InputCheckboxProps {
  onChange?: (name: string | undefined, value: boolean) => void,
  className?: string,
  name?: string,
  id?: string,
  label?: string | React.ReactNode,
  labelClassName?: string,
  labelContent?: React.ReactNode,
  inputRef?: any,
  error?: {message: string},
  defaultValue?: boolean
}

const InputCheckbox: FC<InputCheckboxProps> = ({onChange, className, name, label = '', labelClassName = '', inputRef, id, error, defaultValue = false}) => {
  const inputClass = `${ error && error.message ? 'has-error' : ''}`
  const [value, setValue] = useState(defaultValue || false)

  const inputChange = useCallback((value: boolean) => {
    setValue(value)
    if (onChange) onChange(name, value)
  }, [onChange, name])

  return (
    <div className={`checkbox ${className ? className : ''}`}>
      <input type="checkbox" className={inputClass} id={id ? id : name} name={name} checked={value} onChange={(e) => inputChange(e.target.checked)} ref={inputRef} />
      <label className={`c-gray-700 ${labelClassName}`} htmlFor={id ? id : name}>{label}</label>
      {error && (
        <div className="input__error">
          {error.message}
        </div>
      )}
    </div>
  )
}

export default InputCheckbox
