import InputSelect from 'component/Form/Inputs/InputSelect'
import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'

interface LanguagePickerProps {
  className?: string,
  defaultOption?: {value: string, label: string},
  value?: {value: string, label: string},
  options: {value: string, label: string}[],
  onChangeLang: ({value, label}: {value: string, label: string}) => void,
  simulateMobile?: boolean,
}

const LanguagePicker: FC<LanguagePickerProps> = ({options, defaultOption, className = '', onChangeLang, value, simulateMobile}) => {
  const [currentOption, setCurrentOption] = useState(defaultOption ? defaultOption : options[0])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile =  useMemo(() => simulateMobile ? simulateMobile :  windowWidth <= 768, [windowWidth, simulateMobile]);

  const onClickOption = useCallback((e) => {
    const option = options.find((option) => option.value === e.target.value)
    if (option) {
      setCurrentOption(option)
      onChangeLang(option)
    }
  }, [options, onChangeLang])

  const onChangeSelect = useCallback((label, value) => {
    const option = options.find((option) => {
      return  option.value === value
    })
    if (option) {
      setCurrentOption(option)
      onChangeLang(option)
    }
  }, [options, onChangeLang])

  const onResize = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  useEffect(() => {
    if (value) {
      setCurrentOption(value)
    }
  }, [value])

  return (
    <div className={`language-picker ${className}`}>
      {isMobile ?
        <div className="language-picker__body language-picker__body--mobile">
          <img className="img-circle img-circle--24 mr-2" src={`/static/images/flags/${currentOption.value.toLowerCase()}.png`} alt={currentOption?.label} />
          <select onChange={onClickOption} value={currentOption?.value}>
            {options.map((option, index) => (
              <option key={option.value} value={option.value}>{option?.label}</option>
            ))}
          </select>
          <span className="icon icon-arrow-down c-gray-500 ml-2" />
        </div>
        :
        <InputSelect className="mr-2" placeholder="" isSearchable={false} options={options} defaultOption={defaultOption} onChange={onChangeSelect} />
      }
    </div>
  )
}

export default LanguagePicker
