import { customFetch } from 'api/helpers/customFetch'
import { API_LANGUAGES_URL, API_LANGUAGES_ME_URL } from 'api/helpers/routes'
import { LanguageType } from './index'

export const getLanguages = async (): Promise<{languages: LanguageType[]}> => {
  const request = await customFetch.get(API_LANGUAGES_URL)
  const response = await request.json() 
  return { languages: response }
}

export const postLanguage = async (languageId: string): Promise<Response> => {
  const request = await customFetch.post(API_LANGUAGES_ME_URL, {
    languageId: languageId
  })
  return request
}

export const deleteLanguage = async (languageId: string): Promise<Response> => {
  const request = await customFetch.delete(`${API_LANGUAGES_ME_URL}/${languageId}`)
  return request
}