import React, { FC, useCallback } from 'react'

interface InputImgRadioProps {
  className?: string
  name?: string,
  id?: string,
  value: string,
  imgSrc: string,
  title: string,
  desc: string
  onChange?: (name: string, value: boolean) => void
  inputRef?: any
  defaultChecked: boolean,
  error?: boolean
}

const InputImgRadio: FC<InputImgRadioProps> = (props) => {
  const {
    value,
    className = '',
    id = '',
    defaultChecked,
    name,
    onChange,
    imgSrc,
    title,
    desc
  } = props

  const inputName = name ? name : ''

  const onChangeValue = useCallback((e) => {
    if (onChange) onChange(inputName, e.target.value)
  }, [onChange, inputName])


  return (
    <div className={`input-radio input-radio--img radio radio--btn is-reversed ${className ? className : ''}`} >
      <input name={inputName} type="radio" id={id} onChange={onChangeValue} value={value} defaultChecked={defaultChecked} />
      <label htmlFor={id}>
        <div className="input-radio__left f">
          <div className="input-radio__img">
            <img src={imgSrc} alt="" />
          </div>
          <div className="input-radio__body">
            <div className="h3 ft-600 c-gray-800">{title}</div>
            {desc && <div className="c-gray-600">{desc}</div>}
          </div>
        </div>
      </label>
    </div>
  )
}

export default InputImgRadio
