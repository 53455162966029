import React, { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { ProductsType } from 'store/Products'
import { actions, selectors } from 'store'
import TextArea from 'component/Form/Inputs/TextArea'
import Button from 'component/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux';
import InputImageFile from 'component/Form/Inputs/InputImageFile'
import { useTypedSelector } from 'store/store'
import InputSelect from 'component/Form/Inputs/InputSelect'
import ModalPremium from 'component/Modals/ModalPremium'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import InputCheckList from 'component/Form/Inputs/InputCheckList'
import BannerError from 'component/Banners/BannerError'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { getError, removeTrailingZeros, validationPatterns } from 'utils/helpers'

interface ModalProductEditionProps {
  onRequestClose: (noHistory?: boolean) => void,
  product?: ProductsType,
  menuId?: number,
  categoryId?: number,
  parentCategoryId?: number
}

interface Inputs {
  name: string,
  description: string
  price: number
  diet: {id: number}[]
  spiciness: number
}

const ModalProductEdition: FC<ModalProductEditionProps> = ({onRequestClose, product, menuId, categoryId, parentCategoryId }) => {
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)
  const { showStickyBanner } = useContext(StickyBannerContext)

  const business = useSelector(selectors.business.business);
  const MAXLEN_NAME = 50
    + (business.id === 118 ? 4 : 0)
    + (business.id === 103 ? 5 : 0)
    + (business.id === 89 ? 11 : 0)
    + (business.id === 101 ? 22 : 0)
    + (business.id === 97 ? 45 : 0)
    ; // Temp hack to not affect certain existing businesses, extended limit 54~95
  const MAXLEN_DESC = 200;

  const { register, errors, handleSubmit, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });
  const isEditMode = useMemo(() => product && product.id ? true : false, [product])
  const formId = useMemo(() => 'modal-product-edition-form', [])

  const [isLoading, setIsLoading] = useState(false)
  const [error] = useState<{message: string} | null>(null)
  const photoUrl = useTypedSelector(state => selectors.products.photoUrlForProduct(state, product))
  const photoUrlId = useTypedSelector(state => selectors.products.photoUrlIdForProduct(state, product))

  const dietOptions = useTypedSelector((state) => selectors.products.diets(state))
  const [name, setName] = useState<string | null>(product && product?.i18n.name ? product?.i18n.name : '')
  const [price, setPrice] = useState<string | null>(product && product?.price ? product?.price : '')
  const [description, setDescription] = useState<string | null>(product && product?.i18n.description ? product?.i18n.description : '')

  const [spiciness, setSpiciness] = useState<any>(product && product?.spiciness ? product?.spiciness : '')
  const [diet, setDiet] = useState<{id: number}[] | null>( product && product?.diets ? product?.diets : [])
  const [file, setFile] = useState<File>()
  const [image, setImage] = useState<{url: string, name: string}>({ url: photoUrl || '', name: '' })

  const { t } = useTranslation(['menus', 'common'])

  const spicinessList = useMemo(() => [
    t('menus:productEditionModal:spicinessOptions.notSpicy'),
    t('menus:productEditionModal:spicinessOptions.mildSpice'),
    t('menus:productEditionModal:spicinessOptions.mediumSpice'),
    t('menus:productEditionModal:spicinessOptions.hotSpice'),
  ], [t])

  const requiredPriceTranslation = getError('price')

  const spiceIcon: ReactElement = useMemo(() => (
    <span className="icon icon-spice"></span>
  ), [])

  const spicinessOptions = useMemo(() => spicinessList.map((spiciness, id) => ({label: spiciness, value: id.toString(), icon: spiceIcon, iconNb: id })), [spiceIcon, spicinessList])

  const defaultDiets = useMemo(() => (product && product.diets && product.diets.length > 0) ? product.diets.map((d) => ({id: d.id})) : [], [product])

  const getParamsForNewProduct = useCallback((data) => {
    const newProduct = { ...data }
    newProduct.photoUrl = image.url.length > 0 ? photoUrlId : ''

    if (spiciness) newProduct.spiciness = spiciness
    if (diet) newProduct.diets = diet

    const imageFile = file ? { imageFile: file } : {}
    const menu = menuId !== undefined ? { menuId } : {}
    const category = categoryId !== undefined ? { categoryId } : {}
    const parentCategory = parentCategoryId !== undefined ? { parentCategoryId } : {}
    return { product: newProduct, ...imageFile, ...parentCategory, ...category, ...menu }
  }, [categoryId, file, diet, image, menuId, parentCategoryId, spiciness, photoUrlId])

  const onSubmit = useCallback((form) => {
    const params = getParamsForNewProduct(form)

    setIsLoading(true)
    if (isEditMode && product && product.id) {
      dispatch(actions.products.updateProduct({ id: product.id, ...params }))
    } else {
      dispatch(actions.products.createProduct({ ...params }))
    }
  }, [product, dispatch, isEditMode, getParamsForNewProduct])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose(true)
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    setIsLoading(false)
     showStickyBanner(BannerError)
  }, [ showStickyBanner])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menus:productEditionModal:cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} type="submit" form={formId} text={t(isEditMode ? 'menus:productEditionModal:saveEdit' : 'menus:productEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading, isEditMode])

  const onClickPremium = useCallback(() => {
    let newProduct = product ? { ...product } : { price: '', photoUrl: '', spiciness: '', i18n: {name: '', description: ''}}

    newProduct.price = price ? price : newProduct.price
    newProduct.photoUrl = image.url.length ? photoUrlId : ''
    newProduct.i18n = {
      name: name ? name : newProduct.i18n?.name,
      description: description ? description : newProduct.i18n?.description
    }
    newProduct.spiciness = spiciness ? spiciness : newProduct.spiciness

    showModal(ModalPremium, {}, true, {product: newProduct, menuId, categoryId, parentCategoryId})
  }, [showModal, image, photoUrlId, product, description, name, price, spiciness, menuId, categoryId, parentCategoryId])

  useSagaTakeEvery(actions.products.createProductSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.products.updateProductSuccess, onSuccessEdition)

  useSagaTakeEvery(actions.products.createProductError, onErrorEdition)
  useSagaTakeEvery(actions.products.updateProductError, onErrorEdition)

  const onChangeInputFile = useCallback((name, file, image) => {
    setFile(file)
    setImage(image)
  }, [])

  const onChangeInput = useCallback((name, value) => {
    clearErrors()
    switch(name) {
      case 'name':
        setName(value ? value : '')
        break;
      case 'description':
        setDescription(value ? value : '')
        break;
      case 'price':
        setPrice(value ? value : '')
        break;
      default:
        break;
    }
  }, [clearErrors])

  const onChangeSpiciness = useCallback((name, value) => {
    setSpiciness(value ? value : '')
  }, [])

  const onChangeDiet = useCallback((value) => {
    setDiet(value ? value : '')
  }, [])

  let [defaultSpicinessOption, setDefaultSpicinessOption] = useState({label: '', value: '', icon: undefined})

  useEffect(() => {
    // @ts-ignore
    setDefaultSpicinessOption(spicinessOptions.find(option => option.value === spiciness.toString()))
    // eslint-disable-next-line
  }, [spiciness])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={isEditMode ? t('menus:productEditionModal:title:edit') : t('menus:productEditionModal:title:add')} onRequestClose={onRequestClose}
      className="modal--product-edition"
      footerContent={footerContent}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <InputImageFile
          isPremiumOnly={true}
          onClickPremium={onClickPremium}
          title={t('menus:productEditionModal:inputFile:empty')}
          ctaText={t('menus:productEditionModal:inputFile:cta')}
          handleOnChange={onChangeInputFile}
          defaultImage={image}
          cardClassName="mb-1"
          className="mb-1 input-file-square"
          description={`${t('menus:productEditionModal:inputFile:conditions', {size: '400 x 400'})} (.jpg, .png - 10MB max)`}
          validate={{
            type: "jpg|jpeg|png",
            maxBytes: 1024 * 1024 * 10 // 10mo
          }} />
        <div className="f">
          <InputText
          maxLength={MAXLEN_NAME}
          defaultValue={product?.i18n.name}
          className="modal__input-name f-1 input--material mb-1"
          placeholder={t('menus:productEditionModal:inputName')}
          label={t('menus:productEditionModal:inputName')}
          name="name"
          handleChange={onChangeInput}
          inputRef={register({
            required: {
              value: true,
              message: getError('name')
            }
          })} error={errors.name} />
          <InputText
            defaultValue={product && removeTrailingZeros(product.price)}
            type="number"
            className="input--price f-1 input--material mb-1"
            placeholder={t('menus:productEditionModal:inputPrice')}
            label={t('menus:productEditionModal:inputPrice')}
            name="price"
            inputRef={register({
              required: requiredPriceTranslation,
              pattern: {
                value:  validationPatterns.price,
                message: getError('priceType')
              }
            })}
            handleChange={onChangeInput}
            error={errors && errors.price}
          />
        </div>
        <TextArea
          className="mb-1"
          defaultValue={product?.i18n.description}
          maxLength={MAXLEN_DESC}
          label={t('menus:productEditionModal:textAreaDesc')}
          name="description"
          textAreaRef={register()}
          error={errors?.description}
          onChange={onChangeInput}
        />

        <InputSelect
          defaultOption={defaultSpicinessOption}
          isPremiumOnly={true}
          className="mb-1"
          onClickPremium={onClickPremium}
          placeholder={t('menus:productEditionModal:selectSpiciness')}
          options={spicinessOptions}
          onChange={onChangeSpiciness}
          error={error}
        />

        <InputCheckList
          isPremiumOnly={true}
          onClickPremium={onClickPremium}
          label={t('menus:productEditionModal:inputDiet')}
          boxes={dietOptions}
          defaultValue={defaultDiets}
          onChange={onChangeDiet} />
      </form>
    </LayoutModal>
  )
}
export default ModalProductEdition
