import TopBarDashboard from 'component/Headers/TopBarDashboard';
import MenuOverview from 'component/MenuOverview';
import LayoutContent from 'component/Layouts/LayoutContent';
import LayoutSideBar from 'component/Layouts/LayoutSideBar';
import ModalMenuEdition from 'component/Modals/ModalMenuEdition';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { actions, selectors } from 'store';
import { useTypedSelector } from 'store/store';
import { ModalContext } from 'utils/hooks/ModalBehavior';
import MenuCustomization from 'component/MenuCustomization';
import MenuPreviewWrapper from 'component/MenuPreviewWrapper';
import Button from 'component/Buttons/Button';
import { MENU_STATUS } from 'store/Menus';
import ModalMenuWelcomeKit from 'component/Modals/ModalMenuWelcomeKit';
import i18next from 'i18next';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';

const MenuDetails: FC = () => {
  const { t } = useTranslation(['menuDetails'])
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)

  const [ isLoading, setIsLoading ] = useState(false)

  const params: {id: string} = useParams()
  const menuId = useMemo(() => parseInt(params.id), [params])
  const menu = useTypedSelector((state) => selectors.menus.menu(state, menuId))
  const isOnline = useTypedSelector((state) => selectors.menus.isOnline(state, menuId))
  const isDraft = useTypedSelector((state) => selectors.menus.isDraft(state, menuId))
  const isDraftMode = useSelector(selectors.menuDetails.isDraftMode)
  const apiQueue = useSelector(selectors.menuDetails.apiQueue)

  const isOneMenuAlreadyOnline = useTypedSelector((state) => selectors.menus.isOneMenuAlreadyOnline(state))
  const [canShowModal, setCanShowModal] = useState(true)

  const schedule = useTypedSelector((state) => selectors.menus.schedule(state, menu && menu.id ? menu.id : -1))

  const topBarLeftContent = useMemo(() => (
    <div className="f f-ai-center">
      <div className="c-cream-500 ft-500 f-inline f-ai-center">
        <span className="icon icon-calendar mr-1" /><span>{schedule.days}</span>
        {schedule.hours && <><span className="icon icon-clock ml-2 mr-1" /><span>{schedule.hours}</span></>}
      </div>
      <button className="ml-2 ft-700 c-primary" onClick={() => showModal(ModalMenuEdition, {menu})}>{t('menuDetails:schedule:edit')}</button>
    </div>
  ), [menu, schedule, t, showModal])

  const onClickOnline = useCallback(() => {
    dispatch(
      actions.menus.updateMenuStatus({
        id: menuId,
        status: !isOnline
          ? MENU_STATUS.VISIBLE
          : MENU_STATUS.INVISIBLE
      })
    )
    if (!isOneMenuAlreadyOnline && isDraft && canShowModal) {
      showModal(ModalMenuWelcomeKit)
      setCanShowModal(false)
    }
  }, [menuId, dispatch, isOnline, showModal, canShowModal, isOneMenuAlreadyOnline, isDraft])



  const onClickSave = useCallback(() => {
    dispatch(actions.menuDetails.saveChanges())
    setIsLoading(true)
  }, [dispatch])

  const rightContent = useMemo(() => (
    <>
      {(isDraftMode && apiQueue.length > 0) &&
        <>
        <span className="ft-700 c-red mr-2">{t('menuDetails:unsaved')}</span>
          <Button text={t('menuDetails:ctas:save')} isLoading={isLoading} onClick={onClickSave} isPrimary={true} />
        </>
      }
      <Button isPrimary={!isOnline && !(isDraftMode && apiQueue.length > 0)} text={isOnline ? t('menuDetails:ctas:offline') : t('menuDetails:ctas:online')} onClick={onClickOnline} />
      </>
  ), [t, onClickOnline, onClickSave, isOnline, apiQueue, isDraftMode, isLoading])

  const tabBarContent = (
    <nav className="navbar tabbar">
      <ul className="navbar__links tabbar__body">
        <li><a className={`tabbar__item`} href="#MenuOverview">{t('menuDetails:overview:title')}</a></li>
        <li><a className={`tabbar__item`} href="#MenuCustomization">{t('menuDetails:customization:title')}</a></li>
      </ul>
    </nav>
  )

  const onSaveChangeSuccess = useCallback(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    dispatch(actions.menus.fetchMenus())
    dispatch(actions.menuDetails.fetchMenu({id: menuId}))
    dispatch(actions.categories.fetchCategories())
    dispatch(actions.products.fetchProducts())
    dispatch(actions.products.fetchDietOptions({lang: i18next.language.toUpperCase()}))
  }, [dispatch, menuId])

  useSagaTakeEvery(actions.menuDetails.saveChangesSuccess, onSaveChangeSuccess)

  useEffect(() => {
    i18next.on('languageChanged', () => {
      dispatch(actions.products.fetchDietOptions({lang: i18next.language.toUpperCase()}))
    })
  }, [dispatch])

  if (menu) {
    return (
      <LayoutSideBar pageName="menu-details" type="dashboard">
        <TopBarDashboard
          title={menu.name}
          leftContent={topBarLeftContent}
          rightContent={rightContent}
          tabBarContent={tabBarContent}
          />

        <LayoutContent>
          <div className="page__left">
            <MenuOverview menuId={menuId} className="mb-6" />
            <MenuCustomization menuId={menuId} />
          </div>
          <MenuPreviewWrapper menuId={menuId} />
        </LayoutContent>
      </LayoutSideBar>
    )
  }
  return <></>
}

export default MenuDetails;
