import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'api/helpers/customFetch';
import i18next from 'i18next';
import { RootState } from '../store';

export const prefectures = [
  'Hokkaidō', 'Aomori', 'Iwate', 'Miyagi', 'Akita', 'Yamagata', 'Fukushima', 'Ibaraki', 'Tochigi', 'Gunma', 'Saitama',
  'Chiba', 'Tōkyō', 'Kanagawa', 'Niigata', 'Toyama', 'Ishikawa', 'Fukui', 'Yamanashi', 'Nagano', 'Gifu', 'Shizuoka',
  'Aichi', 'Mie', 'Shiga', 'Kyōto', 'Ōsaka', 'Hyōgo','Nara', 'Wakayama', 'Tottori', 'Shimane','Okayama', 'Hiroshima',
  'Yamaguchi', 'Tokushima','Kagawa', 'Ehime', 'Kōchi', 'Fukuoka','Saga', 'Nagasaki', 'Kumamoto', 'Ōita','Miyazaki',
  'Kagoshima', 'Okinawa'];

export enum BUSINESS_TYPE {
  RESTAURANT = 'RESTAURANT',
  BAR = 'BAR',
  CAFE = 'CAFE',
  BAKERY = 'BAKERY',
  GIFTSHOP = 'GIFTSHOP',
  OTHER = 'OTHER',
}

export enum BusinessTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export interface BusinessConfig {
  id: number
  businessId: number
  logoUrl: string,
  coverUrl: string,
  storyUrl?: string | null,
  theme: BusinessTheme
  backgroundColor: string,
  actionColor: string,
  isSameAddress   : boolean,
  storeAddress    : string,
  storeCity       : string,
  storePrefecture : string,
  storeZipcode    : string,
  isSameTelephone : boolean,
  storeTelephone  : string,
  canPayCash          : boolean,
  canPayCreditcard    : boolean,
  canPayQrcode        : boolean,
  canPayEmoney        : boolean,
  canPayTransportcard : boolean,
  canPayPointcard     : boolean,
  hasWifi         : boolean,
  isPremiumSubscription: boolean
  stripe_subscription_id: string | null,
  seasonalThemeLunar: boolean,
  seasonalThemeBlossom: boolean,
  seasonalThemeTanabata: boolean,
  seasonalThemeHalloween: boolean,
  seasonalThemeXmas: boolean,
  i18n: {
    story : string,
    note  : string,
  }
}

export interface BusinessType  {
  id: number,
  name: string,
  slug: string,
  qrcodeUrl: string,
  estYear: string,
  address: string,
  city: string,
  prefecture: string,
  zipcode: string,
  telephone   : string,
  businessConfig: BusinessConfig,
  businessType: BUSINESS_TYPE,
  facebookId  : string,
  twitterId   : string,
  instagramId : string,
  websiteUrl  : string,
  languages: {id: string}[],
  linkedBusinesses: BusinessType[],
  importPending: number,
}

export interface BusinessState {
  data: {
    business: BusinessType,
    foundLinkableBusiness?: BusinessType,
  }
}

const initialState : BusinessState = {
  data: {
    business: {
      id: 0,
      name: '',
      slug: '',
      qrcodeUrl: '',
      estYear: '',
      address: '',
      languages: [],
      businessType: BUSINESS_TYPE.RESTAURANT,
      zipcode: '',
      telephone   : '',
      businessConfig: {
        id: -1,
        businessId: -1,
        theme: BusinessTheme.LIGHT,
        logoUrl: '',
        coverUrl: '',
        storyUrl: '',
        backgroundColor: '#FFF',
        actionColor: '#FE964A',
        isSameAddress   : false,
        storeAddress    : '',
        storeCity       : '',
        storePrefecture : '',
        storeZipcode    : '',
        isSameTelephone : false,
        storeTelephone  : '',
        canPayCash          : false,
        canPayCreditcard    : false,
        canPayQrcode        : false,
        canPayEmoney        : false,
        canPayTransportcard : false,
        canPayPointcard     : false,
        hasWifi         : false,
        isPremiumSubscription: false,
        stripe_subscription_id: null,
        seasonalThemeLunar: true,
        seasonalThemeBlossom: true,
        seasonalThemeTanabata: true,
        seasonalThemeHalloween: true,
        seasonalThemeXmas: true,
        i18n: {
          story: '',
          note: '',
        }
      },
      city: '',
      facebookId: '',
      twitterId: '',
      instagramId: '',
      websiteUrl: '',
      prefecture: '',
      linkedBusinesses: [],
      importPending: 0
    },
    foundLinkableBusiness: undefined,
  },
}

export const slice = createSlice({
  name: 'Business',
  initialState,
  reducers: {
    setBusiness: (state: BusinessState, action: PayloadAction<{ business: BusinessType}>) => {
      const { business } = action.payload
      state.data.business = business
      if (!business.businessConfig.theme) {
        state.data.business.businessConfig.theme = BusinessTheme.LIGHT
      }
      if (!business.businessConfig.actionColor) {
        state.data.business.businessConfig.actionColor = initialState.data.business.businessConfig.actionColor;
      }
      if (!business.businessConfig.backgroundColor) {
        state.data.business.businessConfig.backgroundColor = state.data.business.businessConfig.theme === BusinessTheme.LIGHT ? '#ffffff' : '#000000'
      }

      if (!business.languages || !business.languages.length) {
        state.data.business.languages = [{id: 'JA'}]
      }


    },
    setTheme: (state: BusinessState, action: PayloadAction<{ theme: BusinessTheme}>) => {
      state.data.business.businessConfig.theme = action.payload.theme
      state.data.business.businessConfig.backgroundColor = action.payload.theme === BusinessTheme.LIGHT ? '#ffffff' : '#000000'
    },
    setColors: (state: BusinessState, action: PayloadAction<{ theme: BusinessTheme, backgroundColor: string, actionColor: string}>) => {
      state.data.business.businessConfig.theme = action.payload.theme
      state.data.business.businessConfig.backgroundColor = action.payload.backgroundColor
      state.data.business.businessConfig.actionColor = action.payload.actionColor
    },
    clearBusiness: (state: BusinessState) => {
      state.data = initialState.data
    },
    fetchBusiness:(state: BusinessState, action: PayloadAction<{id: number, isAdminFetch?: boolean, isManaging?: boolean }>) => undefined,
    fetchBusinessSuccess:(state: BusinessState) => undefined,
    adminFetchBusinessSuccess:(state: BusinessState, action: PayloadAction<{ isManaging?: boolean }>) => undefined,
    fetchBusinessError:(state: BusinessState) => undefined,
    updateBusiness: (state: BusinessState, action: PayloadAction<{id: number, business: BusinessType}>) => undefined,
    updateBusinessSuccess: (state: BusinessState, action: PayloadAction<{business: BusinessType}>) => undefined,
    updateBusinessError: (state: BusinessState, action: PayloadAction<{id: number, e: ErrorType | string }>) => undefined,
    updateBusinessTheme: (state: BusinessState, action: PayloadAction<{id: number, theme: BusinessTheme}>) => undefined,
    updateBusinessThemeSuccess: (state: BusinessState, action: PayloadAction<{business: BusinessType}>) => undefined,
    updateBusinessThemeError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    updateBusinessColors: (state: BusinessState, action: PayloadAction<{id: number, theme: BusinessTheme, backgroundColor: string, actionColor: string}>) => undefined,
    updateBusinessColorsSuccess: (state: BusinessState, action: PayloadAction<{business: BusinessType}>) => undefined,
    updateBusinessColorsError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    updateBusinessLogo: (state: BusinessState, action: PayloadAction<{
      id: number,
      imageFileLogo?: File,
      logoUrl?: string,
      imageFileCover?: File,
      coverUrl?: string,
    }>) => undefined,
    updateBusinessLogoSuccess: (state: BusinessState) => undefined,
    updateBusinessLogoError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    createLogoFile: (state: BusinessState, action: PayloadAction<{ imageFile: File, id: number, type: string }>) => undefined,
    createLogoFileSuccess: (state: BusinessState, action: PayloadAction<{ key: string }>) => undefined,
    createLogoFileError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateBusinessSocialNetworks: (state: BusinessState, action: PayloadAction<{
      id: number,
      facebookId: string,
      twitterId: string,
      instagramId: string
    }>) => undefined,
    updateBusinessSocialNetworksSuccess: (state: BusinessState) => undefined,
    updateBusinessSocialNetworksError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateBusinessStory: (state: BusinessState, action: PayloadAction<{
      id: number,
      estYear: string,
      imageFileStory?: File,
      config: {
        story   : string,
        storyUrl?: string,
      }
    }>) => {
      state.data.business.estYear = action.payload.estYear;
      if (action.payload.config.story && !state.data.business.businessConfig.i18n) {
        state.data.business.businessConfig.i18n = {
          story: action.payload.config.story,
          note: ''
        };
      }
      state.data.business.businessConfig.storyUrl = action.payload.config.storyUrl;
    },
    updateBusinessStorySuccess: (state: BusinessState) => undefined,
    updateBusinessStoryError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updateConfigAddress: (state: BusinessState, action: PayloadAction<{ id: number, config: {
      isSameAddress: boolean,
      storeAddress: string,
      storeCity: string,
      storePrefecture: string,
      storeZipcode: string,
      isSameTelephone: boolean,
      storeTelephone: string,
    }}>) => {
      state.data.business.businessConfig.isSameAddress = action.payload.config.isSameAddress;
      state.data.business.businessConfig.storeAddress = action.payload.config.storeAddress;
      state.data.business.businessConfig.storeCity = action.payload.config.storeCity;
      state.data.business.businessConfig.storePrefecture = action.payload.config.storePrefecture;
      state.data.business.businessConfig.storeZipcode = action.payload.config.storeZipcode;
      state.data.business.businessConfig.isSameTelephone = action.payload.config.isSameTelephone;
      state.data.business.businessConfig.storeTelephone = action.payload.config.storeTelephone;
    },
    updateConfigAddressSuccess: (state: BusinessState) => undefined,
    updateConfigAddressError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    updatePaymentMethods: (state: BusinessState, action: PayloadAction<{ id: number, config: {
      canPayCash          : boolean,
      canPayCreditcard    : boolean,
      canPayQrcode        : boolean,
      canPayEmoney        : boolean,
      canPayTransportcard : boolean,
      canPayPointcard     : boolean,
    }}>) => {
      state.data.business.businessConfig.canPayCash = action.payload.config.canPayCash;
      state.data.business.businessConfig.canPayCreditcard = action.payload.config.canPayCreditcard;
      state.data.business.businessConfig.canPayQrcode = action.payload.config.canPayQrcode;
      state.data.business.businessConfig.canPayEmoney = action.payload.config.canPayEmoney;
      state.data.business.businessConfig.canPayTransportcard = action.payload.config.canPayTransportcard;
      state.data.business.businessConfig.canPayPointcard = action.payload.config.canPayPointcard;
    },
    updatePaymentMethodsSuccess: (state: BusinessState) => undefined,
    updatePaymentMethodsError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    updateExtras: (state: BusinessState, action: PayloadAction<{ id: number, websiteUrl: string, config: {
      hasWifi: boolean,
      note   : string,
    }}>) => {
      state.data.business.websiteUrl = action.payload.websiteUrl;
      state.data.business.businessConfig.hasWifi = action.payload.config.hasWifi;
      if (action.payload.config.note && !state.data.business.businessConfig.i18n) {
        state.data.business.businessConfig.i18n = {
          story: '',
          note: action.payload.config.note
        };
      }
    },
    updateExtrasSuccess: (state: BusinessState) => undefined,
    updateExtrasError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    updateBusinessSettings: (state: BusinessState, action: PayloadAction<{ id: number, settings: {
      name: string,
      zipcode: string,
      telephone: string,
      businessType: BUSINESS_TYPE,
      address: string,
      prefecture: string,
      city: string
    }}>) => {
      const {settings: { address, zipcode, telephone, businessType, prefecture, city }} = action.payload
      state.data.business.address = address
      state.data.business.zipcode = zipcode
      state.data.business.telephone = telephone
      state.data.business.businessType = businessType
      state.data.business.prefecture = prefecture
      state.data.business.city = city
    },
    updateBusinessSettingsSuccess: (state: BusinessState) => undefined,
    updateBusinessSettingsError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType }>) => undefined,
    updatePrefectureList: (state: BusinessState) => undefined,
    updateSeasonalThemes: (state: BusinessState, action: PayloadAction<{ id: number, config: {
      seasonalThemeLunar: boolean,
      seasonalThemeBlossom: boolean,
      seasonalThemeTanabata: boolean,
      seasonalThemeHalloween: boolean,
      seasonalThemeXmas: boolean,
    }}>) => {
      state.data.business.businessConfig.seasonalThemeLunar = action.payload.config.seasonalThemeLunar;
      state.data.business.businessConfig.seasonalThemeBlossom = action.payload.config.seasonalThemeBlossom;
      state.data.business.businessConfig.seasonalThemeTanabata = action.payload.config.seasonalThemeTanabata;
      state.data.business.businessConfig.seasonalThemeHalloween = action.payload.config.seasonalThemeHalloween;
      state.data.business.businessConfig.seasonalThemeXmas = action.payload.config.seasonalThemeXmas;
    },
    updateSeasonalThemesSuccess: (state: BusinessState) => undefined,
    updateSeasonalThemesError: (state: BusinessState, action: PayloadAction<{e: ErrorType | string }>) => undefined,
    fetchPublicBusiness: (state: BusinessState, action: PayloadAction<{ slug: string }>) => undefined,
    setFoundLinkableBusiness: (state: BusinessState, action: PayloadAction<{ business?: BusinessType}>) => {
      state.data.foundLinkableBusiness = action.payload.business ? action.payload.business : undefined;
    },
    addLinkedBusinesses: (state: BusinessState, action: PayloadAction<{linkedBusinessIds: number[]}>) => undefined,
    addLinkedBusinessesSuccess: (state: BusinessState) => undefined,
    addLinkedBusinessesError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    removeLinkedBusinesses: (state: BusinessState, action: PayloadAction<{linkedBusinessIds: number[]}>) => undefined,
    removeLinkedBusinessesSuccess: (state: BusinessState) => undefined,
    removeLinkedBusinessesError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
    manageLinkedBusinesses: (state: BusinessState, action: PayloadAction<{removalIds: number[], additionIds: number[]}>) => undefined,
    manageLinkedBusinessesSuccess: (state: BusinessState) => undefined,
    manageLinkedBusinessesError: (state: BusinessState, action: PayloadAction<{ error: string | ErrorType } >) => undefined,
  }
})

// Selectors
const getRoot = (state: RootState) => state.business;
const business = (state: RootState) => getRoot(state).data.business
const name = (state: RootState) => getRoot(state).data.business.name;
const estYear = (state: RootState) => getRoot(state).data.business.estYear;
const address = (state: RootState) => getRoot(state).data.business.address;
const zipCode = (state: RootState) => getRoot(state).data.business.zipcode;
const telephone = (state: RootState) => getRoot(state).data.business.telephone;
const prefecture = (state: RootState) => getRoot(state).data.business.prefecture;
const businessConfig = (state: RootState) => getRoot(state).data.business.businessConfig;
const theme = (state: RootState) => getRoot(state).data.business.businessConfig.theme;
const isDarkTheme = (state: RootState) => getRoot(state).data.business.businessConfig.theme === 'DARK';
const actionColor = (state: RootState) => getRoot(state).data.business.businessConfig.actionColor;
const backgroundColor = (state: RootState) => getRoot(state).data.business.businessConfig.backgroundColor;
const isSameAddress = (state: RootState) => getRoot(state).data.business.businessConfig.isSameAddress;
const storeAddress = (state: RootState) => getRoot(state).data.business.businessConfig.storeAddress;
const storeCity = (state: RootState) => getRoot(state).data.business.businessConfig.storeCity;
const storePrefecture = (state: RootState) => getRoot(state).data.business.businessConfig.storePrefecture;
const storeZipcode = (state: RootState) => getRoot(state).data.business.businessConfig.storeZipcode;
const isSameTelephone = (state: RootState) => getRoot(state).data.business.businessConfig.isSameTelephone;
const storeTelephone = (state: RootState) => getRoot(state).data.business.businessConfig.storeTelephone;
const hasWifi = (state: RootState) => getRoot(state).data.business.businessConfig.hasWifi;
const languages = (state: RootState) => {
  const languageList = getRoot(state).data.business.languages
  const langs = languageList.length ? [...languageList] : [{id: 'JA'}]
  const currentLangIndex = langs.findIndex(l => l.id.toLowerCase() === i18next.language.toLowerCase())
  if (currentLangIndex < 0) {
    return langs
  }
  const currentLang = langs[currentLangIndex]
  langs.splice(currentLangIndex, 1)
  langs.unshift(currentLang)
  return langs
};
const translationLanguages = (state: RootState) => getRoot(state).data.business.languages.filter(lang => lang.id !== "JA").map((lang) => lang.id)

const logoUrl = (state: RootState, size?: 500 | 250 | 150 | 75) => {
  const url = getRoot(state).data.business.businessConfig.logoUrl;
  if (url && url.length > 0) {
    return size ? `${process.env.REACT_APP_PICTURE_URL}/${url.replace(('original'), size.toString())}` : `${process.env.REACT_APP_PICTURE_URL}/${url}`
  }
  return ''
};
const logoUrlId = (state: RootState) => {
  const url = getRoot(state).data.business.businessConfig.logoUrl
  return url && url.length > 0 ? url : ''
};
const coverUrl = (state: RootState, size?: 500 | 250 | 150 | 75) => {
  const url = getRoot(state).data.business.businessConfig.coverUrl;
  if (url && url.length > 0) {
    return size ? `${process.env.REACT_APP_PICTURE_URL}/${url.replace(('original'), size.toString())}` : `${process.env.REACT_APP_PICTURE_URL}/${url}`
  }
  return '';
};
const coverUrlId = (state: RootState) => {
  const url = getRoot(state).data.business.businessConfig.coverUrl;
  return url && url.length > 0 ? url : '';
};
const storyUrl = (state: RootState, size?: 500 | 250 | 150 | 75) => {
  const url = getRoot(state).data.business.businessConfig.storyUrl;
  if (url && url.length > 0) {
    return size ? `${process.env.REACT_APP_PICTURE_URL}/${url.replace(('original'), size.toString())}` : `${process.env.REACT_APP_PICTURE_URL}/${url}`
  }
  return '';
};
const storyUrlId = (state: RootState) => {
  const url = getRoot(state).data.business.businessConfig.storyUrl;
  return url && url.length > 0 ? url : '';
};
const facebookId = (state: RootState) => getRoot(state).data.business.facebookId;
const twitterId = (state: RootState) => getRoot(state).data.business.twitterId;
const instagramId = (state: RootState) => getRoot(state).data.business.instagramId;
const websiteUrl = (state: RootState) => getRoot(state).data.business.websiteUrl;
const isPremium = (state: RootState) => {
  const searchStr = window.location.search.slice(1);
  const searchParams = new URLSearchParams(searchStr);
  const devParam = searchParams.get('dev');
  const isSimulatedStarter = typeof devParam === 'string' && devParam === 'asStarter';
  const businessConfig = getRoot(state).data.business.businessConfig
  if (isSimulatedStarter && process.env.REACT_APP_ENV !== 'production') {
    return false;
  }
  return businessConfig && businessConfig.isPremiumSubscription ? true : false
}
const location = (state: RootState) => {
  const root = getRoot(state)
  return `${root.data.business.address}, ${root.data.business.prefecture}`
}

const businessTypes  = (state: RootState) => {
  return Object.values(BUSINESS_TYPE).map((type) => {
    return {
      label: i18next.t(`common:businessTypes:${type.toLowerCase()}`),
      value: type
    }
  })
}

const listPrefectures = (state: RootState, lang: string) => {
  return prefectures.map((prefecture) => ({
    i18n: i18next.t(`common:prefectures:${prefecture}`, { lang: lang || 'ja' }),
    value: prefecture
  }))
}

const linkedBusinesses = (state: RootState) => getRoot(state).data.business.linkedBusinesses.filter(biz => biz.id !== getRoot(state).data.business.id).map((biz) => biz.id);

const foundLinkableBusiness = (state: RootState) => getRoot(state).data.foundLinkableBusiness;

const hasPendingMenuImports = (state: RootState) => {
  const root = getRoot(state)
  return root.data.business.importPending > 0
}

export const selectors = {
  business,
  businessTypes,
  name,
  estYear,
  address,
  prefecture,
  location,
  zipCode,
  telephone,
  isPremium,
  theme,
  isDarkTheme,
  actionColor,
  backgroundColor,
  isSameAddress,
  storeAddress,
  storeCity,
  storePrefecture,
  storeZipcode,
  isSameTelephone,
  storeTelephone,
  hasWifi,
  businessConfig,
  logoUrl,
  logoUrlId,
  coverUrl,
  coverUrlId,
  storyUrl,
  storyUrlId,
  facebookId,
  twitterId,
  instagramId,
  websiteUrl,
  languages,
  translationLanguages,
  listPrefectures,
  linkedBusinesses,
  foundLinkableBusiness,
  hasPendingMenuImports
}

// reducer / actions
export const { reducer, actions } = slice
