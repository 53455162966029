import React, { FC } from 'react'

interface ToolTipProps {
  className?: string,
  triggerItem: React.ReactNode,
  title: string,
  desc?: string,
}

const ToolTip: FC<ToolTipProps> = ({className = '', title, desc, triggerItem}) => {
  return (
    <div className={`tooltip ${className}`}>
      <div className="tooltip__trigger">
        {triggerItem}
      </div>
      <div className="tooltip__body">
        <h4 className="ft-700 mb-1">{title}</h4>
        <p className="h5 c-gray-600">{desc}</p>
      </div>
    </div>
  )
}

export default ToolTip