import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import Switch from 'component/Form/Inputs/Switch';
import { useForm } from 'react-hook-form';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';

interface Inputs {

}

interface ModalConfigSeasonalThemesProps {
  onRequestClose: () => void,
}

const ModalConfigSeasonalThemes: FC<ModalConfigSeasonalThemesProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails']);
  const formId = useMemo(() => 'modal-business-seasonalthemes-form', []);

  const { showStickyBanner } = useContext(StickyBannerContext);

  const business = useSelector(selectors.business.business);
  const bizCfg = business.businessConfig;

  const [seasonalThemeLunar, setSeasonalThemeLunar] = useState<boolean>(bizCfg?.seasonalThemeLunar ? true : false);
  const [seasonalThemeBlossom, setSeasonalThemeBlossom] = useState<boolean>(bizCfg?.seasonalThemeBlossom ? true : false);
  const [seasonalThemeTanabata, setSeasonalThemeTanabata] = useState<boolean>(bizCfg?.seasonalThemeTanabata ? true : false);
  const [seasonalThemeHalloween, setSeasonalThemeHalloween] = useState<boolean>(bizCfg?.seasonalThemeHalloween ? true : false);
  const [seasonalThemeXmas, setSeasonalThemeXmas] = useState<boolean>(bizCfg?.seasonalThemeXmas ? true : false);

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form) => {
    setIsLoading(true);
    dispatch(actions.business.updateSeasonalThemes({
      id: business.id,
      config: {
        seasonalThemeLunar: seasonalThemeLunar,
        seasonalThemeBlossom: seasonalThemeBlossom,
        seasonalThemeTanabata: seasonalThemeTanabata,
        seasonalThemeHalloween: seasonalThemeHalloween,
        seasonalThemeXmas: seasonalThemeXmas,
      }
    }));
  }, [dispatch, business.id, seasonalThemeLunar, seasonalThemeBlossom, seasonalThemeTanabata, seasonalThemeHalloween, seasonalThemeXmas]);

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false);
    onRequestClose();
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    showStickyBanner(BannerError);
    setIsLoading(false);
  }, [showStickyBanner])

  const onSwitchChange = useCallback((name, value) => {
    switch(name) {
      case 'seasonalThemeLunar':
        setSeasonalThemeLunar(value ? true : false);
        break;
      case 'seasonalThemeBlossom':
        setSeasonalThemeBlossom(value ? true : false);
        break;
      case 'seasonalThemeTanabata':
        setSeasonalThemeTanabata(value ? true : false);
        break;
      case 'seasonalThemeHalloween':
        setSeasonalThemeHalloween(value ? true : false);
        break;
      case 'seasonalThemeXmas':
        setSeasonalThemeXmas(value ? true : false);
        break;
      default:
        break;
    }
  }, [setSeasonalThemeLunar, setSeasonalThemeBlossom, setSeasonalThemeTanabata, setSeasonalThemeHalloween, setSeasonalThemeXmas])

  useSagaTakeEvery(actions.business.updateSeasonalThemesSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updateSeasonalThemesError, onErrorEdition)

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={onSubmit} text={t('menuDetails:ctas.save')} type="submit" form={formId} isPrimary={true} />
    </>
  ), [t, onRequestClose, onSubmit, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:seasonalThemesModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <div className="c-gray-600 mb-3">{t('menuDetails:seasonalThemesModal.desc')}</div>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <div className="mb-1">
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:seasonalThemesModal.inputLunar')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:seasonalThemesModal.${seasonalThemeLunar ? 'on' : 'off'}`)}
                name="seasonalThemeLunar"
                value={seasonalThemeLunar}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:seasonalThemesModal.inputBlossom')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:seasonalThemesModal.${seasonalThemeBlossom ? 'on' : 'off'}`)}
                name="seasonalThemeBlossom"
                value={seasonalThemeBlossom}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:seasonalThemesModal.inputTanabata')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:seasonalThemesModal.${seasonalThemeTanabata ? 'on' : 'off'}`)}
                name="seasonalThemeTanabata"
                value={seasonalThemeTanabata}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:seasonalThemesModal.inputHalloween')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:seasonalThemesModal.${seasonalThemeHalloween ? 'on' : 'off'}`)}
                name="seasonalThemeHalloween"
                value={seasonalThemeHalloween}
                handleChange={onSwitchChange}
              />
            </span>
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:seasonalThemesModal.inputXmas')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:seasonalThemesModal.${seasonalThemeXmas ? 'on' : 'off'}`)}
                name="seasonalThemeXmas"
                value={seasonalThemeXmas}
                handleChange={onSwitchChange}
              />
            </span>
          </div>
        </form>
    </LayoutModal>
  )
}

export default ModalConfigSeasonalThemes
