import React, { FC, useCallback } from 'react'

interface InputRadioProps {
  className?: string
  name?: string,
  id?: string,
  value: string,
  label: string,
  onChange?: (name: string, value: boolean) => void
  defaultChecked?: boolean,
  inputRef?: any,
}

const InputRadio: FC<InputRadioProps> = (props) => {
  const {
    value,
    className = '',
    id = '',
    defaultChecked,
    name,
    label,
    onChange,
    inputRef = null
  } = props

  const inputName = name ? name : ''

  const onChangeValue = useCallback((e) => {
    if (onChange) onChange(inputName, e.target.value)
  }, [onChange, inputName])


  return (
    <div className={`radio radio--btn is-reversed ${className ? className : ''}`}>
      <input ref={inputRef} name={inputName} type="radio" id={id} onChange={onChangeValue} value={value} defaultChecked={defaultChecked} />
      <label className="ft-up ft-600 c-gray-800" htmlFor={id}>{label}</label>
    </div>
  )
}

export default InputRadio
