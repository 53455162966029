import Button from 'component/Buttons/Button'
import DropdownActions from 'component/Buttons/DropdownActions';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { RouteUrls } from 'utils/routers/constants';
import { actions, selectors } from 'store'
import { AdminBusiness, ADMIN_BUSINESS_STATUS } from 'store/Admin';
import { MENU_STATUS } from 'store/Menus';
import { formatFullDate } from 'utils/helpers'
import ModalQRCodePDF from 'component/Modals/ModalQRCodePDF';
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation';
import ModalBusinessNote from 'component/Modals/ModalBusinessNote';
import { ModalContext } from 'utils/hooks/ModalBehavior';

interface CardBusinessProps {
  business: AdminBusiness
}

const CardBusiness: FC<CardBusinessProps> = ({ business }) => {
  const { t } = useTranslation(['common', 'admin']);
  const dispatch = useDispatch()
  const createdAt =  useMemo(() => new Date(business.createdAt), [business]);
  const activatedAt = business.activatedAt ? new Date(business.activatedAt) : null;
  const welcomeKitSentAt = business.welcomeKitSentAt ? new Date(business.welcomeKitSentAt) : null;
  const firstPublishedAt = business.firstPublishedAt ? new Date(business.firstPublishedAt) : null;
  const letterSentAt = business.letterSentAt ? new Date(business.letterSentAt) : null;
  const snsPostedAt = business.snsPostedAt ? new Date(business.snsPostedAt) : null;
  const photoshotAt = business.photoshotAt ? new Date(business.photoshotAt) : null;

  const [isResent, setIsResent] = useState(false);

  const instagramHandle = useMemo(() => {
    if (business.instagramId && business.instagramId.match(/[:?]/g)) {
      return '・・・';
    }

    return business.instagramId;
  }, [business.instagramId]);

  const facebookHandle = useMemo(() => {
    if (business.facebookId) {
      if (business.facebookId.match(/^profile\.php\?id=\d+$/g)) {
        return business.facebookId.replace('profile.php?id=', '');
      }

      if (business.facebookId.match(/[:?]/g)) {
        return '・・・';
      }

      return decodeURI(business.facebookId);
    }

    return business.facebookId;
  }, [business.facebookId]);

  const isFullAdmin = useSelector(selectors.user.isFullAdmin);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const _NOW = Date.now();
  const _DAYS_SINCE_CREATEDAT = Math.floor((_NOW - createdAt.getTime()) / _MS_PER_DAY);
  const _DAYS_SINCE_ACTIVATEDAT = activatedAt ? Math.floor((_NOW - activatedAt.getTime()) / _MS_PER_DAY) : 0;
  const _DAYS_SINCE_PUBLISHEDAT = firstPublishedAt ? Math.floor((_NOW - firstPublishedAt.getTime()) / _MS_PER_DAY) : 0;

  const activatedStatus = business.status === ADMIN_BUSINESS_STATUS.ACTIVE ? 'completed' : ((!business.isDemo && _DAYS_SINCE_CREATEDAT > 7) ? 'overdue' : 'pending');
  const welcomeKitStatus = welcomeKitSentAt ? 'completed' : ((!business.isDemo && _DAYS_SINCE_ACTIVATEDAT > 5) ? 'overdue' : 'pending');
  const publishedStatus = firstPublishedAt ? 'completed' : ((!business.isDemo && _DAYS_SINCE_ACTIVATEDAT > 10) ? 'overdue' : 'pending');
  const letterStatus = letterSentAt ? 'completed' : ((!business.isDemo && _DAYS_SINCE_PUBLISHEDAT > 10) ? 'overdue' : 'pending');
  const snsPostedStatus = snsPostedAt ? 'completed' : ((!business.isDemo && _DAYS_SINCE_PUBLISHEDAT > 31) ? 'overdue' : 'pending');
  const photoshotStatus = photoshotAt ? 'completed' : (business.isPhotoshootRequired ? 'pending' : '');

  const qrcodeURL = `${process.env.REACT_APP_DIGITO_WEBSITE}/business/${business.slug}`;

  const handleButtonClick = () => {
    dispatch(actions.admin.fetchManagedBusiness({ id: business.id }))
  }

  const fileUrl = `${process.env.REACT_APP_PICTURE_URL}/${business.qrcodeUrl}`;
  const filename = `qr_${business.slug}.png`;

  const { showModal } = useContext(ModalContext);

  const handlePdfButtonClick = useCallback( async () => {
    showModal(ModalQRCodePDF, { business: business });
  }, [business, showModal])

  const _TEXTS = useMemo(() => {return {
    'title': {
      'uncheck': 'Uncheck?',
      'sent': 'Mark as Sent?',
      'posted': 'Mark as Posted?',
      'completed': 'Mark as Completed?',
    },
    'desc': {
      'uncheck': ['Are you sure you want to delete "', '"?'],
      'sent': ['Mark "', '" as sent today?'],
      'posted': ['Mark "', '" as done today?'],
    },
    'cta': {
      'cancel': 'No, cancel',
      'uncheck': 'Yes, uncheck',
      'sent': `Yes, it's sent`,
      'posted': `Yes, it's posted`,
      'completed': `Yes, it's completed`,
    },
    'doubleConfirm': 'Confirm you want to reset this date?',
  }}, []);

  const _handleClickToggle = useCallback((title: string, desc: string, cancelCTAText: string, confirmCTAText: string, payload: any, doubleConfirmText?: string) => {
    const modalProps = {
      title,
      desc,
      cancelCTAText,
      confirmCTAText,
      doubleConfirmText: doubleConfirmText ? doubleConfirmText : null,
      onConfirm: () => {
        dispatch(actions.admin.updateAdminBusiness({ id: business.id, business: payload }))
      },
    };

    showModal(ModalDeleteConfirmation, modalProps);
  }, [showModal, dispatch, business])

  const onClickWelcomeKit = useCallback(() => {
    if (welcomeKitStatus === 'completed') {
      _handleClickToggle(
        _TEXTS.title.uncheck,
        `${_TEXTS.desc.uncheck[0]}Welcome Kit${_TEXTS.desc.uncheck[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.uncheck,
        { welcomeKitSentAt: 'NULL' },
        _TEXTS.doubleConfirm,
      );
    } else {
      _handleClickToggle(
        _TEXTS.title.sent,
        `${_TEXTS.desc.sent[0]}Welcome Kit${_TEXTS.desc.sent[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.sent,
        { welcomeKitSentAt: 'now' },
      );
    }
  }, [_handleClickToggle, welcomeKitStatus, _TEXTS])

  const onClickLetter = useCallback(() => {
    if (letterStatus === 'completed') {
      _handleClickToggle(
        _TEXTS.title.uncheck,
        `${_TEXTS.desc.uncheck[0]}SDG Letter${_TEXTS.desc.uncheck[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.uncheck,
        { letterSentAt: 'NULL' },
        _TEXTS.doubleConfirm,
      );
    } else {
      _handleClickToggle(
        _TEXTS.title.sent,
        `${_TEXTS.desc.sent[0]}SDG Letter${_TEXTS.desc.sent[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.sent,
        { letterSentAt: 'now' },
      );
    }
  }, [_handleClickToggle, letterStatus, _TEXTS])

  const onClickSNS = useCallback(() => {
    if (snsPostedStatus === 'completed') {
      _handleClickToggle(
        _TEXTS.title.uncheck,
        `${_TEXTS.desc.uncheck[0]}SNS Post${_TEXTS.desc.uncheck[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.uncheck,
        { snsPostedAt: 'NULL' },
        _TEXTS.doubleConfirm,
      );
    } else {
      _handleClickToggle(
        _TEXTS.title.posted,
        `${_TEXTS.desc.posted[0]}SNS Post${_TEXTS.desc.posted[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.posted,
        { snsPostedAt: 'now' },
      );
    }
  }, [_handleClickToggle, snsPostedStatus, _TEXTS])

  const onClickTogglePhotoshoot = useCallback(() => {
    dispatch(actions.admin.updateAdminBusiness({ id: business.id, business: {isPhotoshootRequired: !business.isPhotoshootRequired}}));
  }, [dispatch, business])

  const onClickPhotoshoot = useCallback(() => {
    if (photoshotStatus === 'completed') {
      _handleClickToggle(
        _TEXTS.title.uncheck,
        `${_TEXTS.desc.uncheck[0]}PR Photoshoot${_TEXTS.desc.uncheck[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.uncheck,
        { photoshotAt: 'NULL' },
        _TEXTS.doubleConfirm,
      );
    } else {
      _handleClickToggle(
        _TEXTS.title.completed,
        `${_TEXTS.desc.posted[0]}PR Photoshoot${_TEXTS.desc.posted[1]}`,
        _TEXTS.cta.cancel,
        _TEXTS.cta.completed,
        { photoshotAt: 'now' },
      );
    }
  }, [_handleClickToggle, photoshotStatus, _TEXTS])

  const onClickResendVerification = useCallback(() => {
    const modalProps = {
      title: 'Resend Email?',
      desc: <>Resend the email verification now?<br/><span className="c-gray-600">Usually the email will arrive within a few minutes, but can sometimes take a few hours. Please ask the user to check their junk/spam folder if they cannot find the email.</span></>,
      cancelCTAText: 'No, cancel',
      confirmCTAText: 'Yes, send',
      onConfirm: () => {
        setIsResent(true);
        dispatch(actions.admin.resendVerificationEmail({ business }));
      },
    };

    showModal(ModalDeleteConfirmation, modalProps);
  }, [showModal, dispatch, business, setIsResent])

  const onClickBusinessNote = useCallback(() => {
    showModal(ModalBusinessNote, { business });
  }, [showModal, business])

  const onClickToggleEvent = useCallback(() => {
    dispatch(actions.admin.updateAdminBusiness({ id: business.id, business: {isEvent: !business.isEvent}}));
  }, [dispatch, business])

  const onClickDemo = useCallback(() => {
    dispatch(actions.admin.updateDemoFlag({business, isDemo: !business.isDemo}));
  }, [dispatch, business])

  const _RESET_DEADLINE = useMemo(() => new Date(new Date().setDate(new Date().getDate() - 183)), []);

  const renderTimelineItem = useCallback((status: string, itemText: string, isManual: boolean, completedAt?: Date | null, ctaFunc?: any, ctaText?: string) => (
    <div className={`f-1 ${status}`}>
      <span className="f f-direction-column">
        {status === 'completed' && <span className="c-green-600 h2">{isManual
          ? (isFullAdmin && completedAt && completedAt > _RESET_DEADLINE ? <button title="Undo" onClick={ctaFunc}>☑</button> : <>☑</>)
          : <>✓</>
        }</span>}
        {status === 'pending' && <span className="c-cream-500 h2">{isManual
          ? (isFullAdmin ? <button title="Mark as done" onClick={ctaFunc}>☐</button> : <>☐</>)
          : <>⧖</>
        }</span>}
        {status === 'overdue' && <span className="c-error-500 h2">{isManual
          ? (isFullAdmin ? <button title="Mark as done" onClick={ctaFunc}>☐</button> : <>☐</>)
          : <>⧗</>
        }</span>}
        {status === '' && <span className="h2">&nbsp;</span>}
        <span className={status === '' ? 'c-gray-500' : ''}>{itemText}</span>
        {status === 'completed' && completedAt && completedAt >= createdAt && <span className="ft-secondary c-gray-600">{formatFullDate(completedAt)}</span>}
        {isFullAdmin && status !== 'completed' && ctaText && <button className="ft-tertiary c-red" onClick={ctaFunc}>{ctaText}</button>}
      </span>
    </div>
  ), [createdAt, isFullAdmin, _RESET_DEADLINE]);

  return (
    <div className={`card-business${business.isDemo ? ' card-business__demo' : ''}`} id={`slug${business.slug}`}>
      <div className="card-business__main f f-jc-space-b">
        <div className="card-business__title">
          <div className="f-inline f-ai-center mb-1">
            <h3 className="ft-600">{business.name}</h3>
            <span className="f-inline f-ai-center ml-2">
              <span>
                {t(`common:businessTypes:${business.businessType.toLowerCase()}`)}
              </span>
            </span>
            {!!business.isDemo && <span className={`label label--light label--orange ml-1`}>DEMO</span>}
            {!!business.isEvent && <span className={`label label--light label--green ml-1`}>EVENT</span>}
          </div>
          <div className="card-business__info c-gray-600 f f-direction-column f-ai-start mb-1">
            <span className="f-inline f-ai-center">
              <span className="icon icon-pin mr-2" />
              <span><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`〒${business.zipcode} ${t(`common:prefectures.${business.prefecture}`, {lng: 'ja'})}${business.city}${business.address}`)}`} target="_blank" rel="noreferrer">{ `〒${business.zipcode} ${t(`common:prefectures.${business.prefecture}`, {lng: 'ja'})}、${business.city}、${business.address}` }</a></span>
            </span>
            {business.telephone && <span className="f-inline f-ai-center">
              <span className="icon icon-phone mr-2" />
              <span><a href={`tel:${business.telephone}`}>{`${business.telephone}`}</a></span>
            </span>}
          </div>
          <div className="c-gray-600 f mb-1">
            {business.instagramId && <span className="f-inline f-ai-center">
              <i className="icon icon-insta mr-2"></i>
              <span><a href={business.instagramId.match(/:\/\//g) ? business.instagramId : `https://www.instagram.com/${business.instagramId}`} target="_blank" rel="noreferrer">{ instagramHandle }</a></span>
            </span>}
            {business.facebookId && <span className={`f-inline f-ai-center${business.instagramId ? ' ml-2': ''}`}>
              <i className="icon icon-facebook mr-2"></i>
              <span><a href={business.facebookId.match(/:\/\//g) ? business.facebookId : `https://www.facebook.com/${business.facebookId}`} target="_blank" rel="noreferrer">{ facebookHandle }</a></span>
            </span>}
          </div>
          {business.users && (
          <div className="c-gray-600">
            {business.users.map((user, i) => (<div key={i}><span><span className="icon icon-user mr-2" />{user.firstname} {user.lastname}</span><br/><span><span className="icon icon-mail mr-2" />{user.email}</span></div>))}
          </div>
          )}
          {business.menus && business.menus.length > 0 && (
          <div className="c-gray-600">
            <p className="mt-1 mb-0">{t('admin:menuCreation')}:</p>
            <ul className="list">
              {business.menus.map((menu, i) => {
                return (
                  <li key={i} className={`${menu.status === MENU_STATUS.VISIBLE ? 'list-menu-visible' : 'list-menu-invisible'}`}>
                    <span>{menu.name} on </span>
                    <span>{formatFullDate(new Date(menu.createdAt))}</span>
                  </li>
                )
              })}
            </ul>
          </div>
          )}
          <div className="c-gray-600">
            <a className="card-plan__cta card-business__link ft-500" href={qrcodeURL} target="_new" rel="noopener">
              <span className="icon icon-new-tab mr-2" title={qrcodeURL} /><span className="card-business__linktext">{qrcodeURL}</span>
            </a>
          </div>
        </div>
        <div className="card-business__cell">
          <span className={`label label--light ${ business.status === ADMIN_BUSINESS_STATUS.ACTIVE ? 'label--green' : 'label--red' }`} >
            {business.status === ADMIN_BUSINESS_STATUS.ACTIVE
              ? t('admin:businessStatus.active')
              : t('admin:businessStatus.inactive')}
          </span>

          <span className="f f-direction-column f-ai-center">
            {business.businessConfig.isPremiumSubscription ? (
              <span className={`label label--purple`}>PREMIUM</span>
            ) : null}
            {business.freemiumCode ? (
              <span className={`label label--grey mt-1`}>FREEMIUM</span>
            ) : null}
          </span>
        </div>

        <div className="card-business__right">
          {isFullAdmin && <Button
            isPrimary={true}
            onClick={handleButtonClick}
            text={t('admin:takeControl')}
          />}
        </div>
        <div className="card-business__farright">
          <div className="f f-direction-column f-ai-end">
            <DropdownActions iconClassName="icon-more icon-24 c-gray-800">
              <li>
                <a className="ft-700 ft-left" href={fileUrl} target="_blank" rel="noreferrer"><span className="icon icon-qrcode-landing icon-20 c-gray-500 mr-2"></span>View QR code</a>
              </li>
              <li>
                <a className="ft-700 ft-left" href={`${fileUrl}?response-content-disposition=attachment;%20${filename}`} target="_blank" rel="noreferrer" download={filename}><span className="icon icon-download icon-20 c-gray-500 mr-2"></span>Download QR code</a>
              </li>
              <li>
                <button className="ft-700 ft-left" onClick={handlePdfButtonClick}><span className="icon icon-pdf icon-20 c-gray-500 mr-2"></span>Labels PDF</button>
              </li>
              <hr />
              {isFullAdmin && (activatedStatus !== 'completed') && !isResent && <li>
                <button className="ft-700 ft-left" onClick={onClickResendVerification}><span className="icon icon-mail icon-20 c-gray-500 mr-2"></span>Resend Verification</button>
              </li>}
              {isFullAdmin && <li>
                <button className="ft-700 ft-left" onClick={onClickBusinessNote}><span className="icon icon-menu icon-20 c-gray-500 mr-2"></span>Business Note</button>
              </li>}
              {isFullAdmin && (photoshotStatus !== 'completed') && <li>
                <button className="ft-700 ft-left" onClick={onClickTogglePhotoshoot}><span className="icon icon-photo icon-20 c-gray-500 mr-2"></span>{`${photoshotStatus === '' ? 'Add' : 'Remove'}`} Photoshoot</button>
              </li>}
              {isFullAdmin && <li>
                <button className="ft-700 ft-left" onClick={onClickToggleEvent}><span className={`icon icon-${!business.isEvent ? 'map' : 'store'} icon-20 c-gray-500 mr-2`}></span>{`${!business.isEvent ? 'Mark' : 'Unmark'}`} as Event</button>
              </li>}
              {isFullAdmin && <li>
                <button className="ft-700 ft-left" onClick={onClickDemo}><span className={`icon icon-business${!business.isDemo ? '-disabled' : ''} icon-20 c-gray-500 mr-2`}></span>{`${!business.isDemo ? 'Mark' : 'Unmark'}`} as Demo</button>
              </li>}
            </DropdownActions>
            <div className="mt-2 ft-right">
              <Link to={{pathname: RouteUrls.Statistics, state: {business}}}><span className={`icon icon-stats-bars icon-24 c-gray-${business.businessStatistics ? '400' : '200'}`} title="Statistics"></span></Link>
              {business.businessStatistics && <div className="ft-tertiary c-gray-600">{(business.businessStatistics.directs + business.businessStatistics.referrals)}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="f f-jc-space-b">
        {business.businessNote && <div className="f ft-left ml-3 mr-3 mb-2 c-gray-600 f-1">
          <span className="icon icon-menu icon-16 ml-2"></span><p className="ml-2 mr-1">{business.businessNote.split("\n").map((item, key) => { return (item && <span key={key}>{key > 0 && <br />}{item}</span>); })}</p>
        </div>}
        {!business.businessNote && business.referrer && <div className="f-1 ml-3 mr-3"></div>}
        {business.referrer && <div className="f ft-left ml-2 mr-3 mb-2 c-gray-600 f-1">
          <span className="icon-16 ml-1">↪</span><p className="ml-2 mr-1">Ref: { business.referrer }</p>
        </div>}
      </div>
      <div className="card-business__timeline f ft-center">
        {renderTimelineItem(
          'completed',
          'Account Creation',
          false,
          createdAt
        )}
        {renderTimelineItem(
          activatedStatus,
          'Activation',
          false,
          activatedAt,
          onClickResendVerification,
          !isResent ? 'Resend Verification' : undefined
        )}
        {renderTimelineItem(
          welcomeKitStatus,
          'Welcome Kit',
          true,
          welcomeKitSentAt,
          onClickWelcomeKit
        )}
        {renderTimelineItem(
          publishedStatus,
          'Menu Publication',
          false,
          firstPublishedAt
        )}
        {renderTimelineItem(
          letterStatus,
          'SDG Letter',
          true,
          letterSentAt,
          onClickLetter
        )}
        {renderTimelineItem(
          snsPostedStatus,
          'SNS Post',
          true,
          snsPostedAt,
          onClickSNS
        )}
        {renderTimelineItem(
          photoshotStatus,
          'PR Photoshoot',
          true,
          photoshotAt,
          onClickPhotoshoot
        )}
      </div>
    </div>
  )
}

export default CardBusiness
