import Button from 'component/Buttons/Button'
import InputText from 'component/Form/Inputs/InputText'
import TextArea from 'component/Form/Inputs/TextArea'
import TopBar from 'component/Headers/TopBar'
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import ToolTip from 'component/ToolTip'
import i18next from 'i18next'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { useTypedSelector } from 'store/store'
import { getError, getSymbolFromCurrency, validationPatterns } from 'utils/helpers'
import { RouteUrls } from 'utils/routers/constants'

const AddProduct: FC = (props: any) => {
  const dispatch  = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['common', 'onboarding'])

  const backUrl = RouteUrls.AddCategory
  const maxNbProducts = 3

  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 4, 'product'))

  const products = useSelector(selectors.onboarding.products)
  const [nbProducts, setNbProducts] = useState(products.length)
  const [defaultValues, setDefaultValues] = useState(products)

  const renderedProducts = useMemo(() => [...new Array(nbProducts)].map(() => 'category'), [nbProducts])

  const { handleSubmit, register, setValue, clearErrors, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    i18next.on('languageChanged', () => {
      clearErrors()
    })
  }, [clearErrors])

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  const onChangeInput = useCallback(() => {
    clearErrors()
  }, [clearErrors])

  const onClickAddProduct = useCallback(() => {
    setNbProducts(prevState => prevState < maxNbProducts ? prevState + 1 : prevState)
  }, [])
  
  const onSubmit = useCallback((data) => {
    const products: {name: string, description: string, price: number}[] = Object.values(data.products)
    dispatch(actions.onboarding.triggerSetProducts({ products: products }))
    history.push(RouteUrls.Register)
  }, [dispatch, history])

  const onClickDeleteProduct = useCallback((key) => {
    setNbProducts(prevState => prevState - 1)
    const currentValues = Object.values(getValues().products) as {name: string, description: string, price: number}[]
    const newValues = currentValues
    for (let i = key; i < currentValues.length; i++) {
      if (i >= key && currentValues.length > 1) {
        const nextIndex = i + 1;
        if (nextIndex < currentValues.length) {
          const nextElement = currentValues[i + 1];
          newValues[i] = { ...nextElement }
        } else {
          newValues[i] = { name: '', description: '', price: -1 }
        }
      } else if (currentValues.length === 1) {
        newValues[i] = { name: '', description: '', price: -1}
      }
      setValue(`products[${key}].name`, newValues[i].name)
      setValue(`products[${key}].description`, newValues[i].description)
      setValue(`products[${key}].price`, newValues[i].price >= 0 ? newValues[i].price : '')
    }
    setDefaultValues(newValues)
  }, [getValues, setValue]);

  const onClickSkip = useCallback(() => {
    history.push(RouteUrls.Register)
  }, [history])

  useEffect(() => {
    setNbProducts((prevState) => !prevState ? 1  : prevState)
  }, [nbProducts])

  const renderProductForm = useCallback((index: number) => (
    <div className="mb-3" key={index}>
      <div className="mosaic mosaic--2 mosaic--gap-8 mb-1">
        <div className="f-1">
          <InputText 
            maxLength={255}
            defaultValue={defaultValues[index] ? defaultValues[index].name : ''} 
            className="input--material mb-1 ft-left" 
            label={t('onboarding:addProduct:inputProductName')} 
            placeholder={t('onboarding:addProduct:inputProductName')} 
            name={`products[${index}].name`}
            handleChange={onChangeInput}
            error={errors?.products?.[index]?.name}
            inputRef={(
              register({
                required: {
                  value: true,
                  message: getError('name')
                },
                pattern: {
                  value: validationPatterns.notEmptyString,
                  message: getError('name')
                }
              }))} 
            />
          <InputText
            defaultValue={defaultValues[index] && defaultValues[index].price >= 0 ? defaultValues[index].price.toString() : ''}
            className="price input--material ft-left"
            placeholder={t('onboarding:addProduct:inputProductPrice')}
            label={t('onboarding:addProduct:inputProductPrice')}
            name={`products[${index}].price`}
            inputRef={register({
              required: {
                value: true,
                message: getError('price')
              },
              pattern: {
                value:  validationPatterns.price,
                message: getError('priceType')
              }
            })}
            handleChange={onChangeInput}
            error={errors?.products?.[index]?.price}
            right={getSymbolFromCurrency('JPA')}
            />
        </div>
        <div className="f-1">
          <TextArea 
            className="textarea--full ft-left"
            defaultValue={defaultValues[index] ? defaultValues[index].description : ''} 
            onChange={onChangeInput} 
            label={t('onboarding:addProduct:inputProductDescription:title')} 
            name={`products[${index}].description`}
            error={errors?.products?.[index]?.description}
            textAreaRef={register({
              required: {
                value: true,
                message: getError('description')
              },
              pattern: {
                value: validationPatterns.notEmptyString,
                message: getError('description')
              }
            })} />
        </div>
      </div>
      <div className="ft-right mb-2">
        <button type="button" className="ft-700 c-primary" onClick={() => onClickDeleteProduct(index)}>{t('common:upload.delete')}</button>
      </div>
      <hr/>
    </div>
  ), [t, register, onChangeInput, onClickDeleteProduct, defaultValues, errors])

  return (
    <LayoutSideBar pageName="add-product">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__content--form-onboarding">
        <div className="page__wrapper">
          <div className="ft-center">
            <div>
              <h1 className="ft-900 mt-3 mb-2 f-inline">
                {t('onboarding:addProduct:title')} 
                <ToolTip className="tooltip--left" title={t('onboarding:addProduct:tooltip:title')} desc={t('onboarding:addProduct:tooltip:desc')} triggerItem={<button className="icon icon-tooltip c-cream-500 icon-20 tooltip__trigger" />} />
              </h1>
              <p className="h4 mb-4 c-gray-700">{t('onboarding:addProduct:description')}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderedProducts.map((product, index) => renderProductForm(index))}
              {(nbProducts < maxNbProducts) && <Button iconLeft="icon-plus c-cream-500" onClick={onClickAddProduct} type="button" text={t('onboarding:addProduct:add')} />}
              <div className="ft-center">
                <Button className="mt-5 mr-2" type="button" text={t('onboarding:addProduct:skip')} onClick={onClickSkip} /> 
                <Button className="mt-5" isPrimary={true} type="submit" text={t('onboarding:addProduct:next')} /> 
              </div>
            </form>
          </div>
        </div>
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default AddProduct
