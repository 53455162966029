import { call, takeLatest, all, SagaReturnType, put, takeEvery, take } from 'redux-saga/effects';
import { actions } from 'store';
import { MENU_STATUS } from '.';
import { getMenus, postMenu, patchMenu, deleteMenu, patchMenuStatus, postWelcomeKitClaim } from './services';

export function* fetchMenus(action: ReturnType<typeof actions.menus.fetchMenus>) {
  try {
    yield put(actions.menus.setIsLoadingDatasList({isLoading: true}))
    const request: SagaReturnType<typeof getMenus> = yield call(getMenus)
    yield put(actions.menus.setIsLoadingDatasList({isLoading: false}))
    yield put(actions.menus.setMenus({menus: request.menus}))
  } catch(e) {
    yield put(actions.menus.setIsLoadingDatasList({isLoading: false}))
  }
}

export function* createMenu(action: ReturnType<typeof actions.menus.createMenu>) {
  try {
    yield call(postMenu, action.payload.menu)
    yield put(actions.menus.createMenuSuccess())
    yield put(actions.menus.fetchMenus())
  } catch(e: any) {
    yield put(actions.menus.createMenuError({error: e}))
  }
}

export function* updateMenu(action: ReturnType<typeof actions.menus.updateMenu>) {
  try {
    yield call(patchMenu, action.payload.menu, action.payload.id)
    yield put(actions.menus.updateMenuSuccess())
    yield put(actions.menus.fetchMenus())
  } catch(e: any) {
    yield put(actions.menus.updateMenuError({error: e}))
  }
}

export function* updateMenuStatus(action: ReturnType<typeof actions.menus.updateMenuStatus>) {
  try {
    if (action.payload.status === MENU_STATUS.VISIBLE) {
      yield put(actions.menuDetails.saveChanges())
      yield take(actions.menuDetails.saveChangesSuccess)
    }

    yield call(patchMenuStatus, action.payload.id, action.payload.status)
    yield put(actions.menus.updateMenuStatusSuccess())
    yield put(actions.menus.fetchMenus())
  } catch(e: any) {
    yield put(actions.menus.updateMenuStatusError(e))
  }
}

export function* removeMenu(action: ReturnType<typeof actions.menus.removeMenu>) {
  try {
    yield call(deleteMenu, action.payload.id)
    yield put(actions.menus.fetchMenus())
  } catch(e: any) {
  }
}

export function* claimWelcomeKit(action: ReturnType<typeof actions.menus.claimWelcomeKit>) {
  try {
    yield call(postWelcomeKitClaim, action.payload.shippingAddress)
    yield put(actions.menus.claimWelcomeKitSuccess())
  } catch (e: any) {
    yield put(actions.menus.claimWelcomeKitError(e))
  }
}

export function* root() {
  yield all([
    takeEvery(actions.menus.fetchMenus, fetchMenus),
    takeLatest(actions.menus.createMenu, createMenu),
    takeLatest(actions.menus.updateMenu, updateMenu),
    takeLatest(actions.menus.removeMenu, removeMenu),
    takeLatest(actions.menus.updateMenuStatus, updateMenuStatus),
    takeLatest(actions.menus.claimWelcomeKit, claimWelcomeKit)
  ])
}
