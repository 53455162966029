import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MenuPreviewListCategory from './MenuPreviewListCategory';
import MenuPreviewHorizontalListCategory from './MenuPreviewHorizontalListCategory';
import { MenuType } from 'store/Menus';
import { CategoryType } from 'store/Categories';
import { selectors } from 'store';
import LanguagePicker from 'component/LanguagePicker';
import { useTranslation } from 'react-i18next';

interface MenuPreviewProps {
  menu: MenuType,
  schedule: string,
  categories: CategoryType[],
  isPremium: boolean,
  currency: string,
  business: {
    logoUrl?: string,
    coverUrl?: string,
    instagramUrl?: string,
    twitterUrl?: string,
    facebookUrl?: string,
    name: string,
    languages: {id: string}[]
  },
  defaultSelects: {
    lang: string
  }
  config: {
    theme: string,
  }
}

const MenuPreview: FC<MenuPreviewProps> = ({business, categories, isPremium, menu, schedule, currency, defaultSelects, config }) => {
  const { t } = useTranslation(['common'])
  const languageOptions = useMemo(() => business.languages.map((lang) => {
    const langValue = lang.id.toLowerCase()
    return {label: t(`common:shortLanguages:${langValue}`), value: langValue, icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${langValue}.png`}  alt="" />}
  }), [t, business.languages])

  const coverUrl = useSelector(selectors.business.coverUrl);

  const defaultLanguage = useMemo(() => languageOptions.find((lang) => lang.value.toLowerCase() === defaultSelects.lang.toLowerCase() ), [languageOptions, defaultSelects.lang])

  return (
    <div className="menu" data-theme={`${config.theme === 'DARK' ? 'dark' : ''}`}>
      <div className="menu-header">
        <div className="menu-header__content">
          {coverUrl && <img className="menu-header__cover" src={coverUrl} alt=""></img>}
          <div className="menu-header__logo">{business.logoUrl && <img src={business.logoUrl} alt="" />}</div>
          <h1 className="ft-center mt-2">{business.name}</h1>
          <div className="f mt-2">
            {business.facebookUrl && <a href={business.facebookUrl} title="Facebook" className="menu__icon menu-header__icon"><span className="icon icon-facebook icon-24 c-gray-200" /></a>}
            {business.twitterUrl && <a href={business.twitterUrl} title="Twitter" className="menu__icon menu-header__icon"><span className="icon icon-twitter icon-24 c-gray-200" /></a>}
            {business.instagramUrl && <a href={business.instagramUrl} title="Instagram" className="menu__icon menu-header__icon"><span className="icon icon-insta-fill icon-24 c-gray-200" /></a>}
          </div>
        </div>
        <div className="menu-info">
          <span className="f">
            <span className="f-1"></span>
            <span className="tag mt-2 mb-2 expand-btn">{t(`common:preview.expandBtn`, {lng: (defaultLanguage && isPremium) ? defaultLanguage.value : 'ja'})}</span>
            {!isPremium && <span className="tag mt-2 mb-2 expand-btn">{t(`common:preview.expandBtn`, {lng: 'en'})}</span>}
            <span className="f-1"></span>
          </span>
        </div>
      </div>
      <div className="menu-settings">
      <div className="ft-900 f f-jc-space-b f-center menu-settings__dropdown ft-truncat">{menu.name} <span className="icon icon-arrow-down c-gray-500 ml-2" /></div>
        <div className="f f-ai-start">
          {isPremium && <button className="menu-settings__dropdown menu__select menu__select--menus ft-900">{currency} <span className="icon icon-arrow-down c-gray-500"/></button>}
          <LanguagePicker
            value={isPremium ? defaultLanguage : { value: 'ja', label: 'ja' }}
            className="language-picker--small"
            simulateMobile={true}
            options={languageOptions}
            defaultOption={defaultLanguage}
            onChangeLang={() => {}}
          />
        </div>
      </div>
      <MenuPreviewHorizontalListCategory categories={categories} isPremium={isPremium} />
      <MenuPreviewListCategory categories={categories} isPremium={isPremium} previewLang={`${defaultLanguage ? defaultLanguage.value : 'ja'}`} />
      <div className="menu-footer container">
        <div className="menu-footer__top">
          <div>
            <p className="ft-tertiary">{t(`common:preview.footer.generatedBy`, {lng: defaultLanguage ? defaultLanguage.value : 'ja'})}</p>
            <img src={config.theme === 'DARK' ? '/static/images/logo-white.svg' : '/static/images/logo.svg'} alt={""} />
          </div>
          <div className="f">
            <a href="/" title="Facebook" className="menu__icon menu-footer__icon"><span className="icon icon-facebook icon-24 c-gray-200" /></a>
            <a href="/" title="Instagram" className="menu__icon menu-footer__icon"><span className="icon icon-insta-fill icon-24 c-gray-200" /></a>
          </div>
        </div>
        <div className=" mt-3 ft-center">
          <span className="ft-tertiary ft-center">{t(`common:preview.footer.contact.title`, {lng: defaultLanguage ? defaultLanguage.value : 'ja'})}<a href="/" className="ft-underline c-gray-500">{t(`common:preview.footer.contact.cta`, {lng: defaultLanguage ? defaultLanguage.value : 'ja'})}</a></span>
        </div>
      </div>
    </div>
  )
}

export default MenuPreview;
