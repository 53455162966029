import DropdownActions from 'component/Buttons/DropdownActions'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import ModalSubcategoryEdition from 'component/Modals/ModalSubcategoryEdition'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { SubcategoryType } from 'store/Categories'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface CardSubcategoryProps {
  categoryName?: string,
  subcategory: SubcategoryType,
  hasActions?: boolean
}

const CardSubcategory: FC<CardSubcategoryProps> = ({ subcategory, categoryName }) => {
  const { t } = useTranslation(['menus'])
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)

  const deleteCategory = useCallback(() => {
    dispatch(actions.categories.removeCategory({ id: subcategory.id }))
  }, [subcategory, dispatch])

  const onDuplicateSubcategory = useCallback(() => {
    dispatch(actions.categories.createCategory({ category: {
      name: `${subcategory.i18n?.name} (${t('menus:copy')})`,
      description: '',
      parentCategoryId: subcategory.parentCategoryId!,
    } }))
  }, [subcategory, dispatch, t])

  const modalDeleteConfirmationProps = useMemo(() => ({
    title: t('menus:subcategoryDeleteConfirmationModal:title', {
      categoryName: subcategory.i18n?.name
    }),
    desc: t('menus:subcategoryDeleteConfirmationModal:desc'),
    cancelCTAText: t('menus:subcategoryDeleteConfirmationModal:cancelCTA'),
    confirmCTAText: t('menus:subcategoryDeleteConfirmationModal:confirmCTA'),
    onConfirm: deleteCategory,
  }), [deleteCategory, t, subcategory])

  return (
    <div className="card-subcategory">
      <div className="card-menu__title">
        <h3 className="ft-600">{subcategory.i18n?.name}</h3>
      </div>
      <DropdownActions iconClassName="icon-more icon-24 c-gray-800">
        <li>
          <button className="ft-600" onClick={() => showModal(ModalSubcategoryEdition, { subcategory, categoryId: subcategory.parentCategoryId, categoryName })}><span className="icon icon-edit mr-2 icon-16 c-cream-500"></span>{t('menus:subcategory:edit')}</button>
        </li>
        <li style={{display: 'none'}}><button className="c-gray-800 ft-600" onClick={onDuplicateSubcategory}>{t('menus:subcategory:duplicate')}</button></li>
        <li>
          <button className="c-red ft-600" onClick={() => showModal(ModalDeleteConfirmation, { ...modalDeleteConfirmationProps })}><span className="icon icon-trash mr-2 icon-16"></span>{t('menus:subcategory:delete')}</button>
        </li>
      </DropdownActions>
    </div>
  )
}

export default CardSubcategory
