import { all } from 'redux-saga/effects';
import { root as appSagaRoot } from './App/saga';
import { root as userSagaRoot } from './User/saga';
import { root as menusSagaRoot } from './Menus/saga';
import { root as businessSagaRoot } from './Business/saga';
import { root as categoriesSagaRoot } from './Categories/saga';
import { root as menuDetailsSagaRoot } from './MenuDetails/saga';
import { root as productsSagaRoot } from './Products/saga';
import { root as languagesSagaRoot } from './Languages/saga';
import { root as translationsSagaRoot } from './Translations/saga';
import { root as onboardingSagaRoot } from './Onboarding/saga';
import {root as adminSagaRoot} from './Admin/saga'
export default function* rootSaga() {
  yield all([
    menusSagaRoot(),
    menuDetailsSagaRoot(),
    businessSagaRoot(),
    userSagaRoot(),
    categoriesSagaRoot(),
    productsSagaRoot(),
    languagesSagaRoot(),
    translationsSagaRoot(),
    onboardingSagaRoot(),
    appSagaRoot(),
    adminSagaRoot()
  ])
}
