import React, { FC } from 'react';
import { LayoutModalRight } from 'component/Layouts/LayoutModalRight';
import { useTranslation } from 'react-i18next';
import Button from 'component/Buttons/Button';

interface ModalOrderDisplayKitProps {
  onRequestClose: () => void
}

const ModalOrderDisplayKit: FC<ModalOrderDisplayKitProps> = ({onRequestClose}) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <LayoutModalRight
      onRequestClose={onRequestClose}
      className="modal--right modal--qrcode"
      title={t('orderDisplayKit.title')}
    >
      <div className="modal__container">
        <div className="modal__extra-wrapper">
          <img className="mt-4 mb-2" src="static/images/stickers.jpg" alt="Stickers with QR code on it" />
          <h3 className="mb-2">{t('orderDisplayKit.heading')}</h3>
          <p className="ft-primary mb-3 c-gray-600 ft-500">{t('orderDisplayKit.description')}</p>
          <p className="ft-primary mb-3 c-gray-600 ft-500">{t('orderDisplayKit.description2')}</p>
          <ul className="ft-primary c-gray-700">
            <li className="mb-1"><span className="icon icon-check c-green-600 mr-1" /><span dangerouslySetInnerHTML={{__html: t('orderDisplayKit.list.item_1')}}></span></li>
            <li className="mb-1"><span className="icon icon-check c-green-600 mr-1" /><span dangerouslySetInnerHTML={{__html: t('orderDisplayKit.list.item_2')}}></span></li>
            <li className="mb-1"><span className="icon icon-check c-green-600 mr-1" /><span dangerouslySetInnerHTML={{__html: t('orderDisplayKit.list.item_3')}}></span></li>
            <li className="mb-1"><span className="icon icon-check c-green-600 mr-1" /><span dangerouslySetInnerHTML={{__html: t('orderDisplayKit.list.item_4')}}></span></li>
          </ul>
          <Button className="mt-2 mb-2" text={t('orderDisplayKit.button')} isPrimary={true} isExternal={true} link={`https://www.cognitoforms.com/DIGITOMENU2/DIGITO%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%82%AD%E3%83%83%E3%83%88%E6%B3%A8%E6%96%87%E6%9B%B82`} />
        </div>
      </div>
    </LayoutModalRight>
  )
}

export default ModalOrderDisplayKit
