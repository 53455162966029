import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'store'
import PremiumTag from 'component/PremiumTag'

interface InputCheckListProps {
  isPremiumOnly?: boolean
  onClickPremium?: () => void
  defaultValue?: {id: number}[]
  className?: string
  name?: string
  label?: string
  error?: any
  boxes: {id: number, i18n: {name: string}}[]
  onChange?: (value: {id: number}[]) => void,
}

const InputCheckList: FC<InputCheckListProps> = ({ className, label, error, isPremiumOnly= false, onClickPremium, boxes, onChange, defaultValue }) => {
  const isBusinessPremium = useSelector(selectors.business.isPremium)
  const isNotAllowed = useMemo(() => isPremiumOnly && !isBusinessPremium, [isPremiumOnly, isBusinessPremium])
  const [activeTag, setActiveTag] = useState<{ id: number }[] | []>(defaultValue ? defaultValue : [])

  const onClickCheckboxes = useCallback(() => {
    if (isNotAllowed) {
      if(onClickPremium) onClickPremium();
      return;
    }
  }, [isNotAllowed, onClickPremium])

  const classes = `${className ? className : ''}`
  const statusClass = `${ isNotAllowed ? 'disabled' : ''}`

  const inputRef = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    if (onChange) onChange(activeTag)
  }, [activeTag, onChange])

  useEffect(() => {
    if (defaultValue) {
      const dietInputs: any[] = inputRef.current
      const dietOptions = dietInputs.map(dietInput => parseInt(dietInput.value))

      defaultValue.map(activeDietId => {
        const activeTagIndex = dietOptions.indexOf(activeDietId.id)
        if (activeTagIndex > -1) {
          dietInputs[activeTagIndex].checked = true
          return dietInputs[activeTagIndex].parentNode?.classList.add('is-active')
        }
        else {
          dietInputs[activeTagIndex].checked = false
          return dietInputs[activeTagIndex].parentNode?.classList.remove('is-active')
        }
      })
    }
  }, [defaultValue])

  const toggleTagStatus = (e: any) => {
    const tagElement = e.target.parentNode
    const value = parseInt(e.target.value)

    if (e.target.checked) {
      tagElement.classList.add('is-active')
      setActiveTag(activeTag => [...activeTag, {id: value}])
    } else {
      tagElement.classList.remove('is-active')
      setActiveTag(activeTag.filter(tag => tag.id !== value))
    }
  }

  return (
    <div className={`input-checklist ${classes} ${statusClass}`}>
      <div className="input-checklist__label">
        {label}
        {isNotAllowed && <PremiumTag />}
      </div>
      <div className="input-checklist__checkboxes" onClick={onClickCheckboxes}>
        {boxes.map((box, index) => (
          <div key={index}>
            <label className="tag">
              <input type="checkbox" value={box.id} onChange={e => toggleTagStatus(e)} ref={(element) => element ? inputRef.current[index] = element : null} />
              <div>{box.i18n.name}</div>
            </label>
          </div>
        ))}
      </div>
      {error && error.message && (
        <div className="input__error mb-1">
          {error.message}
        </div>
      )}
    </div>
  )
}

export default InputCheckList