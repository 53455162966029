import SideBarDashboard from 'component/Headers/SideBarDashboard'
import SideBar from 'component/Headers/SideBar'
import React, { FC } from 'react'

interface LayoutSideBarProps {
  pageName: string,
  type?: 'dashboard' | 'onboarding',
  children: React.ReactNode
}

const LayoutSideBar: FC<LayoutSideBarProps> = ({type = 'onboarding', pageName, children}) => {
  return (
    <main className={`page ${pageName ? `page--${pageName}` : ''}`}>
      { type === 'dashboard' ? <SideBarDashboard /> : <SideBar/>}
      <div className="page__inner">
        {children}
      </div>
    </main>
  )
}

export default LayoutSideBar