import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { useTranslation } from 'react-i18next'
import Button from 'component/Buttons/Button'
import InputSelect from 'component/Form/Inputs/InputSelect'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import ModalCategoryEdition from '../ModalCategoryEdition'
import ModalSubcategoryEdition from '../ModalSubcategoryEdition'
import { useTypedSelector } from 'store/store'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'

interface ModalMenuCategoryEditionProps {
  onRequestClose: () => void,
  type: 'category' | 'subcategory',
  menuId?: number,
  selectedCategoryId?: number,
  parentCategoryName: string,
  parentCategoryId?: number,
}

const ModalMenuCategoryEdition: FC<ModalMenuCategoryEditionProps> = ({onRequestClose, menuId, selectedCategoryId, type = 'category', parentCategoryId, parentCategoryName}) => {
  const { t } = useTranslation(['menuDetails']);
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)
  const { showStickyBanner } = useContext(StickyBannerContext)

  const selectedCategoriesIds = useSelector(selectors.menuDetails.selectedCategoriesIds)
  const selectedSubcategoriesIds = useSelector(selectors.menuDetails.selectedSubcategoriesIds)

  const categories = useTypedSelector(state => selectors.categories.unselectedCategories(state, selectedCategoriesIds))
  const subCategories = useTypedSelector(state => selectors.categories.unselectedSubCategories(state, selectedSubcategoriesIds, parentCategoryId))

  const isSubcategory = useMemo(() => parentCategoryId && parentCategoryName && parentCategoryName.length,[parentCategoryId, parentCategoryName])

  const selectCategories = type === 'category' ? categories : subCategories
  const categoryOptions = useMemo(() => selectCategories.map((category: any) => ({
    label: ('linkedBusiness' in category && category.linkedBusiness && category.linkedBusiness.name) ? category.linkedBusiness.name : category.i18n?.name,
    value: category.id.toString()
  })), [selectCategories]);

  const [categoryId, setCategoryId] = useState(selectedCategoryId ? selectedCategoryId : -1)
  const defaultCategoryOption = useMemo(() => categoryOptions.find(category => selectedCategoryId && category.value === selectedCategoryId.toString()), [selectedCategoryId, categoryOptions])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<{message: string} | null>(null)

  const onSaveCategory = useCallback(() => {
    if (categoryId < 0 || !menuId) {
      setError({message: t('menuDetails:menuCategoryEditionModal:error:category')})
      return;
    }
    setIsLoading(true)
    dispatch(actions.menuDetails.triggerAddCategory({id: menuId, categoryId, parentCategoryId}))
  }, [dispatch, categoryId, parentCategoryId, menuId, t])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading}  onClick={onRequestClose} text={t('menuDetails:menuCategoryEditionModal:cancel')} />
      <Button isDisabled={isLoading} isLoading={isLoading} onClick={onSaveCategory} type="submit" text={t('menuDetails:menuCategoryEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, onSaveCategory, isLoading])

  const onChangeSelect = useCallback((label, value) => {
    setError(null)
    setCategoryId(parseInt(value))
  }, [])

  const onSuccessEdition = useCallback((action) => {
    if (action.payload.categoryId >= 0) {
      dispatch(actions.menuDetails.removeUntoggledCategory({ id: action.payload.categoryId }))
    }

    setIsLoading(false)
    onRequestClose()
  }, [dispatch, onRequestClose])

  const onErrorEdtion = useCallback(() => {
    showStickyBanner(StickyBannerContext)
    setIsLoading(false)
  }, [showStickyBanner])

  const onClickNewCategory = useCallback(() => {
    const currentModalParams = {
      selectedCategoryId: categoryId,
      parentCategoryName,
      parentCategoryId,
      menuId
    }

    if (isSubcategory) {
      const modalParams = { menuId, categoryId: parentCategoryId, categoryName: parentCategoryName }
      showModal(ModalSubcategoryEdition, modalParams, true, currentModalParams)
    } else {
      showModal(ModalCategoryEdition, { menuId }, true, currentModalParams)
    }
  }, [showModal, isSubcategory, categoryId, menuId, parentCategoryName, parentCategoryId])

  useSagaTakeEvery(actions.menuDetails.addCategorySuccess, onSuccessEdition)

  useSagaTakeEvery(actions.menuDetails.addCategoryError, onErrorEdtion)

  const translationContext = {context: parentCategoryName ? 'subcategory' : ''}
  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:menuCategoryEditionModal.title', {...translationContext, parentCategoryName})} onRequestClose={onRequestClose}
      className="modal--menu-category-edition"
      footerContent={footerContent}
      >
      <div className="f f-ai-center">
        <InputSelect defaultOption={defaultCategoryOption} isSearchable={true} placeholder={t('menuDetails:menuCategoryEditionModal:inputCategory:placeholder', {...translationContext, parentCategoryName})} searchPlaceholder={t('menuDetails:menuCategoryEditionModal:inputCategory:searchPlaceholder', {...translationContext})}  options={categoryOptions} onChange={onChangeSelect} error={error} />
        <div className="or ft-upper ft-700 c-gray-600">{t('menuDetails:menuCategoryEditionModal:or')}</div>
        <Button onClick={onClickNewCategory} text={t('menuDetails:menuCategoryEditionModal:new', {...translationContext})} iconLeft="icon-plus" />
      </div>
    </LayoutModal>
  )
}
export default ModalMenuCategoryEdition
