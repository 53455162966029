import Button from 'component/Buttons/Button'
import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom'
import LanguagePicker from 'component/LanguagePicker';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Langs } from 'utils/routers/constants'

interface TopBarProps {
  isLoading?: boolean,
  backTitle?: string,
  backLink?: string,
  rightContent?: React.ReactNode
}

const TopBar: FC<TopBarProps> = ({backTitle, backLink, isLoading = false, rightContent = <></>}) => {
  const { t } = useTranslation(['onboarding']);

  const languageOptions = useMemo(() => Object.values(Langs).map((lang) => ({label: t(`common:shortLanguages:${lang.toLowerCase()}`), value: lang, icon: <img className="img-circle img-circle--20 mr-2" src={`/static/images/flags/${lang.toLowerCase()}.png`}  alt="" />})), [t])
  const defaultLanguage = useMemo(() => languageOptions.find((lang) => lang.value === i18next.language), [languageOptions])

  const onChangeLang = useCallback((option) => {
    i18next.changeLanguage(option.value)
  }, [])

  return (
    <header className="topbar">
      <div className="topbar__top f f-jc-space-b mb-2 is-mobile">
        <a href={`${process.env.REACT_APP_DIGITO_WEBSITE}/${i18next.language !== 'ja' ? i18next.language : ''}`} className="sidebar__logo">
          <img src={'/static/images/logo.svg'} alt={t('dashboard:title')} />
        </a>
        <LanguagePicker options={languageOptions} defaultOption={defaultLanguage} onChangeLang={onChangeLang} />
      </div>

      {backLink && <div className="topbar__left">
        {backTitle ?
          <Button iconLeft="icon-arrow-left c-cream-500" text={backTitle} link={backLink} isExternal={true} />
          :
          <Link to={backLink} className={`link-icon link-icon--big link-icon--cream ${isLoading ? 'is-disabled' : ''}`}><i className="icon icon-arrow-left"/></Link>
        }
      </div>}
      <div className="topbar__right">
        {rightContent}
      </div>
    </header>
  )
}

export default TopBar
