import React, { FC, useCallback } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import { useTranslation } from 'react-i18next'
import Button from 'component/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'

interface ModalImportPaymentFailedProps {
  onRequestClose: () => void
}

const ModalImportPaymentFailed: FC<ModalImportPaymentFailedProps> = ({ onRequestClose }) => {
  const importId = useSelector(selectors.onboarding.importId)
  const dispatch = useDispatch()
  const { t } = useTranslation(['onboarding'])

  const onRetry = useCallback(() => {
    dispatch(actions.onboarding.goToImportStripeCheckout({importId}))
  }, [dispatch, importId])

  return (
    <LayoutModal 
      onRequestClose={onRequestClose} 
      className="modal--payment-failed modal--sm ft-center"
      >
      <h1 className="mt-2">{t('onboarding:modalImportPaymentFailed:title')}</h1>
      <p className="mt-1" >{t('onboarding:modalImportPaymentFailed:desc')}</p>
      <div className="mt-4">
        <Button onClick={onRequestClose} text={t('onboarding:modalImportPaymentFailed:cancelCTA')} />
        <Button onClick={onRetry} text={t('onboarding:modalImportPaymentFailed:CTA')} isPrimary={true}  />
      </div>
    </LayoutModal>
  )
}
export default ModalImportPaymentFailed
