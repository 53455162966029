import Button from 'component/Buttons/Button'
import React, { FC } from 'react'

interface CardEmptyContentProps {
  className?: string,
  title: string,
  desc?: string,
  ctaText: string,
  onClickCTA: () => void
}

const CardEmptyContent: FC<CardEmptyContentProps> = ({ className, title, desc, ctaText, onClickCTA }) => (
  <div className={`card-empty-content ${className ? className : ''}`}>
    <div className="card-empty-content__left">
      <div className={`h3 ${desc ? '' : 'card-empty-content__title'}`}>{title}</div>
      {desc && <div className="h5 c-gray-600">{desc}</div>}
    </div>
    {ctaText && <Button isPrimary={true}  onClick={onClickCTA} text={ctaText} /> }
  </div>
)

export default CardEmptyContent