import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import InputText from 'component/Form/Inputs/InputText';
import Switch from 'component/Form/Inputs/Switch';
import { useForm } from 'react-hook-form';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';
import { getError, validationPatterns } from 'utils/helpers';

interface Inputs {
  hasWifi   : boolean,
  websiteUrl: string,
}

interface ModalExtrasProps {
  onRequestClose: () => void,
}

const ModalExtras: FC<ModalExtrasProps> = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails']);
  const formId = useMemo(() => 'modal-business-extras-form', []);

  const { showStickyBanner } = useContext(StickyBannerContext);

  const business = useSelector(selectors.business.business);
  const [hasWifi, setHasWifi] = useState(useSelector(selectors.business.hasWifi) ? true : false);
  const websiteUrl = useSelector(selectors.business.websiteUrl);
  const [note, setNote] = useState<string>(business.businessConfig && business.businessConfig?.i18n?.note ? business.businessConfig.i18n.note : '');

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, errors, register, clearErrors } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form) => {
    setIsLoading(true);
    dispatch(actions.business.updateExtras({
      id: business.id,
      websiteUrl: form.websiteUrl,
      config: {
        hasWifi: hasWifi,
        note   : note
      }
    }));
  }, [dispatch, business.id, hasWifi, note]);

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false);
    onRequestClose();
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    showStickyBanner(BannerError);
    setIsLoading(false);
  }, [showStickyBanner])

  const onWifiSwitchChange = useCallback((name, value) => {
    setHasWifi(value ? true : false);
  }, [setHasWifi])

  const onChangeInputText = useCallback(() => {
    clearErrors();
  }, [clearErrors])

  const onChangeInput = useCallback((name, value) => {
    clearErrors()
    switch(name) {
      case 'note':
        setNote(value ? value : '')
        break;
      default:
        break;
    }
  }, [clearErrors])

  useSagaTakeEvery(actions.business.updateExtrasSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updateExtrasError, onErrorEdition)

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={onSubmit} text={t('menuDetails:ctas.save')} type="submit" form={formId} isPrimary={true} />
    </>
  ), [t, onRequestClose, onSubmit, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:extrasModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <div className="mb-1">
            <span className="f f-jc-space-b">
              <h4 className="ft-600 f-1">{t('menuDetails:extrasModal.inputWifi.heading')}</h4>
              <Switch
                className="mb-1 f-1"
                title={t(`menuDetails:extrasModal.inputWifi.${hasWifi ? 'available' : 'unavailable'}`)}
                name="hasWifi"
                value={hasWifi}
                handleChange={onWifiSwitchChange}
              />
            </span>
            <h4 className="ft-600 mb-2">{t('menuDetails:extrasModal.inputWebsite.heading')}</h4>
            <InputText
              maxLength={255}
              defaultValue={websiteUrl}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:extrasModal.inputWebsite.label')}
              placeholder={t('menuDetails:extrasModal.inputWebsite.label')}
              name="websiteUrl"
              handleChange={onChangeInputText}
              inputRef={register({
              pattern: {
                value: validationPatterns.url,
                message: getError('url')
                }
              })}
              error={errors.websiteUrl}
            />
            <h4 className="ft-600 mb-1">{t('menuDetails:extrasModal.inputNote.heading')}</h4>
            <p className="ft-secondary">{t('menuDetails:extrasModal.inputNote.description')}</p>
            <InputText
              maxLength={100}
              defaultValue={note}
              className="input--material mb-1 ft-left"
              label={t('menuDetails:extrasModal.inputNote.label')}
              placeholder={t('menuDetails:extrasModal.inputNote.label')}
              name="note"
              handleChange={onChangeInput}
            />
          </div>
        </form>
    </LayoutModal>
  )
}

export default ModalExtras
