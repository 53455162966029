import { takeEvery, all, select, call, put, fork, SagaReturnType } from 'redux-saga/effects';
import { actions, selectors } from 'store';
import { getImportIdLocalStorage, getOnboardingLocalStorage, getVerificationLocalStorage } from 'store/Onboarding/saga';
import { fetchCSRFToken, fetchUserInfo } from 'store/User/saga';
import * as services from './services'

export function* init() {
  yield fork(getOnboardingLocalStorage)
  yield fork(getVerificationLocalStorage)
  yield fork(getImportIdLocalStorage)

  yield call(fetchCSRFToken)
  yield call(fetchUserInfo)
}

export function* uploadPicture(action: ReturnType<typeof actions.app.uploadPicture>) {
  try {
    const CSRFToken: string = yield select(selectors.user.CSRFToken)
    const upload: SagaReturnType<typeof services.uploadPicture> = yield call(services.uploadPicture, CSRFToken, action.payload.file)
    yield put(actions.app.uploadPictureSuccess({key: upload.key}))
  } catch {

  }
}

export function* subscribeFromStripe(action: ReturnType<typeof actions.app.subscribeFromStripe>) {
  try {
    const request: SagaReturnType<typeof services.subscribeFromStripe> =  yield call(services.subscribeFromStripe, action.payload.period)
    yield call(services.redirecToStripeCheckout, request.sessionId)
  } catch(e) {
  }
}


export function* manageAccountFromStripe(action: ReturnType<typeof actions.app.manageAccountFromStripe>) {
  try {
    const request: SagaReturnType<typeof services.manageAccountFromStripe> = yield call(services.manageAccountFromStripe)
    yield call(services.redirectToStripeBilling, request.url)
  } catch(e) {
  }
}

export function* sendContact(action: ReturnType<typeof actions.app.sendContact>) {
  try {
    yield call(services.handleContactForm, action.payload)
    yield put(actions.app.sendContactSuccess())
  } catch (e: any) {
    yield put(actions.app.sendContactError(e))
  }
}


export function* root() {
  yield all([
    takeEvery(actions.app.init, init),
    takeEvery(actions.app.uploadPicture, uploadPicture),
    takeEvery(actions.app.sendContact, sendContact),
    takeEvery(actions.app.subscribeFromStripe, subscribeFromStripe),
    takeEvery(actions.app.manageAccountFromStripe, manageAccountFromStripe)
  ])
}
