import Button from 'component/Buttons/Button'
import InputRadio from 'component/Form/Inputs/InputRadio'
import TopBar from 'component/Headers/TopBar'
import BottomBar from 'component/Footers/BottomBar';
import LayoutContent from 'component/Layouts/LayoutContent'
import LayoutSideBar from 'component/Layouts/LayoutSideBar'
import React, { FC, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { actions, selectors } from 'store'
import { BUSINESS_TYPE } from 'store/Business'
import { useTypedSelector } from 'store/store'
import { RouteUrls } from 'utils/routers/constants'

const BusinessType: FC = (props: any) => {
  const dispatch  = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['common', 'onboarding'])

  const backUrl = RouteUrls.Home
  const businessType = useSelector(selectors.onboarding.businessType)
  const isReadyForStep = useTypedSelector(state => selectors.onboarding.isReadyForStep(state, 2))

  const {handleSubmit, register} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: "all",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!isReadyForStep) {
      history.push(backUrl)
    }
  }, [isReadyForStep, history, backUrl])

  const onSubmit = useCallback((data) => {
    dispatch(actions.onboarding.triggerSetBusinessType({type: data.businessType}))
    history.push(RouteUrls.Register)
  }, [dispatch, history])

  return (
    <LayoutSideBar pageName="business-type">
      <TopBar backLink={backUrl} />
      <LayoutContent className="page__padding-on-wrapper">
        <div className="ft-center page__wrapper">
          <div>
            <h1 className="ft-900 mt-1 mb-4">{t('onboarding:businessType:title')}</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            { Object.values(BUSINESS_TYPE).map(type =>
              <InputRadio
                inputRef={register()}
                label={t(`common:businessTypes:${type.toLowerCase()}`)}
                key={type}
                className="mb-2"
                value={type}
                name="businessType"
                defaultChecked={businessType === type}
                id={type}
                />
            )}
            <div className="ft-center">
              <Button className="mt-4" isPrimary={true} type="submit" text={t('onboarding:businessType:next')} />
            </div>
          </form>
        </div>
        <BottomBar />
      </LayoutContent>
    </LayoutSideBar>
  )
}

export default BusinessType
