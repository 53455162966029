import React, { FC, useCallback, useEffect, useState } from 'react'

interface InputTextProps {
  autocomplete?: string,
  className?: string,
  inputClass?: string,
  name?: string
  label?: string
  type?: string
  isDark?: boolean
  handleChange?: (name: string | undefined, value: string | undefined, setValueFunc: any | undefined) => void
  handleBlur?: (name: string | undefined, value: string | undefined, setValueFunc: any | undefined) => void,
  onBlur?: (name: string | undefined) => void
  inputRef?: any
  placeholder?: string
  error?: any
  defaultValue?: string
  disabled?: boolean
  required?: boolean
  description?: string
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  id?: string,
  right?: string
}

const InputText: FC<InputTextProps> = (props) => {
  const {
    className,
    inputClass,
    name,
    label,
    type,
    isDark,
    handleChange,
    handleBlur,
    inputRef = null,
    placeholder = '',
    error,
    defaultValue,
    disabled = false,
    required = false,
    description,
    maxLength,
    minLength,
    min,
    max,
    id,
    autocomplete,
    onBlur,
    right
  } = props

  const [value, setValue] = useState(defaultValue || '')

  const onInputChange = useCallback((value: string) => {
    setValue(value)
    if (handleChange) handleChange(name, value, setValue)
  }, [handleChange, name])

  const onInputBlur = useCallback((e: any) => {
    if (onBlur) onBlur(name)
    if (handleBlur) handleBlur(name, e.target.value, setValue);
  }, [name, onBlur, handleBlur])

  const classes = `${className ? className : ''}`
  const inputClasses = `${ inputClass ? inputClass : ''}${ error ? ' has-error' : ''}`
  const inputName = name ? name : 'input'

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const inputId = id ? id : `input-${name}`;

  return (
    <div className={`input ${classes}`}>
      <input
        onBlur={onInputBlur}
        autoComplete={autocomplete ? autocomplete : undefined}
        minLength={minLength ? minLength : undefined}
        maxLength={maxLength ? maxLength : undefined}
        min={min ? min : undefined}
        max={max ? max : undefined}
        className={inputClasses}
        value={value}
        onChange={(e) => onInputChange(e.target.value)}
        type={type}
        name={inputName}
        disabled={disabled}
        placeholder={isDark ? label : placeholder}
        ref={inputRef}
        required={required}
        id={inputId}
        />
      <label htmlFor={inputId}>{label}</label>
      {description && (
        <p>{description}</p>
      )}
      {error && error.message && (
        <div className="input__error mb-1">
          {error.message}
        </div>
      )}
      {
        right && <p className="input__right ft-700 c-gray-800">{right}</p>
      }
    </div>
  )
}

export default InputText
