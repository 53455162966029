import BannerInfo from "component/Banners/BannerInfo";
import CardEmptyContent from "component/Cards/CardEmptyContent";
import CardMenu from "component/Cards/CardMenu";
import Loader from "component/Loader";
import ModalMenuEdition from "component/Modals/ModalMenuEdition";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next/";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectors } from "store";
import { MENU_STATUS } from "store/Menus";
import { ModalContext } from "utils/hooks/ModalBehavior";

interface TabMenusProps {
}

const TabMenus: FC<TabMenusProps> = () => {
  const { t } = useTranslation(['menus'])
  const menus = useSelector(selectors.menus.menus)
  const hasUnfinalizedMenus = useSelector(selectors.menus.hasUnfinalizedMenus)
  const hasPendingMenuImports = useSelector(selectors.business.hasPendingMenuImports)
  const isManagingBusiness = useSelector(selectors.admin.managingBusiness)
  const isLoadingDataList = useSelector(selectors.menus.isLoadingDatasList)
  const { showModal } = useContext(ModalContext)
  const [unfinalizedMenuId, setUnfinalizedMenuId] = useState<number>()

  const showModalMenuEdition = useCallback(() => {
    showModal(ModalMenuEdition)
  }, [showModal])

  useEffect(()=>{
    const unfinalizedMenu = menus.find(menu => menu.status !== MENU_STATUS.VISIBLE)
    setUnfinalizedMenuId(unfinalizedMenu?.id)
  }, [menus])

  const unfinalizedMenuMessage = (
    <Trans
      i18nKey="menus:finalizeMenuInfo"
      values={{ finalize: t('menus:finalize') }}
    >
      content{' '}
      <Link
        className="ml-1 mr-1 ft-underline"
        to={`/dashboard/menu/${unfinalizedMenuId}`}
      >
        finalize
      </Link>{' '}
      after
    </Trans>
  )

const pendingMenuImportMessage = <p className="mb-0">{t('menus:pendingMenuImports')}</p>

  return (
    <section>
      <div className="f f-jc-space-b mb-2">
        <h2>{t('menus:title')}</h2>
        <button onClick={showModalMenuEdition} className="link c-primary ft-600 f-inline f-ai-center"><span className="icon icon-plus icon-16 mr-1"></span>{t('menus:menu:create')}</button>
      </div>
      {hasUnfinalizedMenus && !(hasPendingMenuImports && !isManagingBusiness) && <BannerInfo message={unfinalizedMenuMessage} className="mb-2" />}
      {(hasPendingMenuImports && !isManagingBusiness) && <BannerInfo message={pendingMenuImportMessage} className="mb-2" />}
      { isLoadingDataList ? 
        <Loader className="ft-center" /> 
        : 
        <>
          {menus.map((menu, key) => <CardMenu hasActions={true} key={menu.id} menu={menu} />)}
          {!menus.length && <CardEmptyContent title={t('menus:empty:title')} desc={t('menus:empty:desc')} ctaText={t('menus:empty:cta')} onClickCTA={showModalMenuEdition}/>}
        </>
      }
    </section>
  )
}

export default TabMenus