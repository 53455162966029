import { PDFDocument, PDFImage, PDFPage } from 'pdf-lib';
import uslug from 'uslug'; // TODO deprecated
const QRCode = require('qrcode') // TODO deprecated

 // TODO deprecated
export const getQRCodeImgUrl = async (url: string, colorOptions?: { background: string, bullet: string }, qualityOption?: number, margin?: number ): Promise<string> => {
  const {
    background: lightColor = '#fff',
    bullet: darkColor = '#000',
  } = colorOptions ? colorOptions : {}

  const opts = {
    type: 'image/jpeg',
    quality: qualityOption ? qualityOption : 0.5,
    margin: margin ? margin : 5,
    errorCorrectionLevel: 'M',
    color: {
      dark: darkColor,
      light: lightColor
    }
  }

  try {
    const outputUrl = await QRCode.toDataURL(null, url, opts)
    return outputUrl
  } catch {
    return ''
  }
}

// TODO deprecated
export const getQRCodePDFURL = async (QRCodeUrl: string, fileName: string) => {
  const pdf = {
    dark:  { bullet: '#f5a368', background: '#1c1412' },
    light: { bullet: '#f5a368', background: '#fffbf7' },
    black: { bullet: '#000', background: '#fff' },
  }
  const darkQRCode = await getQRCodeImgUrl(QRCodeUrl, {background: pdf.dark.background, bullet: pdf.dark.bullet}, 1, 2)
  const lightQRCode = await getQRCodeImgUrl(QRCodeUrl, {background: pdf.light.background, bullet: pdf.light.bullet}, 1, 2)
  const blackQRCode = await getQRCodeImgUrl(QRCodeUrl, {background: pdf.black.background, bullet: pdf.black.bullet}, 1, 2)

  const darkQRCodeBytes = await fetch(darkQRCode).then((res) => res.arrayBuffer())
  const lightQRCodeBytes = await fetch(lightQRCode).then((res) => res.arrayBuffer())
  const blackQRCodeBytes = await fetch(blackQRCode).then((res) => res.arrayBuffer())

  const pdfURL = '/static/files/qrcode_download.pdf';
  const existingPdfBytes = await fetch(pdfURL).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  const pages = pdfDoc.getPages()

  const lightQRCodeImage = await pdfDoc.embedJpg(lightQRCodeBytes)
  const darkQRCodeImage = await pdfDoc.embedJpg(darkQRCodeBytes)
  const blackQRCodeImage = await pdfDoc.embedJpg(blackQRCodeBytes)

  const dims = lightQRCodeImage.scaleToFit(80, 80)
  const blackDims = blackQRCodeImage.scaleToFit(96, 96)

  const firstPage = pages[0]
  const secondPage = pages[1]
  const thirdPage = pages[2]

  const leftColumnX = 156 - dims.width / 2
  const rightColumnX = (firstPage.getHeight() / 2) - 70 +  (dims.width / 2)
  const topRowY = firstPage.getWidth() / 2 + (dims.width  / 2) + 171
  const bottomRowY = firstPage.getWidth() / 2 - (dims.width / 2) - 142

  const firstQRCodePos = { x: leftColumnX, y: topRowY }
  const secondQRCodePos = { x: leftColumnX, y: bottomRowY }
  const thirdQRCodePos = { x: rightColumnX, y: topRowY }
  const fourthQRCodePos = { x: rightColumnX, y: bottomRowY }

  const drawImage = (page: PDFPage, qrCodeImage: PDFImage) => {
    page.drawImage(qrCodeImage, {
      x: firstQRCodePos.x,
      y: firstQRCodePos.y ,
      width: dims.width,
      height: dims.height,
    })
    page.drawImage(qrCodeImage, {
      x: secondQRCodePos.x,
      y: secondQRCodePos.y ,
      width: dims.width,
      height: dims.height,
    })
    page.drawImage(qrCodeImage, {
      x: thirdQRCodePos.x,
      y: thirdQRCodePos.y ,
      width: dims.width,
      height: dims.height,
    })
    page.drawImage(qrCodeImage, {
      x: fourthQRCodePos.x,
      y: fourthQRCodePos.y ,
      width: dims.width,
      height: dims.height,
    })
  }

  drawImage(firstPage, lightQRCodeImage)
  drawImage(secondPage, darkQRCodeImage)

  thirdPage.drawImage(blackQRCodeImage, {
    x: firstPage.getWidth() / 2 - (blackDims.width / 2),
    y: firstPage.getHeight() / 2,
    width: blackDims.width,
    height: blackDims.height,
  })

  thirdPage.drawImage(lightQRCodeImage, {
    x: firstPage.getWidth() / 2 - (blackDims.width / 2),
    y: firstPage.getHeight() / 3,
    width: dims.width,
    height: dims.height,
  })

  const pdfName = `DIGITOMENU_${uslug(fileName)}_QRCode.pdf`
  const pdfBytes = await pdfDoc.save()
  const blob = new File([pdfBytes], pdfName, {type: 'application/pdf'});
  const endpointURL = URL.createObjectURL(blob)

  return { url: endpointURL, name: pdfName };
}

const convertMmToPt = (mm: number) => {
  return mm / 0.3528;
}

export const generateQrCodePdfUrl = async (qrCodeUrl: string, fileName: string, labels: any, isApiUrl?: boolean, adjustments?: { marginTop?: number }) => {
  const dims = {
    page: {
      w: convertMmToPt(210),
      h: convertMmToPt(297)
    },
    label: {
      w: convertMmToPt(30),
      h: convertMmToPt(30)
    },
    margin: {
      top: convertMmToPt(14.5),
      left: convertMmToPt(22)
    },
    spacing: {
      vertical: convertMmToPt(4),
      horizontal: convertMmToPt(4)
    },
    bleed: convertMmToPt(0),
    adjustments: {
      marginTop: convertMmToPt(adjustments && adjustments.marginTop ? adjustments.marginTop : 0)
    }
  };

  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([dims.page.w, dims.page.h]);

  try {
    const bufferableUrl = isApiUrl ? await fetch(qrCodeUrl, { credentials: 'include' }).then((res) => res.json()).then((resJson) => resJson.url) : qrCodeUrl;

    const qrImageBytes = await fetch(bufferableUrl).then((res) => res.arrayBuffer());
    const qrImage = await pdfDoc.embedPng(qrImageBytes);

    labels.forEach((labelRow: any, rowIndex: number) => {
      const rowOffset = (dims.label.h + dims.spacing.vertical) * (rowIndex + 1) - dims.spacing.vertical + dims.adjustments.marginTop;

      labelRow.forEach((labelCol: boolean, colIndex: number) => {
        const colOffset = (dims.label.h + dims.spacing.horizontal) * colIndex;

        if (labelCol) {
          page.drawImage(qrImage, {
            x: dims.margin.left - dims.bleed + colOffset,
            y: page.getHeight() - dims.margin.top - dims.bleed - rowOffset,
            width: dims.label.w + dims.bleed * 2,
            height: dims.label.h + dims.bleed * 2,
          });
        }
      });
    });
  } catch (error) {
    // console.log(error);
  }

  const pdfName = `QRCode_${fileName}.pdf`;
  const pdfBytes = await pdfDoc.save();
  const blob = new File([pdfBytes], pdfName, {type: 'application/pdf'});
  const endpointURL = URL.createObjectURL(blob);

  return { url: endpointURL, name: pdfName };
}
