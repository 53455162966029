import { customFetch } from 'api/helpers/customFetch'
import { API_MENUS_URL } from 'api/helpers/routes'
import { CategoryType } from 'store/Categories'
import { defaultLanguage } from 'store/Translations'

export const getMenu = async (params : {id: number, lang?: string}): Promise<{menu: CategoryType[]}> => {
  const { id, lang = defaultLanguage } = params
  const request = await customFetch.get(`${API_MENUS_URL}/${id}`, {
    'Accept-Language': lang,
  })
  const response = await request.json() 
  return { menu: response }
}

export const postCategoryInMenu = async (params: { menuId: number, categoryId: number }, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId } = params
  const request = await customFetch.post(`${API_MENUS_URL}/${menuId}/categories`, {
    categoryId: categoryId
  }, canRetry)
  const response = await request.json() 
  return response
}

export const postProductInMenu = async (params: {menuId: number, categoryId: number, productId: number}, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId, productId } = params
  const request = await customFetch.post(`${API_MENUS_URL}/${menuId}/products`, {
    categoryId,
    productId
  }, canRetry)
  const response = await request.json() 
  return response
}


export const deleteCategory = async (params: {menuId: number, categoryId: number}, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId } = params
  const request = await customFetch.delete(`${API_MENUS_URL}/${menuId}/categories/${categoryId}`, {}, canRetry)
  const response = await request.json() 
  return response
}

export const deleteProduct = async (params: {menuId: number, categoryId: number, productId: number}, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId, productId } = params
  const request = await customFetch.delete(`${API_MENUS_URL}/${menuId}/categories/${categoryId}/products/${productId}`, {}, canRetry)
  const response = await request.json() 
  return response
}

export const patchCategoryOrder = async (params: {menuId: number, categoryId: number, orderNum: number}, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId, orderNum } = params
  const request = await customFetch.patch(`${API_MENUS_URL}/${menuId}/categories/${categoryId}`, {
    orderNum
  }, canRetry)
  return request
}

export const patchProductOrder = async (params: {menuId: number, categoryId: number, productId: number, orderNum: number, newCategoryId?: number}, canRetry: number = 0): Promise<Response> => {
  const { menuId, categoryId, productId, orderNum, newCategoryId } = params
  const request = await customFetch.patch(`${API_MENUS_URL}/${menuId}/categories/${categoryId}/products/${productId}`, {
    orderNum,
    ...(newCategoryId ? {newCategoryId} : {})
  }, canRetry)
  return request
}