import React, { FC } from 'react'
import LayoutDashboardOnboarding from 'component/Layouts/LayoutDashboardOnboarding'

interface LayoutDashboardOnboardingPremiumProps {
  step: number
  length: number
  title: string
  description: string
  onPrevButton: () => void
  onRequestClose: () => void
}

const LayoutDashboardOnboardingPremium: FC<LayoutDashboardOnboardingPremiumProps> = ({step, length, title, description, onPrevButton, onRequestClose}) => {
  return (
    <div>
      <LayoutDashboardOnboarding step={step} length={length} title={title} description={description} onPrevButton={onPrevButton} onRequestClose={onRequestClose} />
    </div>
  )
}

export default LayoutDashboardOnboardingPremium