import Loader from 'component/Loader';
import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next/';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import CardEmptyContent from 'component/Cards/CardEmptyContent';
import CardProduct from 'component/Cards/CardProduct'
import ModalProductEdition from 'component/Modals/ModalProductEdition'
import { ModalContext } from 'utils/hooks/ModalBehavior'

interface TabProductsProps {
}

const TabProducts: FC<TabProductsProps> = () => {
  const products = useSelector(selectors.products.products)
  const isLoadingDataList = useSelector(selectors.products.isLoadingDatasList)
  const { t } = useTranslation(['menus'])
  const { showModal } = useContext(ModalContext)

  const showModalProductEdition = useCallback(() => {
    showModal(ModalProductEdition)
  }, [showModal])

  return (
    <section>
      <div className="f f-jc-space-b mb-2">
        <h2>{t('menus:products.title')}</h2>
        <button onClick={showModalProductEdition} className="link c-primary ft-600 f-inline f-ai-center"><span className="icon icon-plus icon-16 mr-1"></span>{t('menus:product.create')}</button>
      </div>
      <div>
        { isLoadingDataList ? <Loader className="ft-center" /> :
          <>
            {products.map((product, key) => <CardProduct product={product} showDisused={true} key={product.id} />)}
            {!products.length && <CardEmptyContent title={t('menus:products:empty:title')} desc={t('menus:products:empty:desc')} ctaText={t('menus:products:empty:cta')} onClickCTA={showModalProductEdition} />}
          </>
        }
      </div>
    </section>
  )
}

export default TabProducts
