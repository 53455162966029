import ListMenuSubcategory from 'component/Lists/ListMenuSubcategory'
import ListMenuProducts from 'component/Lists/ListMenuProducts'
import ModalMenuCategoryEdition from 'component/Modals/ModalMenuCategoryEdition'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'store'
import { CategoryType, SubcategoryType } from 'store/Categories'
import { useTypedSelector } from 'store/store'
import { ModalContext } from 'utils/hooks/ModalBehavior'
import { Draggable } from "react-beautiful-dnd"
import DropdownActions from 'component/Buttons/DropdownActions'
import ModalDeleteConfirmation from 'component/Modals/ModalDeleteConfirmation'
import ModalCategoryEdition from 'component/Modals/ModalCategoryEdition'
import ModalSubcategoryEdition from 'component/Modals/ModalSubcategoryEdition'

interface CardMenuCategoryProps {
  category: CategoryType | SubcategoryType,
  menuId: number,
  parentCategoryId?: number,
  order: number,
  parentCategoryLinkedBusinessId?: number | null,
}

const CardMenuCategory: FC<CardMenuCategoryProps> = ({ category, menuId, parentCategoryId, order, parentCategoryLinkedBusinessId }) => {
  const { t } = useTranslation(['menuDetails', 'menus'])

  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)

  const isUnToggled = useTypedSelector(state => selectors.menuDetails.isCategoryUntoggled(state, category.id))

  const isSubcategory = useMemo(() => !('subCategories' in category), [category])

  const nbTotalProducts = useTypedSelector(state => selectors.menuDetails.nbTotalProducts(state, category.id, parentCategoryId))

  const linkedBusinessId = useMemo(() => parentCategoryLinkedBusinessId ? parentCategoryLinkedBusinessId : (('linkedBusinessId' in category) ? category.linkedBusinessId : null), [category, parentCategoryLinkedBusinessId])
  const linkedBusinessName = useMemo(() => ('linkedBusiness' in category && category.linkedBusiness && category.linkedBusiness.name) ? category.linkedBusiness.name : null, [category])

  const hasContent = useMemo(() => ('subCategories' in category && category.subCategories && category.subCategories.length > 0) || (category.products && category.products.length > 0), [category])

  const onClickToggle = useCallback(() => {
    if (!isUnToggled) {
      dispatch(actions.menuDetails.addUntoggledCategory({id: category.id}))
    } else {
      dispatch(actions.menuDetails.removeUntoggledCategory({id: category.id}))
    }
  }, [dispatch, isUnToggled, category.id])

  const deleteCategory = useCallback(() => {
    dispatch(actions.menuDetails.removeCategory({ id: menuId, categoryId: category.id, parentCategoryId }))
  }, [category, parentCategoryId, menuId, dispatch])

  const onClickDeleteCategory = useCallback(() => {
    const modalDeleteConfirmationProps = ({
      title: t('menuDetails:removeCategoryConfirmationModal:title', {
        categoryName: category.i18n?.name
      }),
      desc: t('menuDetails:removeCategoryConfirmationModal:desc'),
      cancelCTAText: t('menuDetails:removeCategoryConfirmationModal:cancelCTA'),
      confirmCTAText: t('menuDetails:removeCategoryConfirmationModal:confirmCTA'),
      onConfirm: deleteCategory,
    })
    showModal(ModalDeleteConfirmation, modalDeleteConfirmationProps)
  }, [showModal, deleteCategory, t, category])

  const onClickEditCategory = useCallback(() => {
    if (isSubcategory) {
      showModal(ModalSubcategoryEdition, {
        subcategory: category, categoryId: category.parentCategoryId, categoryName: '', menuId })
    } else {
      showModal(ModalCategoryEdition, { category, menuId })
    }
  }, [showModal, isSubcategory, category, menuId])

  const showModalMenuCategoryEdition = useCallback(() => {
    showModal(ModalMenuCategoryEdition, {
      menuId: menuId,
      parentCategoryName: linkedBusinessName ? linkedBusinessName : category.i18n?.name,
      parentCategoryId: category.id,
      type: 'subcategory'
    });
  }, [showModal, menuId, category, linkedBusinessName])

  const subCategories = useMemo(() => 'subCategories' in category ? category.subCategories : [] , [category])
  const draggableId = useMemo(() => isSubcategory ? `droppableSubcategory-sc${category.id}`  : `droppableCategory-c${category.id}`, [isSubcategory, category])

  return (
    <Draggable key={`c${category.id}`} draggableId={draggableId} index={order}>
      {(provided, snapshot) => (
        <li className="card-menu-category" ref={provided.innerRef} {...provided.draggableProps}>
          <div className="card-menu-category__top">
            <div className="card-menu-category__left">
              <div className="icon icon-drag icon-20 c-gray-400 mr-3" {...provided.dragHandleProps} />
              <h3 className={`${!isSubcategory ? 'ft-700' : ''} mr-1`}>{ linkedBusinessName || category.i18n?.name }</h3>
              <button onClick={onClickToggle} className={`card-menu-category__toggle icon icon-16 c-gray-800 ${!isUnToggled ? 'icon-arrow-up card-menu-category__toggle-down' : 'icon-arrow-down'}`} />
            </div>
            <div className="card-menu-category__right f f-ai-center">
              <span className={!hasContent ? 'card-menu-category__total' : ''}>{t('menuDetails:overview:products')} : {nbTotalProducts}</span>
                <DropdownActions iconClassName="icon-more icon-20 c-gray-800">
                  <li>
                    <button className="ft-700" onClick={onClickEditCategory}>
                      <span className="icon icon-edit mr-2 icon-16 c-cream-500"></span>
                      {isSubcategory ? t('menus:subcategory:edit') : t('menus:category:edit')}</button>
                  </li>
                   { !hasContent &&
                      <li>
                        <button className="c-red ft-700" onClick={onClickDeleteCategory}><span className="icon icon-trash mr-2 icon-16"></span>
                          {isSubcategory ? t('menuDetails:overview:remove:subcategory') : t('menuDetails:overview:remove:category')}</button>
                      </li>
                    }
                </DropdownActions>
            </div>
          </div>
          <div className={`card-menu-category__body ${!isUnToggled ? 'card-menu-category__body--opened' : ''}`}>
            {(!isUnToggled) &&
              <>
                <div className="card-menu-category__list">
                  <div className="card-menu-category__products">
                    <ListMenuProducts menuId={menuId} categoryName={ linkedBusinessName || category.i18n?.name } categoryId={category.id} parentCategoryId={parentCategoryId} products={category.products} nbTotalProducts={nbTotalProducts}
                    linkedBusinessId={linkedBusinessId}
                    />
                  </div>
                  <div className="card-menu-category__categories">
                    {(subCategories.length > 0) && <ListMenuSubcategory menuId={menuId} subcategories={subCategories} parentCategoryId={category.id} linkedBusinessId={linkedBusinessId}/>}
                  </div>
                </div>
                {!isSubcategory && (
                  <div className="ft-right card-menu-category__action">
                    <button
                      className="c-primary ft-600 f-inline f-ai-center"
                      onClick={showModalMenuCategoryEdition}
                    >
                      <span className="icon icon-plus icon-20 mr-1"></span>
                      {t('menuDetails:overview:add:subcategory')}
                    </button>
                  </div>
                )}
              </>
            }
          </div>
        </li>
      )}
    </Draggable>
  )
}

export default CardMenuCategory
