import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import InputText from 'component/Form/Inputs/InputText'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import ScheduleRow from 'component/ScheduleRow';
import Button from 'component/Buttons/Button';
import { AVAILABILITY_DAYS, MenuAvailaibility, MenuType } from 'store/Menus';
import { getError, groupBy } from 'utils/helpers';
import { actions } from 'store';
import { useDispatch } from 'react-redux';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';

interface ModalMenuEditionProps {
  onRequestClose: () => void,
  menu?: MenuType
}

interface Inputs {
  name: string
}

const ModalMenuEdition: FC<ModalMenuEditionProps> = (props) => {
  const {onRequestClose, menu} = props
  const { register, errors, handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });
  const dispatch = useDispatch()
  const { showStickyBanner } = useContext(StickyBannerContext)

  const isEditMode = useMemo(() => menu && menu.id && menu.id >= 0,[menu])
  const avaibilityDays = Object.values(AVAILABILITY_DAYS)

  const [isLoading, setIsLoading] = useState(false)
  const [menuAvailabilities, setMenuAvailabilities] = useState<MenuAvailaibility[]>([])
  const menuAvailabilitiesWithKey = useMemo(() => menuAvailabilities.map((item, key) => ({...item, key})), [menuAvailabilities])

  const menuSchedule = useMemo(() => groupBy(menuAvailabilitiesWithKey, 'day'), [menuAvailabilitiesWithKey])
  const menuScheduleKeys = useMemo(() => Object.keys(menuSchedule), [menuSchedule])
  const formId = useMemo(() => 'modal-menu-edition-form', [])

  const { t } = useTranslation(['menus', 'common'])

  const checkEmptyHours = useCallback(() => {
    let hasNoErrors = true;
    setMenuAvailabilities((prevState) => {
      const newMenuAvailabilities = [...prevState].map((a) => ({ ...a }))
      for (let i = 0; i < newMenuAvailabilities.length; i++) {

        const schedule = newMenuAvailabilities[i]
        const hourOpen = `${schedule.hourOpen.length > 5 ? schedule.hourOpen : `${schedule.hourOpen}:00`}`
        const hourClose = `${schedule.hourClose.length > 5 ? schedule.hourClose : `${schedule.hourClose}:00`}`
        if (hourOpen === hourClose) {
          schedule.error = 'sameHours'
          newMenuAvailabilities[i].error = "sameHours"
          setMenuAvailabilities(newMenuAvailabilities)
          hasNoErrors = false
          break;
        }
      }
      return newMenuAvailabilities
    })
    return hasNoErrors
    // return hasNoErrors
  }, [])

  const onSubmit = useCallback((form) => {
    if (checkEmptyHours()) {
      setIsLoading(true)
      const simpleFormatLength = 5; // HH:mm

      const newMenu = {
        name: form.name,
        menuAvailabilities: menuAvailabilities.map((avaibility) => ({
          day: avaibility.day,
          hourClose: avaibility.hourClose.length <= simpleFormatLength ? `${avaibility.hourClose}:00` : avaibility.hourClose,
          hourOpen: avaibility.hourOpen.length <= simpleFormatLength ? `${avaibility.hourOpen}:00` : avaibility.hourOpen,
        }))
      }
      if (isEditMode && menu) {
        dispatch(actions.menus.updateMenu({id: menu.id, menu: newMenu}))
      } else {
        dispatch(actions.menus.createMenu({menu: newMenu}))
      }
    }
  }, [checkEmptyHours, menuAvailabilities, dispatch, isEditMode, menu])

  const onChangeAvaibility = useCallback((schedules: MenuAvailaibility[], day: string) => {
    if (schedules.length > 0) {
      setMenuAvailabilities((prevState) => {
        const newMenuAvailabilities = [...prevState]
        schedules.forEach((schedule) => {
          if (schedule.key !== undefined) {
            newMenuAvailabilities[schedule.key] = schedule
          } else {
            newMenuAvailabilities.push(schedule)
          }
        })
        return newMenuAvailabilities
      })
    } else {
      setMenuAvailabilities((prevState) => {
        const oldState = [...prevState]
        const newMenuAvailabilities = oldState.filter((item) => item.day !== day)
        return newMenuAvailabilities
      })
    }
  }, [])

  const onRemoveAvaibility = useCallback((key: number | null) => {
    setMenuAvailabilities(menuAvailabilitiesWithKey.filter((item) => item.key !== key))
  }, [menuAvailabilitiesWithKey])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menus:menuEditionModal:cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} type="submit" form={formId} text={t(isEditMode ? 'menus:menuEditionModal:saveEdit' : 'menus:menuEditionModal:save')} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading, isEditMode])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdition = useCallback(() => {
    setIsLoading(false)
    showStickyBanner(BannerError)
  }, [showStickyBanner])

  useSagaTakeEvery(actions.menus.createMenuSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.menus.updateMenuSuccess, onSuccessEdition)

  useSagaTakeEvery(actions.menus.createMenuError, onErrorEdition)
  useSagaTakeEvery(actions.menus.updateMenuError, onErrorEdition)

  useEffect(() => {
    if (isEditMode && menu) {
      setMenuAvailabilities(menu.menuAvailabilities)
    }
  }, [menu, isEditMode])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={isEditMode ? t('menus:menuEditionModal:title:edit') : t('menus:menuEditionModal:title:add')}
      onRequestClose={onRequestClose}
      className="modal--menu-edition"
      footerContent={footerContent}
      >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <InputText
          maxLength={41}
          defaultValue={menu?.name}
          name="name"
          className="mb-2 input--material"
          placeholder={t('menus:menuEditionModal.inputName')}
          type="text"
          label={t('menus:menuEditionModal.inputName')}
          inputRef={register({
            required: {
              value: true,
              message: getError('name')
            }
          })}
          error={errors.name}/>
        <hr />
        <h4 className="h3 mt-2 mb-3">{t('menus:menuEditionModal:schedule')}</h4>
        { avaibilityDays.map((day, key) => {
          const avaibility = menuScheduleKeys.includes(day) ? menuSchedule[day] : []
          return <ScheduleRow key={key} day={day} avaibility={avaibility} onChange={onChangeAvaibility} onRemove={onRemoveAvaibility} />
        })}
      </form>
    </LayoutModal>
  )
}

export default ModalMenuEdition
