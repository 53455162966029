import CardCustomization from 'component/Cards/CardCustomization';
import ModalConfigColors from 'component/Modals/ModalConfigColors';
import ModalConfigTheme from 'component/Modals/ModalConfigTheme';
import ModalConfigTranslations from 'component/Modals/ModalConfigTranslations';
import ModalLogoEdition from 'component/Modals/ModalLogoEdition';
import ModalSocialEdition from 'component/Modals/ModalSocialEdition';
import ModalStory from 'component/Modals/ModalStory';
import ModalConfigAddress from 'component/Modals/ModalConfigAddress';
import ModalPaymentMethods from 'component/Modals/ModalPaymentMethods';
import ModalConfigSeasonalThemes from 'component/Modals/ModalConfigSeasonalThemes';
import ModalExtras from 'component/Modals/ModalExtras';
import ModalPremium from 'component/Modals/ModalPremium';
import ModalBusinessLinks from 'component/Modals/ModalBusinessLinks';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { ModalContext } from 'utils/hooks/ModalBehavior';

const TabStore = () => {
  const { t } = useTranslation(['menuDetails', 'menus', 'common']);

  const { showModal } = useContext(ModalContext);
  const isDarkTheme = useSelector(selectors.business.isDarkTheme);
  const actionColor = useSelector(selectors.business.actionColor);
  const backgroundColor = useSelector(selectors.business.backgroundColor);
  const business = useSelector(selectors.business.business);
  const isPremium = useSelector(selectors.business.isPremium);
  const bizCfg = business.businessConfig;
  const isFullAdmin = useSelector(selectors.user.isFullAdmin);

  const businessTranslationLanguages = useSelector(selectors.business.translationLanguages);
  const listTranslations = useMemo(() => businessTranslationLanguages.map(
    (lang) => t(`common:languages:${lang.toLowerCase()}`)
  ).join(', '), [businessTranslationLanguages, t]);

  const showModalTheme = useCallback(( ) => showModal(ModalConfigTheme), [showModal]);
  const showModalLogoEdition = useCallback(( ) => showModal(ModalLogoEdition), [showModal]);
  const showModalSocialEdition = useCallback(( ) => showModal(ModalSocialEdition), [showModal]);
  const showModalTranslationEdition = useCallback(( ) => showModal(ModalConfigTranslations), [showModal]);
  const showModalStory = useCallback(( ) => showModal(ModalStory), [showModal]);
  const showModalAddress = useCallback(( ) => showModal(ModalConfigAddress), [showModal]);
  const showModalPaymentMethods = useCallback(( ) => showModal(ModalPaymentMethods), [showModal]);
  const showModalConfigSeasonalThemes = useCallback(( ) => showModal(ModalConfigSeasonalThemes), [showModal]);
  const showModalExtras = useCallback(( ) => showModal(ModalExtras), [showModal]);

  const showModalBusinessLinks = useCallback(( ) => showModal(ModalBusinessLinks), [showModal]);

  const showModalColors = useCallback(( ) => {
    if (isPremium) {
      showModal(ModalConfigColors);
    } else {
      showModal(ModalPremium);
    }
  }, [showModal, isPremium])

  const hasPaymentMethod = (bizCfg.canPayCash || bizCfg.canPayCreditcard || bizCfg.canPayQrcode || bizCfg.canPayEmoney || bizCfg.canPayTransportcard || bizCfg.canPayPointcard);

  const hasSeasonalTheme = (bizCfg.seasonalThemeLunar || bizCfg.seasonalThemeBlossom || bizCfg.seasonalThemeTanabata || bizCfg.seasonalThemeHalloween || bizCfg.seasonalThemeXmas);

  return (
    <section>
      <div className="f f-jc-space-b mb-2">
        <h2>{t('menus:store.title')}</h2>
      </div>
      <div className="c-gray-600">{t('menuDetails:customization.desc')}</div>
      <div className="mt-3">
        {!isPremium && <CardCustomization
          title={t('menuDetails:customization:menuTheme')}
          value={t(`menuDetails:customization.theme.${isDarkTheme ? 'dark' : 'light'}`)}
          onClickEdit={showModalTheme}
        />}
        <CardCustomization
          title={t('menuDetails:customization:mainColor')}
          value={[
            <span key="backgroundColor" className="card-customization__color" style={{backgroundColor}} />,
            <span key="actionColor" className="card-customization__color" style={{backgroundColor: actionColor}} />
          ]}
          onClickEdit={showModalColors}
          isPremiumOnly={true}
        />
        <CardCustomization
          title={t('menuDetails:customization:logo')}
          value={[
            (bizCfg.logoUrl || bizCfg.coverUrl) ? <span key="logoCoverUrl" className="icon icon-image icon-16" /> : null,
          ]}
          showInfo={(bizCfg.logoUrl || bizCfg.coverUrl) ? true : false}
          className="card-customization--logo-sns"
          onClickEdit={showModalLogoEdition}
        />
        <CardCustomization
          title={t('menuDetails:customization:social')}
          value={[
            business.facebookId ? <span key="facebookId" className="icon icon-facebook icon-16" /> : null,
            business.twitterId ? <span key="twitterId" className="icon icon-twitter icon-16" /> : null,
            business.instagramId ? <span key="instagramId" className="icon icon-insta-fill icon-16" /> : null,
          ]}
          className="card-customization--logo-sns"
          onClickEdit={showModalSocialEdition}
        />
        <CardCustomization
          title={t('menuDetails:customization:story')}
          value={[
            (bizCfg.i18n && bizCfg.i18n.story) ? <span key="hasStory" className="icon icon-menu icon-16" /> : null,
            (bizCfg.storyUrl) ? <span key="logoStoryUrl" className="icon icon-image icon-16" /> : null,
          ]}
          showInfo={((bizCfg.i18n && bizCfg.i18n.story) || bizCfg.storyUrl) ? true : false}
          onClickEdit={showModalStory}
        />
        <CardCustomization
          title={t('menuDetails:customization:address')}
          value={[
            (bizCfg.isSameAddress || bizCfg.storeAddress) ? <span key="hasAddress" className="icon icon-pin icon-16" /> : null,
            (bizCfg.isSameTelephone || bizCfg.storeTelephone) ? <span key="hasPhone" className="icon icon-phone icon-16" /> : null,
          ]}
          showInfo={(bizCfg.isSameAddress || bizCfg.isSameTelephone || bizCfg.storeAddress || bizCfg.storeTelephone) ? true : false}
          onClickEdit={showModalAddress}
        />
        <CardCustomization
          title={t('menuDetails:customization.payments')}
          value={[
            hasPaymentMethod ? <span key="canPayCash" className="icon icon-coin-yen icon-16" /> : null,
          ]}
          showInfo={hasPaymentMethod ? true : false}
          onClickEdit={showModalPaymentMethods}
        />
        <CardCustomization
          title={t('menuDetails:customization.extras')}
          value={[
            bizCfg.hasWifi ? <span key="hasWifi" className="icon icon-connection icon-16" /> : null,
            business.websiteUrl ? <span key="websiteUrl" className="icon icon-link icon-16" /> : null,
            (bizCfg.i18n && bizCfg.i18n.note) ? <span key="hasNote" className="icon icon-menu icon-16" /> : null,
          ]}
          showInfo={(business.websiteUrl || bizCfg.hasWifi || (bizCfg.i18n && bizCfg.i18n.note)) ? true : false}
          onClickEdit={showModalExtras}
        />
        <CardCustomization
          title={t('menuDetails:customization:translations')}
          value={listTranslations}
          onClickEdit={showModalTranslationEdition}
          isPremiumOnly={true}
        />
        <CardCustomization
          title={t('menuDetails:customization.seasonalTheme')}
          value={[
            hasSeasonalTheme ? <span key="seasonalTheme" className="icon icon-calendar icon-16" /> : null,
          ]}
          showInfo={hasSeasonalTheme ? true : false}
          onClickEdit={showModalConfigSeasonalThemes}
        />
      </div>
      {isFullAdmin && <div className="mt-3">
        <CardCustomization
          title={t('menuDetails:customization.businessLinks')}
          value={business.linkedBusinesses.map(b => <span key={b.id} className="icon icon-store icon-16" title={b.name} />)}
          showInfo={(business.linkedBusinesses.length) ? true : false}
          onClickEdit={showModalBusinessLinks}
        />
      </div>}
    </section>
  )
}

export default TabStore
