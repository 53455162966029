import store from 'store/store'
import { selectors } from 'store'

export interface ErrorType {
  status: number,
  message: string,
  error: string
}
const ErrorException = (status: number, error: string, message: string): ErrorType => ({status, error, message})

class CustomFetch {
  getCSRFToken = () => selectors.user.CSRFToken(store.getState())

  get = (url: string, headers: {} = {}) => fetch(url, { 
    credentials: 'include',
    headers: {...headers} 
  }).then(this.handleErrors)
  
  handleErrors = async (response: any): Promise<Response> => {
    if (!response.ok) {
      const res = await response.json()
      if (res.error && res.statusCode && res.message) {
        throw ErrorException(res.statusCode, res.error, res.message)
      }
      throw ErrorException(response.status, response.type, response.statusText)
    }
    return response;
  }

  fetchByMethodWithRetry = async (url: string, method: string, params: any, headers: {} = {}, canRetryNTimes: number = 0 ) => {
    if (!canRetryNTimes) {
      return this.fetchByMethod(url, method, params, headers)
    }

    try {
      return await this.fetchByMethod(url, method, method, params)
    } catch(err) {
      if (canRetryNTimes === 1) throw err;
      return await this.fetchByMethod(url, method, canRetryNTimes - 1);
    }
  };

  fetchByMethod = (url: string, method: string, params: any, headers: {} = {}) => fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCSRFToken() as string,
      },
      body: params ? JSON.stringify(params) : null,
  }).then(this.handleErrors)

  post = (url: string, params: any, canRetryNTimes?: number) => this.fetchByMethodWithRetry(url, 'POST', params, {}, canRetryNTimes)

  put = (url: string, params: any, canRetryNTimes?: number) => this.fetchByMethodWithRetry(url, 'PUT', params, {}, canRetryNTimes)

  patch = (url: string, params: any, canRetryNTimes?: number) => this.fetchByMethodWithRetry(url, 'PATCH', params, {}, canRetryNTimes)

  delete = (url: string, params?: any, canRetryNTimes?: number) => this.fetchByMethodWithRetry(url, 'DELETE', params, {}, canRetryNTimes)
}

export const customFetch = new CustomFetch()
