import { customFetch } from 'api/helpers/customFetch'
import { API_CATEGORIES_URL } from 'api/helpers/routes'
import { defaultLanguage } from 'store/Translations'
import { CategoryType } from './index'

export const getCategories = async (lang = defaultLanguage): Promise<{categories: CategoryType[]}> => {
  const request = await customFetch.get(API_CATEGORIES_URL, {
    'Accept-Language': lang,
  })
  const response = await request.json();
  return { categories: response }
}

export const getCategory = async (id: number): Promise<{category: CategoryType}> => {
  const request = await customFetch.get(`${API_CATEGORIES_URL}/${id}`)
  const response = await request.json();
  return { category: response }
}

export const postFile = async (CSRFToken: string, id: number, file: File): Promise<{key: string}> => {
  const formData = new FormData()
  formData.append('file', file)

  const request = await fetch(`${API_CATEGORIES_URL}/${id}/files`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': CSRFToken,
    },
    body: formData,
  })

  const response = await request.json();
  return { key: response.key }
}

export const postCategory = async (category: {name: string, description: string, parentCategoryId?: number, photoUrl?: string, linkedBusinessId?: number | null}): Promise<{id: number}> => {
  const parentCategoryId = category.parentCategoryId ? {parentCategoryId: category.parentCategoryId} : {}
  const request = await customFetch.post(API_CATEGORIES_URL, {
    name: category.name,
    description: category.description,
    photoUrl: category.photoUrl && category.photoUrl.length ? category.photoUrl : '',
    ...parentCategoryId,
    linkedBusinessId: category.linkedBusinessId,
  })
  const response = await request.json()
  return { id: response.id }
}

export const patchCategory = async (category: {name: string, description: string, parentCategoryId?: number, photoUrl?: string, linkedBusinessId?: number | null}, id: number): Promise<Response|null> => {
  const parentCategoryId = category.parentCategoryId ? {parentCategoryId: category.parentCategoryId} : {}
  const request = await customFetch.patch(`${API_CATEGORIES_URL}/${id}`, {
    name: category.name,
    description: category.description,
    photoUrl: category.photoUrl && category.photoUrl.length ? category.photoUrl : '',
    ...parentCategoryId,
    linkedBusinessId: category.linkedBusinessId,
  })
  return request
}

export const deleteCategory = async (id: number): Promise<Response> => {
  const request = await customFetch.delete(`${API_CATEGORIES_URL}/${id}`)
  const res = await request.json()
  return res
}
