import CardMenuCategory from 'component/Cards/CardMenuCategory';
import CardEmptyContent from 'component/Cards/CardEmptyContent';
import Loader from 'component/Loader';
import ModalMenuCategoryEdition from 'component/Modals/ModalMenuCategoryEdition';
import React, { FC, useMemo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import { ModalContext } from 'utils/hooks/ModalBehavior';
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { MODE } from 'store/MenuDetails';

export enum DraggableTypes {
  Category = 'Category',
  Subcategory = 'Subcategory',
  Product = 'Product'
}

interface MenuOverviewProps {
  className?: string,
  menuId: number
}

const MenuOverview: FC<MenuOverviewProps> = ({ menuId, className}) => {
  const { t } = useTranslation(['menuDetails'])
  const dispatch = useDispatch()

  const isLoadingDatas = useSelector(selectors.menuDetails.isLoadingDatas)
  const isSavingDatas = useSelector(selectors.menuDetails.isSavingDatas)
  const menu = useSelector(selectors.menuDetails.menu)
  const hasContent = useMemo(() => menu && menu.length > 0, [menu])

  const { showModal } = useContext(ModalContext)

  const showModalMenuCategoryEdition = useCallback(() => {
     showModal(ModalMenuCategoryEdition, { menuId: menuId })
  }, [showModal, menuId])

  const getCategoryId = useCallback((value: string) => {
    const categoryRe =  /(?:-c)(\d+)/
    const categoryId = value.match(categoryRe)
    return categoryId && categoryId.length ? parseInt(categoryId[1]) : -1
  }, [])

  const getSubcategoryId = useCallback((value: string) => {
    const subcategoryRe = /(?:-sc)(\d+)/
    const subcategoryId = value.match(subcategoryRe)
    return subcategoryId && subcategoryId.length ? parseInt(subcategoryId[1]) : -1
  }, [])

  const getCategoriesId = useCallback((value: string) => ({
    categoryId: getCategoryId(value),
    subcategoryId: getSubcategoryId(value),
  }), [getSubcategoryId, getCategoryId])

  const getProductId = useCallback((value: string) => {
    const productRe = /(?:-p)(\d+)/
    const productId = value.match(productRe)
    return productId && productId.length ? parseInt(productId[1]) : -1
  }, [])

  const getLinkedBusinessId = useCallback((value: string) => {
    const linkedBusinessRe = /(?:-lb)(\d+)/
    const linkedBusinessId = value.match(linkedBusinessRe)
    return linkedBusinessId && linkedBusinessId.length ? parseInt(linkedBusinessId[1]) : -1
  }, [])

  const isProductDroppableAtTarget = useCallback((draggable_id: string, target_droppable_id: string) => {
    const linkedBusinessIds = {
      target: getLinkedBusinessId(target_droppable_id),
      product: getLinkedBusinessId(draggable_id)
    };

    return (linkedBusinessIds.target >= 0 && linkedBusinessIds.target !== linkedBusinessIds.product); // categories linked to other businesses can only contain products of the same business
  }, [getLinkedBusinessId])

  const [isDropDisabled, setIsDropDisabled] = useState(false);
  const onDragUpdate = useCallback((result) => {
    const { destination, draggableId, type } = result;

    if (destination && type === DraggableTypes.Product) {
      if (isProductDroppableAtTarget(draggableId, destination.droppableId)) {
        setIsDropDisabled(true);
        return;
      }
    }

    setIsDropDisabled(false);
  }, [setIsDropDisabled, isProductDroppableAtTarget])

  const onDragEnd = useCallback(async (result) => {
    setIsDropDisabled(false); // So next drag starts clean

    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    const order = destination.index

    switch (type) {
      case DraggableTypes.Product:
        const origin = getCategoriesId(source.droppableId)
        const target = getCategoriesId(destination.droppableId)
        const productId = getProductId(draggableId)

        if (isProductDroppableAtTarget(draggableId, destination.droppableId)) {
          return; // Not allowed
        }

        const originCategoryId = origin.subcategoryId >= 0 ? origin.subcategoryId : origin.categoryId
        const targetCategoryId = target.subcategoryId >= 0 ? target.subcategoryId : target.categoryId

        dispatch( actions.menuDetails.switchProductCategory({productId, origin, target, order: destination.index }))

        dispatch(actions.menuDetails.updateProductOrder({
          id: menuId,
          productId,
          categoryId: originCategoryId,
          order,
          ...(targetCategoryId !== originCategoryId ? { newCategoryId: targetCategoryId } : {}),
          mode: MODE.draft
         }))
        break
      case DraggableTypes.Category:
        const categoryId = getCategoryId(draggableId)
        dispatch(actions.menuDetails.switchCategoryOrder({ categoryId: categoryId, order: order }))
        dispatch(actions.menuDetails.updateCategoryOrder({id: menuId, categoryId, order }))
        break
      default:
         const subcategoryId = getSubcategoryId(draggableId)
         const parentCategoryId = getCategoryId(source.droppableId)
        dispatch(actions.menuDetails.switchCategoryOrder({ categoryId: parentCategoryId, order: order, subcategoryId }))
        dispatch(actions.menuDetails.updateCategoryOrder({id: menuId, categoryId: subcategoryId, order }))
        break
    }
  }, [dispatch, getProductId, getCategoriesId, getSubcategoryId, getCategoryId, menuId, isProductDroppableAtTarget, setIsDropDisabled])

  const triggerFetchMenu = useCallback(() => {
    dispatch(actions.menuDetails.fetchMenu({ id: menuId }))
  }, [dispatch, menuId])

  useEffect(() => {
    triggerFetchMenu()
  }, [triggerFetchMenu])

  useEffect(() => {
    dispatch(actions.menuDetails.removeQueue())
    dispatch(actions.menuDetails.setMode({mode: MODE.draft}))
  }, [dispatch])

  return (
    <div className={className ? className : ''} id="MenuOverview">
      <div className="f f-jc-space-b mb-2">
        <h2>{t('menuDetails:overview:title')}</h2>
        {hasContent && (
          <button
            onClick={showModalMenuCategoryEdition}
            className="link c-primary ft-600 f-inline f-ai-center"
          >
            <span className="icon icon-plus icon-20  mr-1"></span>
            {t('menuDetails:overview:add:category')}
          </button>
        )}
      </div>
      {(isLoadingDatas || isSavingDatas) ?
        <Loader /> :
          <>
            {!hasContent && <CardEmptyContent title={t('menuDetails:overview:empty:title')} ctaText={t('menuDetails:overview:empty:cta')} onClickCTA={showModalMenuCategoryEdition}/>}

            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <Droppable
                droppableId={"droppableCategoryZone"}
                type={DraggableTypes.Category}
              >
                {(provided, snapshot) => (
                  <ul style={isDropDisabled ? {cursor: "no-drop"} : {}} ref={provided.innerRef} {...provided.droppableProps}>
                    {hasContent && menu.map((category, key) => <CardMenuCategory order={key} menuId={menuId} category={category} key={`c${category.id}`} />)}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </>
      }
    </div>
  )
}

export default MenuOverview;
