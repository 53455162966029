import CardCategory from 'component/Cards/CardCategory';
import CardEmptyContent from 'component/Cards/CardEmptyContent';
import Loader from 'component/Loader';
import ModalCategoryEdition from 'component/Modals/ModalCategoryEdition';
import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { ModalContext } from 'utils/hooks/ModalBehavior';

interface TabCategoriesProps {
}

const TabCategories: FC<TabCategoriesProps> = () => {
  const categories = useSelector(selectors.categories.categories)
  const isLoadingDataList = useSelector(selectors.categories.isLoadingDatasList)
  const { t } = useTranslation(['menus'])
  const { showModal } = useContext(ModalContext)

  const showModalCategoryEdition = useCallback(() => {
    showModal(ModalCategoryEdition)
  }, [showModal])

  return (
    <section>
      <div className="f f-jc-space-b mb-2">
        <h2>{t('menus:categories.title')}</h2>
        <button onClick={showModalCategoryEdition} className="link c-primary ft-600 f-inline f-ai-center"><span className="icon icon-plus icon-16 mr-1"></span>{t('menus:category.create')}</button>
      </div>
      <div>
        { isLoadingDataList ? <Loader className="ft-center" /> : 
          <>
            {categories.map((category, index) => <CardCategory category={category} isOpen={true} key={category.id} />)}
            {!categories.length && <CardEmptyContent title={t('menus:categories:empty:title')} desc={t('menus:categories:empty:desc')} ctaText={t('menus:categories:empty:cta')} onClickCTA={showModalCategoryEdition}/>}
          </>
        }
      </div>
    </section>
  )
}

export default TabCategories