import { customFetch } from "api/helpers/customFetch";
import { API_ADMIN_BUSINESSES, API_ADMIN_STATISTICS, API_ADMIN_MANAGE_BUSINESS, API_ADMIN_MENU_IMPORTS } from "api/helpers/routes";
import { AdminBusiness, AdminStatistics, AdminMenuImport } from ".";

export const getBusinesses = async (): Promise<{businesses: AdminBusiness[]}> => {
    const request = await customFetch.get(API_ADMIN_BUSINESSES)
    const response = await request.json()
    return {businesses: response}
}

export const getMenuImports = async(): Promise<{menuImports: AdminMenuImport[]}> => {
    const request = await customFetch.get(API_ADMIN_MENU_IMPORTS)
    const response = await request.json()
    return {menuImports: response}
}

export const getStatistics = async(slug?: string): Promise<{statistics: AdminStatistics}> => {
  const request = await customFetch.get(`${API_ADMIN_STATISTICS}${slug ? `?slug=${slug}` : ''}`);
  const response = await request.json();
  return {statistics: response};
}

export const getManagedBusiness = async(id:number): Promise<void> => {
    await customFetch.get(`${API_ADMIN_MANAGE_BUSINESS}/${id}/manage`)
}

export const updateMenuImportStatus = async (id :number): Promise<void> => {
    await customFetch.post(`${API_ADMIN_MENU_IMPORTS}/${id}/process`, null)
}

export const patchAdminBusiness = async (id: number, business: {
  welcomeKitSentAt?: string,
  letterSentAt?: string,
  snsPostedAt?: string,
  isPhotoshootRequired?: boolean,
  photoshotAt?: string,
  businessNote?: string,
  isEvent?: boolean,
}): Promise<Response> => {
  const request = await customFetch.patch(`${API_ADMIN_MANAGE_BUSINESS}/${id}`, business);

  return request;
}

export const postResendVerification = async (id: number): Promise<Response> => {
  const request = await customFetch.post(`${API_ADMIN_MANAGE_BUSINESS}/${id}/resend-verification`, null);

  return request;
}

export const patchDemoFlag = async (id: number, isDemo: boolean): Promise<Response> => {
  const request = await customFetch.patch(`${API_ADMIN_MANAGE_BUSINESS}/${id}/demo`, {
    isDemo
  });

  return request;
}
