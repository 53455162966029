import React, { FC, useCallback, useMemo } from 'react'
import TimeField from 'react-simple-timefield'
import { hours } from 'store/Menus'

interface InputTimeProps {
  className?: string
  name?: string
  realName: hours
  inputKey: number
  onChange?: (key: number, type: hours, time: string) => void
  inputRef?: any
  value: string,
  error?: boolean
}

const InputTime: FC<InputTimeProps> = (props) => {
  const {
    className = '',
    value,
    realName,
    inputKey,
    onChange,
    inputRef,
    error
  } = props

  const timeValue = useMemo(() => value || '00:00', [value])

  const onTimeChange = useCallback((e, time) => {
    if (onChange) onChange(inputKey, realName, time)
  }, [inputKey, realName, onChange])

  return (
    <div className={`input input--time ${className} ${error ? 'has-error' : ''}`}>
      <TimeField ref={inputRef} value={timeValue} style={{width: 'auto', textAlign: 'center', fontWeight: 600}} onChange={onTimeChange} />
    </div>
  )
}

export default InputTime
