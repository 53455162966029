import BannerError from 'component/Banners/BannerError'
import BannerInfo from 'component/Banners/BannerInfo'
import React, { FC, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { actions } from 'store'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeLatest } from 'utils/hooks/useSagaEffects'

const Verify: FC<any> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['login'])
  const { showStickyBanner } = useContext(StickyBannerContext)

  const query = new URLSearchParams(useLocation().search)
  const verifyKey = query.get('verify_key')

  useEffect(() => {
    if(verifyKey) {
      dispatch(actions.user.verifyUser({verifyKey}))
    }
  }, [dispatch, verifyKey])


  const onError = useCallback((e) => {
    history.push("/login")
    showStickyBanner(BannerError, { type: e.payload.message })
  }, [showStickyBanner, history])

  const onSuccess = useCallback(() => {
    history.push("/login")
    showStickyBanner(BannerInfo, { message: t(`login:success:verify`)})
  }, [showStickyBanner, history, t])

  useSagaTakeLatest(actions.user.verifyUserError, onError)
  useSagaTakeLatest(actions.user.verifyUserSuccess, onSuccess)

  return (
    <></>
  )
}

export default Verify
