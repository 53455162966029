import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga';
import { reducers } from './index'
import rootSaga from './saga';

const rootReducer = combineReducers(reducers)
export type RootState = ReturnType<typeof rootReducer>

export const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: true
  // devTools: process.env.NODE_ENV !== 'production'
})

sagaMiddleware.run(rootSaga)

// If you want to use a typed version of useSelector
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
