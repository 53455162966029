import React, { FC, useContext, useMemo } from 'react'
import { StickyBannerContext } from '.'

const StickyBannerRoot: FC = () => {
  const { component: Component, props, hideStickyBanner } = useContext(StickyBannerContext)
  const customProps = useMemo(() => ({
    ...props, 
    onRequestClose: hideStickyBanner, 
    className: `${props && props.className ? `${props.className} ` : ''}banner--sticky`
  }), [props, hideStickyBanner])

  return Component ? <Component {...customProps} /> : null
}

export default StickyBannerRoot