import { all, call, put, race, SagaReturnType, take, takeEvery, takeLatest, select } from 'redux-saga/effects'
import { actions,selectors } from 'store';
import { ProductsType, PRODUCT_STATUS } from '.';
import * as services from './services';

const createTempProduct =  (params: {
    id: number,
    price: number|string,
    currency?: string,
    photoUrl?: string|null,
    name: string,
    description: string,
    status?: PRODUCT_STATUS,
    diets?: any[],
    menuProducts?: any[],
    isDisused?: boolean,
    createdAt?: string,
    isOnline?: boolean,
    isSoldOut?: boolean,
    spiciness?: number|null
  }
): ProductsType => ({
  id: params.id,
  photoUrl: params.photoUrl || '',
  price: params.price.toString(),
  currency: params.currency || 'JPA',
  i18n: {
    name: params.name,
    description: params.description,
  },
  createdAt: params.createdAt || '',
  diets: params.diets || [],
  menuProducts: params.menuProducts || [],
  isDisused: false,
  status: params.status || PRODUCT_STATUS.DRAFT,
  isOnline: false,
  isSoldOut: params.isSoldOut,
  spiciness: params.spiciness || null
})

export function* fetchProducts(action: ReturnType<typeof actions.products.fetchProducts>) {
  try {
    yield put(actions.products.setIsLoadingDatasList({isLoading: true}))
    const request: SagaReturnType<typeof services.getProducts> = yield call(services.getProducts)
    yield put(actions.products.setIsLoadingDatasList({isLoading: false}))
    yield put(actions.products.setProducts({products: request.products}))
  } catch(e) {
    yield put(actions.menus.setIsLoadingDatasList({isLoading: false}))
  }
}

export function* fetchProduct(action: ReturnType<typeof actions.products.fetchProduct>) {
  try {
    yield call(services.getProduct, action.payload.id)
  } catch(e) {
  }
}

export function* fetchLinkedProducts(action: ReturnType<typeof actions.products.fetchLinkedProducts>) {
  try {
    yield put(actions.products.setIsLoadingDatasList({ isLoading: true }));
    const request: SagaReturnType<typeof services.getLinkedProducts> = yield call(services.getLinkedProducts, action.payload.business_id);
    yield put(actions.products.setIsLoadingDatasList({ isLoading: false }));
    yield put(actions.products.appendLinkedProducts({ products: request.products, business_id: action.payload.business_id }));
  } catch(e) {
    yield put(actions.menus.setIsLoadingDatasList({ isLoading: false }));
  }
}

export function* createProduct(action: ReturnType<typeof actions.products.createProduct>) {
  const { product, menuId, categoryId, parentCategoryId, imageFile, withFetch = true } = action.payload
  try {
    const request: SagaReturnType<typeof services.postProduct>= yield call(services.postProduct, product)
    const tempProduct = createTempProduct({ id: request.id, price: product.price, name: product.name, description: product.description, photoUrl: product.photoUrl, spiciness: product.spiciness, diets: product.diets })

    const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
    const needsCreateImageFile = imageFile !== undefined
    const needsAddToMenu = menuId !== undefined && categoryId !== undefined

    if (needsCreateImageFile) {
      yield put(actions.products.createProductFile({ id: request.id, file: imageFile! }))
      const [error, _success]: any[] = yield race([
        take(actions.products.createProductFileError),
        take(actions.products.createProductFileSuccess)
      ])
      if (error) yield put(actions.products.createProductError({ error }))
      if (_success) tempProduct.photoUrl = _success.payload.key
    }

    yield put(actions.products.createProductSuccess({ product: tempProduct }))

    if (needsAddToMenu) {
      if (isDraftMode) {
        yield put(actions.products.addProduct({product: tempProduct}));
      }
      yield put(actions.menuDetails.triggerAddProduct({ id: menuId!, categoryId: categoryId!, parentCategoryId, productId: request.id}))
      const [error, _success]: any[] = yield race([
        take(actions.menuDetails.addProductError),
        take(actions.menuDetails.addProductSuccess)
      ])
      if (error !== undefined) yield put(actions.products.createProductError({ error }))
    }
    if (withFetch) {
      yield put(actions.products.fetchProducts())
    }

  } catch(e: any) {
    yield put(actions.products.createProductError({error: e}))
  }
}

export function* createProductFile(action: ReturnType<typeof actions.products.createProductFile>) {
  const { id, file } = action.payload
  try {
    const CSRFToken: string = yield select(selectors.user.CSRFToken)
    const request: SagaReturnType<typeof services.postFile>= yield call(services.postFile, CSRFToken, id, file)

    yield put(actions.products.createProductFileSuccess({key: request.key}))
  } catch(e: any) {
    yield put(actions.products.createProductFileError({error: e}))
  }
}

export function* updateProduct(action: ReturnType<typeof actions.products.updateProduct>) {
  const { id, imageFile, product, menuId, categoryId, parentCategoryId } = action.payload
  const needsCreateImageFile = imageFile !== undefined

  const tempProduct = createTempProduct({ id: id, ...product })

  const isDraftMode: boolean = yield select(selectors.menuDetails.isDraftMode)
  const needsAddToMenu = menuId !== undefined && categoryId !== undefined

  try {
    yield call(services.patchProduct, product, id)
    if (needsCreateImageFile) {
      yield put(actions.products.createProductFile({ id: id, file: imageFile! }))
      const [error, _success]: any[] = yield race([
        take(actions.products.createProductFileError),
        take(actions.products.createProductFileSuccess)
      ])
      if (error) yield put(actions.products.updateProductError({ error }))
      if (_success) tempProduct.photoUrl = _success.payload.key
    }

    yield put(actions.products.updateProductSuccess({ id }))
    if (!needsAddToMenu && !isDraftMode) {
      yield put(actions.products.fetchProducts())
    } else if (categoryId !== undefined) {
      yield put(actions.menuDetails.updateProduct({id: id, product: tempProduct, categoryId, parentCategoryId}))
    }

  } catch(e: any) {
    yield put(actions.products.updateProductError({error: e}))
  }
}

export function* removeProduct(action: ReturnType<typeof actions.products.removeProduct>) {
  try {
    yield call(services.deleteProduct, action.payload.id)
    yield put(actions.products.fetchProducts())
  } catch(e) {
  }
}

export function* updateProductSold(action: ReturnType<typeof actions.products.updateProductSold>) {
  const { product, categoryId, parentCategoryId, isSoldOut } = action.payload
  const tempProduct = createTempProduct({ id: product.id, name: product.i18n.name, description: product.i18n.description, photoUrl: product.photoUrl, price: product.price, diets: product.diets, isSoldOut, spiciness: product.spiciness })

  try {
    yield call(services.updateProductSold, product.id, isSoldOut)

    yield put(actions.products.updateProductSuccess({ id: product.id }))
    yield put(actions.menuDetails.updateProduct({id: product.id, product: tempProduct, categoryId, parentCategoryId}))
  } catch(e) {
  }
}

export function* fetchDietOptions(action: ReturnType<typeof actions.products.fetchDietOptions>) {
  const { lang = 'JA' } = action.payload
  try {
    const request: SagaReturnType<typeof services.getDietOptions> = yield call(services.getDietOptions, lang)
    yield put(actions.products.setDietOptions({dietOptions: request.diets}))
  } catch(e) {
  }
}

export function* root() {
  yield all([
    takeEvery(actions.products.fetchProducts, fetchProducts),
    takeLatest(actions.products.fetchProduct, fetchProduct),
    takeLatest(actions.products.fetchLinkedProducts, fetchLinkedProducts),
    takeEvery(actions.products.createProduct, createProduct),
    takeLatest(actions.products.updateProduct, updateProduct),
    takeLatest(actions.products.updateProductSold, updateProductSold),
    takeLatest(actions.products.removeProduct, removeProduct),
    takeLatest(actions.products.createProductFile, createProductFile),
    takeLatest(actions.products.fetchDietOptions, fetchDietOptions)
  ])
}
