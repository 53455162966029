import BannerError from 'component/Banners/BannerError'
import CardBusiness from 'component/Cards/CardBusiness'
import InputSelect from 'component/Form/Inputs/InputSelect';
import Switch from 'component/Form/Inputs/Switch';
import { FC, useCallback, useContext, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import { useSagaTakeLatest } from 'utils/hooks/useSagaEffects'

const TabBusinesses: FC = () => {
  const businesses = useSelector(selectors.admin.businesses);
  const dispatch = useDispatch()
  const { showStickyBanner } = useContext(StickyBannerContext)

  const [isShowDemo, setIsShowDemo] = useState(true);

  const [sortOrder, setSortOrder] = useState('createdAt');
  const sortedBusinesses = useMemo(() => {
    const sorted = [...businesses].sort((a, b) => {
      let valA: any = a.createdAt;
      let valB: any = b.createdAt;

      if (sortOrder === 'tasksRequired') {
        const nowDate = (new Date()).getTime();
        const calcTaskScore = (itm: any) => {
          let val = 0;
          if (!itm.isDemo) {
            const creationDate = (new Date(itm.createdAt)).getTime();

            if (!itm.activatedAt) {
              val += (nowDate - creationDate) * 2;
            } else {
              const activationDate = (new Date(itm.activatedAt)).getTime();

              if (!itm.welcomeKitSentAt) {
                val += (nowDate - activationDate) * 6;
              }

              if (!itm.firstPublishedAt) {
                val += (nowDate - activationDate) * 2;
              } else {
                const publicationDate = (new Date(itm.firstPublishedAt)).getTime();

                if (!itm.letterSentAt) {
                  val += (nowDate - publicationDate) * 2;
                }

                if (!itm.snsPostedAt) {
                  val += (nowDate - publicationDate);
                }

                if (itm.isPhotoshootRequired && !itm.photoshotAt) {
                  val += (nowDate - publicationDate);
                }
              }
            }
          }

          return val;
        }

        valA = calcTaskScore(a);
        valB = calcTaskScore(b);
      } else if (sortOrder === 'menuCreatedAt') {
        valA = -1;
        valB = -1;

        if (a.menus && a.menus.length > 0) {
          for (const aItem of a.menus) {
            const newValA = (new Date(aItem.createdAt)).getTime();
            valA = Math.max(valA, newValA);
          }
        }

        if (b.menus && b.menus.length > 0) {
          for (const bItem of b.menus) {
            const newValB = (new Date(bItem.createdAt)).getTime();
            valB = Math.max(valB, newValB);
          }
        }
      } else if (sortOrder === 'stats') {
        valA = a.businessStatistics ? a.businessStatistics.directs + a.businessStatistics.referrals : -1;
        valB = b.businessStatistics ? b.businessStatistics.directs + b.businessStatistics.referrals : -1;
      }

      if (valA > valB) {
        return -1;
      }
      if (valA < valB) {
        return 1;
      }
      return 0;
    });

    return sorted;
  }, [businesses, sortOrder])

  const onAdminFetchBusinessSuccess = useCallback((action) => {
    dispatch(actions.admin.setManagingBusiness({managingBusiness: action.payload.isManaging}))
  }, [dispatch])

  const onFetchManagedBusinessSuccess = useCallback((action) => {
    const { id, isManaging } = action.payload
    dispatch(actions.business.fetchBusiness({ id, isAdminFetch: true, isManaging }))
  }, [dispatch])

  const onFetchError = useCallback(() => {
    showStickyBanner(BannerError)
  }, [showStickyBanner])

  const onDemoSwitchChange = useCallback((name, value) => {
    setIsShowDemo(value ? true : false);
  }, [setIsShowDemo])

  const onChangeSorting = useCallback((name: string, value: string) => {
    setSortOrder(value);
  }, [setSortOrder])

  useSagaTakeLatest(actions.admin.fetchManagedBusinessSuccess, onFetchManagedBusinessSuccess)
  useSagaTakeLatest(actions.admin.fetchManagedBusinessError, onFetchError)
  useSagaTakeLatest(actions.business.adminFetchBusinessSuccess, onAdminFetchBusinessSuccess)
  useSagaTakeLatest(actions.business.fetchBusinessError, onFetchError)

  return (
    <section className="mb-6">
      <div className="f f-jc-right mb-1">
        <div className="f-1"></div>
        <div className="f-1"></div>
        <div className="f-1"></div>
        <div className="f-1 f f-center">
          <Switch
            title={isShowDemo ? 'Show DEMO' : 'Hide DEMO'}
            name="isShowDemo"
            value={isShowDemo}
            handleChange={onDemoSwitchChange}
          />
        </div>
        <div className="f-1 f f-center">
          <InputSelect
            placeholder="Account Creation"
            options={[
              {label: 'Account Creation', value: 'createdAt'},
              {label: 'Menu Creation', value: 'menuCreatedAt'},
              // {label: 'Tasks Outstanding', value: 'tasksRequired'},
              {label: 'Statistics', value: 'stats'},
            ]}
            onChange={onChangeSorting}
          />
        </div>
      </div>
      {sortedBusinesses.map((business, key) => {
        const hide = !isShowDemo && business.isDemo;
        return !hide ? <CardBusiness key={business.id} business={business} /> : null;
      })}
    </section>
  )
}

export default TabBusinesses
