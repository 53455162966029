import { useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { selectors, actions } from 'store'
import { AdminBusiness } from 'store/Admin';
import Button from 'component/Buttons/Button';
import { Chart as ReactChart } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

const TabStatistics = () => {
  const { t } = useTranslation('admin')

  const dispatch = useDispatch();
  const isFullAdmin = useSelector(selectors.user.isFullAdmin);

  const { state }: {state?: {business?: AdminBusiness}} = useLocation();
  const business = state && state.business ? state.business : null;

  useEffect(() => {
    const slug = business && business.slug ? business.slug : '';
    dispatch(actions.admin.fetchStatistics({ slug }));
  }, [dispatch, business])

  const statistics = useSelector(selectors.admin.statistics);
  const businesses = useSelector(selectors.admin.businesses)
  const menuImports = useSelector(selectors.admin.menuImports)

  const businessesNonDemo = businesses.filter(biz => !biz.isDemo);
  const businessesEvent = businessesNonDemo.filter(biz => !!biz.isEvent);

  const [dataset, setDataset] = useState('daily');
  const [datasetLength, setDatasetLength] = useState(28);

  Chart.register(CategoryScale);

  const labelTextColorCb = (context: any) => {
    return context.raw === 0 ? '#999' : '#fff';
  };

  const options = useMemo(() => {
    return {
      animation: {
        delay: (context: any) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default') {
            delay = context.dataIndex * 40 + context.datasetIndex * 8;
          }
          return delay;
        },
      },
      interaction: {
        intersect: false,
        mode: 'index' as 'index',
      },
      plugins: {
        title: {
          display: true,
          text: `Menu Visits${business ? `: ${business.name}` : ''}`,
          font: {
            size: 16,
          },
        },
        subtitle: {
          display: true,
          text: `${(dataset === 'monthly' ? `Monthly (Last ${datasetLength} months)` : (dataset === 'weekly' ? `Weekly (Last ${datasetLength} weeks)` : `Daily (Last ${datasetLength} days)`))}`,
          color: 'gray',
          font: {
            size: 14,
          },
          padding: {
            bottom: 8,
          }
        },
        legend: {
          position: 'bottom' as 'bottom',
          labels: {
            usePointStyle: true,
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          usePointStyle: true,
          callbacks: {
            labelTextColor: labelTextColorCb,
          }
        }
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };
  }, [dataset, datasetLength, business]);

  const chartData = useMemo(() => {
    const notBeforeDate = new Date('2022-03-01'); // DG base date
    var createdStartDate = business && (new Date(business.createdAt)) > notBeforeDate ? new Date(business.createdAt) : notBeforeDate; // If a business, their account creation date, otherwise DG base date
    var firstPublishedStartDate = new Date(business && business.firstPublishedAt ? business.firstPublishedAt : createdStartDate); // If available, their first menu publish date
    createdStartDate.setMinutes(0, 0, 0);
    firstPublishedStartDate.setMinutes(0, 0, 0);
    if (dataset === 'monthly') {
      createdStartDate.setDate(0); // Sets to last day of previous month
      firstPublishedStartDate.setDate(0);
    } else if (dataset === 'weekly') {
      createdStartDate.setDate(createdStartDate.getDate() - 7); // Sets to 1 week prior
      firstPublishedStartDate.setDate(firstPublishedStartDate.getDate() - 7);
    } else {
      createdStartDate.setDate(createdStartDate.getDate() - 1); // Sets to 1 day prior
      firstPublishedStartDate.setDate(firstPublishedStartDate.getDate() - 1);
    }

    const startDate = firstPublishedStartDate > createdStartDate ? firstPublishedStartDate : createdStartDate; // Most recent of the above date options.
    const usingDataset = (dataset === 'monthly' ? statistics.total.monthly : (dataset === 'weekly' ? statistics.total.weekly : statistics.total.daily)).filter(itm => new Date(itm.period) >= startDate);
    setDatasetLength(Math.max(1, usingDataset.length - 1));

    const labels = usingDataset.map((item, idx) => {
      const d = new Date(item.period);
      const isLast = idx === 0;

      if (dataset === 'monthly') {
        return isLast ? '今月' : `${d.getFullYear()}年${d.getMonth() + 1}月`;
      }

      if (dataset === 'weekly') {
        return isLast ? '今週' : `${d.getMonth() + 1}月${d.getDate()}日~`;
      }

      return isLast ? '今日' : `${d.getMonth() + 1}月${d.getDate()}日`;
    }).reverse();

    const totals = usingDataset.map(item => item.sum).reverse();

    const qrs = usingDataset.map(item => item.ref.qr).reverse();
    const typeds = usingDataset.map(item => item.ref.direct).reverse(); // now 'direct' means 'typed'
    const directs = usingDataset.map(item => item.ref.qr + item.ref.direct).reverse();
    const menus = usingDataset.map(item => item.ref.menu).reverse();
    const snss = usingDataset.map(item => item.ref.sns).reverse();
    const searches = usingDataset.map(item => item.ref.search).reverse();
    const links = usingDataset.map(item => item.ref.link).reverse();
    const referrals = usingDataset.map(item => item.ref.menu + item.ref.sns + item.ref.search + item.ref.link).reverse();
    const unknowns = usingDataset.map(item => item.ref.unknown).reverse();

    const sum = usingDataset.map(item => item.sum).reduce((partialSum, a) => partialSum + a, 0) - (usingDataset && usingDataset.length > 0 ? usingDataset[0].sum : 0);
    const mean = Math.round(sum / datasetLength);
    const averages = usingDataset.map(item => mean);

    var datasets = [];

    if (qrs.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'QR Code',
        data: qrs,
        borderColor: '#FEB077',
        backgroundColor: '#FEB077BB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (typeds.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'Typed',
        data: typeds,
        borderColor: '#40BF7C',
        backgroundColor: '#40BF7CBB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (menus.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'Menu',
        data: menus,
        borderColor: '#FFDA80',
        backgroundColor: '#FFDA80BB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (snss.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'SNS',
        data: snss,
        borderColor: '#68B9F0',
        backgroundColor: '#68B9F0BB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (searches.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'Search',
        data: searches,
        borderColor: '#4bc0c0',
        backgroundColor: '#4bc0c0BB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (links.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'Link',
        data: links,
        borderColor: '#FF8AA3',
        backgroundColor: '#FF8AA3BB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    if (unknowns.find(item => item > 0)) {
      datasets.push({
        type: 'bar' as const,
        label: 'Unknown',
        data: unknowns,
        borderColor: '#D7D8DB',
        backgroundColor: '#D7D8DBBB',
        borderWidth: 1,
        pointStyle: 'rect',
        borderRadius: 5
      });
    }

    datasets.push({
      type: 'line' as const,
      label: 'Direct',
      data: directs,
      stack: 'subtotal',
      fill: true,
      borderColor: '#E8F7EF',
      backgroundColor: '#E8F7EF',
      borderWidth: 0,
      tension: 0.1,
      pointStyle: 'rectRot',
      pointRadius: 1,
    });

    datasets.push({
      type: 'line' as const,
      label: 'Referral',
      data: referrals,
      stack: 'subtotal',
      fill: true,
      borderColor: '#E6E1EE',
      backgroundColor: '#E6E1EE',
      borderWidth: 0,
      tension: 0.1,
      pointStyle: 'rectRot',
      pointRadius: 1,
    });

    datasets.push({
      type: 'line' as const,
      label: 'Total',
      data: totals,
      fill: false,
      borderColor: '#4f4f4f44',
      backgroundColor: '#4f4f4f',
      borderWidth: 1,
      tension: 0.1,
      pointStyle: 'star',
      pointRadius: 4,
      pointHoverRadius: 6
    });

    datasets.push({
      type: 'line' as const,
      label: 'Average',
      data: averages,
      stack: 'avg',
      fill: false,
      borderColor: '#7f7f7f44',
      backgroundColor: '#7f7f7f',
      borderWidth: 2,
      tension: 0.3,
      pointStyle: 'line',
      pointRadius: 0,
      pointHoverRadius: 1,
      borderDash: [6, 4]
    });

    return {
      labels: labels,
      datasets: datasets
    };
  }, [statistics, dataset, datasetLength, setDatasetLength, business]);

  const handleButtonClickDaily = useCallback(() => {
    setDataset('daily');
  }, [setDataset])

  const handleButtonClickWeekly = useCallback(() => {
    setDataset('weekly');
  }, [setDataset])

  const handleButtonClickMonthly = useCallback(() => {
    setDataset('monthly');
  }, [setDataset])

  const handleDownloadChart = useCallback((ev: any) => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      ev.target.href = canvas.toDataURL();
      ev.target.download = `chart_${dataset}.png`;
    }
  }, [dataset])

  return (
    <section className="f f-direction-column">
      {!business && <div>
        <div className="f f-ai-center mb-4">
          <h2>{t('tabBar.businesses')}</h2>
          <span>
            <p className="mb-0 h2 ml-2">: {businessesNonDemo.length - businessesEvent.length}<span className="ft-secondary ml-1"> (+ {businessesEvent.length} Event)</span></p>
          </span>
        </div>
        {isFullAdmin && <div className="f f-ai-center mb-4">
          <h2>{t('tabBar.menu_imports')}</h2>
          <p className="mb-0 h2 ml-2"> : {menuImports.length}</p>
        </div>}
      </div>}
      {(chartData && statistics && statistics.total && statistics.total.daily && statistics.total.daily.length > 0) && <div>
        <div className="chart-container">
          <div className="f f-jc-space-b">
            <div></div>
            <div>
              <Button
                isPrimary={dataset === 'daily'}
                onClick={handleButtonClickDaily}
                text={'Daily'}
              />
              <Button
                isPrimary={dataset === 'weekly'}
                onClick={handleButtonClickWeekly}
                text={'Weekly'}
              />
              <Button
                isPrimary={dataset === 'monthly'}
                onClick={handleButtonClickMonthly}
                text={'Monthly'}
              />
            </div>
          </div>
          <ReactChart type='bar' options={options} data={chartData} />
          {false && <p className="ft-right">
            <a href="#chart" download={`chart_${dataset}.png`} onClick={handleDownloadChart}><span className="icon icon-20 icon-download" /></a>
          </p>}
        </div>
        <div className="mt-1">
          <h4>Chart Notes:</h4>
          <ul className="list ft-primary">
            <li>
              <h5 className="h4">Direct</h5>
              <ul>
                <li>
                  <h6 className="h4">QR Code</h6>
                  <p>QR code scans (new-style QR codes only).</p>
                </li>
                <li>
                  <h6 className="h4">Typed</h6>
                  <p>Directly typed URLs, and visits from links when browser/website strong privacy settings are enabled. Also includes old-style QR code scans.</p>
                </li>
              </ul>
            </li>
            <li>
              <h5 className="h4">Referral</h5>
              <ul>
                <li>
                  <h6 className="h4">Menu</h6>
                  <p>Linked to from other DIGITOMENU menus.</p>
                </li>
                <li>
                  <h6 className="h4">SNS</h6>
                  <p>Linked to from known SNS platforms.</p>
                </li>
                <li>
                  <h6 className="h4">Search</h6>
                  <p>Linked to from known search engines.</p>
                </li>
                <li>
                  <h6 className="h4">Link</h6>
                  <p>Linked to from any other website, including unknown SNS and search engines.</p>
                </li>
              </ul>
            </li>
            <li>
              <h5 className="h4">Unknown</h5>
              <p>Mostly older data that was not grouped into categories, or the source could not be detected for some reason.</p>
            </li>
          </ul>
        </div>
      </div>}
    </section>
  )
}

export default TabStatistics
