import React, { FC, ReactElement } from 'react'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'

interface LayoutWelcomeKitProps {
  uppertitle: string
  title: string
  description: string
  img?: string
  buttonContent?: string
  onLeaveButton?: () => void
  onNextButton?: () => void
  qrCode?: ReactElement
  submitButton?: ReactElement
}

const LayoutWelcomeKit: FC<LayoutWelcomeKitProps> = ({uppertitle, title, description, img, onLeaveButton, onNextButton, buttonContent, children, qrCode, submitButton}) => {

  const { t } = useTranslation(['menus'])
  const handleOnRequestClose = () => onLeaveButton && onLeaveButton()

  return (
    <div className="f">
      <div className="modal--flow__text-wrapper">
        <div>
          <span className="ft-600 ft-up c-cream-500">{uppertitle}</span>
          <h2 className="modal--flow__title mt-2">{title}</h2>
          <p className="ft-primary mt-3 mb-3 ft-500 c-cream-500">{description}</p>
          {children}
          <div className="modal--flow__buttons">
            {onNextButton && <Button className="btn--primary c-cream-500" text={buttonContent} onClick={onNextButton} />}
            {submitButton && submitButton}
            {onLeaveButton && <Button text={t('menuModalWelcomeKit.step_2.decline_button')} onClick={handleOnRequestClose} />}
          </div>
        </div>
      </div>
      {img && (
        <div className="modal--flow__welcome-image-wrapper" style={{backgroundImage: `url(${img})`}}>
          {qrCode && <div className="qrcode-image-wrapper">{qrCode}</div>}
        </div>
      )}
    </div>
  )
}

export default LayoutWelcomeKit
