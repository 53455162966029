import React, { FC, useCallback } from 'react'

interface LayoutModalProps {
  children: React.ReactNode,
  footerContent?: React.ReactNode,
  hideClose?: boolean,
  onRequestClose: (noHistory?: boolean) => void
  className?: string,
  overlayClassname?: string,
  title?: string,
  isLoading?: boolean,
  showBack?: boolean,
  onRequestBack?: () => void,
  backText?: string
}

export const LayoutModal: FC<LayoutModalProps> = ({ onRequestClose, onRequestBack, ...otherProps }) => {
  const {isLoading = false} = otherProps
  const handleOnRequestClose = useCallback(() => {
    if (isLoading) {
      return;
    }
    onRequestClose()
  }, [isLoading, onRequestClose])

  const handleOnRequestBack = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (onRequestBack) {
      onRequestBack()
    }
  }, [isLoading, onRequestBack])

  return (
    <>
      <div className={`overlay ${isLoading ? 'is-loading' : ''} ${otherProps.overlayClassname || ''}`} onClick={handleOnRequestClose} />
      { otherProps.title && otherProps.title.length ? 
        <div className={`modal ${otherProps.className || ''} ${isLoading ? 'is-loading' : ''}`}>
          <div className="modal__header">
            <h3 className="ft-center" dangerouslySetInnerHTML={{__html: otherProps.title}} />
            {!otherProps.hideClose && <button className="modal__close icon icon-close icon-20 c-cream-500" onClick={handleOnRequestClose} />}
            {otherProps.showBack && (
            <div
              onClick={handleOnRequestBack}
              className="modal__back-wrapper f f-ai-center"
            >
              <button className="modal__back icon icon-20 c-cream-500" />
              {otherProps.backText && (
                <p className="mb-0">{otherProps.backText}</p>
              )}
            </div>
          )}
          </div>
          <div className="modal__body">
            {otherProps.children}
          </div>
          {otherProps.footerContent && <div className="modal__footer ft-right">
            {otherProps.footerContent}
          </div>}
        </div>
        :
        <div className={`modal ${otherProps.className || ''}`}>
          {!otherProps.hideClose && <button className="modal__close icon icon-close icon-20 c-cream-500" onClick={handleOnRequestClose} />}
          {otherProps.showBack && (
            <div
              onClick={handleOnRequestBack}
              className="modal__back-wrapper f f-ai-center"
            >
              <button className="modal__back icon icon-20 c-cream-500" />
              {otherProps.backText && (
                <p className="mb-0">{otherProps.backText}</p>
              )}
            </div>
          )}
          <div className="modal__body">
            {otherProps.children}
          </div>
          {otherProps.footerContent && <div className="modal__footer ft-right">
            {otherProps.footerContent}
          </div>}
        </div>
      }
    </>
  )
}
