import React, { FC, useMemo, useCallback, useState, useContext } from 'react';
import { LayoutModal } from 'component/Layouts/LayoutModal';
import Button from 'component/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import InputText from 'component/Form/Inputs/InputText';
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects';
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior';
import BannerError from 'component/Banners/BannerError';

interface ModalBusinessLinksProps {
  onRequestClose: () => void
}

const ModalBusinessLinks: FC<ModalBusinessLinksProps> = ({onRequestClose}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['menuDetails', 'common']);
  const { showStickyBanner } = useContext(StickyBannerContext);

  const [isLoading, setIsLoading] = useState(false);

  const business = useSelector(selectors.business.business);
  const businessLinkedBusinesses = useSelector(selectors.business.linkedBusinesses);
  const [businessList, setBusinessList] = useState(business.linkedBusinesses);
  const foundLinkableBusiness = useSelector(selectors.business.foundLinkableBusiness);

  const getLogo = useCallback((biz) => (biz && biz.businessConfig && biz.businessConfig.logoUrl) ? <img className="img-circle img-circle--20 mr-2" src={`${process.env.REACT_APP_PICTURE_URL}/${biz.businessConfig.logoUrl.replace('original', '75')}`} alt="" /> : undefined, []);

  const onSave = useCallback(() => {
    setIsLoading(true);
    const removals = businessLinkedBusinesses.filter((lnkBizId) => !businessList.find(biz => lnkBizId === biz.id));
    const additions = businessList.filter((biz) => !businessLinkedBusinesses.find(lnkBizId => lnkBizId === biz.id)).map(b => b.id);

    dispatch(actions.business.manageLinkedBusinesses({
      removalIds: removals, additionIds: additions
    }));
  }, [dispatch, businessLinkedBusinesses, businessList])


  const onRemoveBusiness = useCallback((biz) => {
    setBusinessList(prevState => prevState.filter(item => item !== biz));
  }, [])

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={onSave} text={t('menuDetails:ctas.save')} isPrimary={true} />
    </>
  ), [t, onRequestClose, onSave, isLoading])

  const [setInputValueFunc, setSetInputValueFunc] = useState({ fn: undefined } as any);
  const onAddLinkedBusiness = useCallback((biz) => {
    setBusinessList(prevState => [...prevState, biz]);
    setSlugInputValue('');
    if (setInputValueFunc.fn !== undefined) {
      setInputValueFunc.fn('');
    }
  }, [setInputValueFunc])

  const onManageLinkedBusinessesSuccess = useCallback(() => {
    setIsLoading(false);
    onRequestClose();
  }, [onRequestClose])

  const onManageLinkedBusinessesError = useCallback(() => {
    showStickyBanner(BannerError);
    setIsLoading(false);
  }, [showStickyBanner])

  const [slugInputValue, setSlugInputValue] = useState('');

  const foundBusinessLinkability = useMemo(() => {
    if (foundLinkableBusiness && foundLinkableBusiness.id) {
      if (foundLinkableBusiness.id === business.id || businessList.find(biz => biz.id === foundLinkableBusiness.id)) {
        return 'already_linked';
      }

      return 'linkable';
    }

    return 'not_found';
  }, [foundLinkableBusiness, business, businessList])

  const onChangeSlug = useCallback((name, value, setValueFunc) => {
    const inputSlug = value.replace(/(.*\/)*/, "").replace(/(\?.*)|(#.*)/g, "");
    setSlugInputValue(inputSlug);
    setSetInputValueFunc({ fn: setValueFunc });

    if (inputSlug) {
      dispatch(actions.business.fetchPublicBusiness({ slug: inputSlug }));
    }
  }, [dispatch, setSlugInputValue, setSetInputValueFunc])

  useSagaTakeEvery(actions.business.manageLinkedBusinessesSuccess, onManageLinkedBusinessesSuccess);
  useSagaTakeEvery(actions.business.manageLinkedBusinessesError, onManageLinkedBusinessesError);

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:businessLinksEditionModal.title')}
      onRequestClose={onRequestClose}
      className="modal--config-translations modal--md"
      footerContent={footerContent}
      >
      <div className="c-gray-600">{t('menuDetails:businessLinksEditionModal.desc')}</div>
      <ul className="list list--languages mt-3">
        {businessList.map((biz, i) => (
          <div className="list__item f f-jc-space-b" key={i}>
            <div>
              {getLogo(biz)}
              <span className="list__text" >{ biz.name }</span>
            </div>
            <button className="c-primary" onClick={() => onRemoveBusiness(biz)}>{t('menuDetails:businessLinksEditionModal.delete')}</button>
          </div>
        ))}
      </ul>
      <hr className="mt-3 mb-3" />
      <div>
        <div className="f f-ai-center">
          <InputText
            maxLength={255}
            handleChange={onChangeSlug}
            className="input--material mr-2 f-1"
            label={t('menuDetails:businessLinksEditionModal.inputSlugLabel')}
            placeholder={t('menuDetails:businessLinksEditionModal.inputSlugLabel')}
            name="slug"
          />
          <Button
            onClick={() => onAddLinkedBusiness(foundLinkableBusiness)}
            isDisabled={!(slugInputValue && foundBusinessLinkability === 'linkable')}
            iconLeft="icon icon-plus c-cream-500"
            text={t('menuDetails:businessLinksEditionModal.add')}
          />
        </div>
        {slugInputValue && <div className="mt-1 ml-3">
          {foundLinkableBusiness && foundBusinessLinkability === 'linkable' ? `◎ ${foundLinkableBusiness.name}` : t(`menuDetails:businessLinksEditionModal.error.${foundBusinessLinkability}`)}
        </div>}
      </div>
    </LayoutModal>
  )
}

export default ModalBusinessLinks
