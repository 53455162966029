import React, { FC, useMemo, useCallback, useState, useContext } from 'react'
import { LayoutModal } from 'component/Layouts/LayoutModal'
import Button from 'component/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import InputImageFile from 'component/Form/Inputs/InputImageFile'
import { useForm } from 'react-hook-form'
import { useSagaTakeEvery } from 'utils/hooks/useSagaEffects'
import { StickyBannerContext } from 'utils/hooks/StickyBannerBehavior'
import BannerError from 'component/Banners/BannerError'

interface Inputs {
  text: string
}

interface ModalLogoEditionProps {
  onRequestClose: () => void,
  menuId: number
}

const ModalLogoEdition: FC<ModalLogoEditionProps> = ({onRequestClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['menuDetails', 'common'])
  const formId = useMemo(() => 'modal-logo-edition-form', [])

  const { showStickyBanner } = useContext(StickyBannerContext)

  const business = useSelector(selectors.business.business)
  const logoUrl = useSelector(selectors.business.logoUrl)
  const logoUrlId = useSelector(selectors.business.logoUrlId)
  const coverUrl = useSelector(selectors.business.coverUrl)
  const coverUrlId = useSelector(selectors.business.coverUrlId)

  const [isLoading, setIsLoading] = useState(false)
  const [fileLogo, setFileLogo] = useState<File | null>()
  const [fileCover, setFileCover] = useState<File | null>()
  const [imageLogo, setImageLogo] = useState<{url: string, name: string}>({ url: logoUrl || '', name: '' })
  const [imageCover, setImageCover] = useState<{url: string, name: string}>({ url: coverUrl || '', name: '' })

  const { handleSubmit } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback((form: Inputs) => {
    setIsLoading(true);
    const imageFileLogo = fileLogo ? { imageFileLogo: fileLogo } : {};
    const logoUrl = imageLogo.url.length > 0 ? logoUrlId : '';
    const imageFileCover = fileCover ? { imageFileCover: fileCover } : {};
    const coverUrl = imageCover.url.length > 0 ? coverUrlId : '';
    const payload = {
      id: business.id,
      ...imageFileLogo, logoUrl,
      ...imageFileCover, coverUrl,
    };
    dispatch(actions.business.updateBusinessLogo(payload));
  }, [fileLogo, fileCover, dispatch, business.id, imageLogo, logoUrlId, imageCover, coverUrlId])

  const onChangeInputFileLogo = useCallback((name, file, imageFile) => {
    setImageLogo(imageFile)
    setFileLogo(file)
  }, [])

  const onChangeInputFileCover = useCallback((name, file, imageFile) => {
    setImageCover(imageFile)
    setFileCover(file)
  }, [])

  const onSuccessEdition = useCallback(() => {
    setIsLoading(false)
    onRequestClose()
  }, [onRequestClose])

  const onErrorEdtion = useCallback(() => {
    console.log(BannerError)
    showStickyBanner(BannerError)
    setIsLoading(false)
  }, [showStickyBanner])

  useSagaTakeEvery(actions.business.updateBusinessLogoSuccess, onSuccessEdition)
  useSagaTakeEvery(actions.business.updateBusinessLogoError, onErrorEdtion)

  const footerContent = useMemo(() => (
    <>
      <Button isDisabled={isLoading} onClick={onRequestClose} text={t('menuDetails:ctas.cancel')} />
      <Button isLoading={isLoading} isDisabled={isLoading} text={t( 'menuDetails:ctas.save')}  type="submit" form={formId} isPrimary={true}  />
    </>
  ), [t, onRequestClose, formId, isLoading])

  return (
    <LayoutModal
      isLoading={isLoading}
      title={t('menuDetails:logoEditionModal:title')}
      onRequestClose={onRequestClose}
      className="modal--config-logo"
      footerContent={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <h4 className="ft-600 mb-1">{t('menuDetails:logoEditionModal.inputLogo.heading')}</h4>
          <p className="ft-secondary">{t('menuDetails:logoEditionModal.inputLogo.description')}</p>
          <InputImageFile
            name="logofile"
            title={t('menuDetails:logoEditionModal.inputLogo.empty')}
            ctaText={t('menuDetails:logoEditionModal.inputLogo.cta')}
            defaultImage={imageLogo}
            handleOnChange={onChangeInputFileLogo}
            cardClassName="mb-1"
            className="mb-1 input-file-circle"
            description={`${t('menuDetails:logoEditionModal.inputLogo.conditions')}`}
            validate={{
              type: "jpg|jpeg|png",
              maxBytes: 1024 * 1024 * 10 // 1mo
            }}
          />
          <h4 className="ft-600 mb-1">{t('menuDetails:logoEditionModal.inputCover.heading')}</h4>
          <p className="ft-secondary">{t('menuDetails:logoEditionModal.inputCover.description')}</p>
          <InputImageFile
            name="coverfile"
            title={t('menuDetails:logoEditionModal.inputCover.empty')}
            ctaText={t('menuDetails:logoEditionModal.inputCover.cta')}
            defaultImage={imageCover}
            handleOnChange={onChangeInputFileCover}
            cardClassName="mb-1"
            className="mb-1"
            description={`${t('menuDetails:logoEditionModal.inputCover.conditions')}`}
            validate={{
              type: "jpg|jpeg|png",
              maxBytes: 1024 * 1024 * 10
            }}
          />
        </form>

    </LayoutModal>
  )
}

export default ModalLogoEdition
